export class KipUser {
  Subscriber:string;
  HotelId: number;
  UserId?: number;
  UserName: string;
  RealName : string;
  UserCode: string;
  UserUuId: string;
  Data?: string;
  Active: boolean;
  
}