import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { ShortTaskItem } from '@app/kip/classes/ShortTaskItem';
import { SchedulerService } from '@app/kip/services/scheduler.service';

@Component({
  selector: 'app-task-assign-supervisor-dialog',
  templateUrl: './task-assign-supervisor-dialog.component.html',
  styleUrls: ['./task-assign-supervisor-dialog.component.scss']
})
export class TaskAssignSupervisorDialogComponent implements OnInit {

  _data: ShortTaskItem;
  @ViewChild('userList') userList : MatSelectionList
  constructor(public dialogRef: MatDialogRef<TaskAssignSupervisorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ShortTaskItem,
    private schedulerService : SchedulerService
    ) { 
    this._data = data;
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(undefined);
  }
  submit() {   
   
    this._data.Supervisor =  this.userList.selectedOptions.selected[0].value;
    this.schedulerService.AssignedToChanged.emit(this._data);
    this.dialogRef.close(this._data);
  }

}
