// Angular 
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
// 3rdparty
import { Subscription } from 'rxjs';
// Hostware
import { KipUser } from '@app/kip/dto/KipUser';
import { VwTechDashboard } from '@app/kip/viewdto/vwTechDashBoard';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TechDashboardService } from '@app/kip/services/tech-dashboard.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { FormStateService } from '@app/kip/services/form-state.service';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { ApplicationPage } from '@app/core/enums/application-page';
import { RequestResult } from '@app/core/dto/RequestResult';
import { vwTaskListFilter } from '@app/kip/viewdto/vwTaskListFilter';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';


@AutoUnsubscribe()
@Component({
  selector: 'maintenance-leader-landing-page',
  templateUrl: './maintenance-leader-landing-page.component.html',
  styleUrls: ['./maintenance-leader-landing-page.component.scss']
})
export class MaintenanceLeaderLandingPageComponent implements OnInit, OnDestroy {

  loadAll$: Subscription;
  hotelUnitChanged$: Subscription;
  loadAllKipUsers$: Subscription;
  techDashboardData: VwTechDashboard;
  loaded: boolean;
  kipUsers: KipUser[] = [];
  columns: number = 1;
  constructor(
    private sessionStorageService: SessionStorageService,
    private techDashboardService: TechDashboardService,
    private router: Router,
    private translate: TranslateService,
    private topBarManagerService: TopBarManagerService,
    private formStateService: FormStateService,
    private kipUserService: KipUserService,
    private breakPointObserver: BreakpointObserver,


  ) {
    topBarManagerService.setFormId(ApplicationPage.maintenance_leader_landing_page);
  }

  //#region =============== Angular Lifecyle events ============================================================================
  
  ngOnInit(): void {
    this.init();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.init();
    })
    this.breakPointObserver.observe([Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
      ]).subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall]) {
         
          this.columns = 1;
        }
        if (state.breakpoints[Breakpoints.Small]) {
         
          this.columns = 1;
        }
        if (state.breakpoints[Breakpoints.Medium]) {
          this.columns = 2;
         
        }
        if (state.breakpoints[Breakpoints.Large]) {
          this.columns = 2;
         
        }
        if (state.breakpoints[Breakpoints.XLarge]) {
          this.columns = 2;
         
        }
  
      });
  }
  ngOnDestroy(): void {
    this.loadAll$?.unsubscribe();
    this.loadAllKipUsers$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== validation ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions =========================================================================================
  navigateToTaskList( type: MaintenanceType ) {
    this.formStateService.resetVwTaskListFilter();
    let filter: vwTaskListFilter = this.formStateService.getVwTaskListFilter();
    filter.MyTasksOnly = false;
    filter.ItemType = "1";
    filter.AssignedTo = -1;
    // filter.GroupId = type.MaintenanceGroupId;
    // this.formStateService.setVwTaskListFilter(filter);
    this.router.navigateByUrl(this.router.createUrlTree(["kip/tasklist"], { queryParams: { fromUrl: 'maintenanceleaderlandingpage' } }))

  }

  navigateToTaskListByAssignedTo(userName: string) {
    this.formStateService.resetVwTaskListFilter();
    let filter: vwTaskListFilter = this.formStateService.getVwTaskListFilter();
    filter.MyTasksOnly = false;
    filter.ItemType = "1";
    if (userName === "-1") {
      filter.AssignedTo = -1;
    } else {
      filter.AssignedTo = this.kipUsers.find(item => item.UserName == userName).UserId;
    }



    this.router.navigateByUrl(this.router.createUrlTree(["kip/tasklist"], { queryParams: { fromUrl: 'maintenanceleaderlandingpage' } }))
  }
  init() {
    this.loadAllKipUsers$ = this.kipUserService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(event => {
      this.kipUsers = event.Data as Array<KipUser>;
      this.loadAll$ = this.techDashboardService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
        var res = result as RequestResult;
        if (res) {
          this.techDashboardData = res.Data as VwTechDashboard;
          this.loaded = true;
        }
      });
    })


  }


  //#endregion =================================================================================================================


}
