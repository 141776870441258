import { Injectable } from "@angular/core";
import { RoomType } from "../dto/RoomType";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions } from "@angular/http";

@Injectable({
  providedIn: 'root'
})
export class RoomTypeService {
  constructor(public http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;

  getRoomTypeById(id: number) {
    return this.http.get(this.apiServer + '/api/RoomType/GetRoomTypeById');
  }
  getRoomType(subscriber: string) {

  }

  addRoomType(data: RoomType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/fro/RoomType/Add', data, { headers });
  }
  updateRoomType(data: RoomType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/fro/RoomType/Update', data, { headers });
  }

  deleteRoomType(ids: string) {
    return this.http.delete(this.apiServer + '/api/fro/RoomType/Delete/' + ids);
  }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/fro/roomtype/LoadAll/' + subscriber + "/" + hotelId);
  }

  loadAllMulti(subscriber: string, hotelIds: string)  {

    return this.http.get(this.apiServer + '/api/fro/roomtype/LoadAll_Multi/' + subscriber + "/" + hotelIds); 
  }
  roomTypeCodeExists(subscriber: string, hotelId: number, roomTypeCode: string) {
    return this.http.get(this.apiServer + '/api/RoomType/GetRoomTypeByRoomTypeCode/' + subscriber + "/" + hotelId + "/" + roomTypeCode);
  }
  roomTypeNameExists(subscriber: string, hotelId: number, roomTypeName: string) {
    return this.http.get(this.apiServer + '/api/RoomType/GetRoomTypeByRoomTypeName/' + subscriber + "/" + hotelId + "/" + roomTypeName);
  }


}
