// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-dialog-content {
  overflow: auto !important;
}

.mat-dialog-container {
  max-width: 50%;
  margin: auto;
}

@media (max-width: 500px) {
  .mat-dialog-container {
    max-width: 100vw;
  }
}
@media screen and (max-width: 959px) {
  .hw-vertical-divider {
    display: none;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
