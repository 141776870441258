import { Injectable } from '@angular/core';

@Injectable()
export class FroLocalizationService {

  constructor() { }

  translate(expression : string ) {
    return expression;
  }
}
