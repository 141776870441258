// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.period-start-end-label {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1em;
}

.mat-form-field-infix-1 {
  width: auto !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
