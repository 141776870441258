
// Angular
import { Component, OnInit, OnDestroy,ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { CleaningTask } from '@app/kip/dto/CleaningTask';
import { CleaningTaskService } from '@app/kip/services/cleaning-task.service';

// 3rdparty
@Component({
  selector: 'cleaning-task-editor-dialog',
  templateUrl: './cleaning-task-editor-dialog.component.html',
  styleUrls: ['./cleaning-task-editor-dialog.component.scss']
})
export class CleaningTaskEditorDialogComponent implements OnInit, OnDestroy {

  @ViewChild('localizer') localizer : DataLocalizerComponent;
  editorForm: FormGroup;
  _data: CleaningTask;
  

  constructor(public dialogRef: MatDialogRef<CleaningTaskEditorDialogComponent>,@Inject(MAT_DIALOG_DATA) private data: CleaningTask,
  private sessionStorageService: SessionStorageService,
  private rfHelperService: ReactiveFormsHelperService,
  private cleaningTaskService: CleaningTaskService,
  private translate: TranslateService,
  private fb: FormBuilder
  ) {
    this.editorForm = this.fb.group({
      TaskCode: ['', [Validators.required, Validators.maxLength(10)]],
      TaskName: ['', [Validators.required, Validators.maxLength(40)]],
      TimeLimit: [0, [Validators.required]],
      Active: true
    });
    this._data = data;
   }
    //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.rfHelperService.fillFormFromRecord(this._data,this.editorForm);
  }

  ngOnDestroy(): void {
  

  }

 

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  submit() {
    //this.rfHelperService.updateRecordFromForm(this._data,this.editorForm);
    
    this._data.SData = this.localizer.jsonData;
    this._data.Active = this.editorForm.controls["Active"].value;
    this._data.TaskCode = this.editorForm.controls["TaskCode"].value;
    this._data.TaskName = this.editorForm.controls["TaskName"].value;
    this._data.TimeLimit = this.editorForm.controls["TimeLimit"].value;
    
    this.dialogRef.close(this._data);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  title() : string {
    return this._data.TaskId === 0 ?   this.translate.instant("common.adding_new_task"):   this.translate.instant("common.change_task")
  }
  //#endregion =================================================================================================================
 
}
