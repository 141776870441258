// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions } from "@angular/http";

//HW
import { environment } from '@environments/environment';
import { PublicArea } from '../dto/PublicArea';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicAreaService {

  constructor(public http: HttpClient) { }
  loadAllObservable: Observable<any>;
  apiServer = environment.apiHost + environment.apiPort;
  getPublicAreaById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/PublicArea/GetById');
  }

  addPublicArea(data: PublicArea) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/PublicArea/Add', data, { headers });
  }

  updatePublicArea(data: PublicArea) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/PublicArea/Update', data, { headers });
  }

  deletePublicArea(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/PublicArea/Delete/' + ids);
  }


  loadAll(subscriber: string, hotelId: number) {
    return  this.http.get(this.apiServer + '/api/kip/PublicArea/LoadAll/' + subscriber + "/" + hotelId);
    // if( this.loadAllObservable) {
    //   return this.loadAllObservable;
    // }
    // else {
    //   this.loadAllObservable = this.http.get(this.apiServer + '/api/kip/PublicArea/LoadAll/' + subscriber + "/" + hotelId).pipe(share());
    //   return this.loadAllObservable;
    // }
    
  }


  publicAreaCodeExists(subscriber: string, hotelId: number, publicAreaCode: string) {
    return this.http.get(this.apiServer + '/api/kip/PublicArea/GetPublicAreaByAreaCode/' + subscriber + "/" + hotelId + "/" + publicAreaCode);
  }

  publicAreaNameExists(subscriber: string, hotelId: number, publicAreaName: string) {
    return this.http.get(this.apiServer + '/api/kip/PublicArea/GetPublicAreaByAreaName/' + subscriber + "/" + hotelId + "/" + publicAreaName);

  }




}
