// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-caption {
  background-color: #ececec;
  border-radius: 20px !important;
  padding: 10px !important;
  margin-right: 10px;
  display: inline-block !important;
  font-weight: 500;
  font-size: 12px;
}

#table-container {
  height: 400px;
  overflow: auto;
  width: 100%;
  margin-top: 1em;
}

table {
  width: 100%;
}

.panel-header {
  height: 400px;
  width: 100%;
}

.boolean-cell {
  font-size: 25px;
  color: #2196f3;
}

@media (max-width: 599px) {
  .hw-grid-cell {
    float: right;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .boolean-cell {
    font-size: 20px;
  }
  .action-buttons {
    padding-bottom: 1em;
    margin-right: 1em;
  }
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    margin-bottom: 1em;
  }
  tr.mat-row,
  tr.mat-footer-row {
    height: 48px;
    width: 100%;
  }
  .hw-mobile-table-title-show {
    text-align: left;
  }
  .hw-mobile-display-inherit {
    display: flex;
  }
}
.float-button {
  margin-bottom: -1em;
  margin-right: 4em;
}

.mat-mdc-fab .mat-icon, .mat-mdc-fab .material-icons, .mat-mdc-mini-fab .mat-icon, .mat-mdc-mini-fab .material-icons {
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
