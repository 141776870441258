// Angular LifeCycle 
import { Component, OnInit, Input } from '@angular/core';

// Hostware
import { TaskStates } from '../../../enums/TaskStates';
import { ParamKeyValue } from '../../../classes/ParamKeyValue';

@Component({
  selector: 'task-state-chips',
  templateUrl: './task-state-chips.component.html',
  styleUrls: ['./task-state-chips.component.css']
})
export class TaskStateChipsComponent implements OnInit {

  @Input() paramKeyValue: ParamKeyValue[];
  includeCreated: boolean;
  includeRunning: boolean;
  includePaused: boolean;
  includeEnded: boolean;
  includeDeleted: boolean;
  includeAccepted: boolean;
  label: string;

  constructor() { }

  ngOnInit() {
   
      this.label = this.paramKeyValue.find(item=>item.Key=="Label").Value;
      this.includeAccepted = this.paramKeyValue.find(item=>item.Key==TaskStates.Accepted).Value === 'true' ? true : false;
      this.includeCreated = this.paramKeyValue.find(item=>item.Key==TaskStates.Created).Value === 'true' ? true : false;
      this.includeRunning = this.paramKeyValue.find(item=>item.Key==TaskStates.Running).Value.toLowerCase()== 'true' ? true : false;
      this.includePaused = this.paramKeyValue.find(item=>item.Key==TaskStates.Paused).Value.toLowerCase()== 'true' ? true : false;
      this.includeEnded = this.paramKeyValue.find(item=>item.Key==TaskStates.Ended).Value.toLowerCase()== 'true' ? true : false;
      this.includeDeleted= this.paramKeyValue.find(item=>item.Key==TaskStates.Deleted).Value.toLowerCase()== 'true' ? true : false;
      
    

  }
  allSelected() {
    if( this.includeAccepted && this.includeCreated && this.includeDeleted && this.includeEnded && this.includePaused && this.includeRunning) {
      return true;
    } else {
      return false;
    }
  }
  
}
