import { Injectable, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable( {
  providedIn: 'root',
})

export class MenuService {

  @Output() moduleChanged: EventEmitter<string> = new EventEmitter();
  constructor( ) { }

  loadModuleMenu(moduleName : string ) {
    this.moduleChanged.emit(moduleName);
  }
}