// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// 3rdparty
import { Subscription } from 'rxjs';

// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { FormStateService } from '@app/kip/services/form-state.service';
import { HkDashboardService } from '@app/kip/services/hk-dashboard.service';
import { VwHkDashboard } from '@app/kip/viewdto/vwHkDashBoard';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationPage } from '@app/core/enums/application-page';
import { RequestResult } from '@app/core/dto/RequestResult';
import { vwRoomStateListFilter } from '@app/kip/viewdto/vwRoomStateListFilter';

@Component({
  selector: 'trustee-landing-page',
  templateUrl: './trustee-landing-page.component.html',
  styleUrls: ['./trustee-landing-page.component.scss']
})
export class TrusteeLandingPageComponent implements OnInit, OnDestroy {

  hkDashboardData: VwHkDashboard;
  loadAll$: Subscription;
  hotelUnitChanged$ : Subscription;
  loaded: boolean;
 
  constructor(
    private sessionStorageService: SessionStorageService,
    private hkDashboardService: HkDashboardService,
    private router: Router,
    private translate: TranslateService,
    private topBarManagerService : TopBarManagerService,
    private formStateService: FormStateService,
    private spinnerService : SpinnerService,

  ) {

    topBarManagerService.setFormId(ApplicationPage.trustee_landing_page);
   }
  
  //#region =============== Angular Lifecyle events ============================================================================
  
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.topBarManagerService.setTopbarBackgroundVisibility(true);
    this.topBarManagerService.setTopbarVisibility(true);
    this.hotelUnitChanged$=this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();

    });
    this.loadAll();
  }

  ngOnDestroy(): void {
    this.loadAll$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    this.spinnerService.setSpinnerVisibility(true);
    this.loadAll$ = this.hkDashboardService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      var res = result as RequestResult;
      if (res) {     
        this.hkDashboardData = res.Data as VwHkDashboard;
        this.hkDashboardData.Readiness_Percent = this.hkDashboardData.Readiness_Percent < 0 ? 0  : this.hkDashboardData.Readiness_Percent; 
        this.loaded = true;
        this.spinnerService.setSpinnerVisibility(false);
      }
      
      
      // this.signalR.roomStateChanged.subscribe( event=> {
      //   const newRoomState = event as PushMsgRoomState;
      //     this.notifocationService.showNotification(newRoomState.Message);
      // })
     });
  }
  navigateToTaskList() {
    this.formStateService.resetVwTaskListFilter();
    this.router.navigateByUrl(this.router.createUrlTree(["kip/tasklist"], { queryParams: {fromUrl:'trusteelandingpage'}} ))
   //    <a [routerLink]="['/kip/tasklist']" [queryParams]="{fromUrl:'trusteelandingpage'}">
  }
  navigateToRoomStateListByRoomState(state: string) {
    this.formStateService.resetVwRoomStateFilter();
    let filter : vwRoomStateListFilter = this.formStateService.getVwRoomStateListFilter();
    
    switch( state) {
      case "dirty" : {
        filter.RoomClean = false;
        filter.RoomDirty = true;
        filter.RoomOOO = false;
        filter.RoomOOS = false;
        filter.RoomOk = false;
        filter.RoomShowRoom = false;
        break;
      }
      case "clean" : {
        filter.RoomClean = true;
        filter.RoomDirty = false;
        filter.RoomOOO = false;
        filter.RoomOOS = false;
        filter.RoomOk = false;
        filter.RoomShowRoom = false;
        break;
      }
      case "checked" : {
        filter.RoomClean = false;
        filter.RoomDirty = false;
        filter.RoomOOO = false;
        filter.RoomOOS = false;
        filter.RoomOk = true;
        filter.RoomShowRoom = false;
        break;
      }
      case "ooo" : {
        filter.RoomClean = false;
        filter.RoomDirty = false;
        filter.RoomOOO =  true;
        filter.RoomOOS = false;
        filter.RoomOk = false;
        filter.RoomShowRoom = false;
        break;
      }
    }
    this.formStateService.setVwRoomStateListFilter(filter);
    this.router.navigate(['kip/roomstatelist'],{ queryParams: {placeType:"R"}})
      .then(result=> {
          console.log('result ' + result)
      }).catch(err=> {
        console.log('error ' + err)
      })
         
      
    
    //this.router.navigateByUrl(this.router.createUrlTree(["kip/roomstatelist"] , { queryParams: {placeType:"R"}} ));
  }

  navigateToRoomStateListByPlaceType(placeType: string) {
    this.formStateService.resetVwRoomStateFilter();
    this.router.navigateByUrl(this.router.createUrlTree(["kip/roomstatelist"], { queryParams: {placeType:placeType}} ))
  }
  //#endregion =================================================================================================================

  

}
