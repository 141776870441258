// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

// Hostware    
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { LostAndFoundDialogComponent } from '@app/kip/dialogs/lost-and-found-dialog/lost-and-found-dialog.component';
import { SelectItem } from '@app/core/classes/SelectItem';
import { Subscribable, Subscription } from 'rxjs';
import { LostAndFoundState } from '@app/kip/enums/LostAndFoundState';
import { LostAndFound } from '@app/kip/dto/LostAndFound';
import { LostAndFoundService } from '@app/kip/services/lost-and-found.service';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { MatDateSelectionModel } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { SpinnerService } from '@app/core/services/spinner.service';


@AutoUnsubscribe()
@Component({
  selector: 'app-lost-and-found',
  templateUrl: './lost-and-found-editor.component.html',
  styleUrls: ['./lost-and-found-editor.component.scss']
})

export class LostAndFoundEditorComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;
  loadByStatusCode$: Subscription;
  getCullingForm$: Subscription;
  getReturningForm$: Subscription;
  hotelUnitChanged$ : Subscription;
  addLostAndFound$ :Subscription;
  updateLostAndFound$ : Subscription;
  deleteLostAndFound$ : Subscription;
    
  dataSource: MatTableDataSource<LostAndFound>;
  selection  = new  SelectionModel<LostAndFound>(true,[]);

  tableColumns: string[] = ["Selection","FindObject", "Area", "FoundTime", "Finder", "Status","Action"];
  statusList: SelectItem[] = [];
  filterForm: FormGroup;

  constructor(
    private hwAlertService: HwAlertService,

    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private lostAndFoundDialog: MatDialog,
    private translate: TranslateService,
    private lostAndFoundService: LostAndFoundService,
    private crudMessageService: CrudMessagesService,
    private spinnerService : SpinnerService,
    private fb: FormBuilder
  ) { 

    const endDate = dayjs();
    const startDate = dayjs().subtract(30, 'days')
    this.filterForm = fb.group({
      'fStatusList': new FormControl(LostAndFoundState.FELVEVE),
      'fDateFrom': new FormControl(startDate),
      'fDateTo': new FormControl(endDate)
    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.statusList.push({ value: "-1", label: this.translate.instant("common.all") });
    this.statusList.push({ value: LostAndFoundState.FELVEVE, label: this.translate.instant("K926.added") });
    this.statusList.push({ value: LostAndFoundState.POSTAZVA, label: this.translate.instant("K926.posted") });
    this.statusList.push({ value: LostAndFoundState.VISSZAADVA, label:this.translate.instant("K926.returned") });
    this.statusList.push({ value: LostAndFoundState.SELEJTEZVE, label:this.translate.instant("K926.culled") });
    this.filterForm.controls['fStatusList'].patchValue(LostAndFoundState.FELVEVE);
    
    this.hotelUnitChanged$=this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.applyFilter();
    });
    this.applyFilter();
  }
  ngOnDestroy(): void {
    this.loadByStatusCode$?.unsubscribe();
    this.getCullingForm$?.unsubscribe();
    this.getReturningForm$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.addLostAndFound$?.unsubscribe();
    this.updateLostAndFound$?.unsubscribe();
    this.deleteLostAndFound$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.clear();
    this.selection.select(...this.dataSource.data);
  }
  decodeStatus(state: LostAndFoundState): string {
    switch (state) {
      case LostAndFoundState.FELVEVE:
        return this.translate.instant("K926.added");
      case LostAndFoundState.POSTAZVA:
        return this.translate.instant("K926.posted");
      case LostAndFoundState.SELEJTEZVE:
        return this.translate.instant("K926.culled");
      case LostAndFoundState.VISSZAADVA:
        return this.translate.instant("K926.returned");
    }
  }

  statusClass(state: LostAndFoundState) {
    switch (state) {
      case LostAndFoundState.FELVEVE:
        return 'added';
      case LostAndFoundState.POSTAZVA:
        return 'posted';
      case LostAndFoundState.SELEJTEZVE:
        return 'culled';
      case LostAndFoundState.VISSZAADVA:
        return 'returned'
    }
  }

  isNotAdded(item: LostAndFound) {
    return item.Status !== LostAndFoundState.FELVEVE ? false : true;
  }

  openDialog(action : string, data?: LostAndFound) {
    let lostAndFound : LostAndFound;
    if (action == OpenDialogAction.ADD) {
      lostAndFound = new LostAndFound();
      lostAndFound.HotelId = this.sessionStorageService.getSelectedHotelId();
      lostAndFound.Subscriber = this.sessionStorageService.getSelectedUnit();
      lostAndFound.FoundTime = dayjs().format("YYYY.MM.DD");
      lostAndFound.LastChange = dayjs().format("YYYY.MM.DD");
      lostAndFound.Status = LostAndFoundState.FELVEVE;
    } else {
      lostAndFound = data;
    }
    const lostAndFoundDialogDef = this.lostAndFoundDialog.open(LostAndFoundDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "50vw",
      panelClass: "lost-and-found-dialog",
      data: lostAndFound
    })

    lostAndFoundDialogDef.afterClosed().subscribe( data=> {
      this.selection.clear();
      if(data) {
        const processableItem = data as LostAndFound;
        if(processableItem.Id) {
          this.updateLostAndFound$ = this.lostAndFoundService.update(processableItem).subscribe( result=> {
            this.crudMessageService.showAddOrEditMessage(result as RequestResult);
            this.applyFilter();
          })
        } else {
          this.addLostAndFound$ = this.lostAndFoundService.add(processableItem).subscribe( result=> {
            this.crudMessageService.showAddOrEditMessage(result as RequestResult);
            this.applyFilter();
          })
        }
      }
    })
  }
  deleteRow(item: LostAndFound) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteLostAndFound$ = this.lostAndFoundService.delete(item.Id.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.applyFilter();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
          } 
         })
      }
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  applyFilter() {
    const fDateFrom = dayjs(this.filterForm.controls["fDateFrom"].value).format("YYYY.MM.DD");
    const fDateTo = dayjs(this.filterForm.controls["fDateTo"].value).format("YYYY.MM.DD");
    this.loadByStatusCode$ = this.lostAndFoundService.loadByStatusCodeAndDate(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId(),
      this.filterForm.controls["fStatusList"].value as LostAndFoundState, fDateFrom, fDateTo).subscribe(result => {
        const res = result as RequestResult;
        if (res) {
          this.dataSource = new MatTableDataSource(res.Data as Array<LostAndFound>);
        }
      })
  }

  cullingList() {
    if(this.selection.selected.length == 0) {
      return;
    }
    this.spinnerService.setSpinnerVisibility(true);
    const fileName = "Selejtezési jegyzőkönyv.pdf";
    this.getCullingForm$ = this.lostAndFoundService.getCullingForm(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),this.getIds()).subscribe( fileData=> {
      let b: any = new Blob([fileData], { type: 'application/-octet-stream' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      this.spinnerService.setSpinnerVisibility(false);
      saveAs(b, fileName),
        error => {
          console.log(error);
          this.spinnerService.setSpinnerVisibility(false);
         
        };
    })
  }
  receiptForm() {
    if(this.selection.selected.length == 0) {
      return;
    }
    this.spinnerService.setSpinnerVisibility(true);
    const fileName = "Talált tárgy átadási jegyzőkönyv.pdf";
    this.lostAndFoundService.getReturningForm(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),this.getIds()).subscribe(fileData=>{
      let b: any = new Blob([fileData], { type: 'application/-octet-stream' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      this.spinnerService.setSpinnerVisibility(false);

      saveAs(b, fileName),
        error => {
          console.log(error);
          this.spinnerService.setSpinnerVisibility(false);
        };
    })
  }



  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  getIds() : string{
    let selectedElements =  this.selection.selected as Array<LostAndFound>;
    let valuesString = "";
    selectedElements.forEach(item=> {
      valuesString = valuesString + item.Id.toString() + ",";
    });
    valuesString = valuesString.substring(0,valuesString.length-1);
    return valuesString;
  }

  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================




}
