// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.added {
  color: red;
  font-weight: 600;
}

.posted {
  color: green;
  font-weight: 600;
}

.returned {
  color: #00e500;
  font-weight: 600;
}

.culled {
  color: burlywood;
  font-weight: 600;
}

@media (max-width: 599px) {
  .hw-grid-cell {
    float: right;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .hw-mobile-display-inherit {
    display: flex;
  }
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    margin-bottom: 0.5em;
  }
  .boolean-cell {
    font-size: 20px;
  }
  .action-buttons {
    width: 100%;
    float: right;
    text-align: right;
    margin-top: 0.25em;
    padding-bottom: 1em !important;
  }
  tr.mat-row, tr.mat-footer-row {
    height: 48px;
    width: 100%;
  }
  .hw-mobile-table-title-show {
    text-align: left;
  }
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 0px;
    border-bottom-width: 0px;
    vertical-align: top;
  }
  tr.mat-header-row {
    display: none;
  }
  .hw-mobile-width-90 {
    width: 100%;
  }
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0px;
  }
  .hw-margin-right-1em-important {
    margin-right: 0em !important;
  }
}
@media screen and (max-width: 599px) {
  #editor-frame {
    margin: 0;
    background-color: white;
    margin-bottom: 5em;
  }
  #result {
    padding-inline-start: 2px;
    background-color: white;
    padding: 0.5em 0em 0em 0em;
  }
  .result-table {
    padding: 0.5em 0em 0em 0.5em;
    margin-left: 0em;
    margin-right: 0em;
  }
  .float-box {
    float: right;
  }
  .scroll-top-float-button, .scroll-top-float-button:hover {
    right: 6em;
    margin-bottom: 1em;
  }
  .command-button {
    margin-bottom: 1em;
  }
  .hw-container-background {
    margin-bottom: 5em;
  }
}
.mat-mdc-table .mat-mdc-header-cell {
  z-index: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
