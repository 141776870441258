// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.mat-expansion-panel-spacing {
  margin: 0 0;
}

.filter-caption {
  background-color: #ececec;
  border-radius: 20px !important;
  padding: 10px !important;
  margin-right: 10px;
  display: inline-block !important;
  font-weight: 500;
  font-size: 12px;
}

#table-container {
  height: 400px;
  overflow: auto;
  width: 100%;
}

table {
  width: 100%;
}

.mat-row.hovered {
  background: #eee;
}

.mat-row.highlighted {
  background: rgb(209, 209, 214);
}

.panel-header {
  height: 400px;
  width: 100%;
}

.action-buttons {
  width: 100%;
  float: right;
  text-align: right;
  margin-top: 0.25em;
  padding-bottom: 0.25em;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 0px !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white;
}

.boolean-cell {
  font-size: 25px;
  color: #2196f3;
}

@media (max-width: 599px) {
  .hw-grid-cell {
    float: right;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .boolean-cell {
    font-size: 20px;
  }
  .action-buttons {
    padding-bottom: 1em;
    margin-right: 1em;
  }
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    margin-bottom: 1em;
  }
  tr.mat-row, tr.mat-footer-row {
    height: 48px;
    width: 100%;
  }
  .hw-mobile-table-title-show {
    text-align: left;
  }
}
.hw-text-align-center {
  text-align: center !important;
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: auto;
}

@media (max-width: 599px) {
  .hw-mobile-display-inherit {
    display: flex;
  }
  .hw-mobile-width-90 {
    width: 100%;
  }
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0px;
    margin-bottom: 3em;
  }
}
.cleaning-type-editor-dialog {
  overflow-y: hidden !important;
  overflow: auto !important;
}

@media (max-width: 599px) {
  .cleaning-type-editor-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
