// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: flex-end;
}

.dialog-title {
  color: #03a9f4;
  font-family: "Roboto";
  font-size: 18px;
}

.mat-optgroup-label {
  font-weight: 800;
}

.hw-mat-caption {
  color: rgba(0, 0, 0, 0.54);
  font-family: "Roboto";
  letter-spacing: normal;
  font-weight: 400;
  font-size: 13px;
}

.task-wrapper {
  border-left: 3px solid #03a9f4;
  padding-left: 1.5em !important;
  margin: top 0;
  margin-bottom: 0;
  font-size: 13px;
  font-family: "Roboto";
}

@media (max-width: 455px) {
  .tab-title {
    display: none;
  }
}
.mat-tab-label {
  opacity: 1;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #03a9f4;
}

.mat-ink-bar {
  height: 5px;
}

/* Styles for tab labels */
.mat-tab-label {
  background-color: transparent;
  color: #03a9f4;
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
  background-color: #03a9f4 !important;
  color: white !important;
  font-weight: 700;
}

.numeric-field {
  font-family: "Roboto";
  font-size: 20px;
  width: 40px;
}

.input-field {
  width: 40px;
}

.time-wrapper {
  margin-top: 20px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-left: 16px !important;
  padding-right: 16px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
