import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HwConfirmationDialogData } from './hw-confirmation-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HwConfirmationDialogComponent} from './hw-confirmation-dialog.component'
import { take, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HwConfirmationDialogService {

  constructor(private dialog: MatDialog) { }

  dialogRef: MatDialogRef<HwConfirmationDialogComponent>
  public open(options: HwConfirmationDialogData) {
    this.dialogRef = this.dialog.open(HwConfirmationDialogComponent, {
      data: options,
      disableClose: false,
      hasBackdrop:true
    })
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
  ));

  }
}
