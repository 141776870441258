// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minibar-room-number {
  text-align: center;
  font-size: 30px;
}

.remove-item-button {
  color: #ff5151;
  font-size: 4vh;
}

.add-item-button {
  color: #369a36;
  font-size: 4vh;
}

th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
  padding-right: 0px;
}

.minibar-quantity {
  text-align: end;
  font-weight: 600;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
