import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RсDashboardService {

  constructor(private http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/DailyTask/GetRcDashboard/' + subscriber + "/" + hotelId);
  }
}
