import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CleaningType } from "../dto/CleaningType";
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CleaningTypeService {

  constructor(public http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;
  loadAllObservable: Observable<any>;
  loadAllOrderbyGrouIdAndTypeNameObservable: Observable<any>;

  getCleaningTypeTypeById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/GetById/' + id);
  }

  add(data: CleaningType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningType/Add', data, { headers });
  }
  update(data: CleaningType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningType/Update', data, { headers });
  }

  delete(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/CleaningType/Delete/' + ids);
  }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/LoadAll/' + subscriber + "/" + hotelId);
   
  }

  loadAllMulti( subscriber: string, hotelids : string) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/LoadAll_Multi/' + subscriber + "/" + hotelids);
  }

  loadAllOrderbyGrouIdAndTypeName( subscriber: string, hotelId: number) {

    return this.http.get(this.apiServer + '/api/kip/CleaningType/LoadAllOrderbyGrouIdAndTypeName/'  + subscriber + "/" + hotelId);
    // if ( this.loadAllOrderbyGrouIdAndTypeNameObservable) {
    //   return this.loadAllOrderbyGrouIdAndTypeNameObservable;
    // }
    // else {
    //   this.loadAllOrderbyGrouIdAndTypeNameObservable = this.http.get(this.apiServer + '/api/kip/CleaningType/LoadAllOrderbyGrouIdAndTypeName/'
    //        + subscriber + "/" + hotelId).pipe(share());
    //        return this.loadAllOrderbyGrouIdAndTypeNameObservable;
    // }
    
  }
  cleaningTypeCodeExists(subscriber: string, hotelId: number, cleaningTypeCode: string) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + cleaningTypeCode);
  }
  cleaningTypeNameExists(subscriber: string, hotelId: number, cleaningTypeName: string) {
    return this.http.get(this.apiServer + '/api/RoomType/GetByTypeName/' + subscriber + "/" + hotelId + "/" + cleaningTypeName);
  }
  loadByTypeCode(subscriber: string, hotelId: number, TypeCode: string) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/LoadTypeByCode/' + subscriber + "/" + hotelId + "/" + TypeCode);
  }

  loadByGroupId(groupId: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningType/LoadTypeByGroupId/' + groupId)  
  }
  // getRoomTypeByRoomTypeCode(subscriber : string,hotelId:number,cleaningTypeCode:string) {
  //   return this.http.get(this.apiServer + '/api/CleaningType/GetRoomTypeByRoomTypeCode/' + subscriber + "/" + hotelId + "/" + cleaningTypeCode); 
  // }

  // addCleaningType( data: CleaningType) {
  //   const headers = new HttpHeaders().set("Content-Type", "application/json");
  //   return this.http.put(this.apiServer + '/api/CleaningType/AddCleaningType',data,{headers});
  // }

}
