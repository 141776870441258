// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-expansion-panel-spacing {
  margin: 0 0;
}

.mat-row.hovered {
  background: #eee;
}

.mat-row.highlighted {
  background: rgb(209, 209, 214);
}

/* Oszlop 1 */
.room-state-icon {
  font-size: 30px;
  color: white;
}

.room-type-label {
  font-weight: 500;
  font-size: 18px;
}

.room-number-trash {
  font-weight: bold;
  font-size: 24px;
}

.room-number {
  font-size: 20px;
  font-weight: 600;
  color: white;
  font-family: "Roboto";
  margin-top: 0.3em;
}

.dirty-icon-color {
  color: #ffafbc !important;
}

.checked-icon-color {
  color: #ccdacd !important;
}

.settings-icon-color {
  color: #adadad !important;
}

.alert-icon-color {
  color: #f9d7ff !important;
}

.show-room-icon-color {
  color: #ffeeba !important;
}

.clean-icon-color {
  color: #9de1ff !important;
}

.family-members {
  font-weight: 500;
  margin: auto;
  font-size: 18px;
}

.wrench-mobile {
  margin: auto;
}

.status-icons {
  margin: auto;
}

.mat-row .mat-cell {
  cursor: pointer;
}

.mat-row:hover .mat-cell {
  background-color: #e2e2e2;
}

@media (max-width: 1260px) {
  .hw-grid-cell {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 300;
  }
  .family-members {
    font-weight: 500;
    font-size: 15px;
  }
  .room-number {
    font-size: 15px;
    font-weight: 500;
    margin-top: 0.5em;
  }
  .room-state-icon {
    font-size: 25px;
  }
  .wrench-mobile {
    font-size: 15px !important;
    margin: auto;
  }
  .broom-mobile {
    font-size: 15px !important;
  }
  .status-icon-mobile {
    font-size: 15px !important;
  }
  .maintenance-task-number {
    font-size: 15px !important;
  }
  .cleaning-task-number {
    font-size: 15px !important;
  }
}
.room-state-list-divider {
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.hw-grid-cell {
  font-size: 13px;
  /* font-family: "Roboto"; */
  font-weight: 400;
  margin: auto;
}

.public-area-row {
  border: 1px solid #e9e9e9;
}

.room-type-label {
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.room-number {
  font-weight: 500;
  font-family: "Roboto";
  color: white;
}

.area-data-label {
  padding: 10px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .room-state-icon {
    text-align: center;
  }
  .room-type-label {
    text-align: center;
  }
  .hw-padding-10px {
    padding: 5px;
  }
  .area-code {
    padding-top: 0em;
  }
  .hw-container-background {
    margin-bottom: 7em;
  }
}
.area-code {
  padding-top: 0.7em;
}

.public-area-label {
  font-size: 13px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}

.scroll-top-float-button {
  margin-bottom: 2em;
  margin-right: 0em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
