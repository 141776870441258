import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'label-chips',
  templateUrl: './label-chips.component.html',
  styleUrls: ['./label-chips.component.css']
})
export class LabelChipsComponent implements OnInit {
  @Input() label: string;
  constructor() { }

  ngOnInit() {
  }

}
