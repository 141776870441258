import { CleaningTask } from "./CleaningTask";

export class CleaningType {
  TypeId : number;
  Subscriber : string;
  HotelId: number;
  TypeCode : string;
  TypeName : string;
  Active : boolean;
  Tasks : Array<CleaningTask>
  Weighting : boolean;
  CleaningGroupId: number;
  SData: string;
  IsRoomClean: boolean;
  AutoAccepted : boolean;
}
