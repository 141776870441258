import { EventEmitter, Injectable, Output } from "@angular/core";
import { UserInfo } from "../dto/UserInfo";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  @Output() LoginProcessFinished: EventEmitter<UserInfo> = new EventEmitter<UserInfo>()

  notifyLoginProcessFinished(userInf : UserInfo) {
    this.LoginProcessFinished.emit(userInf);
  }

}