// Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { ExtraTaskBag } from '@app/kip/classes/ExtraTaskEditorBag'
import { UserService } from '@app/core/services/user.service';
import { RoleType } from '@app/shared/enums/roles';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ExtraTask } from '@app/kip/dto/ExtraTask';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import HwUtils from '@app/shared/helpers/hwutils';


@AutoUnsubscribe()
@Component({
  selector: 'extra-task-editor-dialog',
  templateUrl: './extra-task-editor-dialog.component.html',
  styleUrls: ['./extra-task-editor-dialog.component.scss']
})
export class ExtraTaskEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('localizer') localizer: DataLocalizerComponent;
  @ViewChild('frequencyUnit', { static: true }) frequencyUnit: MatSelect

  editorForm: FormGroup;
  editorType: string;
  loaded: boolean;
  _data: ExtraTaskBag;
  minUnit: number = 1;
  maxUnit: number = 366;
  minTimeUnit: number = 0
  maxTimeUnit: number = 0
  transitionArray = ['DEP_TO_ARR', 'EMPTY_TO_ARR', 'DEP_TO_EMPTY', 'OCCUPIED', 'DEP_TO_OOO', 'OOO_TO_ARR',
    'OOO_TO_OOO', 'OOO_TO_EMPTY', 'EMPTY_TO_OOO', 'DAY_USE', 'OCCUPIED_BED']
  constructor(
    public dialogRef: MatDialogRef<ExtraTaskEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ExtraTaskBag,
    private fb: FormBuilder,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private sessionStorageService: SessionStorageService

  ) {

    this._data = data;
    if (this.userService.hasRole(RoleType.MUSZAKI_VEZETO)) {
      this.editorType = "M"
    } else {
      this.editorType = "C"
    }
    this.editorForm = fb.group({
      'RuleName': new FormControl('', Validators.required),
      'DateFrom': new FormControl(''),
      'DateTo': new FormControl(''),
      // 'TimeFrom': new FormControl(0),
      // 'TimeTo': new FormControl(0),
      'TimeHoursFrom': new FormControl(0),
      'TimeMinutesFrom': new FormControl(0),
      'TimeHoursTo': new FormControl(0),
      'TimeMinutesTo': new FormControl(0),
      'Active': new FormControl(true),
      'Frequency': new FormControl(1),
      'FrequencyUnit': new FormControl('N'),
      'GenerationMethod': new FormControl('M'),
      'RoomTypes': new FormControl([]),
      'TypeIds': new FormControl(""),
      'PublicAreas': new FormControl([]),
      'RoomNumbers': new FormControl([]),
      'DEP_TO_ARR': new FormControl(false),
      'EMPTY_TO_ARR': new FormControl(false),
      'DEP_TO_EMPTY': new FormControl(false),
      'OCCUPIED': new FormControl(false),
      'DEP_TO_OOO': new FormControl(false),
      'OOO_TO_ARR': new FormControl(false),
      'OOO_TO_OOO': new FormControl(false),
      'OOO_TO_EMPTY': new FormControl(false),
      'EMPTY_TO_OOO': new FormControl(false),
      'DAY_USE': new FormControl(false),
      'OCCUPIED_BED': new FormControl(false),
      'RoomTypesAll' : new FormControl(false),
      'PublicAreasAll' : new FormControl(false),
      'RoomNumbersAll' : new FormControl(false)
    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.loaded = true
   
    if (this.editorType == "C") {
      this._data.cleaningTypes = this._data.cleaningTypes.filter(item=> item.TypeId !== -1);
      this.editorForm.controls["TypeIds"].patchValue(this._data.cleaningTypes[0].TypeId)
    } else {
      this._data.maintenanceTypes = this._data.maintenanceTypes.filter(item=> item.TypeId !== -1);
      this.editorForm.controls["TypeIds"].patchValue(this._data.maintenanceTypes[0].TypeId)
    }

    // 
    if (this._data.extraTask.TaskId !== -1) {
      // csv mezőkből tömb létrehozása és conrolok beállítása
      this.editorForm.controls['RuleName'].patchValue(this._data.extraTask.RuleName),
      this.editorForm.controls['DateFrom'].patchValue(this._data.extraTask.DateFrom);
      this.editorForm.controls['DateTo'].patchValue(this._data.extraTask.DateTo);
      this.editorForm.controls['Active'].patchValue(this._data.extraTask.Active);
      // this.editorForm.controls['TimeFrom'].patchValue(this._data.extraTask.TimeFrom);
      // this.editorForm.controls['TimeTo'].patchValue(this._data.extraTask.TimeTo);
      this.editorForm.controls['Active'].patchValue(this._data.extraTask.Active);
      this.editorForm.controls['Frequency'].patchValue(this._data.extraTask.Frequency);
      this.editorForm.controls['FrequencyUnit'].patchValue(this._data.extraTask.FrequencyUnit);
      this.editorForm.controls['GenerationMethod'].patchValue(this._data.extraTask.GenerationMethod);
      this.editorForm.controls['TypeIds'].patchValue(parseInt(this._data.extraTask.TypeIds));
      if( this._data.extraTask.TimeFrom > 0) {
        this.editorForm.controls["TimeHoursFrom"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeFrom).hours)
        this.editorForm.controls["TimeMinutesFrom"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeFrom).minutes)
      }
      if( this._data.extraTask.TimeTo > 0) {
        this.editorForm.controls["TimeHoursTo"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeTo).hours)
        this.editorForm.controls["TimeMinutesTo"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeTo).minutes)
      }
      
      //this.editorForm.controls['TimeFrom'].patchValue(this._data.extraTask.TimeFrom);
      // this.editorForm.controls['TimeFrom'].patchValue(this._data.extraTask.TimeTo);
      if (this._data.extraTask.RoomTypes == "ALL") {
        this.selectAllRoomTypes()
      } else {
        this.editorForm.controls['RoomTypes'].patchValue(this._data.extraTask.RoomTypes == "" ? [] : this._data.extraTask.RoomTypes.split(","));
      }
      if (this._data.extraTask.PublicAreas == "ALL") {
        this.editorForm.controls["PublicAreasAll"].patchValue(true)
        this.selectAllPublicAreas()
      
      } else {
        this.editorForm.controls['PublicAreas'].patchValue(this._data.extraTask.PublicAreas == "" ? [] : this._data.extraTask.PublicAreas.split(","));
      }
      if (this._data.extraTask.RoomNumbers == "ALL") {
        this.editorForm.controls["RoomNumbersAll"].patchValue(true)
        this.selectAllRoomNumbers();
      } else {
        this.editorForm.controls['RoomNumbers'].patchValue(this._data.extraTask.RoomNumbers == "" ? [] : this._data.extraTask.RoomNumbers.split(","));
      }
      if (this._data.extraTask.RoomStateTransitions) {
        const transit = this._data.extraTask.RoomStateTransitions.split(",");
        this.transitionArray.forEach(trans => {
          if (transit.find(item => item == trans)) {
            this.editorForm.controls[trans].patchValue(true)
          }
        })
      }
    }
  }

  ngOnDestroy(): void {

  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  frequencyUnitChanged(event: MatSelectChange) {
    switch (event.value) {
      case ("N"):
        this.minUnit = 1;
        this.maxUnit = 366;
        break;
      case ("O"):
        this.minUnit = 1;
        this.maxUnit = 24;
        break;
      case ("P"):
        this.minUnit = 1;
        this.maxUnit = 60;
        break;
    }
    this.cd.detectChanges();
    //frequencyUnit
  }

  RoomTypesAllToggle( event: MatCheckboxChange) {
    if(event.checked) {
     this.selectAllRoomTypes();
    }
    else {
      this.editorForm.controls["RoomTypes"].patchValue([])        
    }
  }
  RoomNumbersAllToggle( event: MatCheckboxChange) {
    if(event.checked) {
      this.selectAllRoomNumbers();
    } else {
      this.editorForm.controls["RoomNumbers"].patchValue([])  
    }
  }

  PublicAreasAllToggle( event: MatCheckboxChange) {
    if(event.checked) {
      this.selectAllPublicAreas();
    } else {
      this.editorForm.controls["PublicAreas"].patchValue([])
    }
  
  }

  roomNumbersChanged(event) {
    if( (event.value as Array<string>).length !== this._data.rooms.length) {
      this.editorForm.controls["RoomNumbersAll"].patchValue(false)
    } else {
      this.editorForm.controls["RoomNumbersAll"].patchValue(true)
    } 
  }

  roomTypesChanged(event) {
    if( (event.value as Array<string>).length !== this._data.roomTypes.length) {
      this.editorForm.controls["RoomTypesAll"].patchValue(false)
    } else {
      this.editorForm.controls["RoomTypesAll"].patchValue(true)
    } 
  }

  publicAreasChanged(event) {
    if( (event.value as Array<string>).length !== this._data.publicAreas.length) {
      this.editorForm.controls["PublicAreasAll"].patchValue(false)
    } else {
      this.editorForm.controls["PublicAreasAll"].patchValue(true)
    } 
  }
  transitionChanged(event: MatCheckboxChange) {


  }

  refreshFrequencyValues(frequencyUnit: string) {

  }
  submit() {
    let ret: ExtraTask = new ExtraTask();
    ret.TaskId = this._data.extraTask.TaskId;
    ret.Subscriber = this.sessionStorageService.getSelectedUnit();
    ret.HotelId = this.sessionStorageService.getSelectedHotelId();
    ret.Active = this.editorForm.controls['Active'].value;
    ret.RuleName = this.editorForm.controls['RuleName'].value,
      ret.DateFrom = this.editorForm.controls['DateFrom'].value;
    ret.DateTo = this.editorForm.controls['DateTo'].value;
    // ret.TimeFrom = this.editorForm.controls['TimeFrom'].value;
    // ret.TimeTo = this.editorForm.controls['TimeTo'].value;
    ret.Frequency = this.editorForm.controls['Frequency'].value;
    ret.FrequencyUnit = this.editorForm.controls['FrequencyUnit'].value;
    if ((this.editorForm.controls['RoomTypes'].value as string[]).length == this._data.roomTypes.length) {
      ret.RoomTypes = "ALL";
    } else {
      ret.RoomTypes = ((this.editorForm.controls['RoomTypes'].value as string[]).length > 0 ? (this.editorForm.controls['RoomTypes'].value as string[]).join(",") : "")
    }
    ret.TaskType = this.editorType;
    ret.GenerationMethod = this.editorForm.controls['GenerationMethod'].value;
    ret.TypeIds = this.editorForm.controls['TypeIds'].value;
    if ((this.editorForm.controls['PublicAreas'].value as string[]).length == this._data.publicAreas.length) {
      ret.PublicAreas = "ALL";
    } else {
      ret.PublicAreas = ((this.editorForm.controls['PublicAreas'].value as string[]).length > 0 ? (this.editorForm.controls['PublicAreas'].value as string[]).join(",") : "")
    }
    if ((this.editorForm.controls['RoomNumbers'].value as string[]).length == this._data.rooms.length) {
      ret.RoomNumbers = "ALL";
    } else {
      ret.RoomNumbers = ((this.editorForm.controls['RoomNumbers'].value as string[]).length > 0 ? (this.editorForm.controls['RoomNumbers'].value as string[]).join(",") : "")
    }

    let transArray: string[] = [];
    this.transitionArray.forEach(trans => {
      if (this.editorForm.controls[trans].value == true) {
        transArray.push(trans)
      }
    })
    ret.RoomStateTransitions = transArray.join(",");;
    ret.Transitions = ret.RoomStateTransitions;

    // idő visszaalakítása
    ret.TimeFrom = parseInt(this.editorForm.controls["TimeHoursFrom"].value)*60 +  parseInt(this.editorForm.controls["TimeMinutesFrom"].value)
    ret.TimeTo = parseInt(this.editorForm.controls["TimeHoursTo"].value)*60 +  parseInt(this.editorForm.controls["TimeMinutesTo"].value)

    // if( this._data.extraTask.TimeFrom > 0) {
    //   this.editorForm.controls["TimeHoursFrom"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeFrom).hours)
    //   this.editorForm.controls["TimeMinutesFrom"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeFrom).minutes)
    // }
    // if( this._data.extraTask.TimeTo > 0) {
    //   this.editorForm.controls["TimeHoursTo"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeTo).hours)
    //   this.editorForm.controls["TimeMinutesTo"].patchValue(HwUtils.splitTime(this._data.extraTask.TimeTo).minutes)
    // }
    
    ret.SData = this.localizer.jsonData;
    this.dialogRef.close(ret)
  }
  cancel() {
    this.dialogRef.close(undefined)
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  selectAllRoomTypes() {
    let idArr: string[] = [];
    this._data.roomTypes.forEach(item => {
      idArr.push(item.RoomType)
    });
    this.editorForm.controls['RoomTypes'].patchValue(idArr)
    this.editorForm.controls["RoomTypesAll"].patchValue(true)
  }
  selectAllPublicAreas() {
    let idArr: string[] = [];
    this._data.publicAreas.forEach(item => {
      idArr.push(item.PublicAreaId.toString())
    });
    this.editorForm.controls['PublicAreas'].patchValue(idArr)
    this.editorForm.controls["PublicAreasAll"].patchValue(true)
  }
  selectAllRoomNumbers() {
    let idArr: string[] = [];
    this._data.rooms.forEach(item => {
      idArr.push(item.RoomId.toString())
    });
    this.editorForm.controls['RoomNumbers'].patchValue(idArr)
    this.editorForm.controls["RoomNumbersAll"].patchValue(true)
  }

  enableDisableControls() {

  }
  //#endregion =================================================================================================================
}
