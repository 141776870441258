// Angular

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';

// 3rdparty

import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// Hostware
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { SelectItem } from '@app/core/classes/SelectItem';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { RepeatingTasksService } from '@app/kip/services/repeating-tasks.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { RoomService } from '@app/kip/services/room.service';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { Room } from '@app/kip/dto/Room';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { AreaItem, RepeatingTask } from '@app/kip/dto/RepeatingTasks';
import { DynamicControlDescriptor } from '@app/kip/classes/DynamicControlDescriptor';
import { ChipsTypes } from '@app/kip/enums/ChipsTypes';
import { MatTableDataSource } from '@angular/material/table';


import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { AreaWrapper } from '@app/kip/dto/AreaWrapper';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { MatDialog } from '@angular/material/dialog';
import { RepeatingTasksEditorDialogComponent } from '@app/kip/dialogs/repeating-tasks-editor-dialog/repeating-tasks-editor-dialog.component';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
@AutoUnsubscribe()
@Component({
  selector: 'repeating-tasks-editor',
  templateUrl: './repeating-tasks-editor.component.html',
  styleUrls: ['./repeating-tasks-editor.component.scss']
})



export class RepeatingTasksEditorComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;

  loadFroDatum$: Subscription;
  addRepeatingTask$ : Subscription;
  updateRepeatingTask$ : Subscription;
  deleteRepeatingTask$ : Subscription;
  hotelUnitChanged$ : Subscription;

  destroy$: Subject<boolean> = new Subject<boolean>();
  filterForm: FormGroup;
  tableColumns: string[] = [];
  itemTypes: SelectItem[] = [];
  froDatum: string;
  rooms: Room[] = [];
  publicAreas: PublicArea[] = [];
  maintenanceTypes: MaintenanceType[] = [];
  repeatingTasks: RepeatingTask[] = [];
  editorList: RepeatingTask[] = [];
  dynamicControlDescriptor: DynamicControlDescriptor[] = [];
  tmpDynamicControlDescriptor: DynamicControlDescriptor[] = [];
  dataSource: MatTableDataSource<RepeatingTask>;
  loaded: boolean;
  constructor(
    private hwAlertService: HwAlertService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private roomService: RoomService,
    private publicAreaService: PublicAreaService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private  repeatingTaskDialog: MatDialog,
    private hokParamsService: HokParamsService,
    private repeatingTasksService: RepeatingTasksService,
    private maintenanceTypeService: MaintenanceTypeService,
    public styleHelper: StyleHelperService

  ) {
    this.filterForm = fb.group({
      'fAreaType': new FormControl('R'),
      'fDateFrom': new FormControl(''),
      'fDateTo': new FormControl(''),
      'fTaskTypeId': new FormControl('-1'),
      'fAreaId': new FormControl('-1')
    })
    this.itemTypes = [
      { label: this.translate.instant("common.rooms"), value: "R" },
      { label: this.translate.instant("common.public_areas"), value: "P" }

    ]
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.tableColumns = ['AreaType', 'StartDate', 'RepeatDay', 'ShowBefore', 'Areas', 'Action']
    
    this.loadAll();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=>{
      this.loadAll();
    })
  }
  ngOnDestroy(): void {
    this.loadFroDatum$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
    this.addRepeatingTask$?.unsubscribe();
    this.deleteRepeatingTask$?.unsubscribe();
    this.updateRepeatingTask$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
      .subscribe(result => {
        const hokpResult = result as RequestResult;
        this.froDatum = hokpResult.Data;
        combineLatest({
          repeatingTasks: this.repeatingTasksService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), 'M'),
          maintenanceTypes: this.maintenanceTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
          publicAreas: this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
          rooms: this.roomService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this.froDatum)
        }).pipe(takeUntil(this.destroy$)).subscribe(value => {
          this.rooms = (value.rooms as RequestResult).Data as Array<Room>;
          this.rooms.unshift({
            HotelId: 0, Floor: '',
            RoomId: -1,
            Subscriber: '',
            RoomNumber: this.translate.instant('common.all'),
            RoomState: 0,
            RoomType: '',
            NumberOfBed: 0,
            NumberOfAddBed: 0,
            Remark: '',
            RoomTypeName: ''
          })

          this.publicAreas = (value.publicAreas as RequestResult).Data as Array<PublicArea>;
          this.maintenanceTypes = (value.maintenanceTypes as RequestResult).Data as Array<MaintenanceType>;
          this.maintenanceTypes.unshift({
            Active: true,
            TypeId: -1,
            Subscriber: '',
            HotelId: 0,
            TypeCode: '',
            TypeName: this.translate.instant('common.all'),
            MaintenanceGroupId: 0,
            Tasks: [],
            SData: ''
          })

          this.repeatingTasks = (value.repeatingTasks as RequestResult).Data as Array<RepeatingTask>;
          this.dataSource = new MatTableDataSource(this.repeatingTasks)
        })
        this.loaded = true;
        this.filterForm.controls["fTaskTypeId"].setValue("-1");
        this.filterForm.controls["fAreaId"].setValue("-1");
        this.applyFilter()
      })

  }
  applyFilter() {
    let desc: DynamicControlDescriptor = new DynamicControlDescriptor();
    let mustInclude: boolean;
    let tmpTasks: RepeatingTask[] = [];
    this.repeatingTasks.forEach(item => {
      mustInclude = true;

      if (!(this.filterForm.controls["fDateFrom"].value === '' && this.filterForm.controls["fDateTo"].value == '')) {
        mustInclude = true;
      }

      if (this.filterForm.controls["fAreaType"].value == item.AreaType && mustInclude) {
        if (this.filterForm.controls["fAreaId"].value != "-1") {

          const are: AreaItem[] = JSON.parse(item.Areas) as Array<AreaItem>;
          const foundArea: AreaItem = are.find(aItem => aItem.AreaId === this.filterForm.controls["fAreaId"].value);
          if (!foundArea) {
            mustInclude = false;
          }
        }
      } else {
        mustInclude = false;
      }

      if (this.filterForm.controls["fTaskTypeId"].value != "-1" && mustInclude) {
        if (item.TaskTypeId !== parseInt(this.filterForm.controls["fTaskTypeId"].value)) {
          mustInclude = false;
        }
      }

      if (this.filterForm.controls["fDateFrom"].value !== '' && this.filterForm.controls["fDateTo"].value !== '' && mustInclude) {
        const dateFrom = dayjs(this.filterForm.controls["fDateFrom"].value);
        const dateTo = dayjs(this.filterForm.controls["fDateTo"].value);
        const start = dayjs(item.StartDate);
        if (!start.isBetween(dateFrom, dateTo)) {
          mustInclude = false;
        }
      }

      if (mustInclude) {
        tmpTasks.push(item);
      }
      this.editorList = JSON.parse(JSON.stringify(tmpTasks));
    })

    this.dynamicControlDescriptor = [];
    this.tmpDynamicControlDescriptor = [];

    // date interval
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.LabelValue;
    if (this.filterForm.controls["fDateFrom"].value !== '' && this.filterForm.controls["fDateTo"].value !== '') {
      desc.parameters = [];
      const dateFrom = dayjs(this.filterForm.controls["fDateFrom"].value).format("YYYY.MM.DD");
      const dateTo = dayjs(this.filterForm.controls["fDateTo"].value).format("YYYY.MM.DD");
      const dateInterval: string = dateFrom + " - " + dateTo;
      desc.parameters.push({ Key: this.translate.instant("common.date"), Value: dateInterval })
      this.tmpDynamicControlDescriptor.push(desc);
    }
    // task type
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.LabelValue;
    if (this.filterForm.controls["fTaskTypeId"].value != "-1") {
      desc.parameters = [];
      const selectedTaskType = this.maintenanceTypes.find(item => item.TypeId == this.filterForm.controls["fTaskTypeId"].value).TypeName;
      desc.parameters.push({ Key: this.translate.instant("common.date"), Value: selectedTaskType });
    }


    this.dynamicControlDescriptor = JSON.parse(JSON.stringify(this.tmpDynamicControlDescriptor));
    this.editorList = JSON.parse(JSON.stringify(tmpTasks)) as RepeatingTask[];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;
  }

  showAddOrEditMessage(result: RequestResult) {
    switch (result.Code) {
      case 0:
        this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        break;
      case -1:
        this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

        break;
      case -4:
        this.hwAlerService.error(this.translate.instant("common.task_code_already_exists"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

        break;

    }
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }


  openDialog(action: string, data?: RepeatingTask) {
    let repeatingTaskItem: RepeatingTask;
    if (action == OpenDialogAction.ADD) {
      repeatingTaskItem = new RepeatingTask();
      repeatingTaskItem.TaskId = 0;
      repeatingTaskItem.AreaType = "R";
      repeatingTaskItem.Areas = '';
      repeatingTaskItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      repeatingTaskItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      repeatingTaskItem.IsActive = true;
      repeatingTaskItem.RepeatDay = 1;
      repeatingTaskItem.ShowBefore = 1;
      repeatingTaskItem.StartDate = dayjs().add(1, 'day').format("YYYY.MM.DD");
      repeatingTaskItem.TaskType = "M";
      repeatingTaskItem.TaskTypeId = this.maintenanceTypes[0].TypeId;
    } else {
      repeatingTaskItem = data;
    }
    const repeatingEditorRef = this.repeatingTaskDialog.open(RepeatingTasksEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      panelClass: "cleaning-type-editor-dialog",
      data: repeatingTaskItem
    })

    repeatingEditorRef.afterClosed().subscribe(data => {
      if (data) {
        const processableItem = data as RepeatingTask;
        if (processableItem.TaskId == 0) {
          this.addRepeatingTask$ = this.repeatingTasksService.add(processableItem).subscribe( result=> {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        } else {
          this.updateRepeatingTask$ = this.repeatingTasksService.update(processableItem).subscribe( result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        }
      }
    })
  }
  deleteRow(item: RepeatingTask) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteRepeatingTask$ = this.repeatingTasksService.delete(item.TaskId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAll();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
          } 
         })
      }
    })
  }

  typeName(rowData: RepeatingTask) {
    const foundItem = this.maintenanceTypes.find(item => item.TypeId === rowData.TaskTypeId);
    if (foundItem) {
      return foundItem.TypeName;
    } else {
      return ""
    }
  }
  formatAreas(task: RepeatingTask): string {
    let formattedAreas: string = "";
    const areas = JSON.parse(task.Areas) as AreaWrapper
    areas.Areas.forEach(area => {
      if (task.AreaType === "R") {
        formattedAreas += this.rooms.find(room => room.RoomId == area.AreaId).RoomNumber;
      } else {
        formattedAreas += this.publicAreas.find(room => room.PublicAreaId == area.AreaId).AreaName;

      }
      formattedAreas += " ;"
    })
    return formattedAreas;
  }


  //#endregion =================================================================================================================




}
