// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// 3rdparty
import { Subscription } from 'rxjs';
// Hostware
import { SelectItem } from '@app/core/classes/SelectItem';
import { HousekeepingSettingsContainerService } from '@app/kip/services/housekeeping-settings-container.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationPage } from '@app/core/enums/application-page';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'maintenance-settings-container',
  templateUrl: './maintenance-settings-container.component.html',
  styleUrls: ['./maintenance-settings-container.component.scss']
})
export class MaintenanceSettingsContainerComponent implements OnInit {

  listActive: boolean = true;
  editorActive: boolean = false;
  selectedEditor = 'kc';
  headerText: string = "Karbantartás beállítások";
  subHeaderText: string;
  editorList: SelectItem[];
  editorSelectionVisible: boolean = true;
  visibilityChanged$: Subscription;
  constructor(
    private hkscService: HousekeepingSettingsContainerService,
    private topbarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,

  ) { }

   //#region =============== Angular Lifecyle events ============================================================================
   ngOnInit() {
    this.topbarManagerService.setFormId(ApplicationPage.hotel_settings_container);
    this.topbarManagerService.setTopbarBackgroundVisibility(true);
    this.headerText = this.translate.instant("common.maintenance_settings");
    this.visibilityChanged$ = this.hkscService.visibilityChanged.subscribe(evn => {
      this.editorSelectionVisible = evn;
    })

    this.hkscService.headerTextChanged.subscribe(evn => {
      this.headerText = evn;
    });

    this.hkscService.subHeaderTextChanged.subscribe(evn => {
      this.subHeaderText = evn;
    });

    this.hkscService.listModeSelected.subscribe(evn => {
      this.listActive = evn;
      this.editorActive = !evn;
    });

    this.hkscService.editModeSelected.subscribe(evn => {
      this.editorActive = evn;
      this.listActive = !evn;
    });

    this.editorList = [
      {
        label : this.translate.instant("common.task_groups"),
        value : "kc"
      },
      {
        label : this.translate.instant("common.task_types"),
        value : "ku"
      },
      {
        label : this.translate.instant("common.task_to_do"),
        value : "kt"
      },
      {
        label : this.translate.instant("K925.repating_tasks"),
        value : "rt"
      },
      {
        label : this.translate.instant("extra_tasks.title"),
        value : "ex"
      }
      
  
    ]
    this.subHeaderText = this.editorList.find(item=> item.value == "kc").label;

  }
  ngOnDestroy(): void {
    this.visibilityChanged$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  editorChanged(event: MatSelectionListChange) {
    this.selectedEditor = event.options[0].value;
    this.subHeaderText =  event.options[0].getLabel();
    
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  //#endregion =================================================================================================================


}
