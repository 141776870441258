import { vwTask } from "./vwTask";
import { vwRoom } from "./vwRoom";
import { vwPublicArea } from "../vwdailytask/vwPublicArea"; 
import { vwTimeLine } from "../vwdailytask/vwTimeLine";

export class vwTaskListItem {
  TaskType: string;
  TypeCode: string;
  TipusType: string;               //  'M' maintenance   'C' cleaning  'O' Common  'X' Extra
  TypeName: string;
  TypeId: number;
  AssignedToId : number;
  AssignedToName: string;
  AssignedToRealName: string;
  InspectorId: number;
  InspectorName: string;
  InspectorRealName: string;
  Ready: boolean;
  Accepted: boolean;
  TypeTimeStr: string;
  Tasks: vwTask[];
  TimeLine: vwTimeLine[];
  Subscriber: string;
  HotelId: number;
  DueDate: string;
  Room: vwRoom;
  PublicArea : vwPublicArea;
  Comment: string;
  Need_Cleaning : boolean;
  GroupId: number;
}