import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HouseKeepingStatus } from '../enums/HKStatuses';
import { vwRoomHkStatus } from '../viewdto/vwRoomHkStatus';
import { HokParamsService } from './hok-params.service';
import { vwRoomStateListFilter } from '../viewdto/vwRoomStateListFilter';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(
    private http:HttpClient
    
    ) { 

     
    }
  apiServer = environment.apiHost + environment.apiPort;
  loadAllObservable: Observable<any>;
  loadAll( subscriber: string, hotelId: number, froDate: string) : Observable<any> {
    return this.http.get(this.apiServer + '/api/kip/Room/LoadRooms/'+ subscriber + "/" + hotelId + '/' + froDate);
  }

  setRoomState(subscriber: string, hotelId: number,data: vwRoomHkStatus) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/Room/SetRoomState/' + subscriber + "/" + hotelId, data, { headers });
  }

  getRoomStateListPdf(subscriber: string, hotelId: number,data: vwRoomStateListFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/Room/GetRoomListPdf/'+ subscriber + "/" + hotelId ,data, {responseType:'blob'});
  }


  getRoomStateListExcel(subscriber: string, hotelId: number,data: vwRoomStateListFilter) {
   const headers = new HttpHeaders().set("Content-Type", "application/json");
   return this.http.put(this.apiServer + '/api/kip/Room/GetRoomListExcel/'+ subscriber + "/" + hotelId ,data, {responseType:'blob'});
  }
}
