import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { NotFoundComponent } from './components/not-found/not-found.component';
import { AppComponent } from '../app.component';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from '../components/login/login.component';

// import { AppLandingPageComponent } from '../pages/app-landing-page/app-landing-page.component';
import { environment } from '@environments/environment';



const routes: Routes = [

  {
    path: 'kip',
    loadChildren: () => import('../kip/kip.module').then(m=>m.KipModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'hot',
    loadChildren: () => import('../hot/hot.module').then(m=>m.HotModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'fro',
    loadChildren: () => import('../fro/fro.module').then(m=>m.FroModule),
    canLoad: [AuthGuard]
  }

  // {
  //   path: '**',
  //   component: NotFoundComponent
  // }
];


@NgModule({
  // imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production })],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CoreRoutingModule {


}
