export const environment = {
  production: false,
  home:false,
  apiHost: 'https://hok.hostwaremobile.com',
  apiPort: '',
  httpHost: 'https://hok.hostwaremobile.com',
  siteRoot: '',
  dummDataUrlBase: 'http://localhost:4200/',
  photoBaseUrl: 'https://hok.hostwaremobile.com/photos/',
  messagingWebApiUrl: 'https://pus.hostwaremobile.com',
  publicVapidKey : 'BAJR1Fpjfd_cCiPYAyd5FzqSCYk8-r_RQQidFnn-AUXHwwPADCTLtoxVDXBr744kqBVddIikGpUhvWPbqi7ZFH4'
};
