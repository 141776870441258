import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

constructor(private http: HttpClient) { }

  public loadChatMessages(userId : number) {
    return this.http.get('../../../assets/json/open_chat_messages.json');
  }
  }
