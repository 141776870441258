// Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// 3rdparty

// Hostware
import { CameraManagerService } from '@app/kip/services/camera-manager.service';
import { vwCleaningState } from '@app/kip/viewdto/vwCleaningState/vwCleaningState';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MinibarDialogComponent } from '../minibar-dialog/minibar-dialog.component';
import { ImageCaptureService } from '@app/kip/services/image-capture.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent implements OnInit,OnDestroy {

  _data: vwTaskListItem
  constructor(
    public cameraManager: CameraManagerService,
    private imageCaptureService: ImageCaptureService,
    public dialogRef: MatDialogRef<PhotoDialogComponent> , @Inject(MAT_DIALOG_DATA) private data: vwTaskListItem
  ) { 
    this._data = data;
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.imageCaptureService.imageApproved.subscribe( result => {
      this.dialogRef.close(result)
    })
    this.imageCaptureService.imageDeclined.subscribe( result=> {
      this.dialogRef.close(undefined)
    })        
  }

  ngOnDestroy(): void {
      
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================
    
  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  //#endregion =================================================================================================================



  
}
