import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

import { CleaningGroup } from '../dto/CleaningGroup';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CleaningGroupService {

constructor(private http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;
  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningGroup/LoadAll/' + subscriber + "/" + hotelId);   
  }
  
  getById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningGroup/GetById'); 
  }

  addCleaningGroup(data:CleaningGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningGroup/Add',data,{headers});
  }

  updateCleaningGroup( data: CleaningGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningGroup/Update',data,{headers});
  }

  deleteCleaningGroup( ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/CleaningGroup/Delete/' + ids);  
  }
   
}
