// Angular
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';

// 3rdparty

// Hostware
import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'maintenance-group-editor-dialog',
  templateUrl: './maintenance-group-editor-dialog.component.html',
  styleUrls: ['./maintenance-group-editor-dialog.component.scss']
})
export class MaintenanceGroupEditorDialogComponent implements OnInit {
  
  @ViewChild('localizer',{static:true}) localizer :DataLocalizerComponent


  loadAllMaintenanceGroups$ : Subscription;
  allMaintenanceGroups: MaintenanceGroup[]=[];
  editorForm: FormGroup;
  _data: MaintenanceGroup;
  constructor(public dialogRef: MatDialogRef<MaintenanceGroupEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: MaintenanceGroup,
    private maintenanceGroupService: MaintenanceGroupService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this._data = data;
    this.editorForm = fb.group({
      "GroupName" : new FormControl(this.data.GroupName)
    });
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.loadAllMaintenanceGroups$ = this.maintenanceGroupService.loadAll(this._data.Subscriber, this._data.HotelId).subscribe( result=> {
      this.allMaintenanceGroups = result as Array<MaintenanceGroup>;
   })
  
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  submit() {
    this._data.GroupName=this.editorForm.controls["GroupName"].value;

    this._data.SData = this.localizer.jsonData;
    this.dialogRef.close(this._data);
  }
  

  groupNameAlreadyExist( groupName: string) : boolean {
    return false;
  }

  title() : string {
    return this._data.GroupId === 0 ?   this.translate.instant("common.add_new_task_group"):   this.translate.instant("common.change_task_group")
    
  }
}
