import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit {

  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string = 'hu';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

}

