import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '@app/core/interfaces/MenuItem';
import { AuthService } from '@app/core/services/auth.service';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
import { RoleType } from '@app/shared/enums/roles';
import { RoleService } from '@app/shared/services/role.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'maintenance-task-menu',
  templateUrl: './maintenance-task-menu.component.html',
  styleUrls: ['./maintenance-task-menu.component.css']
})


export class MaintenanceTaskMenuComponent implements OnInit {

  @Input() taskItem: vwTaskListItem
  @Output() startMaintenance: EventEmitter<vwTaskListItem> = new EventEmitter()
  @Output() reAssign: EventEmitter<vwTaskListItem> = new EventEmitter()
  @Output() endMaintenance: EventEmitter<vwTaskListItem> = new EventEmitter()
  @Output() pauseMaintenance: EventEmitter<vwTaskListItem> = new EventEmitter();
  @Output() acceptMaintenance: EventEmitter<vwTaskListItem> = new EventEmitter();
  @Output() editComment: EventEmitter<vwTaskListItem> = new EventEmitter();
  @Output() delete: EventEmitter<vwTaskListItem> = new EventEmitter();
  @Output() makePhoto: EventEmitter<vwTaskListItem> = new EventEmitter();
  @Output() showTaskHistory: EventEmitter<vwTaskListItem> = new EventEmitter();


  maintenanceActions: MenuItem[] = [];

  constructor(
    private roleService: RoleService,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.buidMaintenanceMenu();
  }

  buidMaintenanceMenu() {
    this.maintenanceActions = [];
    if (this.taskItem.Tasks.length > 0) {

      switch (this.taskItem.Tasks[0].TaskState) {
        case TaskStates.Created: {
          if (this.authService.getUserIdFromToken() == this.taskItem.AssignedToId) {
            this.maintenanceActions.push({
              label: this.translate.instant("common.start"), icon: 'mdi mdi-play-circle-outline', id: "startMaintenance"
            });
          }
          if (this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
            // this.maintenanceActions.push({
            //   label: this.translate.instant("common.re_assign"), icon: 'mdi mdi-arrow-expand-right', id: "reAssign"
            // });
            this.maintenanceActions.push({
              label: this.translate.instant("common.modify"), icon: 'mdi mdi-border-color', id: "modify"
            });
            this.maintenanceActions.push({
              label: this.translate.instant("common.delete"), icon: 'mdi mdi-delete', id: "delete"
            });
          }
          break;
        }

        case TaskStates.Running: {
          if (this.taskItem.AssignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
            this.maintenanceActions.push({
              label: this.translate.instant("common.end"), icon: 'mdi mdi-radiobox-marked', id: "endMaintenance"
            });
            this.maintenanceActions.push({
              label: this.translate.instant("common.pause"), icon: 'mdi mdi-pause-circle-outline', id: "pauseMaintenance"
            });
          }
          break;
        }

        case TaskStates.Paused: {
          if (this.taskItem.AssignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
            this.maintenanceActions.push({
              label: this.translate.instant("common.start"), icon: 'mdi mdi-play-circle-outline', id: "startMaintenance"
            });
            this.maintenanceActions.push({
              label: this.translate.instant("common.end"), icon: 'mdi mdi mdi-radiobox-marked', id: "endMaintenance"
            });
          }
          break;
        }

        case TaskStates.Ended: {
          if (this.taskItem.InspectorId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
            this.maintenanceActions.push({
              label: this.translate.instant("common.inspected"), icon: 'mdi mdi-play-circle-outline', id: "acceptMaintenance"
            });
          }

        }
          break;

      }

    }

    this.maintenanceActions.push({
      label: this.translate.instant("common.photo"), icon: 'mdi mdi-camera', id: "makePhoto"
    });
    if ((this.roleService.userIsInRole(RoleType.MUSZAKOS) && this.taskItem.AssignedToId == this.authService.getUserIdFromToken() ||
      this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) && this.taskItem.InspectorId == this.authService.getUserIdFromToken()
    )) {
      this.maintenanceActions.push({
        label: this.translate.instant("K200p.edit_comment"), icon: 'mdi mdi-border-color', id: "editComment"
      });
    }
    this.maintenanceActions.push({
      label: this.translate.instant("menu.task_history"), icon: 'mdi mdi-view-list-outline', id: "taskHistory"
    });

    if (this.maintenanceActions.length == 0) {
      this.maintenanceActions.push({
        label: this.translate.instant("common.no_available_actions"), icon: 'mdi mdi-border-color'
      });
    }
  }


  selectMenu(id: string) {
    switch (id) {
      case "modify":
        this.editComment.emit(this.taskItem);
        break;
      case "startMaintenance":
        this.startMaintenance.emit(this.taskItem);
        break;
      case "reAssign":
        this.reAssign.emit(this.taskItem);
        break;
      case "endMaintenance":
        this.endMaintenance.emit(this.taskItem);
        break;
      case "pauseMaintenance":
        this.pauseMaintenance.emit(this.taskItem)
        break;
      case "acceptMaintenance":
        this.acceptMaintenance.emit(this.taskItem);
        break;
      case "editComment":
        this.editComment.emit(this.taskItem);
        break;
      case "makePhoto":
        this.makePhoto.emit(this.taskItem);
        break;
      case "delete":
        this.delete.emit(this.taskItem);
        break;
      case "taskHistory":
        this.showTaskHistory.emit();
        break;
    }
  }
}
