// Angular 
import { Injectable } from '@angular/core';
// PrimeNg
import { SelectItem } from '../../core/classes/SelectItem';
// 3rdParty
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TaskFilterSettingsService {
hkList :  SelectItem[];
  constructor() { }

  loadAll() : BehaviorSubject<SelectItem[]> {
    var hkObservableList : BehaviorSubject<SelectItem[]> = new BehaviorSubject([]);  
    var hkList =[
      {label:'Részletes lista az összes szobára', value:{code:'1'}},
      {label:'Üres piszkos szobák', value:{code:'2'}}
    ];
    hkObservableList.next(hkList);
    return hkObservableList;
  }
}
