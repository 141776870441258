
export class CleaningTask {
  TaskId : number;
  Subscriber:string;
  HotelId : number;
  TaskCode : string;
  TaskName : string;
  TimeLimit: number;
  Active: boolean;
  TaskState: string;
  SData : string;
}

