// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectItem } from '@app/core/classes/SelectItem';
import { LostAndFound } from '@app/kip/dto/LostAndFound';
import { LostAndFoundState } from '@app/kip/enums/LostAndFoundState';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
// 3rdparty

// Hostware

@Component({
  selector: 'app-lost-and-found-dialog',
  templateUrl: './lost-and-found-dialog.component.html',
  styleUrls: ['./lost-and-found-dialog.component.scss']
})
export class LostAndFoundDialogComponent implements OnInit {

  editorForm: FormGroup;
  _data: LostAndFound;
  statusList: SelectItem[] = [];
  constructor(public dialogRef: MatDialogRef<LostAndFoundDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: LostAndFound,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this._data = data;
    this.editorForm = fb.group({
      'FindObject': new FormControl(this._data.FindObject, [Validators.required, Validators.maxLength(255), Validators.minLength(5)]),
      'Area': new FormControl(this._data.Area, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      'FoundTime': new FormControl(this._data.FoundTime, Validators.required),
      'Finder': new FormControl(this._data.Finder, [Validators.required, Validators.minLength(2)]),
      'Status': new FormControl(this._data.Status, Validators.required)
    })


  }


  //#region =============== Angular Lifecyle events ============================================================================

  ngOnInit() {
    this.statusList.push({ value: LostAndFoundState.FELVEVE, label: this.translate.instant("K926.added") });
    this.statusList.push({ value: LostAndFoundState.POSTAZVA, label: this.translate.instant("K926.posted") });
    this.statusList.push({ value: LostAndFoundState.VISSZAADVA, label: this.translate.instant("K926.returned") });
    this.statusList.push({ value: LostAndFoundState.SELEJTEZVE, label: this.translate.instant("K926.culled") });

    if(this._data.Id == 0) {
      this.editorForm.controls["Status"].setValue(LostAndFoundState.FELVEVE);
    } else {
       this.editorForm.controls["Status"].setValue(this._data.Status);
    }
   
    this.editorForm.controls["FindObject"].setValue(this._data.FindObject);
    this.editorForm.controls["FoundTime"].setValue(dayjs(this._data.FoundTime,"YYYY.MM.DD"));
    this.editorForm.controls["Finder"].setValue(this._data.Finder);
    this.editorForm.controls["Area"].setValue(this._data.Area);

  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  cancel() {
    this.dialogRef.close(undefined);
  }
  submit() {   
    this._data.Status =  this.editorForm.controls["Status"].value;
    this._data.FindObject = this.editorForm.controls["FindObject"].value;
    this._data.FoundTime = dayjs(this.editorForm.controls["FoundTime"].value,"YYYY.MM.DD").format("YYYY.MM.DD")
    this._data.Finder =  this.editorForm.controls["Finder"].value;
    this._data.Area = this.editorForm.controls["Area"].value;
    this.dialogRef.close(this._data);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  ================================== =======================================================
  //#endregion =================================================================================================================


}
