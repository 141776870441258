// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { TranslateService } from '@ngx-translate/core';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
// Hostware
import { RoomService } from '@app/kip/services/room.service';
import { Room } from '@app/kip/dto/Room';
import { RoomCache } from '@app/kip/dto/RoomCache';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';



@AutoUnsubscribe()
@Component({
  selector: 'room-editor',
  templateUrl: './room-editor.component.html',
  styleUrls: ['./room-editor.component.scss']
})
export class RoomEditorComponent implements OnInit, OnDestroy {


  constructor(
    private roomService : RoomService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private hokParamsService: HokParamsService,
    private topBarManagerService : TopBarManagerService,
  ) {

  }
  @ViewChild(MatSort) sort!: MatSort;
  editorList : RoomCache[]=[];
  tableColumns: string[] = ["RoomNumber", "Floor", "RoomType", "RoomTypeName", "NumberOfBed", "NumberOfAddBed",'Remark'];

  loadAll$:Subscription;
  loadFrodatum$: Subscription;
  dataSource: MatTableDataSource<RoomCache>;


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.loadAll$?.unsubscribe();
    this.loadFrodatum$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  
  loadAll() {
    this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(event => {
      const hokpResult = event as RequestResult;
      const froDatum = hokpResult.Data;
      this.loadAll$ = this.roomService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),froDatum).subscribe(result => {
        var res = result as RequestResult;
        if( res) {
          this.editorList = res.Data as Array<RoomCache>;
          this.dataSource = new MatTableDataSource(this.editorList)
        }
      });   

    })

  }

  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================




}
