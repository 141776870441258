import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageManagerService } from '@app/kip/services/image-manager.service';
import { environment } from '@environments/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';


@AutoUnsubscribe()
@Component({
  selector: 'photo-galleria-dialog',
  templateUrl: './photo-galleria-dialog.component.html',
  styleUrls: ['./photo-galleria-dialog.component.scss']
 
})
export class PhotoGalleriaDialogComponent implements OnInit, OnDestroy {

  loadImages$: Subscription;
  loaded: boolean =false
  selectedImages: PhotoItem[] = [];
  imageNames: string[] = [];
  typeId = this.data;
  imagePointer: number = 0;

  constructor(
    public dialogRef: MatDialogRef<PhotoGalleriaDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: number,
    private imageManagerService: ImageManagerService
  ) {

  }

  ngOnInit() {
    this.imageManagerService.loadImages(this.data).subscribe(result => {
      this.imageNames = result as Array<string>;
      this.imageNames.forEach(item => {
        let photo: PhotoItem = new PhotoItem();
        photo.alt = "";
        photo.title = "";
        photo.previewImageSrc = environment.photoBaseUrl + item;
        photo.thumbnailImageSrc = environment.photoBaseUrl + item;
        this.selectedImages.push(photo);
      })
      this.loaded=true;
    })
  }
  ngOnDestroy(): void {
    this.loadImages$?.unsubscribe();
  }

  submit() {
    this.dialogRef.close(undefined);
  }

  nextImage() {
    if(this.imagePointer < this.selectedImages.length-1) {
      this.imagePointer++;
    } 
  }
  previousImage() {
    if(this.imagePointer > 0) {
      this.imagePointer--;
    } 
  }

  getImagePath() : string {
    return  this.selectedImages[this.imagePointer].previewImageSrc; 
  }

  nextButtonStyle() {
    let styles={};
    if( this.imagePointer == this.selectedImages.length-1) {
      styles["cursor"]="default"
      styles["color"]="lightgrey";
    } else {
      styles["cursor"]="pointer"
      styles["color"]="black";
    }
    return styles;
  }
  previousButtonStyle() {
    let styles={};
    if( this.imagePointer == 0) {
      styles["cursor"]="default"
      styles["color"]="lightgrey";
    } else {
      styles["cursor"]="pointer"
      styles["color"]="black";
    }
    return styles;
  }
}


class PhotoItem {
  previewImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
  title: string;
}
