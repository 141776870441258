// Angular 
import { HttpClient } from '@angular/common/http'; 


import { Injectable } from '@angular/core';
import { SelectItem } from '../../core/classes/SelectItem';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CleaningStatusService {
  statusList : SelectItem[]=[];
constructor(private http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;
  getCleaningStatuses() : SelectItem[] {
    
    this.statusList = [
      {label: 'Üres kiadható',   value: 1},
      {label: 'Üres tiszta',     value: 2},
      {label: 'Üres piszkos',    value: 3},
      {label: 'Foglalt OK',      value: 4},
      {label: 'Foglalt tiszta',  value: 5},
      {label: 'Foglalt piszkos', value: 6},
      {label: 'Out Of Order',    value: 7},
      {label: 'Out Of Service',  value: 8},
      {label: 'Show Room',       value: 9}
    ]
    return this.statusList;
  } 

  getLabelFromValue(itemValue: number) : string {
   
  return this.statusList.find( item=> item.value == itemValue).label;
  }

  public loadCleaningsSummary(subscriber: string, hotelid : number) {
    return this.http.get(this.apiServer + '/api/kip/DailyTask/LoadCleaningsSummary/' + subscriber + "/" + hotelid + "/5"); 
  }
}

