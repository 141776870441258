
// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { saveAs } from 'file-saver';

// Hostware
import { vwMinibarConsumption } from '@app/kip/viewdto/vwMinibarConsumption';
import { Subscription } from 'rxjs';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MinibarService } from '@app/kip/services/minibar.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'minibar-list',
  templateUrl: './minibar-list.component.html',
  styleUrls: ['./minibar-list.component.scss']
})
export class MinibarListComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;

  loadFroDatum$: Subscription;
  loadMiniBarConsumption$: Subscription;
  froDate: string;


  dataSource: MatTableDataSource<vwMinibarConsumption>;
  tableColumns: string[] = ["ResNumber", "RoomNumber", "ItemNumber", "ItemName", "Quantity", "GrossAmount"];

  constructor(
    private hokParamsService: HokParamsService,
    private topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private miniBarService: MinibarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService

  ) { }

  //#region =============== Angular Lifecyle events ============================================================================

  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      const res = result as RequestResult;
      this.froDate = res.Data;
      this.loadMiniBarConsumption$ = this.miniBarService.loadOneDayConsumption(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), res.Data).subscribe(consump => {
        const consumpRes: RequestResult = consump as RequestResult;
        this.dataSource = new MatTableDataSource(consumpRes.Data as Array<vwMinibarConsumption>);
      })
    })

  }
  ngOnDestroy(): void {
    this.loadMiniBarConsumption$?.unsubscribe();
    this.loadFroDatum$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  pdfExport() {
    this.spinnerService.setSpinnerVisibility(true);
    const fileName = this.translate.instant("common.minibar_list") + "-" + this.froDate + ".pdf" ;
    this.miniBarService.getMinibarPdf(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId(),this.froDate).subscribe(fileData=> {
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      this.spinnerService.setSpinnerVisibility(false);
      
      
      saveAs(b,fileName),
      error=> 
        {
          console.log(error);
          this.spinnerService.setSpinnerVisibility(false);
        
        };
      
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
 
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================


}
