import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CameraManagerService {

  @Output() startCameraRecording: EventEmitter<boolean> = new EventEmitter(); 
  @Output() stopCameraRecording: EventEmitter<boolean> = new EventEmitter(); 

  public StartCamera() {
    this.startCameraRecording.emit(true);
  }

  public StopCamera() {
    this.stopCameraRecording.emit(true);
  }
constructor() { }


}
