import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HokParamsService {
  apiServer = environment.apiHost + environment.apiPort;

  constructor(private http: HttpClient) { }

  loadFroDatum (subscriber: string, hotelid : number) {


    return this.http.get(this.apiServer + '/api/kip/HokParams/LoadFroDatum/' + subscriber + "/" + hotelid); 
  }
  
}
