import { Injectable, EventEmitter,Output } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MiniBarItem } from '../dto/MiniBar';

@Injectable({
  providedIn: 'root'
})



export class MinibarService {

  constructor( 
    public http: HttpClient 
  ) { }

  /* events for UI */
  @Output()
  minibarVisibilityChanged: EventEmitter<boolean> = new EventEmitter(); 
  changeMinibarVisibility(isVisible : boolean) {
    this.minibarVisibilityChanged.emit(isVisible);
  }

  apiServer = environment.apiHost + environment.apiPort;
  /* CRUD operations */

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/Minibar/LoadAll/' + subscriber + "/" + hotelId);  
  }
  
 
  minibarCharge(subscriber: string, hotelId: number , SzoNum:string, RoomResNumber: number, data: MiniBarItem[])  {
    return this.http.put(this.apiServer + '/api/kip/Minibar/MinibarCharge/' + subscriber + '/' + hotelId +
      '/' + SzoNum + '/' + RoomResNumber ,data);
  }
  loadOneDayConsumption(subscriber: string, hotelId: number,dateValue: string){
    return this.http.get(this.apiServer + '/api/kip/Minibar/LoadOneDayConsumption/' + subscriber + "/" + hotelId + "/" + dateValue );  
  }

  getMinibarPdf(subscriber: string, hotelId: number,dateValue: string) {
    return this.http.get(this.apiServer + '/api/kip/Minibar/GetMinibarPdf/' + subscriber + "/" + hotelId + "/" + dateValue, {responseType:'blob'} ); 
  }

}
