// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hw-accepted-toast {
  background-color: #4caf50;
  color: white;
}

.hw-error-toast {
    color: white;
    background-color: red;
    border-color: white;
}

.hw-caution-toast {
  background-color: #f9a827;
  color: white;
}

.hw-primary-toast {
  background-color: #00cc2e;
  color: #000000de;
}


.ui-toast {
	position: fixed;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 10px solid transparent;
    /* border-radius: 1.25rem; */
    font-weight: 500;
    font-size: 17px;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.ui-toast .ui-toast-message {
    position: relative;
	display: block;
    margin-bottom: .75em;
    overflow: hidden;
}

.ui-toast .ui-toast-message-content {
    padding: .5em 1em;
}

.ui-toast .ui-toast-close-icon {
	position: absolute;
	top: .25em;
	right: .25em;
    cursor: pointer;
    display: inline-block;
}

.ui-toast .ui-toast-summary {
	font-weight: bold;
	padding: 0 0 .5em 0;
	display: block;
}

.ui-toast .ui-toast-icon {
    position: absolute;
    display: inline-block;
    left: .5em;
    top: .25em;
    padding: 0;
    font-size: 2em;
}

.ui-toast .ui-toast-message-text-content {
	padding: 0 0 .25em 0;
    margin-left: 2.5em;
}
/* Positions */
.toast-top-right {
	/* top: 20px; */
	right: 20px;
}

.toast-top-left {
	/* top: 20px; */
	left: 20px;
}

.toast-bottom-right {
	/* bottom: 20px; */
	right: 20px;
}

.toast-bottom-left {
	/* bottom: 20px; */
	right: 20px;
}

.toast-top-center {
	/* top: 20px; */
    left: 50%;
    margin-left: -10em;
}

.toast-bottom-center {
	bottom: 20px;
	left: 50%;
    margin-left: -10em;
}

.toast-center {
	left: 50%;
	top: 50%;
    min-width: 20vw;
    transform: translate(-50%, -50%);
}
.zindex_1000 {
  z-index: 20000;
}

@media all and (min-width: 401px) and (max-width: 599px) {
    .zindex_1000 {
        position: unset;
        width: 100%;
    }
}

@media all and (min-width: 100px) and (max-width: 400px) {
    .zindex_1000 {
        position: unset;
        width: auto;
    }
}

@media all and (min-width: 100px) and (max-width: 599px) {
    .hw-padding-left-2em {
        padding-left: 1.5em !important;
    }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
