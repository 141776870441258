import { Injectable } from '@angular/core';
import { RequestResult } from '@app/core/dto/RequestResult';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn:'root'})
export class CrudMessagesService {

constructor(
  private hwAlertService: HwAlertService,
  private translate: TranslateService
) { }


showAddOrEditMessage(result: RequestResult) {
  switch (result.Code) {
    case 0:
      this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
      break;
    case -1:
      this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      break;
    case -4:
      this.hwAlertService.error(this.translate.instant("common.task_code_already_exists"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      break;

  }
}

showDeleteMessage(result: RequestResult) {
  if (result.Code == 0) {
    this.hwAlertService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
  } else {
    this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
  }

}

}
