
// Angular
import { Component, OnInit, OnDestroy,ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { MaintenanceTask } from '@app/kip/dto/MaintenanceTask';
import { MaintenanceTaskService } from '@app/kip/services/maintenance-task.service';

@Component({
  selector: 'maintenance-task-editor-dialog',
  templateUrl: './maintenance-task-editor-dialog.component.html',
  styleUrls: ['./maintenance-task-editor-dialog.component.scss']
})
export class MaintenanceTaskEditorDialogComponent implements OnInit {

  
  @ViewChild('localizer') localizer : DataLocalizerComponent;
  editorForm: FormGroup;
  _data: MaintenanceTask;
  
  constructor(public dialogRef: MatDialogRef<MaintenanceTaskEditorDialogComponent>,@Inject(MAT_DIALOG_DATA) private data: MaintenanceTask,
  private sessionStorageService: SessionStorageService,
  private rfHelperService: ReactiveFormsHelperService,
  private maintenanceTaskService: MaintenanceTaskService,
  private translate: TranslateService,
  private fb: FormBuilder) { 

    this.editorForm = this.fb.group({
      TaskCode: ['', [Validators.required, Validators.maxLength(10)]],
      TaskName: ['', [Validators.required, Validators.maxLength(40)]],
      Active: true
    });
    this._data = data;
  }

     //#region =============== Angular Lifecyle events ============================================================================
     ngOnInit() {
      this.rfHelperService.fillFormFromRecord(this._data,this.editorForm);
    }
  
 //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  submit() {
    //this.rfHelperService.updateRecordFromForm(this._data,this.editorForm);
    
    this._data.SData = this.localizer.jsonData;
    this._data.Active = this.editorForm.controls["Active"].value;
    this._data.TaskCode = this.editorForm.controls["TaskCode"].value;
    this._data.TaskName = this.editorForm.controls["TaskName"].value;
      
    this.dialogRef.close(this._data);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  title() : string {
    return this._data.TaskId === 0 ?   this.translate.instant("common.adding_new_task"):   this.translate.instant("common.change_task")
    
  }
  //#endregion =================================================================================================================


}
