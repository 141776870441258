// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';


//HW
import { environment } from "../../../environments/environment";
import { KipUser } from '../dto/KipUser';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class KipUserService {

  apiServer = environment.apiHost + environment.apiPort;
  loadAllObservable: Observable<any>;
  
  constructor(public http: HttpClient) { }
  loadAll(subscriber: string, hotelId: number) : Observable<any> {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/LoadAll/' + subscriber + "/" + hotelId);
  }

  
  loadByUserGroupId (subscriber: string, hotelId: number, userGroupId : number ) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/LoadByUserGroupId/'  + subscriber + "/" + hotelId + "/" + userGroupId);   
  }

  loadByGroupId( id: number) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/LoadByUserGroupId/'  + id);   
  }
  loadByGroupIdMulti(ids: string) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/GetUsersByGroupIds/'  + ids);   
  }
  loadUsersWithRoles(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/LoadRolesByUser/' + subscriber + "/" + hotelId);   
  }

  

  //  multi
  loadAllMulti(subscribers : string, hotelIds : string ) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/loadall_multi/' + subscribers + "/" + hotelIds);   
  }

  loadUsersWithRolesMulti(subscriber: string, hotelIds: string) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/LoadRolesByUser_multi/' + subscriber + "/" + hotelIds);   
  }

  getUsersByGroupIds( groupIds : string) {
    return this.http.get(this.apiServer + '/api/kip/KipUsers/GetUsersByGroupIds/' + groupIds); 
  }
}
