import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FroUser } from '../dto/FroUser';

import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ResourceService } from './resource.service';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class UserService  {

  apiServer = environment.apiHost + environment.apiPort;
  constructor(protected authService: AuthService, 
              protected resourceService: ResourceService,
              public http: HttpClient,
              private sessionStorageService : SessionStorageService
              ) {
   
  }

  getUserInfo( email : string) {
    return this.http.get(this.apiServer + '/api/userinfo/userinfo/GetRawUserDataByEmail/' + email );
  }
  loadAll() {
    return this.http.get(this.apiServer + '/api/userinfo/userinfo/LoadAll' );
  }
  hasRole(role: string) {
    const rolesArr:string[] = this.sessionStorageService.getUserInfo().Roles;
    return rolesArr.find(item=>item==role) ? true : false 
  }
  
}
