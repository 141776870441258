import { Injectable } from '@angular/core';
import { TaskStates } from '../enums/TaskStates';
import HwUtils from '@shared/helpers/hwutils';
import { vwCleaningType } from '../viewdto/vwdailytask/vwCleaningType';
import { vwPublicArea } from '../viewdto/vwdailytask/vwPublicArea';
import dayjs from 'dayjs'
import { RoomState } from '@app/shared/enums/roomstates';

@Injectable({
  providedIn: 'root'
})
export class StyleHelperService {

  constructor() { }

  publicAreaColorStyle(rowData: vwPublicArea) {

    let styles = {};
    styles["padding-left"] = "10px";
    styles["padding-right"] = "10px";

    let foundTime: boolean = false;
    let foundCleaningType: vwCleaningType;
    let foundIndex: number = 0;
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    if (rowData.CleaningTypes.length == 1) {
      foundCleaningType = rowData.CleaningTypes[0];
      switch (foundCleaningType.Tasks[0].TaskState) {
        case TaskStates.Ended:
          styles["background-color"] = "#03a9f4";
          break;
        case TaskStates.Accepted:
          styles["background-color"] = "#81C784";
          break;
        default:
          styles["background-color"] = "#F35870";

      }
      return styles;
    }
    else {
      for (var i = 0; i < rowData.CleaningTypes.length; i++) {
        if ((HwUtils.HoursAndMinutesToMinutes(rowData.CleaningTypes[i].TypeTimeStr) > nowMinutes) && !foundTime) {
          foundTime = true;
          if (i > 0) {
            foundIndex = i - 1;
          } else {
            foundIndex = i;
          }

          foundCleaningType = rowData.CleaningTypes[foundIndex];
        }
      }

    }
    if (foundCleaningType) {
      // if (foundIndex == rowData.CleaningTypes.length - 1) {  // utolsó a sorban, nincs több
      switch (foundCleaningType.Tasks[0].TaskState) {
        case TaskStates.Created:
          styles["background-color"] = "#F35870";
          break;
        case TaskStates.Ended:
          styles["background-color"] = "#03A9F4";

          break;
        case TaskStates.Accepted:
          styles["background-color"] = "#81c784";
          break;

        case TaskStates.Deleted:
          styles["background-color"] = "#03a9f4";
          break;
        default:
          styles["background-color"] = "#F35870";

      }
      // } else {  // vannak további időpontok
      //   if (foundCleaningType?.Tasks[0].TaskState == 'Created') {
      //     styles["background-color"] = "#F35870"
      //   } else {
      //     styles["background-color"] = "#03a9f4";
      //   }

      //}
    }
    else {
      //az utolsó státuszától függ:
      if (rowData.CleaningTypes.length > 0) {
        if (rowData.CleaningTypes[rowData.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Deleted ||
          rowData.CleaningTypes[rowData.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Accepted) {
          styles["background-color"] = "#03a9f4";
        } else {
          styles["background-color"] = "#F35870"
        }
      } else {
        styles["background-color"] = "#03a9f4"

      }
    }
    return styles;

  }

  publicAreaStateIcon(rowData: vwPublicArea) {


    let styles = {};
    styles["padding-left"] = "10px";
    styles["padding-right"] = "10px";

    let foundTime: boolean = false;
    let foundCleaningType: vwCleaningType;
    let foundIndex: number = 0;
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    if (rowData.CleaningTypes.length == 1) {
      foundCleaningType = rowData.CleaningTypes[0];
      switch (foundCleaningType.Tasks[0].TaskState) {
        case TaskStates.Created:
          return {
            'mdi mdi-delete': true,
            'room-state-icon': true,
            'dirty-button-icon-color': true
          };
        case TaskStates.Ended:
          return {
            'mdi mdi-star': true,
            'room-state-icon': true,
            'clean-button-icon-color': true,
            'star-icon-color': true
          };
        case TaskStates.Accepted:
          return {
            'mdi mdi-check-all': true,
            'room-state-icon': true,
            'checked-button-icon-color': true,
            'check-all-all-icon-color': true
          };
        default:
          return {
            'mdi mdi-star': true,
            'room-state-icon': true,
            'clean-button-icon-color': true
          };

      }

    }
    else {
      for (var i = 0; i < rowData.CleaningTypes.length; i++) {
        if ((HwUtils.HoursAndMinutesToMinutes(rowData.CleaningTypes[i].TypeTimeStr) > nowMinutes) && !foundTime) {
          foundTime = true;
          if (i > 0) {
            foundIndex = i - 1;
          } else {
            foundIndex = i;
          }

          foundCleaningType = rowData.CleaningTypes[foundIndex];
        }
      }

    }
    if (foundCleaningType) {
      if (foundIndex == rowData.CleaningTypes.length - 1) {  // utolsó a sorban, nincs több
        switch (foundCleaningType.Tasks[0].TaskState) {
          case TaskStates.Ended:
            return {
              'mdi mdi-star': true,
              'room-state-icon': true,
              'clean-button-icon-color': true,
              'star-icon-color': true
            };
          case TaskStates.Accepted:
            return {
              'mdi mdi-check-all': true,
              'room-state-icon': true,
              'checked-button-icon-color': true,
              'check-all-all-icon-color': true
            };

          case TaskStates.Deleted:
            return {
              'mdi mdi-star': true,
              'room-state-icon': true,
              'clean-button-icon-color': true,
              'star-icon-color': true
            };
          default:
            return {
              'mdi mdi-delete': true,
              'room-state-icon': true,
              'dirty-button-icon-color': true
            }

        }
      } else {  // vannak további időpontok

        switch (foundCleaningType?.Tasks[0].TaskState) {
          case TaskStates.Created:
            return {
              'mdi mdi-delete': true,
              'room-state-icon': true,
              'dirty-button-icon-color': true
            }
          case TaskStates.Accepted:
            return {
              'mdi mdi-check-all': true,
              'room-state-icon': true,
              'checked-button-icon-color': true,
              'check-all-all-icon-color': true
            };
          case TaskStates.Ended: {
            return {
              'mdi mdi-star': true,
              'room-state-icon': true,
              'clean-button-icon-color': true,
              'star-icon-color': true
            };
          }
        }
      }
    }
    else {
      //az utolsó státuszától függ:
      if (rowData.CleaningTypes.length > 0) {
        if (rowData.CleaningTypes[rowData.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Deleted ||
          rowData.CleaningTypes[rowData.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Accepted) {
          return {
            'mdi mdi-star': true,
            'room-state-icon': true,
            'clean-button-icon-color': true,
            'star-icon-color': true
          };
        } else {
          return {
            'mdi mdi-delete': true,
            'room-state-icon': true,
            'dirty-button-icon-color': true
          }
        }
      } else {
        return {
          'mdi mdi-star': true,
          'room-state-icon': true,
          'clean-button-icon-color': true,
          'star-icon-color': true
        };

      }
    }
    return styles;

  }

  publicAreaState(pSelectedPublicArea: vwPublicArea) {

  }

  roomStateIconColorStyle( state: RoomState) {
    let styles={};
    switch( state) {
      case RoomState.EMPTY_DIRTY:
      case RoomState.RESERVED_DIRTY: 
        styles["color"]="#f35870"
        break;
      
      case RoomState.EMPTY_CLEAN:
      case RoomState.RESERVED_CLEAN:
        styles["color"]="#03a9f4"
        break;
      
      case RoomState.EMPTY_AVAILABLE:
      case RoomState.RESERVED_OK:
        styles["color"] = "#81c784";
        break;

      case RoomState.OUT_OF_ORDER:
        styles["color"] = "#424242";
        break;

      case RoomState.SHOW_ROOM :
        styles["color"] = "#ffca28";
        break;
      case RoomState.OUT_OF_SERVICE:
        styles["color"] = "#ce93d8";
        break;
    } 


    return styles;

  }
  booleanCell(cellValue: boolean) {
    if (cellValue) {
      return {
        'mdi mdi-check': true,
        'boolean-cell': true
      }
    }
  }
}
