import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroIfNull'
})
export class ZeroIfNullPipe implements PipeTransform {

  transform(value: any, args?: any): any {
   if( value == null) {
     return 0;
   }
   else {
     return value;
   }
  }

}
