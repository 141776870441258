import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {  SelectItem } from '../classes/SelectItem';

@Injectable({
  providedIn: 'root'
})
export class ReactiveFormsHelperService {

  constructor() { }

  fillFormFromRecord( record : any , fg: FormGroup) {
     
    Object.keys(fg.controls).forEach(key => {
      if( fg.controls[key]) {
       if(fg.controls[key]  instanceof FormControl) {
        fg.controls[key].patchValue(record[key])
       }
       else {
         // formgroup 
         if(fg.controls[key]  instanceof FormGroup) {
            let group = fg.controls[key] as FormGroup;
            Object.keys(group.controls).forEach(gkey =>  {
              if( group.controls[gkey]) {
                group.controls[gkey].patchValue(record[gkey]);
              }
            })
         }
       }
      }
    });
  }

  updateRecordFromForm( record : any, fg:FormGroup) {
    Object.keys(fg.controls).forEach(key => {
      if( fg.controls[key]) {
       if(fg.controls[key]  instanceof FormControl) {
        record[key] = fg.controls[key].value;
       }
       else {
         // formgroup 
         if(fg.controls[key]  instanceof FormGroup) {
            let group = fg.controls[key] as FormGroup;
            Object.keys(group.controls).forEach(gkey =>  {
              if( group.controls[gkey]) {
                record[gkey] = group.controls[gkey].value;
              }
            })
         }
       }
      }
    });
  }

   /**
    * Performs the specified action for each element in an array.
    * @param array  The array of objects to convert
    * @param labelKey  The key of the object to use as the label (can be string or array of strings, if Array then first element is the separator)
    * @param valueKey  Optional key of the object to use as the value, default is the object
    * @param placeholder  Optional placeholder element
    */
   toSel(array: any[], labelKey: string | string[], valueKey?: string, placeholder?: string): SelectItem[] {
    if (!array||!labelKey) return undefined;
    let tmpArray;
    let marray : any[] = JSON.parse(JSON.stringify(array)); 
    if (labelKey instanceof Array) {
    
      if (labelKey && valueKey) {
        tmpArray = marray.map( (arrayValue) => ({ label: arrayValue[labelKey[1]], value: String(arrayValue[valueKey]) }));
      }else if (labelKey && !valueKey) {
        tmpArray = marray.map( (arrayValue) => ({ label: arrayValue[labelKey[1]], value: arrayValue }));
      }
      for (let j = 2; j < labelKey.length; j++) {
        if (labelKey && valueKey) {
          tmpArray = marray.map( (arrayValue, i) => ({ label: tmpArray[i].label + labelKey[0] + arrayValue[labelKey[j]], value: String(arrayValue[valueKey]) }));
        }else if (labelKey && !valueKey) {
          tmpArray = marray.map( (arrayValue, i) => ({ label: tmpArray[i].label + labelKey[0] + arrayValue[labelKey[j]], value: arrayValue }));
        }
      }
    }else {
      if (labelKey && valueKey) {
        tmpArray = marray.map(arrayValue => ({ label: arrayValue[labelKey], value: String(arrayValue[valueKey]) }));
      }else if (!labelKey && valueKey) {
        tmpArray = marray.map(arrayValue => ({ label: arrayValue, value: String(arrayValue[valueKey]) }));
      }else if (labelKey && !valueKey) {
        tmpArray = marray.map(arrayValue => ({ label: arrayValue[labelKey], value: arrayValue }));
      }else {
        tmpArray = marray.map(arrayValue => ({ label: arrayValue, value: arrayValue }));
      }
    }
    if (placeholder) {
      tmpArray.unshift({label: placeholder, value: null});
    }
   
    return tmpArray;
   
  }
 
}
