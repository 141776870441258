import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HwAlertComponent } from './hw-alert.component';
import { HwAlertService } from './hw-alert.service';

@NgModule({
    imports: [CommonModule],
    declarations: [HwAlertComponent],
    exports: [HwAlertComponent]
  
})
export class HwAlertModule { }