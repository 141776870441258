
// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { CleaningTaskEditorDialogComponent} from '@kip/dialogs/cleaning-task-editor-dialog/cleaning-task-editor-dialog.component';
import { CleaningTask } from '@app/kip/dto/CleaningTask';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { CleaningTaskService } from '@app/kip/services/cleaning-task.service';
import { ApplicationPage } from '@app/core/enums/application-page';
import { StyleHelperService } from '@app/kip/services/style-helper.service';

@AutoUnsubscribe()
@Component({
  selector: 'cleaning-task-editor',
  templateUrl: './cleaning-task-editor.component.html',
  styleUrls: ['./cleaning-task-editor.component.scss']
})
export class CleaningTaskEditorComponent implements OnInit,OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  filterForm: FormGroup;
  loaded: boolean;
  editorList: CleaningTask[]=[];
  allCleaningTasks: CleaningTask[]=[];
  filterCaptions: string[] = [];
  cleaningTasks: CleaningTask[]=[];
  dataSource: MatTableDataSource<CleaningTask>;

  tableColumns = ['TaskCode','TaskName','TimeLimit','Active','Action']

  loadAllCleaningTasks$ : Subscription;
  hotelUnitChanged$: Subscription;
  addCleaningTask$: Subscription;
  deleteCleaningTask$: Subscription;
  updateCleaningTask$: Subscription;


  constructor(
    private hwAlertService: HwAlertService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private cleaningTaskService: CleaningTaskService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private cleaningTaskDialog: MatDialog,
    private translate: TranslateService,
    private fb: FormBuilder,
    public styleHelper: StyleHelperService


  ) {
     
    this.filterForm = fb.group({
      'fActive': new FormControl(true),
        'fTaskCode': new FormControl('', [Validators.maxLength(10)]),
        'fTaskName': new FormControl('', [Validators.maxLength(40)])
      })
  
    }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.setFormId(ApplicationPage.cleaning_task_editor);
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    });
    this.loadAll();
    
  }
  ngOnDestroy(): void {
    this.loadAllCleaningTasks$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.addCleaningTask$?.unsubscribe();
    this.deleteCleaningTask$?.unsubscribe();
    this.updateCleaningTask$?.unsubscribe();
    
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  applyFilter() {
    var tmpArr: CleaningTask[] = [];
    var mustInclude: boolean;
    this.allCleaningTasks.forEach(item => {
      mustInclude = true;
      this.filterCaptions = [];
      if (this.filterForm.controls["fActive"].value && mustInclude) {
        if (!item.Active) {
          mustInclude = false;
        }
        else {
          this.filterCaptions.push(this.translate.instant("common.active"))
        }
      }
      if(!item.TaskCode.toLowerCase().startsWith(this.filterForm.controls["fTaskCode"].value.toString().toLowerCase()) && 
        this.filterForm.controls["fTaskCode"].value !== "" && mustInclude) {
        mustInclude = false;
      }
      if(!item.TaskName.toLowerCase().startsWith(this.filterForm.controls["fTaskName"].value.toString().toLowerCase())  && 
        this.filterForm.controls["fTaskName"].value !== "" && mustInclude) {
        mustInclude = false;
      }
       
      if (mustInclude) {
        tmpArr.push(item);
      }
       // update filter line
      if(this.filterForm.controls["fTaskCode"].value !=="")  {
        this.filterCaptions.push(this.translate.instant("common.code") + ": " + this.filterForm.controls["fTaskCode"].value);
      } 
      if(this.filterForm.controls["fTaskName"].value !=="")  {
        this.filterCaptions.push(this.translate.instant("common.appellation") + ": " + this.filterForm.controls["fTaskName"].value);
      } 

    })
    this.editorList = JSON.parse(JSON.stringify(tmpArr)) as CleaningTask[];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;

  }
  openDialog( action : string, data?:CleaningTask) {
    let cleaningTaskItem: CleaningTask;
    if( action == OpenDialogAction.ADD) {
      cleaningTaskItem = new CleaningTask();
      cleaningTaskItem.Active = true;
      cleaningTaskItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      cleaningTaskItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      cleaningTaskItem.TaskId = 0;
    } else {
      cleaningTaskItem = data;
    }

    const cleaningTaskDialogDef = this.cleaningTaskDialog.open(CleaningTaskEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      panelClass: "cleaning-type-editor-dialog",
      data: cleaningTaskItem
    });

    cleaningTaskDialogDef.afterClosed().subscribe(data => {
      if(data) {
         const processableItem = data as CleaningTask;
         if(processableItem.TaskId == 0) {
          this.addCleaningTask$ = this.cleaningTaskService.addCleaningTask(data).subscribe(result=> {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
         } else {
          this.updateCleaningTask$ = this.cleaningTaskService.updateCleaningTask(data).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
         }
      }
    }) 
  }
  deleteRow(item: CleaningTask) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteCleaningTask$ = this.cleaningTaskService.deleteCleaningTask(item.TaskId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAll();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
          } 
         })
      }
    })
  }
  //#endr
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    this.loadAllCleaningTasks$ = this.cleaningTaskService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe( result => {
      const res = result as RequestResult;
      this.editorList = res.Data as Array<CleaningTask>;
      this.allCleaningTasks = res.Data as Array<CleaningTask>;
      this.dataSource = new MatTableDataSource(this.editorList);
      this.dataSource.sort = this.sort;
      this.loaded = true;   
      this.applyFilter();
    })
  }

  
  showAddOrEditMessage(result: RequestResult) {
    switch(result.Code) {
      case 0:
        this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        break;
      case -1: 
        this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

        break;
      case -4:
        this.hwAlerService.error(this.translate.instant("common.task_code_already_exists"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

        break;

    }
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }
  //#endregion =================================================================================================================

}
