// Angular LifeCycle 
import { Component, OnInit, Input } from '@angular/core';

// Hostware
import { TaskStates } from '../../../enums/TaskStates';
import { ParamKeyValue } from '../../../classes/ParamKeyValue'

@Component({
  selector: 'task-type-chips',
  templateUrl: './task-type-chips.component.html',
  styleUrls: ['./task-type-chips.component.css']
})
export class TaskTypeChipsComponent implements OnInit {
  @Input() paramKeyValue: ParamKeyValue[];
  isCleaning: boolean;
  isMaintenance: boolean;
  label: string;

  constructor() { }

  ngOnInit() { 
    this.label = this.paramKeyValue.find(item=>item.Key=="Label").Value;
    this.isCleaning = this.paramKeyValue.find(item=>item.Key=="Cleaning").Value === 'true' ? true : false;
    this.isMaintenance= this.paramKeyValue.find(item=>item.Key=="Maintenance").Value === 'true' ? true : false;

  }

}
