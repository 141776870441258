import { CleaningType } from "../dto/CleaningType";
import { ExtraTask } from "../dto/ExtraTask";
import { MaintenanceType } from "../dto/MaintenanceType";
import { PublicArea } from "../dto/PublicArea";
import { Room } from "../dto/Room";
import { RoomType } from "../dto/RoomType";

export  class ExtraTaskBag {
  cleaningTypes : CleaningType[]
  maintenanceTypes: MaintenanceType[]
  extraTask: ExtraTask
  rooms : Room[]
  roomTypes : RoomType[]
  publicAreas : PublicArea[]
   
}