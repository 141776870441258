export class vwPublicAreaCleaningType {

  PublicAreaCleaningTypeId : number;
  CleaningTypesId : number;
  PublicAreaId : number;
  PeriodHourStart : number;
  PeriodHourEnd : number;
  PeriodMinStart : number;
  PeriodMinEnd : number;
  PeriodicityMinutes : number;
  PublicAreaName : string;
  CleaningTypeName : string; 

}



