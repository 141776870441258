// Angular
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { KipUser } from '@app/kip/dto/KipUser';
import { KipUserGroup } from '@app/kip/dto/KipUserGroup';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { Subscription } from 'rxjs';
import { RequestResult } from '@app/core/dto/RequestResult';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';


@AutoUnsubscribe()
@Component({
  selector: 'user-group-editor-dialog',
  templateUrl: './user-group-editor-dialog.component.html',
  styleUrls: ['./user-group-editor-dialog.component.scss']
})
export class UserGroupEditorDialogComponent implements OnInit,OnDestroy {

  @ViewChild("selected")  selectedUsersRef : MatSelectionList;
  @ViewChild("avaliable") availableUsersRef : MatSelectionList;
  
  loadAllUsers$ : Subscription;
  
  title: string;
  _data: KipUserGroup;
  editorForm: FormGroup;
  filterForm: FormGroup;
  allUsers: KipUser[]=[];

  selectedUsers: KipUser[]=[];
  availableUsers: KipUser[]=[];
  selectedUsersFiltered: KipUser[]=[];
  availableUsersFiltered: KipUser[]=[];

  constructor( public dialogRef: MatDialogRef<UserGroupEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data : KipUserGroup,
               private translate: TranslateService,
               private fb: FormBuilder,
               private kipUserService : KipUserService,
               private sessionStorageService: SessionStorageService
  ) {
    this._data = data;
    this.title = data.UserGroupId == -1 ? this.translate.instant("common.add_new_user_group") : this.translate.instant("K901p6.edit_user_groups");
    this.editorForm = this.fb.group({
      UserGroupName:[ data.UserGroupId == -1 ? '': data.UserGroupName,[Validators.required, Validators.maxLength(40)] ]
    })

    this.filterForm = this.fb.group({
      fAvailable: [''],
      fSelected: [''],
    })
    
  }
  

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {

    this.loadAllUsers$ = this.kipUserService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      var res = result as RequestResult;
      let tmpUsers: KipUser[]=[];
      if(this._data.UserGroupId !== -1) {
        this.editorForm.controls["UserGroupName"].setValue(this._data.UserGroupName);
      }
      if (res) {
        this.allUsers = res.Data as Array<KipUser>;
        this.selectedUsers =  [...this._data.Users];
        this.allUsers.forEach(user=> {
          if(!this.selectedUsers.find( u=>u.UserId == user.UserId )) {
            tmpUsers.push(user)
          }
        })
        this.availableUsers=[...tmpUsers];
        this.availableUsersFiltered = [...this.availableUsers];
        this.selectedUsersFiltered = [...this.selectedUsers];
      }
     
    });
  }
  ngOnDestroy(): void {
    this.loadAllUsers$?.unsubscribe();      
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  

  selectedFilterChanged( ) {
    this.applySelectedFilter();
  }
  availableFilterChanged() {
    this.applyAvailableFilter();
  }
  submit() {
    this._data.UserGroupName = this.editorForm.controls["UserGroupName"].value;
    this.data.Users = this.selectedUsers;
    this.dialogRef.close(this._data);
  }
  cancel() {
    this.dialogRef.close(undefined);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  addToSelected() {
    this.availableUsersRef.selectedOptions.selected.forEach(item=> {
      this.selectedUsers.push(this.allUsers.find(avail=>avail.UserName==item.value))
     
      this.availableUsers.splice(this.availableUsers.findIndex(avail=>avail.UserName==item.value),1)
    
    })
   this.synch();
   
  }
  removeFromSelected() {
    this.selectedUsersRef.selectedOptions.selected.forEach(item=> {
      this.availableUsers.push(this.allUsers.find(avail=>avail.UserName==item.value))
      this.selectedUsers.splice(this.selectedUsers.findIndex(sel=>sel.UserName==item.value),1)
     
     
    })
    this.synch();
  }

  applySelectedFilter() {
    const newValue = this.filterForm.controls["fSelected"].value;
    if(newValue.trim().length == 0) {
      this.selectedUsersFiltered=[...this.selectedUsers];
    }  else {
      this.selectedUsersFiltered=this.selectedUsers.filter(item=>item.RealName.toLowerCase().startsWith(newValue.toLowerCase()));
    }
  }

  applyAvailableFilter() {
    const newValue = this.filterForm.controls["fAvailable"].value;
    if(newValue.trim().length == 0) {
      this.availableUsersFiltered=[...this.availableUsers];
    }  else {
      this.availableUsersFiltered=this.availableUsers.filter(item=>item.RealName.toLowerCase().startsWith(newValue.toLowerCase()));
    }
  }
  synch() {
    this.applyAvailableFilter();
    this.applySelectedFilter();
    this.selectedUsersFiltered = [...this.selectedUsersFiltered];
    this.selectedUsersFiltered.sort(this.compareFn)
    this.availableUsersFiltered = [...this.availableUsersFiltered];
    this.availableUsersFiltered.sort(this.compareFn)
    this.selectedUsersRef.deselectAll();
    this.availableUsersRef.deselectAll();
  }
  addToSelectedDisabled() : boolean {
    if( this.availableUsersFiltered.length == 0 || this.availableUsersRef.selectedOptions.selected.length == 0) {
      return true
    } else {
      return false
    }
  }

  removeFromSelectedDisabled() : boolean {
    if( this.selectedUsersFiltered.length == 0 || this.selectedUsersRef.selectedOptions.selected.length == 0) {
      return true
    } else {
      return false
    }
  }

  compareFn(a:KipUser, b:KipUser) {
    if (a.UserName < b.UserName) {
      return -1;
    }
    if (a.UserName > b.UserName) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
  //#endregion =================================================================================================================

}
