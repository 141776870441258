// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
//import { RequestOptions } from "@angular/common/http";


//HW
import { environment } from "../../../environments/environment";
import { CommonProblemTask } from '../dto/CommonProblemTask';

// 3rd Party

@Injectable()
export class CommonProblemTaskService {

  constructor(public http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;

  getCleaningTaskById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemTask/GetById' ); 
  }

  addCommonProblemTask(data: CommonProblemTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CommonProblemTask/Add',data,{headers});
  }

  updateCommonProblemTask( data: CommonProblemTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CommonProblemTask/Update',data,{headers});
  }

  deleteCommonProblemTask( ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/CommonProblemTask/Delete/' + ids);  
  }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemTask/LoadAll/' + subscriber + "/" + hotelId);   
  }

  commonProblemTaskCodeExists(subscriber : string,hotelId:number, commonProblemTaskCode:string) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemTask/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + commonProblemTaskCode); 
  }

  commonProblemTaskNameExists(subscriber : string,hotelId:number, commonProblemTaskName:string) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemTask/GetByTypeName/' + subscriber + "/" + hotelId + "/" + commonProblemTaskName); 
  }


}
