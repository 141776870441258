// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

//Hostware
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';

import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { CleaningTask } from '@app/kip/dto/CleaningTask';
import { ApplicationPage } from '@app/core/enums/application-page';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { CleaningTypeEditorDialogComponent } from '@app/kip/dialogs/cleaning-type-editor-dialog/cleaning-type-editor-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'cleaning-type-editor',
  templateUrl: './cleaning-type-editor.component.html',
  styleUrls: ['./cleaning-type-editor.component.scss']
})
export class CleaningTypeEditorComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;
  tableColumns: string[] = ["TypeCode", "TypeName", "Weighting", "Active", "AutoAccepted", "Action"];


  loaded: boolean;
  filterCaptions: string[] = [];
  filterForm: FormGroup;
  editorList: CleaningType[];
  allCleaningTypes: CleaningType[];
  cleaningGroups: CleaningGroup[];

  dataSource: MatTableDataSource<CleaningType>;
  // Subscriptions
  loadAllCleaningGroups$: Subscription;

  hotelUnitChanged$: Subscription;
  addCleaningType$: Subscription;
  deleteCleaningType$: Subscription;
  updateCleaningType$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  // TS Translation
  constructor(
    private hwAlertService: HwAlertService,
    private rfHelperService: ReactiveFormsHelperService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private cleaningGroupService: CleaningGroupService,
    private cleaningTypeService: CleaningTypeService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private cleaningTypeDialog: MatDialog,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {

    // this.filterForm = fb.group({
    //    'fActive': new FormControl(true),
    //     'fTaskCode': new FormControl('', [Validators.required, , Validators.maxLength(10)]),
    //     'fTaskName': new FormControl('', [Validators.required, , Validators.maxLength(40)])
    //   })  

    this.filterForm = fb.group({
      'fActive': new FormControl(true),
      'fTypeCode': new FormControl('', [ Validators.maxLength(10)]),
      'fTypeName': new FormControl('', [Validators.maxLength(40)]),
      'fCleaningGroupId': new FormControl(''),
      'fAutoAccepted': new FormControl(false)

    })

  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.setFormId(ApplicationPage.cleaning_task_editor);
    this.hotelUnitChanged$=this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    });
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.hotelUnitChanged$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.addCleaningType$?.unsubscribe();
    this.deleteCleaningType$?.unsubscribe();
    this.updateCleaningType$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  openDialog(action: string, data?: CleaningType) {
    let cleaningTypeItem: CleaningType;
    if (action == OpenDialogAction.ADD) {
      cleaningTypeItem = new CleaningType();
      cleaningTypeItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      cleaningTypeItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      cleaningTypeItem.Active = true;
      cleaningTypeItem.AutoAccepted = false;
      cleaningTypeItem.Tasks = [];
      cleaningTypeItem.TypeId = 0;
      cleaningTypeItem.CleaningGroupId = 0;
    } else {
        cleaningTypeItem = data;
    }
      const cleaningTypeDialogDef = this.cleaningTypeDialog.open(CleaningTypeEditorDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "80vw",
        // height: "60vh",
        panelClass: "cleaning-type-editor-dialog",
        data: cleaningTypeItem
      })
      
      cleaningTypeDialogDef.afterClosed().subscribe(data => {
        if (data) {
          const processableItem = data as CleaningType;
          if (processableItem.TypeId == 0) {
            this.addCleaningType$ = this.cleaningTypeService.add(processableItem).subscribe(result => {
              this.showAddOrEditMessage(result as RequestResult);
              this.loadAll();
            })
          } else {
            this.updateCleaningType$ = this.cleaningTypeService.update(processableItem).subscribe(result => {
              this.showAddOrEditMessage(result as RequestResult);
              this.loadAll();
            })
          }
        }
      })
    
  }
  deleteRow(item: CleaningType) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteCleaningType$ = this.cleaningTypeService.delete(item.TypeId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAll();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));

          } 
         })
      }
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  applyFilter() {

    var tmpArr: CleaningType[] = [];
    var mustInclude: boolean;
    this.allCleaningTypes.forEach(item => {
      mustInclude = true;
      this.filterCaptions = [];

      // Active
      if (this.filterForm.controls["fActive"].value && mustInclude) {
        if (!item.Active) {
          mustInclude = false;
        }
      }
      // AutoAccepted
      if (this.filterForm.controls["fAutoAccepted"].value && mustInclude) {
        if (item.AutoAccepted) {
          mustInclude = true;
        } else {
          mustInclude = false;
        }
      }

      // TypeCode
      if (!item.TypeCode.toLowerCase().startsWith(this.filterForm.controls["fTypeCode"].value.toString().toLowerCase()) &&
        this.filterForm.controls["fTypeCode"].value !== "" && mustInclude) {
        mustInclude = false;
      }

      // TypeName
      if (!item.TypeName.toLowerCase().startsWith(this.filterForm.controls["fTypeName"].value.toString().toLowerCase()) &&
        this.filterForm.controls["fTypeName"].value !== "" && mustInclude) {
        mustInclude = false;
      }


      // CleaningGroup 
      if (parseInt(this.filterForm.controls["fCleaningGroupId"].value) == -1 && mustInclude) {
        mustInclude = true;
      }
      else {
        if (item.CleaningGroupId !== parseInt(this.filterForm.controls["fCleaningGroupId"].value)) {
          mustInclude = false;
        }
      }
      if (mustInclude) {
        tmpArr.push(item);
      }
    });
    // update filter line
    if (this.filterForm.controls["fActive"].value) {
      this.filterCaptions.push(this.translate.instant("common.active"));
    }
    if (this.filterForm.controls["fAutoAccepted"].value) {
      this.filterCaptions.push(this.translate.instant("K912p6.auto_accepted"));
    }
    this.filterCaptions.push(this.translate.instant("common.group") + ": " + this.cleaningGroups.find(item => item.GroupId == parseInt(this.filterForm.controls["fCleaningGroupId"].value)).GroupName);

    if (this.filterForm.controls["fTypeCode"].value !== "") {
      this.filterCaptions.push(this.translate.instant("common.code") + ": " + this.filterForm.controls["fTypeCode"].value);
    }
    if (this.filterForm.controls["fTypeName"].value !== "") {
      this.filterCaptions.push(this.translate.instant("common.appellation") + ": " + this.filterForm.controls["fTypeName"].value);
    }
    // 
    this.editorList = JSON.parse(JSON.stringify(tmpArr)) as CleaningType[];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;
  }


  loadAll() { 
    const combinedSources = combineLatest({
      cleaningGroups: this.cleaningGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      cleaningTypes: this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    })
    combinedSources.pipe(takeUntil(this.destroy$)).subscribe(value => {
        this.allCleaningTypes = (value.cleaningTypes as RequestResult).Data as Array<CleaningType>;
        this.cleaningGroups = (value.cleaningGroups as RequestResult).Data as Array<CleaningGroup>;
        this.cleaningGroups.unshift(
          {
            GroupId: -1,
            GroupName: this.translate.instant("common.all"),
            HotelId: this.sessionStorageService.getSelectedHotelId(),
            Subscriber: this.sessionStorageService.getSelectedUnit(),
            SData: ''
          })
        this.loaded = true;
        this.filterForm.controls["fCleaningGroupId"].patchValue(this.cleaningGroups[0].GroupId);
        this.applyFilter();
    })

  }

  showAddOrEditMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }

  booleanCell( val: boolean) {
    if(val) {
        return {'mdi mdi-check' : true,
        'boolean-cell':true
    
    }
    }
  }
  //#endregion =================================================================================================================


}
