import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'date-chips',
  templateUrl: './date-chips.component.html',
  styleUrls: ['./date-chips.component.css']
})


export class DateChipsComponent  {
  @Input() dateFrom: string;
  @Input() dateTo: string;

}
