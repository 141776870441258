// Angular
import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

// 3rdparty
import { MenuItem } from '@app/core/interfaces/MenuItem';
import { AuthService } from '@app/core/services/auth.service';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { RoleType } from '@app/shared/enums/roles';
import { RoleService } from '@app/shared/services/role.service';

// Hostware
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'task-menu',
  templateUrl: './task-menu.component.html',
  styleUrls: ['./task-menu.component.css']
})
export class TaskMenuComponent implements OnInit {

  @ViewChild("menu") menu: MatMenu;
  @Input() taskState: string;

  @Input() accepted: boolean;

  @Input() assignedToId: number;
  @Input() supervisorId: number;
  @Input() translations: any;
  @Input() cleaningTypeId: number;
  @Input() mode: string;
  @Input() needCleaning: boolean;
  @Output() startCleaning: EventEmitter<void> = new EventEmitter();
  @Output() endCleaning: EventEmitter<void> = new EventEmitter();
  @Output() pauseCleaning: EventEmitter<void> = new EventEmitter();
  @Output() reAssign: EventEmitter<void> = new EventEmitter();
  @Output() modify: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() accept: EventEmitter<void> = new EventEmitter();

  @Output() makePhoto: EventEmitter<void> = new EventEmitter();
  // translations : any = {};
  @Output() allowCleaning: EventEmitter<void> = new EventEmitter();
  @Output() denyCleaning: EventEmitter<void> = new EventEmitter();

  @Output() editComment: EventEmitter<void> = new EventEmitter();

  @Output() showHistory: EventEmitter<void> = new EventEmitter();

  cleaningActions: MenuItem[] = [];
  maintenanceActions: MenuItem[] = [];
  isCleaning: boolean;

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private translate: TranslateService
  ) { }




  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.buildCleaningMenu();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  buildCleaningMenu() {

    this.cleaningActions = [];


    switch (this.taskState) {
      case TaskStates.Created: {
        if (this.assignedToId == this.authService.getUserIdFromToken()) {
          this.cleaningActions.push({
            label: this.translate.instant("common.start"), icon: 'mdi mdi-play-circle-outline', id: "startCleaning"
          });

        }
        if (this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          // this.cleaningActions.push({
          //   label: this.translate.instant("common.re_assign"), icon: 'mdi mdi-arrow-expand-right', id: "reAssign"
          // });
          this.cleaningActions.push({
            label: this.translate.instant("common.modify"), icon: 'mdi mdi-border-color', id: "modify"
          });
          this.cleaningActions.push({
            label: this.translate.instant("common.delete"), icon: 'mdi mdi-delete', id: "delete"
          });
        }
      }
        break;

      case TaskStates.Running: {
        if (this.assignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.cleaningActions.push({
            label: this.translate.instant("common.end"), icon: 'mdi mdi-radiobox-marked', id: "endCleaning"
          });
          this.cleaningActions.push({
            label: this.translate.instant("common.pause"), icon: 'mdi mdi-pause-circle-outline', id: "pauseCleaning"
          });

        }
      }
        break;
      case TaskStates.Paused: {
        if (this.assignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.cleaningActions.push({
            label: this.translate.instant("common.start"), icon: 'mdi mdi-play-circle-outline', id: "startCleaning"

          });
          this.cleaningActions.push({
            label: this.translate.instant("common.end"), icon: 'mdi mdi mdi-radiobox-marked', id: "endCleaning"
          });
        }
      }
        break;

      case TaskStates.Ended: {
        if (this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.cleaningActions.push({
            label: this.translate.instant("common.inspected"), icon: 'mdi mdi-check', id: "accept"
          });
        }
      }
        break;

    }
    if (this.needCleaning == true) {
      if ((this.taskState !== TaskStates.Ended) && (this.taskState !== TaskStates.Accepted)) {
        if (this.assignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.cleaningActions.push({
            label: this.translate.instant("common.dnd"), icon: 'mdi mdi-minus-circle-off', id: "denyCleaning"
          });
        }
      }
    }
    else {
      if ((this.taskState !== TaskStates.Ended) && (this.taskState !== TaskStates.Accepted)) {
        if (this.assignedToId == this.authService.getUserIdFromToken() || this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.cleaningActions.push({
            label: this.translate.instant("common.dontdnd"), icon: 'mdi mdi-broom', id: "allowCleaning"
          });
        }
      }
    }
    this.cleaningActions.push({
      label: this.translate.instant("common.photo"), icon: 'mdi mdi-camera', id: "makePhoto"
    });
    if (this.cleaningActions.length == 0) {
      this.cleaningActions.push({
        label: this.translate.instant("common.no_available_actions"), icon: 'mdi mdi-border-color'
      });
    }


    if ((this.roleService.userIsInRole(RoleType.GONDNOKNO) && this.supervisorId == this.authService.getUserIdFromToken() ||
      this.roleService.userIsInRole(RoleType.TAKARITO) && this.assignedToId == this.authService.getUserIdFromToken()
    )) {
      this.cleaningActions.push({
        label: this.translate.instant("K200p.edit_comment"), icon: 'mdi mdi-border-color', id: "editComment"
      });
    }


    this.cleaningActions.push({
      label: this.translate.instant("menu.task_history"), icon: 'mdi mdi-view-list-outline', id: "taskHistory"
    });
  }

  selectMenu(id: string) {
    switch (id) {
      case "startCleaning":
        this.startCleaning.emit();
        break;
      case "endCleaning": {
        this.endCleaning.emit();
        break;
      }
      case "pauseCleaning": {
        this.pauseCleaning.emit();
        break;
      }
      case "reAssign": {
        this.reAssign.emit();
        break;
      }
      case "modify": {
        this.modify.emit();
        break;
      }
      case "delete": {
        this.delete.emit();
        break;
      }
      case "accept": {
        this.accept.emit();
        break;
      }
      case "makePhoto": {
        this.makePhoto.emit();
        break;
      }
      case "allowCleaning": {
        this.allowCleaning.emit();
        break;
      }
      case "denyCleaning": {
        this.denyCleaning.emit();
        break;
      }
      case "editComment": {
        this.editComment.emit();
        break;
      }
      case "taskHistory": {
        this.showHistory.emit();
      }

    }
  }
  //#endregion =================================================================================================================
}

/*
 
 
  // translations : any = {};
  
  @Output() denyCleaning: EventEmitter<void> = new EventEmitter();

  @Output() editComment: EventEmitter<void> = new EventEmitter();
*/