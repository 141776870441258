
// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup,FormBuilder, FormControl } from '@angular/forms';

// 3rdparty
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { CleaningType } from '@app/kip/dto/CleaningType';
import { SubscriberParameter } from '@app/kip/dto/SubscriberParameter';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { SubscriberParameterService } from '@app/kip/services/subscriber-parameter.service';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib';
import { SubscriberParamTypes } from '@app/kip/enums/SubscriberParamTypes';
import { TranslateService } from '@ngx-translate/core';
import { GlobalMessageService } from '@app/shared/services/global-message.service';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { RequestResult } from '@app/core/dto/RequestResult';


@AutoUnsubscribe()
@Component({
  selector: 'subscriber-parameter-editor',
  templateUrl: './subscriber-parameter-editor.component.html',
  styleUrls: ['./subscriber-parameter-editor.component.scss']
})
export class SubscriberParameterEditorComponent implements OnInit,OnDestroy {

  editorForm: FormGroup;

  hotelUnitChanged$ : Subscription;
  updateAll$ : Subscription;
  allCleaningTypes: CleaningType[]=[];
  allSubscriberParameters : SubscriberParameter[]=[];
  
  
  
  constructor(
    private sessionStorageService: SessionStorageService,
    private topBarManagerService: TopBarManagerService,
    private cleaningTypeService: CleaningTypeService,
    private hwAlertService: HwAlertService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private subsParamService : SubscriberParameterService,
     private crudMessageService : CrudMessagesService
  ) {

    this.editorForm = fb.group( {
      'fBaseAmmenityType': new FormControl(''),
      'fSendNotifications' : new FormControl(''),
      'fFroDateFromWeb' : new FormControl('')
    });

   }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=> {
      this.init();
    })
    this.init();
  }
  ngOnDestroy(): void {
    this.hotelUnitChanged$?.unsubscribe();
    this.updateAll$?.unsubscribe(); 
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  init() {
    combineLatest([this.loadAllCleaningTypes(), this.loadSubscriberParameters()]).subscribe(([resCleaningTypes,resSubscriberParameters]) => {
      this.allCleaningTypes = resCleaningTypes.Data as Array<CleaningType>;
      const notUsed : CleaningType = new CleaningType();
      notUsed.TypeId = -1;
      notUsed.TypeName =  this.translate.instant("common.not_set");
      this.allCleaningTypes.splice(0,0,notUsed)
      this.allSubscriberParameters = resSubscriberParameters  as Array<SubscriberParameter>;
      const baseAmmenityType =  this.allSubscriberParameters.find( item=> item.Param_Typecode == SubscriberParamTypes.BASE_AMMENITY_TYPE
                                                        && item.HotelId == this.sessionStorageService.getSelectedHotelId() 
                                                        && item.SubscriberCode == this.sessionStorageService.getSelectedUnit());
      const sendNotifications = this.allSubscriberParameters.find( item=> item.Param_Typecode == SubscriberParamTypes.SEND_NOTIFICATIONS
                                                        && item.HotelId == this.sessionStorageService.getSelectedHotelId() 
                                                        && item.SubscriberCode == this.sessionStorageService.getSelectedUnit());
      const froDateFromWeb =   this.allSubscriberParameters.find( item=> item.Param_Typecode == SubscriberParamTypes.FRO_DATE_FROM_WEB
        && item.HotelId == this.sessionStorageService.getSelectedHotelId() 
        && item.SubscriberCode == this.sessionStorageService.getSelectedUnit());

      const ignoreMultiHotel =    this.allSubscriberParameters.find( item=> item.Param_Typecode == SubscriberParamTypes.IGNORE_MULTI_HOTEL
        && item.HotelId == this.sessionStorageService.getSelectedHotelId() 
        && item.SubscriberCode == this.sessionStorageService.getSelectedUnit());

      if( baseAmmenityType) {
        this.editorForm.controls["fBaseAmmenityType"].setValue(baseAmmenityType.Param_Int_Value);
      }
      else {
        let newItem = new SubscriberParameter();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        newItem.Id = -1,
        newItem.Param_Int_Value = -1;
        newItem.Param_Typecode = SubscriberParamTypes.BASE_AMMENITY_TYPE;
        newItem.SubscriberCode = this.sessionStorageService.getSelectedUnit();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        this.allSubscriberParameters.push(newItem);
        this.editorForm.controls["fBaseAmmenityType"].patchValue("-1");
      }

      if( sendNotifications)  {
        this.editorForm.controls["fSendNotifications"].patchValue( sendNotifications.Param_Int_Value === 0 ? false : true);
      } else {
        let newItem = new SubscriberParameter();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        newItem.Id = -1,
        newItem.Param_Int_Value = 1;
        newItem.Param_Typecode = SubscriberParamTypes.SEND_NOTIFICATIONS;
        newItem.SubscriberCode = this.sessionStorageService.getSelectedUnit();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        this.allSubscriberParameters.push(newItem);
        this.editorForm.controls["fSendNotifications"].patchValue(true);
      }

      if( froDateFromWeb)  {
        this.editorForm.controls["fFroDateFromWeb"].patchValue( froDateFromWeb.Param_Int_Value === 0 ? false : true);
      } else {
        let newItem = new SubscriberParameter();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        newItem.Id = -1,
        newItem.Param_Int_Value = 0;
        newItem.Param_Typecode = SubscriberParamTypes.FRO_DATE_FROM_WEB;
        newItem.SubscriberCode = this.sessionStorageService.getSelectedUnit();
        newItem.HotelId = this.sessionStorageService.getSelectedHotelId();
        this.allSubscriberParameters.push(newItem);
        this.editorForm.controls["fFroDateFromWeb"].patchValue(true);
      }
    })

  }


  loadAllCleaningTypes() : Observable<any> {
    return this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId()).pipe(map((result)=> {
      return result;
    }))
  }
  loadSubscriberParameters() : Observable<any> {
    return this.subsParamService.loadAll(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId()).pipe(map((result)=> {
      return result;
    })) 
  }


  updateAll() {
    this.allSubscriberParameters.find(item=>item.Param_Typecode == SubscriberParamTypes.BASE_AMMENITY_TYPE).Param_Int_Value 
        = parseInt(this.editorForm.controls["fBaseAmmenityType"].value);

        
    this.allSubscriberParameters.find(item=>item.Param_Typecode == SubscriberParamTypes.SEND_NOTIFICATIONS).Param_Int_Value 
        =  this.editorForm.controls["fSendNotifications"].value === true ? 1:0;

    this.allSubscriberParameters.find(item=>item.Param_Typecode == SubscriberParamTypes.FRO_DATE_FROM_WEB).Param_Int_Value 
        =  this.editorForm.controls["fFroDateFromWeb"].value === true ? 1:0;


    this.updateAll$ =  this.subsParamService.updateAll(this.allSubscriberParameters).subscribe( result=> {
      const res = result as RequestResult;
      this.crudMessageService.showAddOrEditMessage(res)
    })
   
  }

  submit() {
    this.updateAll();
  }
  //#endregion =================================================================================================================
   

}
 