import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CleaningTask } from '../dto/CleaningTask';

@Injectable({
  providedIn: 'root'
})
export class CleaningTaskService {

  constructor(private http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningTask/LoadAll/' + subscriber + "/" + hotelId);
  }


  getCleaningTaskById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/CleaningTask/GetById');
  }

  addCleaningTask(data: CleaningTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningTask/Add', data, { headers });
  }

  updateCleaningTask(data: CleaningTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningTask/Update', data, { headers });
  }

  deleteCleaningTask(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/CleaningTask/Delete/' + ids);
  }


  cleaningTaskCodeExists(subscriber: string, hotelId: number, cleaningTaskCode: string) {
    return this.http.get(this.apiServer + '/api/kip/CleaningTask/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + cleaningTaskCode);
  }

  cleaningTaskNameExists(subscriber: string, hotelId: number, cleaningTaskName: string) {
    return this.http.get(this.apiServer + '/api/kip/CleaningTask/GetByTypeName/' + subscriber + "/" + hotelId + "/" + cleaningTaskName);
  }


}
