// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, ValidatorFn ,ValidationErrors, Validators } from '@angular/forms';

// 3rdparty
import { Subscription, Observable, combineLatest, Subject, takeUntil } from 'rxjs';
import { CalendarEvent, DAYS_OF_WEEK, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import dayjs from 'dayjs';

// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { TaskSchedulerCalendarService } from '@app/kip/services/task-scheduler-calendar.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { FormStateService } from '@app/kip/services/form-state.service';
import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { vwTaskSchedulerItem } from '@app/kip/viewdto/vwTaskSchedulerItem';
import { RequestResult } from '@app/core/dto/RequestResult';
import HwUtils from '@app/shared/helpers/hwutils';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { AddTaskQueryParam } from '@app/kip/classes/AddTaskParam';
import { FormOperationMode } from '@app/core/enums/form-operation-mode';
import { MatDialog } from '@angular/material/dialog';
import { AddTaskDialogComponent } from '@app/kip/dialogs/add-task-dialog/add-task-dialog.component';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RoleService } from '@app/shared/services/role.service';
import { RoleType } from '@app/shared/enums/roles';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
@AutoUnsubscribe()
@Component({
  selector: 'task-scheduler-calendar',
  templateUrl: './task-scheduler-calendar.component.html',
  styleUrls: ['./task-scheduler-calendar.component.scss']
})
export class TaskSchedulerCalendarComponent implements OnInit,OnDestroy {

  @ViewChild('fTaskType', {static:true}) slideT : MatSlideToggle
  froDatum: string;
  selectedTaskTypeName: string;
  selectedTaskTypeCode: string = "H";
  selectedTaskStateCode: string ="NAC"; // Active TaskState != 'Accepted'    vagy 'ALL' == Összes
  selectedTaskStateName: string;
  taskTypeTitle : string;
  isCleaning : boolean;
  addTaskParam : AddTaskQueryParam;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SUNDAY, DAYS_OF_WEEK.SATURDAY];

  currCalendarLocale: any;
  locale: string;

  maintenanceGroups: MaintenanceGroup[];
  resultList: vwTaskSchedulerItem[] = [];
  changedItems: vwTaskSchedulerItem[] = [];
  hooveredItem: CalendarEvent;
  
  cleaningGroups: CleaningGroup[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  refresh: Subject<any> = new Subject();
  schedulerForm: FormGroup;
  viewDate: Date = new Date();
  url:string;
  events: CalendarEvent[] = [];
  origEvents: CalendarEvent[] = [];
  loaded: boolean;
  loadFroDatum$: Subscription;
  getScheduleItems$: Subscription;
  saveScheduleItems$: Subscription;
  hotelUnitChanged$: Subscription;
  getOneTaskState$ : Subscription;
  constructor(

    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
     private topBarManagerService: TopBarManagerService,
     private cleaningGroupService: CleaningGroupService,
     private taskSchedulerCalendarService: TaskSchedulerCalendarService,
     private dailyTaskService: DailyTaskService,
     private hokParamsService: HokParamsService,
     private maintenanceGroupService: MaintenanceGroupService,
     private formStateService: FormStateService,
     private crudMessageService: CrudMessagesService,
     private router: Router,
     private fb: FormBuilder,
     private addTaskDialog : MatDialog,
     private roleService: RoleService,
     private cd: ChangeDetectorRef
  ) {

    this.schedulerForm = this.fb.group({
      'fTaskType': new FormControl(''),
      'fTaskState': new FormControl(true),
      'fCleaningGroup': new FormControl(""),
      'fMaintenanceGroup': new FormControl(""),
      'fDateFrom': new FormControl("",[Validators.required]),
      'fDateTo': new FormControl("",[Validators.required])
    })
   
    this.url= this.router.url;
   }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.locale = this.sessionStorageService.getUserLanguage();
    if(this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
      //this.schedulerForm.controls["fTaskType"].setValue(true)
      this.selectedTaskTypeCode = "H"
      this.isCleaning = true;
    }
    if(this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
      //this.schedulerForm.controls["fTaskType"].setValue(false)
      this.selectedTaskTypeCode = "M"
      this.isCleaning = false;
    }
   // this.cd.detectChanges();
    this.taskTypeTitle =   this.isCleaning ? this.translate.instant("common.cleaning") : this.translate.instant("common.maintenance")
    this.init();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(result=> {
      this.init();
    })
  }
  ngOnDestroy(): void {
    this.loadFroDatum$?.unsubscribe();
    this.getScheduleItems$?.unsubscribe();
    this.saveScheduleItems$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
    this.getOneTaskState$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  addTask() {
    
    this.addTaskParam = new AddTaskQueryParam();
    this.addTaskParam.returnUrl = this.url;
    this.addTaskParam.mode = FormOperationMode.OperationModeAdd;
    this.addTaskParam.dailyTypeId = -1;
    this.addTaskParam.placeNumber = "-1";
    this.addTaskParam.placeTypeCode ="R" ;
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "100vw",
      // height: "80vh",
      panelClass: "add-task-dialog",
      
      data: this.addTaskParam
    })
    dialogRef.afterClosed().subscribe( result=> {
      if( result) {
              const res = result as RequestResult;
      this.crudMessageService.showAddOrEditMessage(res);
      this.applyFilter();
      }

  })}

  editTask( event: CalendarEvent) {
    let  state: TaskStates =  this.resultList.find(item=> item.TypeId == event.id ).TaskState as TaskStates ;
    if( state == TaskStates.Accepted) {
      return
    } 
    
    this.getOneTaskState$ = this.dailyTaskService.loadOneTaskState(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), parseInt(event.id.toString())).subscribe( result=> {
      const res = result as RequestResult;
      const oneTask = res.Data as vwTaskListItem;
      this.addTaskParam = new AddTaskQueryParam();
      this.addTaskParam.returnUrl = this.url;
      this.addTaskParam.mode = FormOperationMode.OperationModeEdit;
      this.addTaskParam.placeNumber = oneTask.Room.RoomNumber == null ? oneTask.PublicArea.AreaCode : oneTask.Room.RoomNumber;
      this.addTaskParam.placeTypeCode = oneTask.Room.RoomNumber == null ? "P" : "R";

      this.addTaskParam.dailyTypeId = parseInt(event.id.toString());
      //addTaskParam.placeTypeCode = this.resultList.find( item=> item.TypeId == event.id).
      const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "100vw",
        // height: "80vh",
        panelClass: "add-task-dialog",
        data: this.addTaskParam
      })
      dialogRef.afterClosed().subscribe( result=> {
        if(result) {
                const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        this.applyFilter();
  
        }
  
    })
  
    })

}

  // taskTypeChanged(e) {
  //   if (e.checked) {
  //     this.selectedTaskTypeCode = "M";
  //     this.selectedTaskTypeName = this.translate.instant("common.maintenance");
  //   }
  //   else {
  //     this.selectedTaskTypeCode = "H";
  //     this.selectedTaskTypeName = this.translate.instant("common.cleaning");

  //   }
  // }

  taskStateChanged(e) {
      if (e.checked) {
        this.selectedTaskStateCode = "NAC";
        this.selectedTaskStateName =  this.translate.instant("K950.opened");
      }
      else {
        this.selectedTaskStateCode = "ALL";
        this.selectedTaskStateName =  this.translate.instant("common.all");
      }
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    
    if(!event.draggable) {
      return;
    }
    this.events = this.events.map(iEvent => { 
      if (iEvent === event)  {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
  }

  saveAll() {
    let itemsToDownload: vwTaskSchedulerItem[] = [];
    this.events.forEach(eventItem => {
      let origItem: CalendarEvent = this.origEvents.find(item => item.id == eventItem.id);
      if ((origItem.start !== eventItem.start) || (origItem.end !== eventItem.end)) {
        let origItem: vwTaskSchedulerItem = this.resultList.find(schItem => schItem.TypeId == eventItem.id);
        origItem.DueDate = dayjs(eventItem.start.toDateString()).format("YYYY.MM.DD hh:mm").toString();
        itemsToDownload = [...itemsToDownload, origItem];
      }
    })
    this.saveScheduleItems$ = this.taskSchedulerCalendarService.saveIntervalTasks(itemsToDownload).subscribe(result => {
      let res = result as RequestResult;
      this.crudMessageService.showAddOrEditMessage(res);
      this.applyFilter();
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================
    
  //#region =============== Validation    ======================================================================================
  
  isDateIntervalValid() : ValidatorFn  {
    return (group: FormGroup) =>  ValidationErrors => {
      if (dayjs(group.controls['fDateFrom'].value).isAfter(dayjs(group.controls['fDateTo'].value).format('YYYY.MM.DD'))) {
        group.controls['fDateFrom'].setErrors({ DateRangeError: true });
      }
      else {
        group.controls['fDateFrom'].setErrors(null)
      }
     
    }
  }
  
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  init() {
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(event => {
      const hokpResult = event as RequestResult;
      this.froDatum = hokpResult.Data as string;
      this.viewDate = new Date(this.froDatum);
      const yearmonth = this.froDatum.substring(0, 7);
      const d = dayjs(this.froDatum);
      let firstDay = dayjs(yearmonth + "." + "01","YYYY.MM.DD");
      
      this.schedulerForm.controls["fDateFrom"].setValue(dayjs(yearmonth + "." + "01","YYYY.MM.DD"));
      this.schedulerForm.controls["fDateTo"].setValue(dayjs(yearmonth + "." + HwUtils.leftPad(d.daysInMonth(), 2),"YYYY.MM.DD"));
     // this.selectedTaskTypeName = this.translate.instant("common.maintenance");
      this.selectedTaskStateName = this.translate.instant("K950.opened");
      this.loadCodeTables();
    })
  }
  applyFilter() {
    if (!this.schedulerForm.valid) {
      return;
    }
    this.viewDate = new Date(this.schedulerForm.controls["fDateFrom"].value);
    this.resultList = [];
    this.events = [];
    this.origEvents = [];
    let groupId: number;
   
    if ( this.selectedTaskTypeCode == 'H') {
      groupId = parseInt(this.schedulerForm.controls["fCleaningGroup"].value);
      this.taskTypeTitle = this.translate.instant("common.cleaning") 
    }
    else {
      groupId = parseInt(this.schedulerForm.controls["fMaintenanceGroup"].value);
      this.taskTypeTitle = this.translate.instant("common.maintenance") 
    }
    // moment(this.editorForm.controls["DateFrom"].value).format("YYYY.MM.DD
    //this.selectedTaskTypeCode = this.schedulerForm.controls["fTaskType"].value == true ? "H" : "M"
    //this.selectedTaskTypeName = this.schedulerForm.controls["fTaskType"].value == false ? this.translate.instant("common.cleaning") : this.translate.instant("common.maintenance")

    this.getScheduleItems$ = this.taskSchedulerCalendarService.getSchedulerItems(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),
      dayjs(this.schedulerForm.controls["fDateFrom"].value).format("YYYY.MM.DD"),
      dayjs(this.schedulerForm.controls["fDateTo"].value).format("YYYY.MM.DD"), this.selectedTaskTypeCode, groupId).subscribe(result => {
        let res = result as RequestResult;
        let dateParts: string[];
        this.events=[];
        this.origEvents = [];
        if (res) {
          if (res.Code == 0) {
            this.resultList = res.Data as vwTaskSchedulerItem[];
            let oneEvent: CalendarEvent;

            this.resultList.forEach(item => {
              if(this.selectedTaskStateCode == 'NAC') {
                if( item.TaskState != TaskStates.Accepted) {
                  dateParts = item.DueDate.substring(0, 10).split('.');
                  oneEvent = {
                    start: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
                    end: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
                    title: item.TypeName,
                    id: item.TypeId,
                    draggable : this.draggable(item.TypeId.toString())
                  };
                  
                  this.events = [...this.events, oneEvent];
                  this.origEvents = [...this.events, oneEvent];
                }

              } else {
                dateParts = item.DueDate.substring(0, 10).split('.');
                oneEvent = {
                  start: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
                  end: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
                  title: item.TypeName,
                  id: item.TypeId,
                  draggable : this.draggable(item.TypeId.toString())
                };
                this.events = [...this.events, oneEvent];
                this.origEvents = [...this.events, oneEvent];
              }

            })
          
          }
        }
     
      })
  }
  loadCodeTables() {
    combineLatest({
      allCleaningGroups:this.cleaningGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      allMaintenenceGroups: this.maintenanceGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    }).pipe(takeUntil(this.destroy$)).subscribe( 
        value=> {
          this.cleaningGroups= (value.allCleaningGroups as RequestResult).Data as Array<CleaningGroup>;
          this.maintenanceGroups= (value.allMaintenenceGroups as RequestResult).Data as Array<MaintenanceGroup>;
          const allGroup = { GroupId: -1, Subscriber: "", HotelId: 1, GroupName: this.translate.instant("common.all"), SData: "" }
          this.cleaningGroups.splice(0, 0, allGroup);
          this.maintenanceGroups.splice(0, 0, allGroup);
          this.schedulerForm.controls["fCleaningGroup"].setValue(-1);
          this.schedulerForm.controls["fMaintenanceGroup"].setValue(-1);

          let filter = this.formStateService.getTaskSchedulerCalendarFilter();
          // csak ha volt már inicializálva
          if( filter.taskTypeCode) {
            this.schedulerForm.controls["fDateFrom"].setValue(filter.dateFrom);
            this.schedulerForm.controls["fDateTo"].setValue(filter.dateTo);
            this.selectedTaskStateCode = filter.taskTypeCode;
            // if (filter.taskTypeCode == "H") {
            //   this.selectedTaskTypeCode  this.schedulerForm.controls["fTaskType"].setValue(true);
            // }
            // else {
            //   this.selectedTaskTypeCode = "M";
            //   this.schedulerForm.controls["fTaskType"].setValue(false);
            // }
            
            if (  this.selectedTaskTypeCode == 'H' ) {
              this.schedulerForm.controls["fCleaningGroup"].setValue(filter.groupId.toString());
            }
            else {
              this.schedulerForm.controls["fMaintenanceGroup"].setValue(filter.groupId.toString());
            }
          }

          this.applyFilter();
        }
    )
  }

  draggable(typeId: string) {
    let  state: TaskStates =  this.resultList.find(item=> item.TypeId == parseInt(typeId) ).TaskState as TaskStates ;
  
    if( state == TaskStates.Accepted ) {
      return false;  
    } else {
      return true;
    }
  }

  areaName(typeId: number): string {
    let ret = this.resultList.find(item => item.TypeId == typeId).AreaName;
    return ret;
  }

  comment(typeId: number): string {
    let ret = this.resultList.find(item => item.TypeId == typeId).Comment;
    return ret;
  }

  commentChunk(typeId: number): string {
    let ret = this.resultList.find(item => item.TypeId == typeId).Comment;
    if (ret?.length > 15) {
      ret = ret.substring(0, 15) + '...';
    }
    return ret;
  }
  /* style functions */
  dayClass(d: Date) {
    if (d.getDay() == 0 || d.getDay() == 6) {
      return { "weekend-day": true }
    }
    else {
      return { "week-day": true }
    }
  }  

  editIconStyle( event: CalendarEvent) {
    let styles = {};
    let  state: TaskStates =  this.resultList.find(item=> item.TypeId == event.id ).TaskState as TaskStates ;
    
    if( state == TaskStates.Accepted ) {
      styles["cursor"]= "not-allowed";
    } else {
      styles["cursor"]= "pointer";
    }
    return styles
  }

  cardBorderStyle( event: CalendarEvent) {
    let styles = {};
    let  state: TaskStates =  this.resultList.find(item=> item.TypeId == event.id ).TaskState as TaskStates ;
    if( state == TaskStates.Accepted ) {
      styles["border-color"]= "green";
      styles["color"]="green";
    } else {
      styles["border-color"]= "red";
      styles["color"]="red";
    }
    return styles
  }

  cardTitleStyle(event: CalendarEvent) {
    let styles = {};
    let  state: TaskStates =  this.resultList.find(item=> item.TypeId == event.id ).TaskState as TaskStates ;
    if( state == TaskStates.Accepted ) {
      styles["color"]= "green";
    } else {
      styles["color"]= "red";
    }
    return styles
  }

  taskStateTitle(event: MatSlideToggle) : string {
    return event.checked ? this.translate.instant("K950.opened") : this.translate.instant("common.all")
  }

  taskTypeChange( event :MatSlideToggleChange ) {
    this.selectedTaskTypeCode = event.checked ?  "H" : "M"
    this.taskTypeTitle =  event.checked ? this.translate.instant("common.cleaning") : this.translate.instant("common.maintenance")
    // this.schedulerForm.controls['fTaskType'].setValue(event.checked ?  true :false)  
    this.cd.detectChanges();
  }

  //#endregion =================================================================================================================



}
