import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({providedIn:'root'})
export class AdminService {
  apiServer = environment.apiHost + environment.apiPort;
constructor(private http : HttpClient) {

 }

 syncGuestImportance(subscriber: string, hotelId: number) {
  return this.http.get(this.apiServer + '/admin/sync/SyncGuestImportance/' + subscriber + "/" + hotelId);  
 }

 syncMinibarItems(subscriber: string, hotelId: number) {
  return this.http.get(this.apiServer + '/admin/sync/syncMinibarItems/' + subscriber + "/" + hotelId);
 }
}
