import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions } from "@angular/http";


//HW
import { environment } from "../../../environments/environment";
import { MaintenanceTask } from '../dto/MaintenanceTask';
@Injectable({
  providedIn: 'root'
})
export class MaintenanceTaskService {


  constructor(public http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;

  getMaintenanceTaskById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceTask/GetById');
  }

  addMaintenanceTask(data: MaintenanceTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceTask/Add', data, { headers });
  }

  updateMaintenanceTask(data: MaintenanceTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceTask/Update', data, { headers });
  }

  deleteMaintenanceTask(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/MaintenanceTask/Delete/' + ids);
  }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceTask/LoadAll/' + subscriber + "/" + hotelId);
  }

  maintenanceTaskCodeExists(subscriber: string, hotelId: number, MaintenanceTaskCode: string) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceTask/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + MaintenanceTaskCode);
  }

  maintenanceTaskNameExists(subscriber: string, hotelId: number, MaintenanceTaskName: string) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceTask/GetByTypeName/' + subscriber + "/" + hotelId + "/" + MaintenanceTaskName);
  }



}
