
// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// 3rdparty
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { VwRcDashboard } from '@app/kip/viewdto/vwRcDashBoard';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RсDashboardService } from '@app/kip/services/rс-dashboard.service';
import { FormStateService } from '@app/kip/services/form-state.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { vwRoomStateListFilter } from '@app/kip/viewdto/vwRoomStateListFilter';


@AutoUnsubscribe()
@Component({
  selector: 'recepcionist-landing-page',
  templateUrl: './recepcionist-landing-page.component.html',
  styleUrls: ['./recepcionist-landing-page.component.scss']
})
export class RecepcionistLandingPageComponent implements OnInit, OnDestroy {

  
  loadAll$: Subscription;
  hotelUnitChanged$ : Subscription;
  rcDashboardData: VwRcDashboard;
  loaded: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private rcDashboardService: RсDashboardService,
    private formStateService: FormStateService,
    private topBarManagerService : TopBarManagerService,
    private router: Router

  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.topBarManagerService.setTopbarBackgroundVisibility(true);
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=> {
      this.loadAll();
    })
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.loadAll$?.unsubscribe();
    this.hotelUnitChanged$.unsubscribe();      
  }
  //#endregion =================================================================================================================

//#region =============== List event hadlers   ===============================================================================
//#endregion =================================================================================================================

//#region =============== Form events ========================================================================================
//#endregion =================================================================================================================

//#region =============== Dialog events ======================================================================================
//#endregion =================================================================================================================
  
//#region =============== Validation    ======================================================================================
//#endregion =================================================================================================================

//#region =============== Functions  =========================================================================================


//#endregion =================================================================================================================
loadAll() {
  this.loadAll$ = this.rcDashboardService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
    var res = result as RequestResult;
    if (res) {
      this.rcDashboardData = res.Data as VwRcDashboard;
      this.loaded=true;
    }
  });
}

navigateToRoomStateListByRoomState(state: string) {
  this.formStateService.resetVwRoomStateFilter();
  let filter : vwRoomStateListFilter = this.formStateService.getVwRoomStateListFilter();
  switch(state) {
    case "arrival" :{
      filter.Checked = false;
      filter.CheckedOut = false;
      filter.Checkin = true;
      filter.Checkout = false;
      filter.EarlyCheckin = true;
      filter.LateCheckout = false;
      filter.Occupied = false;
      filter.RoomEmpty = false;
      break;
    }
    case "occupied": {
      filter.Checked = false;
      filter.CheckedOut = false;
      filter.Checkin = false;
      filter.Checkout = false;
      filter.EarlyCheckin = false;
      filter.LateCheckout = false;
      filter.Occupied = true;
      filter.RoomEmpty = false;
      break;
    }
   
    case "departure":{
      filter.Checked = false;
      filter.CheckedOut = true;
      filter.Checkin = false;
      filter.Checkout = false;
      filter.EarlyCheckin = false;
      filter.LateCheckout = true;
      filter.Occupied = false;
      filter.RoomEmpty = false;
      break;
      
    }
    
    case "empty": {
      filter.Checked = false;
      filter.CheckedOut = false;
      filter.Checkin = false;
      filter.Checkout = false;
      filter.EarlyCheckin = false;
      filter.LateCheckout = false;
      filter.Occupied = false;
      filter.RoomEmpty = true;
      break;
     
    }
    
    case "ooo" : {
      filter.RoomClean = false;
      filter.RoomDirty = false;
      filter.RoomOOO = true;
      filter.RoomOOS = false;
      filter.RoomOk = false;
      filter.RoomShowRoom = false;
      break;
    }
    
    case "oos" : {
      filter.RoomClean = false;
      filter.RoomDirty = false;
      filter.RoomOOO = false;
      filter.RoomOOS = true;
      filter.RoomOk = false;
      filter.RoomShowRoom = false;

      break;
    }
    
    case "show-rooms" : {
      filter.RoomClean = false;
      filter.RoomDirty = false;
      filter.RoomOOO = false;
      filter.RoomOOS = false;
      filter.RoomOk = false;
      filter.RoomShowRoom = true;

    }
  }
  this.router.navigateByUrl(this.router.createUrlTree(["kip/roomstatelist"], { queryParams: {roomstate:state,placeType:'R'}} ))
}
}
