// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#calendar_header_month_title {
  text-transform: capitalize;
  font-weight: 600;
  text-align: end;
  padding-right: 1em;
  padding-top: 0.5em;
}

@media (max-width: 599px) {
  #calendar_header_month_title {
    text-align: start;
    padding-right: 1em;
    padding-top: 0em;
  }
}
@media screen and (max-width: 1279px) and (min-width: 768px) {
  .calendar-update {
    text-align: left;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
