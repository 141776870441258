import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PublicAreaCleaningType } from '../dto/PublicAreaCleaningType';

@Injectable({
  providedIn: 'root'
})
export class PublicAreaCleaningTypeService {

  apiServer = environment.apiHost + environment.apiPort;
constructor(public http: HttpClient) { }

  getPublicAreaCleaningTypeById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/PublicAreaCleaningType/GetById' ); 
  }
  addPublicAreaCleaningType(data: PublicAreaCleaningType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/PublicAreaCleaningType/Add',data,{headers});
  }
  updatePublicAreaCleaningType( data: PublicAreaCleaningType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/PublicAreaCleaningType/Update',data,{headers});
  }
  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/PublicAreaCleaningType/LoadAll/' + subscriber + "/" + hotelId); 
  }
  loadAllvwPublicAreaCleaningType(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/PublicAreaCleaningType/LoadAllVwPublicAreaCleaningTypesLoadAll/' + subscriber + "/" + hotelId);  
  }
  deletePublicAreaCleaningType(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/PublicAreaCleaningType/Delete/' + ids); 
  }
}
