
// Angular
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { MenuItem } from '@app/core/interfaces/MenuItem';
import { RoleType } from '@app/shared/enums/roles';
import { RoleService } from '@app/shared/services/role.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { ChangePasswordDialogComponent } from '@app/core/dialogs/change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { MinibarService } from '@app/kip/services/minibar.service';
import { AdminService } from '@app/kip/services/admin.service';
import { RequestResult } from '@app/core/dto/RequestResult';


@AutoUnsubscribe()
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  menuButtonVisible: boolean = true;
  messagingEnabled: boolean;
  vibrationsVisible: boolean;
  vibrationsEnabled: boolean;
  processing: boolean;
  sideBarVisible = false;
  menuItems?: MenuItem[];
  menuItemsTemp: MenuItem[];
  userName: string;
  loaded: boolean;

  constructor(
    private roleService: RoleService,
    private translate: TranslateService,
    private authService: AuthService,
    private sideBarService: SidebarService,
    public sessionStorageService: SessionStorageService,
    public topBarManagerService: TopBarManagerService,
    private changePasswordDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private hwAlertService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private adminService: AdminService

  ) {
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.sideBarService.sidebarToggle.subscribe(xx => {
      this.fillModuleMenu('kip')
    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.topBarManagerService.setToggleSideBar(false)
      }
    })
  }



  getSubscriberName() {
    return this.sessionStorageService.getSelectedUnit();
  }

  panelTitleStyle(item: MenuItem) {
    let styles = {};
    if (item.items) {
      styles["cursor"] = "none";
    } else {
      styles["cursor"] = "pointer"
    }
    return styles;
  }
  fillModuleMenu(moduleName: string) {
    this.loaded = false;
    this.userName = this.sessionStorageService.getUserInfo().Name;


    this.menuItemsTemp = [];
    switch (moduleName) {
      case 'kip': {
        if (this.roleService.userIsInRole(RoleType.GONDNOKNO)) {
          this.menuItemsTemp.push(this.mnuTrusteeLandingPage());
          this.menuItemsTemp.push(this.mnuTrusteeTaskList());
          this.menuItemsTemp.push(this.mnuRoomStateList());
          this.menuItemsTemp.push(this.mnuPublicAreasList());
          this.menuItemsTemp.push(this.mnuTrusteeScheduler());
          this.menuItemsTemp.push(this.mnuMiniBar());
          this.menuItemsTemp.push(this.mnuTrusteeSettings());
          this.menuItemsTemp.push(this.mnuTaskSchedulerCalendar());
          this.menuItemsTemp.push(this.mnuReports());
          this.menuItemsTemp.push(this.mnuSync());
        }
        if (this.roleService.userIsInRole(RoleType.TAKARITO)) {
          this.menuItemsTemp.push(this.mnuTrusteeTaskList());
          this.menuItemsTemp.push(this.mnuRoomStateList());
          this.menuItemsTemp.push(this.mnuPublicAreasList());
        }
        if (this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) {
          this.menuItemsTemp.push(this.mnuTechnicalLeaderLandingPage());
          this.menuItemsTemp.push(this.mnuTrusteeTaskList());
          /*this.itemsTemp.push(this.mnuChat());*/
          this.menuItemsTemp.push(this.mnuRoomStateList());
          this.menuItemsTemp.push(this.mnuPublicAreasList());
          this.menuItemsTemp.push(this.mnuTechicalLeaderScheduler());
          this.menuItemsTemp.push(this.mnuTechnicalLeaderSettings());
          this.menuItemsTemp.push(this.mnuTaskSchedulerCalendar());
          this.menuItemsTemp.push(this.mnuReports());
        }
        if (this.roleService.userIsInRole(RoleType.MUSZAKOS)) {
          this.menuItemsTemp.push(this.mnuTrusteeTaskList());
          this.menuItemsTemp.push(this.mnuRoomStateList());
          this.menuItemsTemp.push(this.mnuPublicAreasList());
        }
        if (this.roleService.userIsInRole(RoleType.RECEPCIOS)) {
          this.menuItemsTemp.push(this.mnuReceptionistLandingPage());
          this.menuItemsTemp.push(this.mnuReceptionistTaskList());
          this.menuItemsTemp.push(this.mnuRoomStateList());
          this.menuItemsTemp.push(this.mnuPublicAreasList());
          this.menuItemsTemp.push(this.mnuMiniBar());
          this.menuItemsTemp.push(this.mnuTaskSchedulerCalendar());
          this.menuItemsTemp.push(this.mnuReports());
        }
        if (this.roleService.userIsInRole(RoleType.ADMIN)) {
          this.menuItemsTemp.push(this.mnuAdminSettings());
          this.menuItemsTemp.push(this.mnuAdminDashboardsMenu());
          this.menuItemsTemp.push(this.mnuReports());
          this.menuItemsTemp.push(this.mnuSync());
        }
        break;
      }
      case 'hot': {
        this.menuItems = [
          {
            label: this.translate.instant("menu.roomtype_editor"),
            icon: 'mdi mdi-file-outline',
            routerLink: ['/hot/roomtypeeditor']
            // url: environment.baseUrl + '/hot/roomtypeeditor',
          }
        ]
      }
        break;
      case 'fro': {
        this.menuItems = []
        break;
      }
      case 'root': {
        this.menuItems = []
        break;
      }
    }
    this.menuItemsTemp.push(this.mnuLostAndFound());
    this.menuItems = JSON.parse(JSON.stringify(this.menuItemsTemp)) as MenuItem[];
    this.loaded = true;


    //#endregion ======================================================================================


  }
  canHideToggle(item: MenuItem): boolean {
    return item.items ? false : true;
  }

  mnuItemClick(item: MenuItem) {
    switch (item.id) {
      case "syncguestimportance":
        const guestOptions: HwConfirmationDialogData = {
          cancelButtonCaption: this.translate.instant("common.no"),
          message: this.translate.instant("menu.sync_guest_importance"),
          title: this.translate.instant("common.message"),
          okButtonCaption: this.translate.instant("common.yes")
        }
        this.confirmService.open(guestOptions);
        this.confirmService.confirmed().subscribe(confirmed => {
          if (confirmed) {
            this.adminService.syncGuestImportance(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
              const res = result as RequestResult;
              if (res.Code == 0) {
                this.hwAlertService.info(this.translate.instant("common.sync_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
              } else {
                this.hwAlertService.error(this.translate.instant("common.sync_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
              }
            })
          }
        })
        break;
      case "syncminibar":
        const minibarOptions: HwConfirmationDialogData = {
          cancelButtonCaption: this.translate.instant("common.no"),
          message: this.translate.instant("menu.start_minibar_synch"),
          title: this.translate.instant("common.message"),
          okButtonCaption: this.translate.instant("common.yes")
        }
        this.confirmService.open(minibarOptions);
        this.confirmService.confirmed().subscribe(confirmed => {
          if (confirmed) {
            this.adminService.syncMinibarItems(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
              const res = result as RequestResult;
              if (res.Code == 0) {
                this.hwAlertService.info(this.translate.instant("common.sync_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
              } else {
                this.hwAlertService.error(this.translate.instant("common.sync_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
              }
            })
          }
        })
        break;
      case "synchusers": {
        const sych_user_options: HwConfirmationDialogData = {
          cancelButtonCaption: this.translate.instant("common.no"),
          message: this.translate.instant("menu.synch_users"),
          title: this.translate.instant("common.message"),
          okButtonCaption: this.translate.instant("common.yes")
        }
        this.confirmService.open(sych_user_options);
        this.confirmService.confirmed().subscribe(confirmed => {
          if (confirmed) {
            alert( "Szinkron elindítva")
            // this.adminService.syncMinibarItems(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
            //   const res = result as RequestResult;
            //   if (res.Code == 0) {
            //     this.hwAlertService.info(this.translate.instant("common.sync_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
            //   } else {
            //     this.hwAlertService.error(this.translate.instant("common.sync_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
            //   }
            // })
          }
        })
        break;

      }
    }
    console.log(item.id);
  }
  needLink(item: MenuItem): boolean {
    if (item.id == 'syncminibar' || item.id == 'syncguestimportance' || item.id=='synchusers') {
      return false;
    } else {
      return true;
    }
  }
  //#region menuitems 

  // *********  KIP ***********************
  mnuCleaningTypeEditor(): MenuItem {
    const menuItem: MenuItem = {
      // label: 'Takarítás feladat típusok',
      id: "cleaningtypeeditor",
      label: this.translate.instant("menu.cleaning_type"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/cleaningtypeeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuCleaningTaskEditor() {
    const menuItem: MenuItem = {
      id: "tasklist",
      label: this.translate.instant("menu.maintenance_of_cleaning_tasks"),
      icon: 'mdi mdi-file-multiple mdi-24px',
      routerLink: ['/kip/cleaningtaskeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuMaintenanceTaskEditor(): MenuItem {
    const menuItem: MenuItem = {
      id: "technicaltasks",
      label: this.translate.instant("menu.technical_tasks"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/maintenancetaskeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuMaintenanceTypeEditor() {
    const menuItem: MenuItem = {
      id: "technicaltaskstypes",
      label: this.translate.instant("menu.technical_task_types"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/maintenancetypeeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuCommonProblemTaskEditor() {
    const menuItem: MenuItem = {
      id: "commonproblems",
      label: this.translate.instant("menu.common_problems"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/commonproblemtaskeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuCommonProblemTypeEditor() {
    const menuItem: MenuItem = {
      id: "commonproblemtypes",
      label: this.translate.instant("menu.common_problem_types"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/commonproblemtypeeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuPublicAreaEditor() {
    const menuItem: MenuItem = {
      id: "publicareas",
      label: this.translate.instant("menu.public_areas"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/publicareaeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuHouseKeepingList() {
    const menuItem: MenuItem = {
      id: "housekeepinglist",
      label: this.translate.instant("menu.housekeeping_list"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/housekeepinglist'],

      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuPublicAreasList() {
    const menuItem: MenuItem = {
      id: "publicareas",
      label: this.translate.instant("menu.public_areas"),
      icon: 'mdi mdi-arrow-expand-all mdi-24px',
      routerLink: ['/kip/roomstatelist?placeType=P'],
      queryParams: { 'placeType': 'P' },
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuRoomStateList() {
    const menuItem: MenuItem = {
      id: "roomstatelist",
      label: this.translate.instant("menu.room_state_list"),
      icon: 'mdi mdi-bed mdi-24px',
      routerLink: ['/kip/roomstatelist?placeType=R'],
      queryParams: { 'placeType': 'R' },
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuPublicAreaAndCleaningTypes() {
    const menuItem: MenuItem = {
      id: "publicareasandcleaningtypes",
      label: this.translate.instant("menu.public_areas_and_cleaning_types"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/careacleaningtype'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuTrusteeScheduler() {
    const menuItem: MenuItem = {
      id: "trusteescheduler",
      label: this.translate.instant("menu.trustee_scheduler"),
      icon: 'mdi mdi-briefcase-download mdi-24px',
      routerLink: ['/kip/scheduler?mode=C'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuUserGroupEditor() {
    const menuItem: MenuItem = {
      id: "usergroupeditor",
      label: this.translate.instant("menu.user_group_editor"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/usergroupeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuCleaRoomStateEditor() {
    const menuItem: MenuItem = {
      id: "roomstatetransitionsandcleaningtypes",
      label: this.translate.instant("menu.roomstate_transitions_and_cleaning_types"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/kip/clearoomstateeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuTrusteeLandingPage(): MenuItem {
    const menuItem: MenuItem = {
      id: "dashboard",
      label: this.translate.instant("menu.dashboard"),
      icon: 'mdi mdi-view-dashboard mdi-24px',
      routerLink: ['/kip/trusteelandingpage'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuTrusteeTaskList() {
    const menuItem: MenuItem = {
      id: "tasklist",
      label: this.translate.instant("menu.tasks"),
      icon: 'mdi mdi-file-multiple mdi-24px',
      routerLink: ['/kip/tasklist'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuSettings() {

  }


  //*********** HOT *************/
  mnuRoomTypeEditor() {
    const menuItem: MenuItem = {
      id: "roomtypeeditor",
      label: this.translate.instant("menu.roomtype_editor"),
      icon: 'mdi mdi-file-outline mdi-24px',
      routerLink: ['/hot/roomtypeeditor'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  /* 
    mnuChat() {
      const menuItem : MenuItem = {
        label: this.translate.instant("menu.chat"),
        icon: 'mdi mdi-forum',
        routerLink: ['/kip'],
        routerLinkActiveOptions: { exact: true }
      }
      return menuItem;
    } */
  mnuOOORooms() {
    const menuItem: MenuItem = {
      id: "ooorooms",
      label: this.translate.instant("menu.ooo_rooms"),
      icon: 'mdi mdi-cog mdi-24px',
      routerLink: ['/kip/ooorooms'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuMiniBar() {
    const menuItem: MenuItem = {
      id: "minibar",
      label: this.translate.instant("menu.minibar"),
      icon: 'mdi mdi-fridge mdi-24px',
      routerLink: ['/kip/minibarlist'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuTrusteeSettings() {
    const menuItem: MenuItem = {
      id: "settings",
      label: this.translate.instant("menu.settings"),
      icon: 'mdi mdi-wrench mdi-24px',
      items: [{
        id: "housekeepingsettings",
        label: this.translate.instant("menu.housekeeping_settings"),
        routerLink: ['/kip/housekeepingsettings'],
        routerLinkActiveOptions: { exact: true }
      }
      ]
    }
    return menuItem;
  }

  // technical leader 
  mnuTechnicalLeaderLandingPage() {
    const menuItem: MenuItem = {
      id: "dashboard",
      label: this.translate.instant("menu.dashboard"),
      icon: 'mdi mdi-view-dashboard mdi-24px',
      routerLink: ['/kip/maintenanceleaderlandingpage'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuTechnicalLeaderTaskList() {
    const menuItem: MenuItem = {
      id: "tasklist",
      label: this.translate.instant("menu.tasks"),
      icon: 'mdi mdi-file-multiple mdi-24px',
      routerLink: ['/kip/tasklist'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuTechicalLeaderScheduler() {
    const menuItem: MenuItem = {
      id: "maintenancescheduler",
      icon: "mdi mdi-briefcase-download mdi-24px",
      label: this.translate.instant("menu.maintenance_scheduler"),
      routerLink: ['/kip/scheduler?mode=M'],
      routerLinkActiveOptions: { exact: true },
    }
    return menuItem;
  }

  mnuAdminSchedulerMenu() {
    const menuItem: MenuItem = {
      id: "trusteescheduler",
      label: this.translate.instant("menu.scheduler"),
      routerLinkActiveOptions: { exact: true },
      icon: "mdi mdi-briefcase-download mdi-24px",
      items: [
        {
          id: "maintenancescheduler",
          label: this.translate.instant("menu.maintenance_scheduler"),
          routerLink: ['/kip/maintenancescheduler'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          id: "trusteescheduler",
          label: this.translate.instant("menu.trustee_scheduler"),
          routerLink: ['/kip/trusteescheduler'],
          routerLinkActiveOptions: { exact: true },
        }
      ]
    }
    return menuItem;
  }

  mnuAdminDashboardsMenu() {
    const menuItem: MenuItem = {
      id: "admindashboards",
      label: this.translate.instant("menu.dashboard"),
      routerLinkActiveOptions: { exact: true },
      icon: "mdi mdi-briefcase-download mdi-24px",
      items: [
        {
          id: "maintenancedashboard",
          label: this.translate.instant("menu.maintenance_dashboard"),
          routerLink: ['/kip/maintenanceleaderlandingpage'],
          routerLinkActiveOptions: { exact: true },
        },
        {
          id: "trusteedashboard",
          label: this.translate.instant("menu.housekeeping_dasboard"),
          routerLink: ['/kip/trusteelandingpage'],
          routerLinkActiveOptions: { exact: true },
        }
      ]
    }
    return menuItem;
  }
  mnuTechnicalLeaderSettings() {

    const menuItem: MenuItem = {
      id: "settings",
      label: this.translate.instant("menu.settings"),
      icon: 'mdi mdi-wrench mdi-24px',
      routerLinkActiveOptions: { exact: true },
      items: [
        {
          id: "maintenancesettings",
          label: this.translate.instant("menu.maintenance_settings"),
          routerLink: ['/kip/maintenancesettings'],
          routerLinkActiveOptions: { exact: true }
        }
      ]

    }
    return menuItem;
  }

  mnuTechnicalLeaderWithoutMain() {
    const menuItem: MenuItem = {
      id: "maintenancesettings",
      label: this.translate.instant("menu.maintenance_settings"),
      routerLink: ['/kip/maintenancesettings'],
      routerLinkActiveOptions: { exact: true }

    }
    return menuItem;
  }

  // receptionist 
  mnuReceptionistLandingPage() {
    const menuItem: MenuItem = {
      id: "dashboard",
      label: this.translate.instant("menu.dashboard"),
      icon: 'mdi mdi-view-dashboard mdi-24px',
      routerLink: ['/kip/recepcionistlandingpage'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }
  mnuReceptionistTaskList() {
    const menuItem: MenuItem = {
      id: "tasklist",
      label: this.translate.instant("menu.tasks"),
      icon: 'mdi mdi-file-multiple mdi-24px',
      routerLink: ['/kip/tasklist'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }


  //  admin 
  mnuAdminLandingPage() {
    const menuItem: MenuItem = {
      id: "dashboard",
      label: this.translate.instant("menu.dashboard"),
      icon: 'mdi mdi-view-dashboard mdi-24px',
      routerLink: ['/kip'],
      routerLinkActiveOptions: { exact: true }
    }

    return menuItem;
  }

  mnuTaskSchedulerCalendar() {
    const menuItem: MenuItem = {
      id: "taskschedulercalendar",
      label: this.translate.instant("menu.taskschedulercalendar"),  //*this.translate.instant("menu.dashboard"),
      icon: 'mdi mdi-timetable mdi-24px',
      routerLink: ['/kip/taskschedulercalendar'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;
  }

  mnuReports() {
    const menuItem: MenuItem = {
      id: 'reports',
      label: this.translate.instant("menu.reports"),
      icon: 'mdi mdi-format-list-text mdi-24px',
      items: [
        {
          id: "taskhistory",
          label: this.translate.instant("menu.task_history"),
          routerLink: ['/kip/taskhistory'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: "hkreport",
          label: this.translate.instant("menu.hk_report"),
          routerLink: ['/kip/hkreport'],
          routerLinkActiveOptions: { exact: true }
        }

      ]
    }
    return menuItem;
  }
  mnuAdminSettings() {
    const menuItem: MenuItem = {
      id: "settings",
      label: this.translate.instant("menu.settings"),
      icon: 'mdi mdi-wrench mdi-24px',
      items: [
        {
          id: "userssettings",
          label: this.translate.instant("menu.users_settings"),
          routerLink: ['/kip/usersettingseditor'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: "hotelsettings",
          label: this.translate.instant("menu.hotel_settings"),
          routerLink: ['/kip/hotelsettingseditor'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: "housekeepingsettings",
          label: this.translate.instant("menu.housekeeping_settings"),
          routerLink: ['/kip/housekeepingsettings'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: "maintenancesettings",
          label: this.translate.instant("menu.maintenance_settings"),
          routerLink: ['/kip/maintenancesettings'],
          routerLinkActiveOptions: { exact: true }
        }
      ]
    }
    return menuItem;
  }

  mnuLostAndFound() {
    const menuItem: MenuItem = {
      id: "publicareas",
      label: this.translate.instant("K926.findings"),
      icon: 'mdi mdi-package-variant mdi-24px',
      routerLink: ['/kip/lostandfound'],
      routerLinkActiveOptions: { exact: true }
    }
    return menuItem;


  }

  mnuSync() {
    const menuItem: MenuItem = {
      id: "sync",
      label: this.translate.instant("menu.sync"),
      icon: 'mdi mdi-sync mdi-24px',
      items: [
        {
          id: "syncguestimportance",
          label: this.translate.instant("menu.syncguestimportance"),
          routerLink: ['#']
        },
        {
          id: "syncminibar",
          label: this.translate.instant("menu.syncminibaritems"),
          routerLink: ['#']
        },
        {
          id: "synchusers",
          label: this.translate.instant("menu.synch_users"),
          routerLink: ['#']  
        },

      ]
    }
    return menuItem
  }
  menuItemClass(item: MenuItem) {
    const ic = item.icon
    return {
      'mdi-24px': true,
      ic: true,
      'menu-item-icon': true,
      'cursor': 'pointer'
    }

    //class="{{subItem.icon}} menu-item-icon"
  }
  //#endregion
  logout() {
    this.sessionStorageService.clear();
    this.topBarManagerService.setToggleSideBar(false);
    this.topBarManagerService.resetNumberOfTasks();
    this.topBarManagerService.setLoginState(false);

    this.authService.gotoLogin();

    this.topBarManagerService.setTopbarVisibility(false);

  }

  showChangePasswordDialog() {
    const dialoRef = this.changePasswordDialog.open(ChangePasswordDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "30vw",
      panelClass: "password-change-dialog",
    })
  }
}
