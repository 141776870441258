import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApplicationPage } from '../enums/application-page';
import { Units, UserInfo } from '../dto/UserInfo';
import { RequestResult } from '../dto/RequestResult';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { SelectItem } from '../classes/SelectItem';
import { SessionStorageService } from './session-storage.service';
import { DailyTaskService } from '../../kip/services/daily-task.service';
import { Observable, Subscription } from 'rxjs';
import { RabbitLogService } from '../../kip/services/rabbit-log.service';

@Injectable({
  providedIn: 'root'
})
export class TopBarManagerService {

  @Output() formIdChanged: EventEmitter<string> = new EventEmitter();
  @Output() userNameChanged: EventEmitter<string> = new EventEmitter();
  @Output() toggleSideBar: EventEmitter<boolean> = new EventEmitter();

  @Output() hotelArrayFilled : EventEmitter<true> = new EventEmitter();

  @Output() hotelUnitChanged: EventEmitter<string> = new EventEmitter();

  @Output() topBarVisibilityChanged: EventEmitter<boolean> = new EventEmitter();

  @Output() topbarBackgroundVisibilityChanged: EventEmitter<boolean> = new EventEmitter();

  @Output() numberOfTasksChanged : EventEmitter<number> = new EventEmitter();

  @Output() noAckChanged: EventEmitter<number> = new EventEmitter();
  
  // hotel combo services
  
  @Output() ShowRootHotel: EventEmitter<null> = new EventEmitter()
  @Output() HideRootHotel: EventEmitter<null> = new EventEmitter()

  @Output() ReloadNotifications: EventEmitter<string> = new EventEmitter();
  
  @Output() EnableVibration: EventEmitter<boolean> = new EventEmitter();
  
  @Output() LoggedIn : EventEmitter<boolean>= new EventEmitter();

  topBarVisible: boolean = false;

constructor(
      private userService : UserService,
      private authService : AuthService,
      private sessionStorageService: SessionStorageService,
      private dailytaskservice: DailyTaskService,
      private rabbitLogService: RabbitLogService
      ) { }

      loadMyTaskCount$: Subscription;
      checkNoAck$: Subscription;

  setFormId (caption: ApplicationPage) {
    this.formIdChanged.emit(caption);
  }
  setUserName(userName : string) {
    this.userNameChanged.emit(userName);
  }

  setToggleSideBar(toggle : boolean) {
    this.toggleSideBar.emit(toggle);
  }
  
  setLoginState(toggle: boolean) {
    this.LoggedIn.emit(toggle);
  }
  fillHotelArray() {
    let userInf = <UserInfo>JSON.parse(sessionStorage.getItem("userInfo")); 
    let hotelUnits : SelectItem[] = [];
   
    if(this.authService.isLoggedIn && userInf)
      userInf.Units.forEach(element => {
        hotelUnits.push({ value: element.Id,label: element.Code})
    });
    // if(hotelUnits.length > 0) {
    //   this.sessionStorageService.setSelectedUnit(hotelUnits[0].label);
    // }
    this.hotelArrayFilled.emit(true);
  }
  getHotelUnits() : Units[] {
    let userInf = <UserInfo>JSON.parse(sessionStorage.getItem("userInfo")); 
    return  userInf.Units;
  }
  clearHotelArray() {
   
  }
  setHotelUnit( hotelUnit : string )   {
    this.hotelUnitChanged.emit(hotelUnit);
  }
  
  setTopbarVisibility( visible: boolean) {
   
    this.topBarVisibilityChanged.emit(visible);
  }

  setTopbarBackgroundVisibility( visible: boolean) {
    this.topbarBackgroundVisibilityChanged.emit(visible);
  }

  setNumberOfTasks() {

    this.loadMyTaskCount$ = this.dailytaskservice.getNumberOfTasks(this.sessionStorageService.getSelectedUnit(), 
                                                                  this.sessionStorageService.getSelectedHotelId(),
                                                                  this.sessionStorageService.getUserName())
      .subscribe(evt=> {
        const res  =  evt as RequestResult;
        this.numberOfTasksChanged.emit(res.Data);
      })
  }

  setNoAck() {
    this.checkNoAck$ = this.rabbitLogService.checkNoAck(this.sessionStorageService.getSelectedUnit(), 
    this.sessionStorageService.getSelectedHotelId()).subscribe( result=> {
      const res  =  result  as RequestResult;
      if( res.Code == 0) {
        this.noAckChanged.emit(res.Data as number);
       
      }
    })
  }
  resetNumberOfTasks() {
    this.numberOfTasksChanged.emit(0);
  }
   
  openfullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
  
    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
    // this.isfullscreen = true;
  }
  
  closefullscreen(){
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
  }
}
