import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SidebarService {

  @Output() sidebarToggle : EventEmitter<null> = new EventEmitter();

  constructor() { }

}
