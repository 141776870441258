export class RoomType {

  Id : number;
  Subscriber: string;
  HotelId : number;
  RoomType :string;
  RoomTypeName:string;
  HKWeight:number;
  Active: boolean;
  NumberOfAddBed : number;
  NumberOfBed: number;
  BedType: string;
  
}