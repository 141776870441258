import { NgModule, ModuleWithProviders } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, RouteReuseStrategy } from '@angular/router';

// import { CleaningTaskEditorComponent } from './pages/cleaning-task-editor/cleaning-task-editor.component';
// import { CleaningTypeEditorComponent } from './pages/cleaning-type-editor/cleaning-type-editor.component';
// import { CommonProblemTaskEditorComponent } from './pages/commonproblem-task-editor/commonproblem-task-editor.component';
// import { CommonProblemTypeEditorComponent } from './pages/commonproblem-type-editor/commonproblem-type-editor.component';
// import { MaintenanceTaskEditorComponent } from './pages/maintenance-task-editor/maintenance-task-editor.component';
// import { MaintenanceTypeEditorComponent } from './pages/maintenance-type-editor/maintenance-type-editor.component';



import { KipRoutingModule } from './kip-routing.module';
import { MaterialModule } from '../../../src/app/core/material.module';
import { RoomTypeService } from './services/roomtype-service';
//import { CleaningTaskService } from './services/cleaning-task.service';
//import { CleaningTypeService } from './services/cleaning-type.service';
//import { MaintenanceTaskService } from './services/maintenance-task.service';
import { FroLocalizationService } from '../core/services/localization.service';
//import { MaintenanceTypeService } from './services/maintenance-type.service';
import { CommonProblemTaskService } from './services/commonproblem-task.service';
import { CommonProblemTypeService } from './services/commonproblem-type.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KipLandingPageComponent } from '../kip/pages/kip-landing-page/kip-landing-page.component';
import { TaskFilterSettingsService } from './services/task-filter-settings.service';
import { RoomStateListComponent } from './pages/room-state-list/room-state-list.component';
import { CleaningStatusService } from './services/cleaning-status.service';
import { SharedModule } from '../shared/shared.module'
import { HttpClientModule } from '@angular/common/http';
import { MenuService } from '../core/services/menu.service';

import { TopBarManagerService } from '../core/services/topbar-manager.service';
import { DateChipsComponent } from './components/filter-chips/date-chips/date-chips.component';
import { LabelChipsComponent } from './components/filter-chips/label-chips/label-chips.component';
import { LabelValueChipsComponent } from './components/filter-chips/label-value-chips/label-value-chips.component';
import { IconValueComponent } from './components/filter-chips/icon-value/icon-value.component';
import { RoomstateChipsComponent } from './components/filter-chips/roomstate-chips/roomstate-chips.component';
import { TaskStateChipsComponent } from './components/filter-chips/task-state-chips/task-state-chips.component';
import { TaskTypeChipsComponent } from './components/filter-chips/task-type-chips/task-type-chips.component';
import { RoomReservationStateChipsComponent } from './components/filter-chips/room-reservation-state-chips/room-reservation-state-chips.component';
import { MultiIconChipsComponent } from './components/filter-chips/multi-icon-chips/multi-icon-chips.component';
import { TaskSchedulerCalendarComponent } from './pages/task-scheduler-calendar/task-scheduler-calendar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { environment } from '../../environments/environment';
import { CoreModule } from "../../app/core/core.module";
import { WebcamModule } from '../core/components/webcam.module';
import { TrusteeLandingPageComponent } from './pages/trustee-landing-page/trustee-landing-page.component';
import { BlueHeaderComponent } from './components/blue-header/blue-header.component';
import { HousekeepingSettingsContainerComponent } from './components/housekeeping-settings-container/housekeeping-settings-container.component';
import { CleaningTaskEditorComponent } from './pages/cleaning-task-editor/cleaning-task-editor.component';
import { CleaningTypeEditorComponent } from './pages/cleaning-type-editor/cleaning-type-editor.component';
import { CleaningTypeRoomstateEditorComponent } from './pages/cleaning-type-roomstate-editor/cleaning-type-roomstate-editor.component';
import { PublicAreaCleaningTypeEditorComponent } from './pages/public-area-cleaning-type-editor/public-area-cleaning-type-editor.component';
import { CleaningGroupEditorComponent } from './pages/cleaning-group-editor/cleaning-group-editor.component';
import { DataLocalizerComponent } from './components/data-localizer/data-localizer.component';
import { CleaningGroupEditorDialogComponent } from './dialogs/cleaning-group-editor-dialog/cleaning-group-editor-dialog.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CleaningTypeEditorDialogComponent } from './dialogs/cleaning-type-editor-dialog/cleaning-type-editor-dialog.component';
import { BooleanFieldDirective } from './directives/booleanField.directive';
import { CleaningTaskEditorDialogComponent } from './dialogs/cleaning-task-editor-dialog/cleaning-task-editor-dialog.component';
import { PublicAreaCleaningTypeEditorDialogComponent } from './dialogs/public-area-cleaning-type-editor-dialog/public-area-cleaning-type-editor-dialog.component';
import { MaintenanceSettingsContainerComponent } from './components/maintenance-settings-container/maintenance-settings-container.component';
import { MaintenanceGroupEditorComponent } from './pages/maintenance-group-editor/maintenance-group-editor.component';
import { MaintenanceTypeEditorComponent } from './pages/maintenance-type-editor/maintenance-type-editor.component';
import { MaintenanceTaskEditorComponent } from './pages/maintenance-task-editor/maintenance-task-editor.component';
import { RepeatingTasksEditorComponent } from './pages/repeating-tasks-editor/repeating-tasks-editor.component';
import { MaintenanceLeaderLandingPageComponent } from './pages/maintenance-leader-landing-page/maintenance-leader-landing-page.component';
import { MaintenanceGroupEditorDialogComponent } from './dialogs/maintenance-group-editor-dialog/maintenance-group-editor-dialog.component';
import { MaintenanceTypeEditorDialogComponent } from './dialogs/maintenance-type-editor-dialog/maintenance-type-editor-dialog.component';
import { MaintenanceTaskEditorDialogComponent } from './dialogs/maintenance-task-editor-dialog/maintenance-task-editor-dialog.component';
import { RepeatingTasksEditorDialogComponent } from './dialogs/repeating-tasks-editor-dialog/repeating-tasks-editor-dialog.component';
import { HotelSettingsContainerComponent } from './components/hotel-settings-container/hotel-settings-container.component';
import { RoomtypeEditorComponent } from './pages/roomtype-editor/roomtype-editor.component';

import customParseFormat from 'dayjs/plugin/customParseFormat'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import dayjs, { Dayjs } from 'dayjs';

import 'dayjs/locale/hu';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import 'dayjs/locale/th';
import 'dayjs/locale/de';
import localeEn from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeHu from '@angular/common/locales/hu';
import localeTh from '@angular/common/locales/th';
import { HwDayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '../../../../shared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';
import { MatDayjsDateModule } from '../../../../shared/hw-material-dayjs-adapter/lib/adapter/'
import { LOCALE_ID } from '@angular/core';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RoomstateListFilterComponent } from './components/roomstate-list-filter/roomstate-list-filter.component';
import { RoomStatePanelComponent } from './pages/room-state-panel/room-state-panel.component';
import { AddTaskDialogComponent } from './dialogs/add-task-dialog/add-task-dialog.component';

import { TaskListEditorComponent } from './pages/task-list-editor/task-list-editor.component';
import { TaskListFilterComponent } from './components/task-list-filter/task-list-filter.component';
import { MinibarDialogComponent } from './dialogs/minibar-dialog/minibar-dialog.component';
import { TaskMenuComponent } from './components/task-menu/task-menu.component';
import { TaskHistoryDialogComponent } from './dialogs/task-history-dialog/task-history-dialog.component';
import { PhotoDialogComponent } from './dialogs/photo-dialog/photo-dialog.component';
import { ImageCaptureComponent } from './components/image-capture/image-capture.component';
import { UserGroupEditorComponent } from './pages/user-group-editor/user-group-editor.component';
import { UserGroupEditorDialogComponent } from './dialogs/user-group-editor-dialog/user-group-editor-dialog.component';
import { CalendarHeaderComponent } from './pages/task-scheduler-calendar/calendar-header/calendar-header/calendar-header.component';
import { SchedulerComponent } from './pages/scheduler/scheduler.component'
import { RoomEditorComponent } from './pages/room-editor/room-editor.component';
import { PublicAreaEditorComponent } from './pages/public-area-editor/public-area-editor.component';
import { PublicAreaEditorDialogComponent } from './dialogs/public-area-editor-dialog/public-area-editor-dialog.component';
import { SubscriberParameterEditorComponent } from './pages/subscriber-parameter-editor/subscriber-parameter-editor.component';
import { RecepcionistLandingPageComponent } from './pages/recepcionist-landing-page/recepcionist-landing-page.component';
import { TaskItemCardComponent } from './components/task-item-card/task-item-card.component';
import { ExtraTaskEditorDialogComponent } from './dialogs/extra-task-editor-dialog/extra-task-editor-dialog.component';

import { AvatarModule } from 'ngx-avatars'

import { HkReportComponent } from './pages/hk-report/hk-report.component';
import { TaskReportComponent } from './pages/task-report/task-report.component';

import { LostAndFoundDialogComponent } from './dialogs/lost-and-found-dialog/lost-and-found-dialog.component';
import { LostAndFoundEditorComponent } from './pages/lost-and-found-editor/lost-and-found-editor.component';
import { MinibarListComponent } from './pages/minibar-list/minibar-list.component';
import { MaintenanceTask } from './dto/MaintenanceTask';
import { MaintenanceTaskMenuComponent } from './components/maintenance-task-menu/maintenance-task-menu.component';
import { PhotoGalleriaDialogComponent } from './dialogs/photo-galleria-dialog/photo-galleria-dialog.component';
import { PublicAreaPanelComponent } from './pages/public-area-panel/public-area-panel.component';
import { TaskAssignDialogComponent } from './dialogs/task-assign-dialog/task-assign-dialog.component';
import { TaskAssignSupervisorDialogComponent } from './dialogs/task-assign-supervisor-dialog/task-assign-supervisor-dialog.component';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { ExtraTaskEditorComponent } from './pages/extra-task-editor/extra-task-editor.component';


dayjs.extend(customParseFormat);
dayjs.extend(isLeapYear);


registerLocaleData(localeHu, 'hu');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeEn, 'en');

export const HU_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export function createTranslateLoader(http: HttpClient) {
  let httpServer = environment.httpHost;
  // console.log('httpserver ' + httpServer);
  // console.log('baseurl ' + environment.baseUrl);
  // console.log('prod ' + environment.production);
  return new TranslateHttpLoader(http, httpServer + '/assets/i18n/', '.json');
}
 


@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    KipRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    WebcamModule,
    CurrencyMaskModule,
    MatDayjsDateModule,
    MatDayjsDateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxMatTimepickerModule.setLocale('hu-HU'),
     AvatarModule,
    TranslateModule.forChild({
      extend: true

    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TrusteeLandingPageComponent,
    KipLandingPageComponent,
    BlueHeaderComponent,
    HousekeepingSettingsContainerComponent,
    CleaningTaskEditorComponent,
    CleaningTaskEditorDialogComponent,
    CleaningTypeEditorComponent,
    CleaningTypeRoomstateEditorComponent,
    PublicAreaCleaningTypeEditorComponent,
    CleaningGroupEditorComponent,
    DataLocalizerComponent,
    CleaningGroupEditorDialogComponent,
    CleaningTypeEditorDialogComponent,
    PublicAreaCleaningTypeEditorDialogComponent,
    BooleanFieldDirective,
    MaintenanceTaskEditorComponent,
    MaintenanceTypeEditorComponent,
    MaintenanceGroupEditorDialogComponent,
    RoomStatePanelComponent,
    AddTaskDialogComponent,
    UserGroupEditorComponent,
    UserGroupEditorDialogComponent,
    HotelSettingsContainerComponent,
    HkReportComponent,
    LostAndFoundEditorComponent,
    LostAndFoundDialogComponent,
    RoomStateListComponent,
    RoomstateListFilterComponent,
    SchedulerComponent,
    TaskListEditorComponent,
    RoomtypeEditorComponent,
    MaintenanceTypeEditorDialogComponent,
    MaintenanceSettingsContainerComponent,
    RoomEditorComponent,
    MaintenanceTaskEditorDialogComponent,
    MaintenanceGroupEditorComponent,
    RecepcionistLandingPageComponent,
    MaintenanceLeaderLandingPageComponent,
    DateChipsComponent,
    LabelChipsComponent,
    LabelValueChipsComponent,
    IconValueComponent,
    RoomstateChipsComponent,
    TaskStateChipsComponent,
    TaskTypeChipsComponent,
    RoomReservationStateChipsComponent,
    MultiIconChipsComponent,
    TaskSchedulerCalendarComponent,
    CalendarHeaderComponent,
    ImageCaptureComponent,
    SubscriberParameterEditorComponent,
    RepeatingTasksEditorComponent,
    RepeatingTasksEditorDialogComponent,
    TaskListFilterComponent,
    MinibarDialogComponent,
    TaskMenuComponent,
    TaskHistoryDialogComponent,
    PhotoDialogComponent,
    PublicAreaEditorComponent,
    PublicAreaEditorDialogComponent,
    TaskItemCardComponent,
    TaskReportComponent,
    MinibarListComponent,
    MaintenanceTaskMenuComponent,
    PhotoGalleriaDialogComponent,
    PublicAreaPanelComponent,
    TaskAssignDialogComponent,
    TaskAssignSupervisorDialogComponent,
    ExtraTaskEditorComponent,
    ExtraTaskEditorDialogComponent

  ],
  // entryComponents: [
  //   // HwConfirmationDialogComponent
  //   CleaningGroupEditorDialogComponent,
  //   CleaningTypeEditorDialogComponent,
  //   CleaningTaskEditorDialogComponent,
  //   PublicAreaCleaningTypeEditorDialogComponent,
  //   MaintenanceGroupEditorDialogComponent,
  //   MaintenanceTypeEditorDialogComponent,
  //   MaintenanceTaskEditorDialogComponent,
  //   RepeatingTasksEditorDialogComponent,
  //   AddTaskDialogComponent,
  //   MinibarDialogComponent,
  //   TaskHistoryDialogComponent,
  //   PhotoDialogComponent,
  //   UserGroupEditorDialogComponent,
  //   PublicAreaEditorDialogComponent,
  //   LostAndFoundDialogComponent,
  //   PhotoGalleriaDialogComponent,
  //   TaskAssignDialogComponent

  // ],

  providers: [

    FroLocalizationService,
    CommonProblemTaskService,
    CommonProblemTypeService,
    TaskFilterSettingsService,
    CleaningStatusService,
    HttpClient,
    provideNgxMask()
    // { provide: LOCALE_ID, useValue: 'hu' },
    // { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    // { provide: MAT_DATE_FORMATS, useValue: HU_FORMATS },
    // {provide: DateAdapter,
    //   useValue: HwDayjsDateAdapter,
    //   deps: [MAT_DATE_LOCALE,MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    // },
    // { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    // // { provide:RouteReuseStrategy, useClass : CustomReuseStrategy },
    // { provide: LOCALE_ID, useValue: 'hu' },
    // { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    // { provide: MAT_DATE_FORMATS, useValue: HU_FORMATS },
    // { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [KipLandingPageComponent]

})
export class KipModule {

  constructor(
    private router: Router,
    private menuService: MenuService,
    private topBarManagerService: TopBarManagerService
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {

        this.topBarManagerService.setToggleSideBar(false);

      }
    })

  }


}

