import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageManagerService {
  apiServer = environment.apiHost + environment.apiPort;
  constructor( 
    private http: HttpClient
  ) { }


  public uploadImage( base64Image : string , taskId: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const cnt: Base64Image  = new Base64Image();
    cnt.Content = base64Image;
    return this.http.put(this.apiServer + '/api/kip/PhotoManager/Upload/' + taskId, cnt, { headers });
  }

  public loadImages( taskId: number) {
    return this.http.get(this.apiServer + '/api/kip/PhotoManager/GetPhotos/' + taskId);
  }
  
  public deleteImage(imageName : string ) {
    return this.http.get(this.apiServer + '/api/kip/PhotoManager/DeletePhoto/' + imageName);
  }

}

export class Base64Image {
  Content: string;
}