// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { RoomService } from '@app/kip/services/room.service';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { RoomTypeService } from '@app/kip/services/roomtype-service';
import { ExtraTaskService } from '@app/kip/services/extra-task-service';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { Room } from '@app/kip/dto/Room';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { RoomType } from '@app/kip/dto/RoomType';
import { UserService } from '@app/core/services/user.service';
import { RoleType } from '@app/shared/enums/roles';
import { ExtraTask } from '@app/kip/dto/ExtraTask';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { ExtraTaskEditorDialogComponent } from '@app/kip/dialogs/extra-task-editor-dialog/extra-task-editor-dialog.component';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { ExtraTaskBag } from '@app/kip/classes/ExtraTaskEditorBag';

@AutoUnsubscribe()
@Component({
  selector: 'extra-task-editor',
  templateUrl: './extra-task-editor.component.html',
  styleUrls: ['./extra-task-editor.component.scss']
})
export class ExtraTaskEditorComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;
  editorType: string;
  title: string;
  loaded: boolean;
  filterForm: FormGroup;
  froDate: string;
  loadAllExtraTasks$: Subscription
  addExtraTask$: Subscription;
  updateExtraTask$: Subscription;
  deleteExtraTask$: Subscription;
  generateTasks$ : Subscription;
  hotelUnitChanged$ : Subscription;
  cleaningTypes: CleaningType[] = [];
  maintenanceTypes: MaintenanceType[] = [];
  publicAreas: PublicArea[] = [];
  roomTypes: RoomType[] = [];
  rooms: Room[] = [];
  extraTasks: ExtraTask[] = [];
  extraTasksOriginal : ExtraTask[]=[];

  tableColumns: string[] = ["RuleName", "TypeName", "Frequency", "GenerationMethod", "Active", "Action"]
  dataSource: MatTableDataSource<ExtraTask>;

  constructor(
    private hwAlertService: HwAlertService,
    private topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private spinnerService: SpinnerService,
    private cleaningTypeService: CleaningTypeService,
    private maintenanceTypeService: MaintenanceTypeService,
    private roomService: RoomService,
    private roomTypeService: RoomTypeService,
    private publicAreaService: PublicAreaService,
    private extraTaskService: ExtraTaskService,
    private hokParamsService: HokParamsService,
    private userService: UserService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmService: HwConfirmationDialogService,
    private extraTaskDialog: MatDialog,

  ) {
    this.filterForm = fb.group({
      'fTaskType': new FormControl(''),
      'fGenerationMethod': new FormControl('M'),
      'fActive': new FormControl(true)
    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.loaded = false;
    if (this.userService.hasRole(RoleType.MUSZAKI_VEZETO)) {
      this.editorType = "M"
    } else {
      this.editorType = "C"
    }
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=> {
        this.loadAll()
    })
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.loadAllExtraTasks$?.unsubscribe();
    this.addExtraTask$?.unsubscribe();
    this.updateExtraTask$?.unsubscribe();
    this.deleteExtraTask$?.unsubscribe();
    this.generateTasks$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  typeName(typeId: number) {
    if (this.editorType == "C") {
      const foundItem = this.cleaningTypes.find(item => item.TypeId == typeId);
      if (foundItem) {
        return foundItem.TypeName;
      } else {
        return "???"
      }
    } else {
      const foundItem = this.maintenanceTypes.find(item => item.TypeId == typeId);
      if (foundItem) {
        return foundItem.TypeName;
      } else {
        return "???"
      }

    }
  }
  generationMethod(task:ExtraTask): string {
    return task.GenerationMethod == "M" ? this.translate.instant("extra_tasks.manual") : this.translate.instant("extra_tasks.on_close")
  }

  openDialog(action: string, task: ExtraTask) {
    let extraBag = new ExtraTaskBag();
    if (this.editorType == "C") {
      extraBag.cleaningTypes = this.cleaningTypes;
      extraBag.maintenanceTypes = [];
    } else {
      extraBag.cleaningTypes = [];
      extraBag.maintenanceTypes = this.maintenanceTypes;
    }


    extraBag.roomTypes = this.roomTypes;
    extraBag.publicAreas = this.publicAreas;
    extraBag.rooms = this.rooms;
    extraBag.extraTask = new ExtraTask();
    if (action == OpenDialogAction.ADD) {
      extraBag.extraTask.Active = true;
      extraBag.extraTask.DateFrom = "";
      extraBag.extraTask.DateTo = "";
      extraBag.extraTask.Frequency = 1;
      extraBag.extraTask.FrequencyUnit = "N";
      extraBag.extraTask.PublicAreas = "";
      extraBag.extraTask.RoomNumbers = "";
      extraBag.extraTask.RoomTypes = "";
      extraBag.extraTask.RuleName = "";
      extraBag.extraTask.TaskId = -1;
      extraBag.extraTask.TaskType = "M";
      extraBag.extraTask.RoomStateTransitions = "";;
      extraBag.extraTask.TypeIds = "";
      extraBag.extraTask.SData = "{}"
    } else {
      extraBag.extraTask = task;
    }
    const dialogRef = this.extraTaskDialog.open(ExtraTaskEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "90vw",
      data: extraBag
    })

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const extra = data as ExtraTask;
        if (action == OpenDialogAction.ADD) {
          extra.TaskId = null;
          this.addExtraTask$ = this.extraTaskService.add(extra).subscribe(result => {
            
            const res = result as RequestResult;
            if (res.Code == 0) {
              this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
              this.loadAll();
            } else {
              this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
            }
          })
        }
        else {
          this.updateExtraTask$ = this.extraTaskService.update(data).subscribe(result => {
            const res = result as RequestResult;
            if (res.Code == 0) {
              this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
              this.loadAll();
            } else {
              this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
            }
          })
        }
      } else {

      }
    })
  }
  deleteRow(task: ExtraTask) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteExtraTask$ = this.extraTaskService.delete(task.TaskId).subscribe( result=> {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.hwAlertService.info(this.translate.instant("common.delete_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
            this.loadAll();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
          }
        })
      }
    })
  }
  
  generateTasks( task: ExtraTask) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("extra_tasks.task_generation_confirm"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.generateTasks$ = this.extraTaskService.generateTasks(task.TaskId).subscribe( result=> {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.hwAlertService.info(this.translate.instant("extra_tasks.task_generation_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
          }
        })
      }
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  async getFroDate() {
    const froDateResult = await firstValueFrom(this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()));
    const res = froDateResult as RequestResult;
    this.froDate = res.Data.toString();
  }

  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }


  loadAll() {
    this.getFroDate().then(result => {
      let sources = [
        this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
        this.maintenanceTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
        this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
        this.roomTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
        this.roomService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this.froDate),
        this.extraTaskService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
      ];
      this.loadAllExtraTasks$ = forkJoin(sources).subscribe(([cleaningTypes, maintenanceTypes, publicAreas, roomTypes, rooms, extraTasks]) => {
        let resCt = cleaningTypes as RequestResult;
        this.cleaningTypes = resCt.Data as CleaningType[];
        let resMt = maintenanceTypes as RequestResult;
        this.maintenanceTypes = resMt.Data as MaintenanceType[]
        let resPa = publicAreas as RequestResult;
        this.publicAreas = resPa.Data as PublicArea[]
        let resRt = roomTypes as RequestResult;
        this.roomTypes = resRt.Data as RoomType[]

        let resRo = rooms as RequestResult;
        this.rooms = resRo.Data as Room[]

        let resExt = extraTasks as RequestResult;
        this.extraTasks = resExt.Data as ExtraTask[]
        this.extraTasksOriginal = [...this.extraTasks];
        // set combo positions
        if (this.editorType == "C") {
          let allCleaning: CleaningType = new CleaningType();
          allCleaning.TypeId = -1;
          allCleaning.TypeName = this.translate.instant("common.all") 
          this.cleaningTypes.splice(0,0,allCleaning)
          this.filterForm.controls["fTaskType"].patchValue(this.cleaningTypes[0].TypeId)
        } else {
          let allMaintenances: MaintenanceType = new MaintenanceType();
          allMaintenances.TypeId = -1;
          allMaintenances.TypeName = this.translate.instant("common.all") 
          this.maintenanceTypes.splice(0,0,allMaintenances)
          this.filterForm.controls["fTaskType"].patchValue(this.maintenanceTypes[0].TypeId)
        }
        this.loaded = true;
        this.dataSource = new MatTableDataSource(this.extraTasks);
        this.applyFilter()
      })

    })

  }
  applyFilter() {
    let filtered : ExtraTask[]=[...this.extraTasksOriginal];
    if( this.filterForm.controls["fTaskType"].value !== -1) {
     filtered = filtered.filter( item=>item.TypeIds == this.filterForm.controls["fTaskType"].value);
    } 
    filtered = filtered.filter( item=>item.GenerationMethod == this.filterForm.controls["fGenerationMethod"].value);

    filtered = filtered.filter( item=>item.Active == this.filterForm.controls["fActive"].value);

    this.extraTasks = [...filtered];
    
    // van valamiféle dátum szűrés
    

    this.dataSource =  new MatTableDataSource(this.extraTasks);
    

  }
  booleanCell(val: boolean) {
    if (val) {
      return {
        'mdi mdi-check': true,
        'boolean-cell': true

      }
    }
  }

  getControls(key: string): AbstractControl {
    return this.filterForm.controls[key];
  }
  //#endregion =================================================================================================================
}
