export default class  HwUtils {
  
  /* string helpers */
  public static  leftPad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public static rightPadAlt255(stringToPad: string,size: number) {
    let padNeeded : number = size - stringToPad.length;
    let ret : string = stringToPad;
    for(var i=1;i<= padNeeded;i++)  {
      ret = ret + " ";
    }
    return ret;
  }
  public static zeroIfNull( data: number ) {
    if (data == null) {
      return 0
    }
    return data;
  }

  /* datetime helpers */

 public static HoursAndMinutesToMinutes( hms : string) {
    if( hms) {
      const  hours   = Number(hms.split(":")[0])*60;
      const  minutes = Number(hms.split(":")[1]);  
      return   hours+minutes;
    }
    else {
      return 0;
    }
  }

  public static MinutesToHoursAndMinutes(minutes: number) {
    let result : string = "";
    let hour : string = "";
    let min : string = "";
    if( minutes >= 60) {
      hour = Math.floor(minutes / 60 ).toString();
      min = (minutes%60).toString();
    }
    else {
      min = minutes.toString();
      hour = "00";
    }
    return HwUtils.leftPad(parseInt(hour),2)+  ":" + HwUtils.leftPad(parseInt(min),2);
  }


 public static formatMinutes( minutes : number) : string {
    let result : string = "";
    let hour : string = "";
    let min : string = "";
    if( minutes >= 60 && minutes < 1440) {
      hour = Math.floor(minutes / 60 ).toString();
      min = (minutes%60).toString();
    }
    else {
      min = minutes.toString();
      hour = "00";
    }
    return HwUtils.leftPad(parseInt(hour),2)+  ":" + HwUtils.leftPad(parseInt(min),2);
  }
 
  public static splitTime ( minutes: number) : HoursMinutes {
    let hour : number;
    let min : number;

    if( minutes >= 60 && minutes < 1440) {
      hour = Math.floor(minutes / 60 );
      min = (minutes%60);
    }
    else {
      min = minutes;
      hour = 0;
    }
    const hm : HoursMinutes = {hours : hour, minutes: min}
    return hm
  }

}
export interface  HoursMinutes {
    "hours" : number
    "minutes" : number
}