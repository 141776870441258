// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty {
 color: #ada8a8;
}
.early-checkin {
  color: #779a34;
}
.checkin {
  color: #779a34;
}
.checked-in {
  color: #779a34;
}
.occupied {
  color:#85b0ec;
}
.checkout {
  background-color:#FFFFFF00;
  color: #f35870;
}
.late-checkout {
  background-color:#FFFFFF00;
  color: #f35870;
}
.checked-out {
  background-color:#FFFFFF00;
  color: #f35870;
}

.chips-font-size {
  font-size: 22px;
  padding-right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
