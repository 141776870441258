// Angular

import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
import { Subscription,Subject, takeUntil, combineLatest, map } from 'rxjs';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

// Hostware
import { RequestResult } from '@app/core/dto/RequestResult';
import { DynamicControlDescriptor } from '@app/kip/classes/DynamicControlDescriptor';
import { ChipsTypes } from '@app/kip/enums/ChipsTypes';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { ApplicationPage } from '@app/core/enums/application-page';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { RoomState } from '@app/shared/enums/roomstates';
import { RoleService } from '@app/shared/services/role.service';
import { AuthService } from '@app/core/services/auth.service';
import { vwMaintenanceType } from '@app/kip/viewdto/vwdailytask/vwMaintenanceType';
import { TaskStatus } from '@app/kip/viewdto/taskStatus';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { RoleType } from '@app/shared/enums/roles';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { KipUser } from '@app/kip/dto/KipUser';
import { vwTaskListFilter } from '@app/kip/viewdto/vwTaskListFilter';
import { RoomService } from '@app/kip/services/room.service';
import { vwTaskReadinessItem } from '@app/kip/viewdto/vwTaskReadinessItem';
import { FormStateService } from '@app/kip/services/form-state.service';
import { environment } from '@environments/environment';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { TransitionCheckState } from '@angular/material/checkbox';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { SelectItem } from '@app/core/classes/SelectItem';
import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { RoomStateFilterConditions } from '../roomstate-list-filter/roomstate-list-filter.component';

@Component({
  selector: 'task-list-filter',
  templateUrl: './task-list-filter.component.html',
  styleUrls: ['./task-list-filter.component.scss']
})

@AutoUnsubscribe()
export class TaskListFilterComponent implements OnInit, OnDestroy {

  @Output() filterConditionsChanged: EventEmitter<TaskListFilterConditions> = new EventEmitter();

  hotelUnitChanged$: Subscription;
  loadCleaningGroups$: Subscription;
  loadMaintenanceGroups$: Subscription;
  loadCleaningTypes$: Subscription;
  loadMaintenanceTypes$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  allVisible: boolean = false;
  maintenancesVisible: boolean = false;
  cleaningsVisible: boolean = true;

  filterForm: FormGroup;
  itemTypes: SelectItem[];
  cleaningGroups: CleaningGroup[] = [];
  cleaningTypes: CleaningType[] = [];
  maintenanceGroups: MaintenanceGroup[] = [];
  maintenanceTypes: MaintenanceType[] = [];

  dynamicControlDescriptor: DynamicControlDescriptor[] = [];
  tmpDynamicControlDescriptor: DynamicControlDescriptor[] = [];
  kipUsers: KipUser[] = [];
  supervisors: KipUser[] = [];
  cleaningFilterVisible : boolean;
  maintenanceFilterVisible : boolean;
  

  constructor(
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private rfHelper: ReactiveFormsHelperService,
    private fb: FormBuilder,
    private roleService: RoleService,
    private topBarManagerService: TopBarManagerService,
    private cleaningGroupService: CleaningGroupService,
    private maintenanceGroupService: MaintenanceGroupService,
    private maintenanceTypeService: MaintenanceTypeService,
    private cleaningTypeService: CleaningTypeService,
    private kipUserService: KipUserService 
  ) {
    this.filterForm = fb.group({
      'fItemType': new FormControl("0"),
      'fRoomNumber': new FormControl(""),
      'fFloor': new FormControl(""),
      'fMyTasksOnly': new FormControl(true),
      'fTaskStateCreated': new FormControl(false),
      'fTaskStatePaused': new FormControl(false),
      'fTaskStateEnded': new FormControl(false),
      'fTaskStateRunning': new FormControl(false),
      'fTaskStateDeleted': new FormControl(false),
      'fTaskStateAccepted': new FormControl(false),
      'fCleaningGroup': new FormControl(0),
      'fMaintenanceGroup': new FormControl(0),
      'fCleaningType': new FormControl('0'),
      'fMaintenanceType': new FormControl('0'),
      'fAssignedTo': new FormControl({
        value: "-1",
        disabled: !(this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO))
      }),
      'fSupervisor': new FormControl("-1")
    })
    this.itemTypes = [
      { value: "-1", label: this.translate.instant("common.all") },
      { value: "0", label: this.translate.instant("common.housekeeping") },
      { value: "1", label: this.translate.instant("common.maintenance") }
    ]
  }

  ngOnInit() {
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(evt => {
      this.loadAll();
    })
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.hotelUnitChanged$?.unsubscribe();
    this.loadCleaningGroups$?.unsubscribe();
    this.loadMaintenanceGroups$?.unsubscribe();


  }
  //#region =============== Angular Lifecyle events ============================================================================
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  fItemTypeChanged(event) {
    switch (event) {
      case "-1": {
        this.maintenancesVisible = true;
        this.cleaningsVisible = true;
        this.allVisible = true;
        this.cleaningFilterVisible = false;
        this.maintenanceFilterVisible = false;
      }
        break;
      case "0": {
        this.maintenancesVisible = false;
        this.maintenanceFilterVisible = false;
        this.cleaningsVisible = true;
        this.allVisible = false;
        this.cleaningFilterVisible = true;
        this.fCleaningGroupChanged(0);
      }
        break;
      case "1": {
        this.maintenancesVisible = true;
        this.cleaningsVisible = false;
        this.allVisible = false;
        this.cleaningFilterVisible = false;
        this.maintenanceFilterVisible = true;
        this.fMaintenanceGroupChanged(0);
      }
        break;

    }
    this.applyFilter();
  }


  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    const combinedSources = combineLatest({
      cleaningGroups: this.cleaningGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      maintenanceGroups : this.maintenanceGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      kipUsers: this.loadAllKipUsers(),
    })
    combinedSources.pipe(takeUntil(this.destroy$)).subscribe( value=> {
      let cg = (value.cleaningGroups as RequestResult).Data as  CleaningGroup[];
      const allCGr: CleaningGroup = { GroupId: 0, GroupName: this.translate.instant("common.all"), HotelId: this.sessionStorageService.getSelectedHotelId(), Subscriber: this.sessionStorageService.getSelectedUnit(), SData: '' }
      cg.splice(0, 0, allCGr);
      this.cleaningGroups = [...cg];

      let mg = (value.maintenanceGroups as RequestResult).Data as  MaintenanceGroup[];
      const allMgr: MaintenanceGroup = { GroupId: 0, GroupName: this.translate.instant("common.all"), HotelId: this.sessionStorageService.getSelectedHotelId(), Subscriber: this.sessionStorageService.getSelectedUnit(), SData: '' }
      mg.splice(0, 0, allMgr);
      this.maintenanceGroups = [...mg];
    
      this.kipUsers = (value.kipUsers as RequestResult).Data as (Array<KipUser>);
      const kipus = (value.kipUsers as RequestResult).Data as (Array<KipUser>);
      kipus.forEach(kipuser => {
        if (this.roleService.isUserInRole(kipuser.UserName, RoleType.GONDNOKNO) ||
          this.roleService.isUserInRole(kipuser.UserName, RoleType.MUSZAKI_VEZETO)) {
          this.supervisors.push(JSON.parse(JSON.stringify(kipuser)));
        }
      })
      let allUser: KipUser = new KipUser();
      allUser.UserId = -1;
      allUser.UserName = this.translate.instant("common.all");
      allUser.RealName = this.translate.instant("common.all");
      allUser.Subscriber = "";
      allUser.HotelId = this.sessionStorageService.getSelectedHotelId();
      allUser.UserCode = ''
      allUser.UserUuId = '';
      allUser.Data = '';
      allUser.Active = true;
      this.supervisors.splice(0, 0, allUser);
      this.kipUsers.splice(0, 0, allUser);
      this.filterForm.controls["fSupervisor"].setValue("-1");
      this.filterForm.controls["fAssignedTo"].setValue("-1");

      if( this.roleService.userIsInRole(RoleType.GONDNOKNO) ||  this.roleService.userIsInRole(RoleType.TAKARITO)) {
        this.cleaningsVisible = true;
        this.maintenancesVisible = false;
        this.allVisible = false;
        this.cleaningFilterVisible = true;
        this.maintenanceFilterVisible = false;
        this.filterForm.controls["fItemType"].setValue("0");
        this.filterForm.controls["fCleaningGroup"].setValue(0)
        this.fCleaningGroupChanged(0);
      }
      if( this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) ||  this.roleService.userIsInRole(RoleType.MUSZAKOS)) {
        this.cleaningsVisible = false;
        this.cleaningFilterVisible = false;
        this.allVisible = false;
        this.maintenancesVisible = true;
        this.maintenanceFilterVisible = true;

        this.filterForm.controls["fItemType"].setValue("1");
        this.filterForm.controls["fMaintenanceGroup"].setValue(0)
        this.fMaintenanceGroupChanged(0);
      }

      if(this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) || this.roleService.userIsInRole(RoleType.RECEPCIOS)) {
        this.filterForm.controls["fTaskStateCreated"].setValue(true);
        this.filterForm.controls["fTaskStateRunning"].setValue(true); 
        this.filterForm.controls["fTaskStatePaused"].setValue(true); 
        this.filterForm.controls["fTaskStateEnded"].setValue(true); 
      }
      if(this.roleService.userIsInRole(RoleType.MUSZAKOS) || this.roleService.userIsInRole(RoleType.TAKARITO)) {
        this.filterForm.controls["fTaskStateCreated"].setValue(true);
        this.filterForm.controls["fTaskStateRunning"].setValue(true); 
        this.filterForm.controls["fTaskStatePaused"].setValue(true); 
       
      }
      if(this.roleService.userIsInRole(RoleType.RECEPCIOS)) {
        this.filterForm.controls["fTaskStateAccepted"].setValue(true); 
      }
      this.filterChanged();

    })



  }

  applyFilter() {
    this.filterChanged();
  }

  filterChanged() {
    this.tmpDynamicControlDescriptor = [];
    let conditions: TaskListFilterConditions = new TaskListFilterConditions();
    let desc: DynamicControlDescriptor = new DynamicControlDescriptor();
    
    
    this.rfHelper.updateRecordFromForm(conditions, this.filterForm);

    if (this.allVisible) {
      conditions.fCleaningType = "0";
      conditions.fCleaningGroup = "0";
    }
    if (this.cleaningFilterVisible) {
      conditions.fCleaningGroup = this.filterForm.controls["fCleaningGroup"].value;
      conditions.fCleaningType = this.filterForm.controls["fCleaningType"].value;
    }
    if (this.maintenancesVisible) {
      conditions.fMaintenanceGroup = this.filterForm.controls["fMaintenanceGroup"].value;
      conditions.fMaintenanceType = this.filterForm.controls["fMaintenanceType"].value;
    }



    this.filterConditionsChanged.emit(conditions);
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.TaskState;
    desc.parameters = [];
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.statuses") });
    desc.parameters.push({ Key: "Created", Value: this.filterForm.controls["fTaskStateCreated"].value.toString() });
    desc.parameters.push({ Key: "Running", Value: this.filterForm.controls["fTaskStateRunning"].value.toString() });
    desc.parameters.push({ Key: "Paused", Value: this.filterForm.controls["fTaskStatePaused"].value.toString() });
    desc.parameters.push({ Key: "Ended", Value: this.filterForm.controls["fTaskStateEnded"].value.toString() });
    desc.parameters.push({ Key: "Deleted", Value: this.filterForm.controls["fTaskStateDeleted"].value.toString() });
    desc.parameters.push({ Key: "Accepted", Value: this.filterForm.controls["fTaskStateAccepted"].value.toString() });
    this.tmpDynamicControlDescriptor.push(desc);
    //--------------------
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.TaskType;
    desc.parameters = []
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.group") });
    switch (this.filterForm.controls["fItemType"].value) {
      case "-1": {
        desc.parameters.push({ Key: "Cleaning", Value: 'true' });
        desc.parameters.push({ Key: "Maintenance", Value: 'true' });
      }
        break;
      case "0": {
        desc.parameters.push({ Key: "Cleaning", Value: 'true' });
        desc.parameters.push({ Key: "Maintenance", Value: 'false' });
      }
        break;
      case "1": {
        desc.parameters.push({ Key: "Maintenance", Value: 'true' });
        desc.parameters.push({ Key: "Cleaning", Value: 'false' });
      }
        break;
    }
    this.tmpDynamicControlDescriptor.push(desc);
    //----------------------

    if (this.filterForm.controls["fRoomNumber"].value !== "") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-door", Value: this.filterForm.controls["fRoomNumber"].value });
      this.tmpDynamicControlDescriptor.push(desc);

    }
    //----------------------

    if (this.filterForm.controls["fFloor"].value !== "") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-stairs", Value: this.filterForm.controls["fFloor"].value });
      this.tmpDynamicControlDescriptor.push(desc);
    }
    //----------------------
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.MultiIcon;
    desc.parameters = []
    if (this.filterForm.controls["fMyTasksOnly"].value) {
      desc.parameters.push({ Key: "mdi mdi-calendar-check", Value: this.translate.instant("common.my_task"), Data: { 'color': '#5aa0ba', 'font-size': '22px', 'padding-left': '5px', 'padding-right': '5px' } });
    }
    // else {
    //    desc.parameters.push({Key:"", Value:this.translations["common"]["all_task"]});
    // }
    this.tmpDynamicControlDescriptor.push(desc);
    //----------------------

    if (this.filterForm.controls["fAssignedTo"].value !== "-1") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = []
      desc.parameters.push({ Key: 'mdi mdi-clipboard-account', Value: this.kipUsers.find(item => item.UserId == parseInt(this.filterForm.controls["fAssignedTo"].value)).RealName });
      this.tmpDynamicControlDescriptor.push(desc);
    }

    //---------------------------
    if (this.filterForm.controls["fSupervisor"].value !== "-1") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-account-voice", Value: this.supervisors.find(item => item.UserId == this.filterForm.controls["fSupervisor"].value).RealName });
      this.tmpDynamicControlDescriptor.push(desc);

    }
    if (!this.allVisible) {
      if (this.cleaningsVisible && this.filterForm.controls["fCleaningGroup"].value != '0') {
        desc = new DynamicControlDescriptor();
        desc.controlType = ChipsTypes.LabelValue;
        desc.parameters = [];
        desc.parameters.push({ Key: this.translate.instant("common.cleaning_group"), Value: this.cleaningGroups.find(item => item.GroupId == this.filterForm.controls["fCleaningGroup"].value).GroupName })
        this.tmpDynamicControlDescriptor.push(desc);
      }
      if (this.cleaningsVisible && parseInt(this.filterForm.controls["fCleaningType"].value) != 0) {
        desc = new DynamicControlDescriptor();
        desc.controlType = ChipsTypes.LabelValue;
        desc.parameters = [];
        desc.parameters.push({ Key: this.translate.instant("common.cleaning_type"), Value: this.cleaningTypes.find(item => item.TypeId == this.filterForm.controls["fCleaningType"].value).TypeName });
        this.tmpDynamicControlDescriptor.push(desc);
      }

      if (this.maintenancesVisible && this.filterForm.controls["fMaintenanceGroup"].value != '0') {
        desc = new DynamicControlDescriptor();
        desc.controlType = ChipsTypes.LabelValue;
        desc.parameters = [];
        desc.parameters.push({ Key: this.translate.instant("common.maintenance_group"), Value: this.maintenanceGroups.find(item => item.GroupId == this.filterForm.controls["fMaintenanceGroup"].value).GroupName })
        this.tmpDynamicControlDescriptor.push(desc);
      }
      if (this.maintenancesVisible && parseInt(this.filterForm.controls["fMaintenanceType"].value) != 0) {
        desc = new DynamicControlDescriptor();
        desc.controlType = ChipsTypes.LabelValue;
        desc.parameters = [];
        desc.parameters.push({ Key: this.translate.instant("common.maintenance_type"), Value: this.maintenanceTypes.find(item => item.TypeId == this.filterForm.controls["fMaintenanceType"].value).TypeName });
        this.tmpDynamicControlDescriptor.push(desc);
      }
    }


    this.dynamicControlDescriptor = JSON.parse(JSON.stringify(this.tmpDynamicControlDescriptor));

  }




  fMaintenanceGroupChanged(event) {
    this.maintenanceTypes = [];
    this.loadMaintenanceTypes$ = this.maintenanceTypeService.loadByGroupId(event).subscribe(result => {
      const res = result as RequestResult;
      this.maintenanceTypes = res.Data as MaintenanceType[];
      let maintenanceTypeAll = new MaintenanceType();
      maintenanceTypeAll.TypeCode = "0";
      maintenanceTypeAll.TypeId = 0;
      maintenanceTypeAll.TypeName = this.translate.instant("common.all");
      this.maintenanceTypes.splice(0, 0, maintenanceTypeAll)
      this.filterForm.controls['fMaintenanceType'].setValue(0);
      this.filterChanged();
    })
  }

  fCleaningGroupChanged(event) {
    this.cleaningTypes = [];
    this.loadCleaningTypes$ = this.cleaningTypeService.loadByGroupId(event).subscribe(result => {
      const res = result as RequestResult;
      this.cleaningTypes = res.Data as CleaningType[];
      let cleaningTypeAll = new CleaningType();
      cleaningTypeAll.TypeCode = "0";
      cleaningTypeAll.TypeId = 0;
      cleaningTypeAll.TypeName = this.translate.instant("common.all");
      this.cleaningTypes.splice(0, 0, cleaningTypeAll);
      this.filterForm.controls['fCleaningType'].setValue(0);
      this.filterChanged();
    })
  }

  myTaskOnlyTitle(event: MatSlideToggle) : string {
    return event.checked ? this.translate.instant("common.my_task") : this.translate.instant("common.all_task")

  }

  loadAllKipUsers() {
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
        units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
        hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
      } else {
        units = this.sessionStorageService.getSelectedUnit();
        hotels = this.sessionStorageService.getSelectedHotelId().toString();
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }

    return this.kipUserService.loadAllMulti(units, hotels).pipe(map((result) => {
      return result;
    }));
  }

  setFilter(filter : vwTaskListFilter) {
    this.filterForm.controls["fAssignedTo"].setValue(filter.AssignedTo.toString());
    this.filterForm.controls["fFloor"].setValue(filter.Floor);
    this.filterForm.controls["fRoomNumber"].setValue(filter.RoomNumber);
    this.filterForm.controls["fItemType"].setValue(filter.ItemType);
    this.filterForm.controls["fMyTasksOnly"].setValue(filter.MyTasksOnly);
    this.filterForm.controls["fTaskStateCreated"].setValue(filter.TaskStateCreated);
    this.filterForm.controls["fTaskStatePaused"].setValue(filter.TaskStatePaused);
    this.filterForm.controls["fTaskStateEnded"].setValue(filter.TaskStateEnded);
    this.filterForm.controls["fTaskStateRunning"].setValue(filter.TaskStateRunning);
    this.filterForm.controls["fTaskStateDeleted"].setValue(filter.TaskStateDeleted);
    this.filterForm.controls['fTaskStateAccepted'].setValue(filter.TaskStateAccepted);
    this.filterForm.controls['fSupervisor'].setValue(filter.Supervisor.toString());
    this.filterChanged();
  }


  getFilter() : vwTaskListFilter {
    let filter: vwTaskListFilter = new vwTaskListFilter();
    filter.AssignedTo = parseInt(this.filterForm.controls["fAssignedTo"].value);
    filter.RoomNumber = this.filterForm.controls["fRoomNumber"].value;
    filter.Floor = this.filterForm.controls["fFloor"].value;
    filter.ItemType = this.filterForm.controls["fItemType"].value;
    filter.MyTasksOnly = this.filterForm.controls["fMyTasksOnly"].value;
    filter.TaskStateCreated = this.filterForm.controls["fTaskStateCreated"].value;
    filter.TaskStatePaused = this.filterForm.controls["fTaskStatePaused"].value;
    filter.TaskStateEnded = this.filterForm.controls["fTaskStateEnded"].value;
    filter.TaskStateRunning = this.filterForm.controls["fTaskStateRunning"].value;
    filter.TaskStateDeleted = this.filterForm.controls["fTaskStateDeleted"].value;
    filter.TaskStateAccepted = this.filterForm.controls['fTaskStateAccepted'].value;
    filter.Supervisor = this.filterForm.controls["fSupervisor"].value;
    if (this.allVisible) {
      filter.TypeId = 0;
      filter.GroupId = 0;
    }
    if (this.cleaningFilterVisible) {
      filter.GroupId = this.filterForm.controls["fCleaningGroup"].value;
      filter.TypeId = this.filterForm.controls["fCleaningType"].value;
    }
    if (this.maintenancesVisible) {
      filter.GroupId = this.filterForm.controls["fMaintenanceGroup"].value;
      filter.TypeId = this.filterForm.controls["fMaintenanceType"].value;
    }

    return filter;
  }

  setMaintanenceGroupId( groupId: number) {
    this.filterForm.controls["fMaintenanceGroup"].setValue(groupId);
    this.filterChanged();
  }
  selectAllTaskStatus() {
    this.filterForm.controls["fTaskStateCreated"].setValue(true);
    this.filterForm.controls["fTaskStateRunning"].setValue(true);
    this.filterForm.controls["fTaskStateRunning"].setValue(true);
    this.filterForm.controls["fTaskStatePaused"].setValue(true);
    this.filterForm.controls["fTaskStateEnded"].setValue(true);
    this.filterForm.controls["fTaskStateDeleted"].setValue(true);
    this.filterForm.controls["fTaskStateAccepted"].setValue(true);
    this.filterChanged();
  }
}



//#endregion =================================================================================================================



export class TaskListFilterConditions {
  fItemType: string;
  fRoomNumber: string;
  fFloor: number;
  fMyTasksOnly: boolean;
  fTaskStateCreated: boolean;
  fTaskStatePaused: boolean;
  fTaskStateEnded: boolean;
  fTaskStateRunning: boolean;
  fTaskStateDeleted: boolean;
  fTaskStateAccepted: boolean;
  fCleaningGroup: string;
  fMaintenanceGroup: string;
  fCleaningType: string;
  fMaintenanceType: string;
  fAssignedTo: string;
  fSupervisor: string;
}
