import { Injectable, Output, EventEmitter } from '@angular/core';
import { TaskType } from '@app/shared/enums/tasktype';
import { AddedTask } from '../classes/AddedTask';

import { environment } from '@environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { VwNewDailyTask } from '../viewdto/vwNewDailyTask';

@Injectable({
  providedIn: 'root'
})
export class AddTaskService {
  apiServer = environment.apiHost + environment.apiPort;
  constructor(private http: HttpClient) { }


  @Output()
  taskAdded : EventEmitter<AddedTask> = new EventEmitter()

  exitedWithoutAdd : EventEmitter<true> = new EventEmitter();

  addTask( data: VwNewDailyTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/AddDailyTask',data,{headers});

    //this.taskAdded.emit(params);
  }

 
  exitWithoutAdd() {
    this.exitedWithoutAdd.emit(true);  
  }
}
