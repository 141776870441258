import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { MaintenanceGroup } from '../dto/MaintenanceGroup';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGroupService {
  constructor(private http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;
  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceGroup/LoadAll/' + subscriber + "/" + hotelId);   
  }
  
  getById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceGroup/GetById'); 
  }

  addMaintenanceGroup(data:MaintenanceGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceGroup/Add',data,{headers});
  }

  updateMaintenanceGroup( data: MaintenanceGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceGroup/Update',data,{headers});
  }

  deleteMaintenanceGroup( ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/MaintenanceGroup/Delete/' + ids);  
  }
}