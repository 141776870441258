// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: flex-end;
}

/* Popup title */
.change-password-title {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 20px;
}

@media (max-width: 600px) {
  .change-password-title {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 15px;
  }
}
.change-password-subtitle {
  font-size: 14px;
}

.dialog-padding {
  padding: 0em 1em 0em 1em !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
