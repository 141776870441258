// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// Hostware
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { GlobalMessageService } from '@app/shared/services/global-message.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { ApplicationPage } from '@app/core/enums/application-page';
import { PublicAreaEditorDialogComponent } from '@app/kip/dialogs/public-area-editor-dialog/public-area-editor-dialog.component';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';

@AutoUnsubscribe()
@Component({
  selector: 'public-area-editor',
  templateUrl: './public-area-editor.component.html',
  styleUrls: ['./public-area-editor.component.scss']
})
export class PublicAreaEditorComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;

  filterForm: FormGroup;

  editorList: PublicArea[] = [];
  publicAreas: PublicArea[] = [];
  allPublicAreas: PublicArea[] = [];
  filterCaptions: string[] = [];

  dataSource: MatTableDataSource<PublicArea>;
  tableColumns: string[] = ["AreaCode", "AreaName", "Active", "Action"];

  loadAllPublicAreas$: Subscription;
  hotelUnitChanged$: Subscription;
  addPublicArea$: Subscription;
  deletePublicArea$: Subscription;
  updatePublicArea$: Subscription;


  constructor(
    private hwAlertService: HwAlertService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private publicAreaService: PublicAreaService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private crudMessageService: CrudMessagesService,
    private confirmService: HwConfirmationDialogService,
    public styleHelper: StyleHelperService,
    private publicAreaDialog: MatDialog
  ) {

    this.filterForm = this.fb.group({
      'fActive': new FormControl(true),
      'fAreaCode': new FormControl('', [Validators.maxLength(6)]),
      'fAreaName': new FormControl('', [Validators.maxLength(80)])
    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.topBarManagerService.setFormId(ApplicationPage.public_area_cleaning_type_editor);
    this.loadAllPublicAreas();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAllPublicAreas();
    });
  }

  ngOnDestroy(): void {
    this.loadAllPublicAreas$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.addPublicArea$?.unsubscribe();
    this.deletePublicArea$?.unsubscribe();
    this.updatePublicArea$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }

  deletePublicArea(item: PublicArea) {
    
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deletePublicArea$ = this.publicAreaService.deletePublicArea(item.PublicAreaId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAllPublicAreas();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
          } 
         })
      }
    })
  }

  openDialog(action: string, data?: PublicArea) {
    let publicAreaItem: PublicArea;
    if (action == OpenDialogAction.ADD) {
      publicAreaItem = new PublicArea();
      publicAreaItem.PublicAreaId = 0;
      publicAreaItem.Active = true;
      publicAreaItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      publicAreaItem.Subscriber = this.sessionStorageService.getSelectedUnit();
    } else {
      publicAreaItem = data;

    }
    const dialogDef = this.publicAreaDialog.open(PublicAreaEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "40vw",
      panelClass: "public-area-editor-dialog",
      data: publicAreaItem
    })
    dialogDef.afterClosed().subscribe(data => {
      if (data) {
        const processableItem = data as PublicArea;
        if (processableItem.PublicAreaId == 0) {
          this.addPublicArea$ = this.publicAreaService.addPublicArea(processableItem).subscribe(
            result => {
              this.crudMessageService.showAddOrEditMessage(result as RequestResult);
              this.loadAllPublicAreas();
            }
          )
        } else {
          this.updatePublicArea$ = this.publicAreaService.updatePublicArea(processableItem).subscribe(
            result => {
              this.crudMessageService.showAddOrEditMessage(result as RequestResult);
              this.loadAllPublicAreas();
            }
          )       
        }
      }
    })

  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAllPublicAreas() {
    this.loadAllPublicAreas$ = this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        this.editorList = res.Data as Array<PublicArea>;
        this.allPublicAreas = res.Data as Array<PublicArea>;
        this.editorList = JSON.parse(JSON.stringify(this.allPublicAreas.filter(item => item.Active)));
        this.filterCaptions = [];
        this.filterCaptions.push(this.translate.instant("common.active"));
        this.dataSource = new MatTableDataSource(this.editorList);
        this.dataSource.sort = this.sort;
        this.applyFilter();
      }
    })
  }

  applyFilter() {

    var tmpArr: PublicArea[] = [];
    var mustInclude: boolean;
    this.allPublicAreas.forEach(item => {
      mustInclude = true;
      this.filterCaptions = [];
      if (this.filterForm.controls["fActive"].value && mustInclude) {
        if (!item.Active) {
          mustInclude = false;
        }
        else {
          this.filterCaptions.push(this.translate.instant("common.active"));
        }
      }
      if (!item.AreaCode.toLowerCase().startsWith(this.filterForm.controls["fAreaCode"].value.toString().toLowerCase()) &&
        this.filterForm.controls["fAreaCode"].value !== "" && mustInclude) {
        mustInclude = false;
      }
      if (!item.AreaName.toLowerCase().startsWith(this.filterForm.controls["fAreaName"].value.toLowerCase()) &&
        this.filterForm.controls["fAreaName"].value !== "" && mustInclude) {
        mustInclude = false;
      }

      if (mustInclude) {
        tmpArr.push(item);
      }
    })
    this.editorList = [...tmpArr];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;
  }

  //#endregion =================================================================================================================




}
