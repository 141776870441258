
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';

export interface HwConfirmationDialogData {
  title: string;
  message: string;
  okButtonCaption : string;
  cancelButtonCaption: string;
}



@Component({
  selector: 'hw-confirmation-dialog',
  templateUrl : './hw-confirmation-dialog.component.html',
  styleUrls: ['./hw-confirmation-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HwConfirmationDialogComponent implements OnInit {

  dialogData: HwConfirmationDialogData
  constructor( 
              public dialogRef: MatDialogRef<HwConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: HwConfirmationDialogData) { }

  ngOnInit() {
  }
  onOkClick() {
    this.dialogRef.close(true);
  }
  onCancelClick() {
    this.dialogRef.close(false);
  }
}
