import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ParamKeyValue } from '../../../classes/ParamKeyValue';

@Component({
  selector: 'roomstate-chips',
  templateUrl: './roomstate-chips.component.html',
  styleUrls: ['./roomstate-chips.component.css']
})
export class RoomstateChipsComponent implements OnInit,OnChanges {
  
  
  @Input() paramKeyValue: ParamKeyValue[]
  label:string;
  includeClean: boolean;
  includeDirty: boolean;
  includeOOO: boolean;
  includeOOS: boolean;
  includeOK: boolean;
  includeShowRoom: boolean;
  constructor() { }

  ngOnInit() {
  
  }
  ngOnChanges(): void {
    this.label =  this.paramKeyValue.find(item=>item.Key=="Label").Value;
    this.includeClean = this.paramKeyValue.find(item=>item.Key=="Clean").Value.toLowerCase()== 'true' ? true : false;
    this.includeDirty = this.paramKeyValue.find(item=>item.Key=="Dirty").Value.toLowerCase()== 'true' ? true : false;
    this.includeOOO   = this.paramKeyValue.find(item=>item.Key=="OOO").Value.toLowerCase()== 'true' ? true : false;
    this.includeOOS   = this.paramKeyValue.find(item=>item.Key=="OOS").Value.toLowerCase()== 'true' ? true : false;
    this.includeOK    = this.paramKeyValue.find(item=>item.Key=="Ok").Value.toLowerCase()== 'true' ? true : false;
    this.includeShowRoom    = this.paramKeyValue.find(item=>item.Key=="ShowRoom").Value.toLowerCase()== 'true' ? true : false;
  }

  allSelected(): boolean {
    if( this.includeClean && this.includeDirty && this.includeOK && this.includeOOO && this.includeOOS && this.includeShowRoom) {
      return true ;
    } else {
      return false;
    }
  }

}
