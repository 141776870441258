export enum RoomState {
  EMPTY_AVAILABLE  = 1,
  EMPTY_CLEAN  = 2,
  EMPTY_DIRTY = 3,
  RESERVED_OK = 4,
  RESERVED_CLEAN = 5,
  RESERVED_DIRTY = 6,
  OUT_OF_ORDER = 7,
  OUT_OF_SERVICE = 8,
  SHOW_ROOM = 9
}
