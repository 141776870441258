import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LostAndFoundState } from '@app/kip/enums/LostAndFoundState';
import { environment } from '@environments/environment';
import { LostAndFound } from '../dto/LostAndFound';


@Injectable({
  providedIn: 'root'
})
export class LostAndFoundService {
  apiServer = environment.apiHost + environment.apiPort;


  constructor(private http: HttpClient) {

  }

  add(data: LostAndFound) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/lostandfound/add', data, { headers })
  }

  update(data: LostAndFound) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/lostandfound/update', data, { headers })
  }
  delete(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/lostandfound/Delete/' + ids);
  }
  getById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/lostandfound/getbyid/' + id);
  }


  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/lostandfound/LoadAll/' + subscriber + "/" + hotelId);
  }
  loadByStatusCodeAndDate(subscriber: string, hotelId: number, statusCode: LostAndFoundState, dateFrom: string,dateTo:string) {
    return this.http.get(this.apiServer + '/api/kip/lostandfound/LoadByStatusCodeAndDate/' + subscriber + "/" + hotelId + "/" + statusCode 
      + "/" + dateFrom + "/" + dateTo );
  }

  getReturningForm(subscriber: string, hotelId: number, ids: string) {
    return this.http.get(this.apiServer + '/api/kip/lostandfound/getreturningform/' + subscriber + "/" + hotelId + "/" + ids ,{ responseType: 'blob' });
  }

  getCullingForm(subscriber: string, hotelId: number, ids: string) {
    return this.http.get(this.apiServer + '/api/kip/lostandfound/getcullingform/' + subscriber + "/" + hotelId + "/" + ids ,{ responseType: 'blob' }) ;
  }
}
