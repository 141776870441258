import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { ShortTaskItem } from '@app/kip/classes/ShortTaskItem';
import { SchedulerService } from '@app/kip/services/scheduler.service';

@Component({
  selector: 'task-assign-dialog',
  templateUrl: './task-assign-dialog.component.html',
  styleUrls: ['./task-assign-dialog.component.scss']
})
export class TaskAssignDialogComponent implements OnInit {

  _data: ShortTaskItem;
  @ViewChild('userList') userList : MatSelectionList
  constructor(public dialogRef: MatDialogRef<TaskAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ShortTaskItem,
    private schedulerService : SchedulerService
    ) { 
    this._data = data;
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(undefined);
  }
  submit() {   
   
    this._data.AssignedTo =  this.userList.selectedOptions.selected[0].value;
    this.schedulerService.AssignedToChanged.emit(this._data);
    this.dialogRef.close(this._data);
  }
}
