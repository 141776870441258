// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

// HW 
import { environment } from "../../../environments/environment";
import { vwTransCleaning } from '../dto/CleaningTypeRoomState';


@Injectable({
  providedIn: 'root'
})
export class CleaningtypeRoomstateService {

  apiServer = environment.apiHost + environment.apiPort;

  constructor(public http: HttpClient) { }

  loadAll(Subscriber: string, hotelId: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(this.apiServer + '/api/kip/CleaningTypeRoomState/LoadAll/' + Subscriber + "/" + hotelId );
  }
  Save(data: vwTransCleaning) {

    //data,{headers}
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningTypeRoomState/SaveAll/' + data.Subscriber + "/" + data.Hotelid,data,{headers});
  }
}
