
// Angular
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { RequestResult } from '@app/core/dto/RequestResult';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { CleaningTask } from '@app/kip/dto/CleaningTask';
// 3rdparty

// Hostware
import { CleaningType } from '@app/kip/dto/CleaningType';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { CleaningTaskService } from '@app/kip/services/cleaning-task.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'cleaning-type-editor-dialog',
  templateUrl: './cleaning-type-editor-dialog.component.html',
  styleUrls: ['./cleaning-type-editor-dialog.component.scss']
})
export class CleaningTypeEditorDialogComponent implements OnInit, OnDestroy {

  @ViewChild('tasks') tasks : MatSelectionList; 
  @ViewChild('localizer') localizer : DataLocalizerComponent;
  editorForm: FormGroup;
  _data: CleaningType;
  cleaningGroups: CleaningGroup[]=[];
  cleaningTasks: CleaningTask[]=[];
  loadCleaningGroups$ : Subscription;
  taskListVisible : boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor( public dialogRef: MatDialogRef<CleaningTypeEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: CleaningType,
    private cleaningTypeService: CleaningTypeService,
    private cleaningGroupService: CleaningGroupService,
    private fb: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private rfHelperService: ReactiveFormsHelperService,
    private cleaningTaskService: CleaningTaskService,
    private translate: TranslateService
  ) { 
    this.editorForm = this.fb.group({

      'Active': new FormControl(true),
      'Weighting': new FormControl(false),
      'TypeCode': new FormControl('', [Validators.required, , Validators.maxLength(10)]),
      'TypeName': new FormControl('', [Validators.required, , Validators.maxLength(40)]),
      'CleaningGroupId' : new FormControl(0),
      'IsRoomClean' : new FormControl(true),
      'AutoAccepted' : new FormControl(false)
    });
    this._data = data;
  }
  

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnDestroy(): void {
    this.loadCleaningGroups$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }

  ngOnInit() {
    this.loadAll();
   
  }
 

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================
    
  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAll() {
    const combinedSources= combineLatest({
      cleaningGroups: this.cleaningGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      cleaningTasks: this.cleaningTaskService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    }).pipe(takeUntil(this.destroy$)).subscribe(
      value => {
        this.cleaningTasks = (value.cleaningTasks as RequestResult).Data as Array<CleaningTask>;
        this.cleaningGroups = (value.cleaningGroups as RequestResult).Data as Array<CleaningGroup>;
        this.rfHelperService.fillFormFromRecord(this._data,this.editorForm);
        this.editorForm.controls["CleaningGroupId"].setValue(this._data.CleaningGroupId);
        if(this._data.CleaningGroupId == 0) {
         
          this.editorForm.controls["CleaningGroupId"].setValue(this.cleaningGroups[0].GroupId);
        }
     
    })

  }

  /* dialógus kezelőgombok */
  cancel() {
    this.dialogRef.close(undefined);
  }

  submit() {
    // ellenőrzés !!

   
    // get  selected
    this._data.CleaningGroupId = this.editorForm.controls["CleaningGroupId"].value;
    this._data.Active = this.editorForm.controls["Active"].value;
    this._data.SData = this.localizer.jsonData;
    this._data.Weighting= this.editorForm.controls["Weighting"].value;
    this._data.TypeCode =this.editorForm.controls["TypeCode"].value;
    this._data.TypeName = this.editorForm.controls["TypeName"].value;
    this._data.IsRoomClean = this.editorForm.controls["IsRoomClean"].value;
    this._data.AutoAccepted = this.editorForm.controls["AutoAccepted"].value;
    this.dialogRef.close(this._data);
  }

  /* ellenörző lista műveleinek gombjai */ 
  deleteTasks() {
    // kiválasztott elemek
    const sel =  this.tasks.selectedOptions.selected;
    sel.forEach(item=> {
      this._data.Tasks.splice(this._data.Tasks.findIndex( task=> task.TaskId === parseInt(item.value)),1);
    })

  }  


  /* Tasklista választó gombjai */
  addToSelected() {
    const sel =  this.tasks.selectedOptions.selected;
    sel.forEach(item=> {
      if( !this._data.Tasks.find( task=> task.TaskId == parseInt(item.value))) {
        this._data.Tasks.push( this.cleaningTasks.find( ct=> ct.TaskId ==  parseInt(item.value) ));
      }
    })
    this.tasks.selectedOptions.deselect();
    this.taskListVisible = false;
  }

  cancelAddTask() {
    this.tasks.selectedOptions.deselect();
    this.taskListVisible = false;
  }

  alreadyAdded( cItem: CleaningTask) : boolean {
    return this._data.Tasks.find( item=>item.TaskId == cItem.TaskId) ? true: false
  }
  title() : string {
    return this._data.TypeId === 0 ?   this.translate.instant("common.add_new_task_type"):   this.translate.instant("common.change_task_type")
    
  }

  //#endregion =================================================================================================================




}
/*

  loadAllCleaningTasks$: Subscription;
  getCleaningTasksById$: Subscription;
  addCleaningTask$: Subscription;
  updateCleaningTask$: Subscription;
  deleteCleaningTask$: Subscription;

  cleaningTaskCodeExists$: Subscription;
  cleaningTaskNameExists$: Subscription;
    cleaningTaskCodeExistsError: number = -4;
  cleaningTaskNameExistsError: number = -5;




   this.loadAllCleaningTasks$?.unsubscribe();
  this.getCleaningTasksById$?.unsubscribe();
  this.addCleaningTask$?.unsubscribe();
  this.updateCleaningTask$?.unsubscribe();
  this.deleteCleaningTask$?.unsubscribe();
  this.cleaningTaskCodeExists$?.unsubscribe();
  this.cleaningTaskNameExists$?.unsubscribe();
*/