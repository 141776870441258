
export class ExtraTask {
  TaskId: number;
  Subscriber : string;
  HotelId : number ;
  RuleName: string;
  Frequency: number;
  TimeFrom: number;
  TimeTo : number;
  FrequencyUnit: string; // (P)erc , (O)ra, (N)ap  
  RoomStateTransitions: string;   //csv separated or 'ALL'
  Transitions: string;
  RoomNumbers: string;   //csv separated or 'ALL' 
  RoomTypes : string;    // csv separated or 'ALL'
  PublicAreas: string;   // csv separated or 'ALL'
  TaskType: string;      // (C)leaning , (M)aintenance
  TypeIds: string ;      // csv separated or 'ALL'
  GenerationMethod: string // (Z)áráskor (M)anual
  DateFrom: string;
  DateTo: string;
  Active: boolean;
  SData: string;
}