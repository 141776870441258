// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-chips-position {
    padding-bottom: 3px;
    margin-top: 8px;
    height: 25px;
} 

.hw-chips-label {
    display: contents;
    overflow: hidden;
    margin-left: 0.3em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
