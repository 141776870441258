import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ResourceService } from './resource.service';
import { AuthItem } from '../interfaces/auth-item';


@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authService: AuthService, private resourceService: ResourceService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth();
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.auth();
  }

  private auth(): boolean {
    if ((!this.authService.hasToken()) || (!this.authService.isAuthTokenValid())) {
      this.router.navigate([this.resourceService.loggedOutRoute]);
      return false;
    }
    else {
      return true;
    }
  }
  // private auth(): boolean | Observable<boolean> | Promise<boolean> {
  //   if (!this.authService.hasToken()) {
  //     this.router.navigate([this.resourceService.loggedOutRoute]);
  //     return false;
  //   }
  //   return this.authService.isAuthTokenValid()
  //     ? true
  //     : new Observable<boolean>((observer) => {
  //       this.authService.setPendingRefreshStatus(true);
  //       this.authService.fireTokenRefresh().subscribe((res: AuthItem) => {
  //         this.authService.setPendingRefreshStatus(false);
  //         this.authService.saveToken(res);
  //         this.authService.triggerDifferedRequests();
  //         observer.next(true);
  //         observer.complete();
  //       },
  //         (error) => {
  //           observer.error();
  //           observer.complete();
  //         });
  //     });
  // }
}

