// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#page-wrapper {
  background-color: rgb(189, 184, 184);
  background-color: white;
}

#editor-selection {
  background-color: white;
}

#selection-wrapper {
  background-color: #fafafa;
}

@media screen and (max-width: 840px) {
  #listbox {
    display: none;
  }
}
@media screen and (min-width: 841px) {
  #dropdown {
    display: none;
  }
}
.hw-font-size-14 {
  font-size: 14px !important;
}

.mobile-dropdown-menu {
  width: 95%;
  margin-left: 0.5em;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 0px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
