import { EventEmitter, Injectable, Output } from '@angular/core';
import { ShortTaskItem } from '../classes/ShortTaskItem';

@Injectable({providedIn:'root'})
export class SchedulerService {
  @Output() AssignedToChanged = new EventEmitter<ShortTaskItem>()
  @Output() SupervisorChanged = new EventEmitter<ShortTaskItem>()
constructor() { }

}
 