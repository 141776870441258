import { Injectable, Output, EventEmitter } from '@angular/core';
import { vwRoomStateListFilter } from '../viewdto/vwRoomStateListFilter';
import { vwTaskListFilter } from '../viewdto/vwTaskListFilter';
import { KIPFORM } from '../enums/KipForms';
import { AuthService } from '../../core/services/auth.service';
import { RoleService } from '../../shared/services/role.service';
import { RoleType } from '../../shared/enums/roles';
import { vwTaskSchedulerCalendarFilter } from '../viewdto/vwTaskSchedulerCalendarFilter';
import { HokParamsService } from './hok-params.service';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { RequestResult } from '../../core/dto/RequestResult';
import HwUtils from '../../shared/helpers/hwutils';
import * as  moment  from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
// import { ObserveOnOperator } from 'rxjs/internal/operators/observeOn';
import { ObserversModule } from '@angular/cdk/observers';


@Injectable({
  providedIn: 'root'
})





export class FormStateService {
  public schedFilterInit=new BehaviorSubject(true);

constructor(
  private authService: AuthService,
  private roleService: RoleService,
  private hokParamsService: HokParamsService,
  private sessionStorageService: SessionStorageService
) { 
  
  
  this._vwRoomStateListFilter = new vwRoomStateListFilter();
  this._vwTaskListFilter = new vwTaskListFilter();
  this._vwTaskSchedulerCalendarFilter = new vwTaskSchedulerCalendarFilter();



  // this.resetVwRoomStateFilter();
  // this.resetVwTaskListFilter();
}

 private _vwRoomStateListFilter: vwRoomStateListFilter;
 private _vwTaskListFilter: vwTaskListFilter;
 private _vwTaskSchedulerCalendarFilter: vwTaskSchedulerCalendarFilter;
 private _selectedRoomNumber: string;

 public TaskListFilterInitialised: boolean = false;
 public RoomStateListFilterInitialised : boolean = false;
 public TaskSchedulerFilterInitialised: boolean = false;

 

 private addTaskCaller : KIPFORM;
 
 //----- vwRoomStateFilter -------------------
 public getVwRoomStateListFilter() {
   return this._vwRoomStateListFilter;
 }
 public setVwRoomStateListFilter( pvwRoomStateListFilter : vwRoomStateListFilter) {
   this._vwRoomStateListFilter = pvwRoomStateListFilter;
   this.RoomStateListFilterInitialised = true;
 }
 public resetVwRoomStateFilter() {
   
   this._vwRoomStateListFilter.Checked = true;
   this._vwRoomStateListFilter.CheckedOut = true;
   this._vwRoomStateListFilter.Checkin = true;
   this._vwRoomStateListFilter.Checkout = true;
   this._vwRoomStateListFilter.EarlyCheckin = true;
   this._vwRoomStateListFilter.Floor = "";
   this._vwRoomStateListFilter.ItemType = "-1";
   this._vwRoomStateListFilter.LateCheckout = true;
   this._vwRoomStateListFilter.Occupied = true;
   this._vwRoomStateListFilter.RoomClean = true;
   this._vwRoomStateListFilter.RoomDirty = true;
   this._vwRoomStateListFilter.RoomEmpty = true;
   this._vwRoomStateListFilter.RoomNumber = "";
   
   this._vwRoomStateListFilter.RoomOOS = true;
   this._vwRoomStateListFilter.RoomOk = true;
   this._vwRoomStateListFilter.RoomOOO = true;
  

  //  if( this.roleService.userIsInRole(RoleType.RECEPCIOS)) {
  //   this._vwRoomStateListFilter.RoomOk = true;
  //   this._vwRoomStateListFilter.RoomOOO = true;
  //  }
  //  else {
  //   this._vwRoomStateListFilter.RoomOk = false;
  //   this._vwRoomStateListFilter.RoomOOO = false;
  //  }
  
   this._vwRoomStateListFilter.RoomShowRoom = true;
   this._vwRoomStateListFilter.RoomType = "-1";
   this.RoomStateListFilterInitialised = true;

 }
//----- vwTaskListFilter -------------------
public getVwTaskListFilter() {
  return this._vwTaskListFilter;
}

public setVwTaskListFilter( pVwTaskListFilter: vwTaskListFilter) {
  this.TaskListFilterInitialised = true;
  this._vwTaskListFilter = pVwTaskListFilter;
}
public resetVwTaskListFilter() {
 
  
  this._vwTaskListFilter.Floor = "";
 

  if(this.roleService.userIsInRole(RoleType.ADMIN) || this.roleService.userIsInRole(RoleType.RECEPCIOS) ) {
    this._vwTaskListFilter.ItemType = "-1";
  }
  if( this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) || this.roleService.userIsInRole(RoleType.MUSZAKOS)) {
    this._vwTaskListFilter.ItemType = "1";
  }
  if( this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.TAKARITO)) {
    this._vwTaskListFilter.ItemType = "0";
  }
  if(this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)  
                                                       || this.roleService.userIsInRole(RoleType.RECEPCIOS) 
                                                       || this.roleService.userIsInRole(RoleType.ADMIN)) {

    if(this.roleService.userIsInRole(RoleType.RECEPCIOS )) {
      this._vwTaskListFilter.MyTasksOnly = false;
    }
    else {
      this._vwTaskListFilter.MyTasksOnly = true;
    }
   
    this._vwTaskListFilter.AssignedTo = -1;
    this._vwTaskListFilter.Supervisor = -1;
    this._vwTaskListFilter.RoomNumber = "";
    if(this.roleService.userIsInRole(RoleType.RECEPCIOS )) {
      this._vwTaskListFilter.TaskStateAccepted = true;
    } else {
      this._vwTaskListFilter.TaskStateAccepted = false;
    }
 
    this._vwTaskListFilter.TaskStateCreated = true;
    this._vwTaskListFilter.TaskStateDeleted = false;
    this._vwTaskListFilter.TaskStateEnded = true;
    this._vwTaskListFilter.TaskStatePaused = true;
    this._vwTaskListFilter.TaskStateRunning = true;


  }
  if(this.roleService.userIsInRole(RoleType.MUSZAKOS) || this.roleService.userIsInRole(RoleType.TAKARITO) ) {
    this._vwTaskListFilter.MyTasksOnly = true;
    this._vwTaskListFilter.AssignedTo = this.authService.getUserIdFromToken();
    this._vwTaskListFilter.Supervisor = -1;
    this._vwTaskListFilter.RoomNumber = "";
    this._vwTaskListFilter.TaskStateAccepted = false;
    this._vwTaskListFilter.TaskStateCreated = true;
    this._vwTaskListFilter.TaskStateDeleted = false;
    this._vwTaskListFilter.TaskStateEnded = false;
    this._vwTaskListFilter.TaskStatePaused = true;
    this._vwTaskListFilter.TaskStateRunning = true;
  }
  
}

//----- Selected Room -------------------
public getSelectedRoomNumber() {
   return this._selectedRoomNumber;
 }
 public setSelectedRoomNumber(pSelectedRommNumber : string) {
  this._selectedRoomNumber = pSelectedRommNumber;
 }

 //----- AddTask Caller -------------------
 public setAddTaskCaller(caller: KIPFORM) {
  this.addTaskCaller = caller;
 }

 public getAddTaskCaller() : KIPFORM {
   return this.addTaskCaller;
 }

//----- Task Scheduler Calendar ------------
 resetTaskSchedulerCalendarFilter( froDatum: string) {
    const yearmonth = froDatum.substring(0,7);
    // const mom = moment(froDatum);
    this._vwTaskSchedulerCalendarFilter.dateFrom = (yearmonth+"."+ "01");
    // this._vwTaskSchedulerCalendarFilter.dateTo = (yearmonth+"." +  HwUtils.leftPad(mom.daysInMonth(),2));
    this._vwTaskSchedulerCalendarFilter.groupId = -1;
  
    if(this.roleService.userIsInRole(RoleType.GONDNOKNO) 
      || this.roleService.userIsInRole(RoleType.TAKARITO) 
      || this.roleService.userIsInRole(RoleType.TAKARITO)  
      || this.roleService.userIsInRole(RoleType.ADMIN)) { 
      this._vwTaskSchedulerCalendarFilter.taskTypeCode = "H";
    }
    else {
      this._vwTaskSchedulerCalendarFilter.taskTypeCode = "M";
    }
    this.TaskListFilterInitialised = true;
 }
public  setTaskSchedulerCalendarFilterInitialised() {
  this.TaskListFilterInitialised = true;
 }
 setTaskSchedulerCalendarFilter(  pVwTaskSchedulerCalendarFilter :  vwTaskSchedulerCalendarFilter) {

  this.TaskSchedulerFilterInitialised = true;
  this._vwTaskSchedulerCalendarFilter = pVwTaskSchedulerCalendarFilter;
 }
 getTaskSchedulerCalendarFilter() {
  return this._vwTaskSchedulerCalendarFilter;
 }

}


