export class SubscriberParameter {
  Id : number;
  SubscriberCode : string;
  HotelId : number;
  Param_Typecode : string;
  Param_String_Value : string;
  Param_Date_Value : string;
  Param_Int_Value : number; 
  Param_Decimal_Value : number;
  Param_Json_Value : string;
  Param_Xml_Value: string;
  public Param_Blob_Value : any; 
}