// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: flex-end;
}

.center {
  margin: auto;
  width: 50%;
  height: 50%;
  padding: 10px;
}

.button-class {
  font-size: 50px;
  font-weight: 700;
}

.photo {
  width: auto;
  height: auto;
}

.mat-dialog-container {
  width: max-content !important;
  height: max-content !important;
  min-height: auto !important;
  max-height: auto !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mat-dialog-content {
  overflow: auto;
  width: 100%;
  height: auto;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: 100%;
  margin: 0;
  padding: 1;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  height: auto !important;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
