import { Injectable } from "@angular/core";
import { MaintenanceType } from "../dto/MaintenanceType";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions } from "@angular/http";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceTypeService {

  constructor(public http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;
  loadAllObservable: Observable<any>;
  loadAllOrderbyGrouIdAndTypeNameObservable: Observable<any>;
  getMaintenanceTypeById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/GetMaintenanceTypeById');
  }

  add(data: MaintenanceType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceType/Add', data, { headers });
  }
  update(data: MaintenanceType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/MaintenanceType/Update', data, { headers });
  }

  delete(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/MaintenanceType/Delete/' + ids);
  }

  loadAll(subscriber: string, hotelId: number) : Observable<any> {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/LoadAll/' + subscriber + "/" + hotelId);
  }

  loadAllMulti(subscriber: string, hotelids : string) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/LoadAll_Multi/' + subscriber + "/" + hotelids);
  }
  loadAllOrderbyGrouIdAndTypeName( subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/LoadAllOrderbyGrouIdAndTypeName/' + subscriber + "/" + hotelId)
  }

  maintenanceTypeCodeExists(subscriber: string, hotelId: number, cleaningTypeCode: string) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + cleaningTypeCode);
  }
  maintenanceTypeNameExists(subscriber: string, hotelId: number, maintenanceTypeName: string) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/GetByTypeName/' + subscriber + "/" + hotelId + "/" + maintenanceTypeName);
  }
  loadByGroupId(groupId: number) {
    return this.http.get(this.apiServer + '/api/kip/MaintenanceType/LoadTypeByGroupId/' + groupId)  
  }
}
