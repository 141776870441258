// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.comment-overlay {
  width: 300px;
  height: 100px;
  border: solid 2px #ccc;
  border-radius: 5px;
  background: rgb(170, 169, 169);
  text-align: center;
  padding: 10px;
  margin: 0;
}

.room-type {
  font-size: 17px;
  font-weight: 700;
}

.toggler {
  font-family: "Roboto";
  font-size: 20px;
}

.card-container {
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  border-bottom: solid 5px #f5f5f5;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* státuszok */
.dep-to-arr-from {
  font-size: 15px;
  color: white;
  background-color: #e57373;
}

.dep-to-arr-to {
  font-size: 15px;
  color: white;
  background-color: #81c784;
}

.empty-to-arr-from {
  font-size: 15px;
  color: #c1c1c1;
}

.empty-to-arr-to {
  font-size: 15px;
  color: white;
  background-color: #81c784;
}

.dep-to-empty-from {
  font-size: 15px;
  color: white;
  background-color: #e57373;
}

.dep-to-empty-to {
  font-size: 15px;
  color: #c1c1c1;
}

.occupied-from {
  font-size: 20px;
  color: #85b0ec;
}

.occupied-to {
  font-size: 20px;
  color: #85b0ec;
}

.dep-to-ooo-from {
  font-size: 15px !important;
  color: white;
  background-color: #e57373;
}

.dep-to-ooo-to {
  font-size: 15px !important;
  color: #424242;
}

.ooo-to-arr-from {
  font-size: 15px !important;
  color: #424242;
}

.ooo-to-arr-to {
  font-size: 15px !important;
  color: white;
  background-color: #81c784;
}

.ooo-to-ooo-from {
  font-size: 15px !important;
  color: #424242;
}

.ooo-to-ooo-to {
  font-size: 15px !important;
  color: #424242;
}

.ooo-to-empty-from {
  font-size: 15px !important;
  color: #424242;
}

.ooo-to-empty-to {
  font-size: 15px !important;
  color: #c1c1c1;
}

.room-state-icon {
  font-size: 15px;
}

.comment-style {
  font-size: 11px;
}

.hw-margin-auto {
  margin: auto;
}

.col-horizontal-center {
  margin-top: auto;
  margin-bottom: auto;
}

.subs-hotel-caption {
  font-weight: 500;
  font-size: 15px;
}

.assign-button {
  font: 47px/40px Helvetica, Arial, sans-serif !important;
  color: #41c300;
  text-align: center;
  width: 40px !important;
  height: 40px !important;
}

.add-account-margin {
  margin-top: 0.8em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
