// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-background-image {
  height: 190px;
  width: 280px;
  background-color: #03a9f4;
}

.licenc-row {
  padding-bottom: 5px;
  padding-top: 10px;
}

.licenc-name {
  padding-left: 2em !important;
  padding-right: 0.2em;
  vertical-align: middle !important;
  font-size: 15px !important;
  color: #ffffff !important;
  font-weight: 700;
}

.licenc-cloud-name {
  padding: 0 !important;
  vertical-align: middle !important;
  font-size: 15px !important;
  color: #ffffff !important;
}

.licenc-kip-name {
  padding-left: 0.2em;
  padding-right: 0.2em;
  vertical-align: middle !important;
  font-size: 15px !important;
  color: #ffffff !important;
  font-weight: 700;
}

.licenc-version-name {
  padding: 0 !important;
  vertical-align: middle !important;
  font-size: 15px !important;
  color: #ffffff !important;
}

/* menu classes */
.menu-item-icon {
  font-size: 25px !important;
  padding-right: 10px !important;
}

.menu-item-label {
  font-size: 14px;
  font-family: "Roboto";
  text-decoration: none;
  cursor: "pointer";
}

.mat-expansion-panel-header {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.menu-item-wrapper {
  margin-left: 10px;
}

:visited {
  color: #000;
}

a {
  color: #000;
}

.menu-selected-hotel-name {
  vertical-align: middle !important;
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 35px;
}

.menu-kft-name {
  vertical-align: middle !important;
  font-size: 24px;
  padding: 0.625em 0.5em !important;
  color: #ffffff !important;
}

.avatar-content {
  text-align: center;
  border-radius: 100%;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(241, 196, 15);
  font: 16px/50px Helvetica, Arial, sans-serif;
  width: 50px;
  height: 50px;
}

.user-icons {
  padding-left: 0.8em;
}

.menu-user-name {
  vertical-align: middle !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

.single-menu-item {
  padding-left: 25px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-expansion-panel-body {
  padding: 0 43px 0px !important;
}

.mat-expansion-panel-header.mat-expanded {
  margin-bottom: 0em;
}

.single-menu-item:hover {
  background: rgba(0, 0, 0, 0.04);
}

.mat-nav-list a {
  padding-left: 25px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
