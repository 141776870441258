import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'blue-header',
  templateUrl: './blue-header.component.html',
  styleUrls: ['./blue-header.component.scss']
})
export class BlueHeaderComponent implements OnInit {

  constructor() { }

  @Input() title: string;
  
  @Input() subTitle : string;
  ngOnInit() {
    
  }

}
