import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { vwTaskSchedulerItem } from '../viewdto/vwTaskSchedulerItem';

@Injectable({
  providedIn: 'root'
})
export class TaskSchedulerCalendarService {

  private apiServer = environment.apiHost + environment.apiPort;
  constructor(
    public http: HttpClient
  ) { }

  getSchedulerItems(Subscriber: string, HotelId: number, StartDate: string,  EndDate: string,  Type: string,  TaskGroup: number) {
    return this.http.get(this.apiServer + '/api/kip/DailyTask/LoadIntervalTasks/' + Subscriber+"/" + HotelId + "/"  +
      StartDate + "/" + EndDate + "/" + Type + "/" + TaskGroup.toString()); 
  }

  saveIntervalTasks(items:vwTaskSchedulerItem[]) {
    return this.http.put(this.apiServer + '/api/kip/DailyTask/SaveIntervalTasks' , items);
  }

  }
