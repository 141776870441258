import { MaintenanceTask } from "./MaintenanceTask";

export class MaintenanceType {
  TypeId : number;
  Subscriber : string;
  HotelId: number;
  TypeCode : string;
  TypeName : string;
  Active : boolean;
  MaintenanceGroupId : number;
  Tasks : Array<MaintenanceTask>;
  SData:  string;
}