export class vwTaskListFilter {
  ItemType : string;  // "-1" Housekeeping+Maintenance ; Housekeeping: "0" ; maintenance : "1"
  RoomNumber: string;
  PublicAreaCode: string;
  Floor: string;
  MyTasksOnly : boolean ; // ha az AssignedTo == a bejelentkezett user UserId-vel
  TaskStateCreated: boolean;
  TaskStatePaused : boolean;
  TaskStateEnded : boolean;
  TaskStateRunning: boolean;
  TaskStateDeleted: boolean;
  TaskStateAccepted: boolean;
  AssignedTo: number  // "-1" az összes felhasználóhoz rendelt feladat,  egyébként  az AssignedTo ben meghatározott user feladatai  
  Supervisor : number; 
  StartDate: string;
  EndDate: string;
  TypeId: number; //CleaningTypeId vagy MaintenanceTypeId 
  GroupId: number; // CleaningGroupId  MaintenanceGroupId
  CleaningTypes: string; //all  vagy 123,456,654
  MaintenanceTypes: string; //all  vagy 213,343,465
}