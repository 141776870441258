// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-wrapper {
  visibility: "visible";
  z-index: "10000";
  width: "100%";
  height: "100%";
  position: "absolute";
  left: 0;
  top: 0;
  position: fixed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
