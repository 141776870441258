
// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// Hostware 
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { CleaningGroupEditorDialogComponent } from '@app/kip/dialogs/cleaning-group-editor-dialog/cleaning-group-editor-dialog.component';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { GlobalMessageService } from '@app/shared/services/global-message.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { SpinnerService } from '@app/core/services/spinner.service';




@AutoUnsubscribe()
@Component({
  selector: 'cleaning-group-editor',
  templateUrl: './cleaning-group-editor.component.html',
  styleUrls: ['./cleaning-group-editor.component.scss']
})
export class CleaningGroupEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort!:MatSort;

  tableColumns: string[] = ["GroupName", "Action"];
  loaded: boolean;

  editorForm: FormGroup;
  editorList: CleaningGroup[] = [];

  dataSource : MatTableDataSource<CleaningGroup>;


  loadAllCleaningGroups$: Subscription;
  getCleaningGroupById$: Subscription;
  addCleaningGroup$: Subscription;
  updateCleaningGroup$: Subscription;
  deleteCleaningGroup$: Subscription;
  cleaningGroupNameExists$: Subscription;
  hotelUnitChanged$: Subscription;

  constructor(
    public hwAlertService: HwAlertService,
    private hwConfirmationService: HwConfirmationDialogService,
    public cleaningGroupService: CleaningGroupService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private cleaningGroupDialog: MatDialog,
    private spinnerService : SpinnerService
  ) {

    this.editorForm = fb.group({
      'filterInput': new FormControl(''),
    })
    this.dataSource = new MatTableDataSource();
  }



  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.loadAllCleaningGroups();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=> {
      this.loadAllCleaningGroups();
    })
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy(): void {
    this.loadAllCleaningGroups$?.unsubscribe();
    this.getCleaningGroupById$?.unsubscribe();
    this.addCleaningGroup$?.unsubscribe();
    this.updateCleaningGroup$?.unsubscribe();
    this.deleteCleaningGroup$?.unsubscribe();
    this.cleaningGroupNameExists$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  openDialog(action: string, data?: CleaningGroup) {
    let cleaningGroupItem: CleaningGroup;

    if (action == OpenDialogAction.ADD) {
      cleaningGroupItem = new CleaningGroup();
      cleaningGroupItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      cleaningGroupItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      cleaningGroupItem.GroupId = 0;
    } else {
      cleaningGroupItem = data;
    }

    const cleaningGroupDialogRef = this.cleaningGroupDialog.open(CleaningGroupEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "65vw",
      // height: "70vh",
      panelClass: "cleaning-group-editor-dialog",
      data:cleaningGroupItem 

    })
    cleaningGroupDialogRef.afterClosed().subscribe(data => {
      // nem Cancellel zártuk le a dialógust
      if (data) {
        const processableItem = data as CleaningGroup;
        if (processableItem.GroupId == 0) {
          // add
          this.addCleaningGroup$ = this.cleaningGroupService.addCleaningGroup(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAllCleaningGroups();
          })
        } else {
          // update 
          this.updateCleaningGroup$ = this.cleaningGroupService.updateCleaningGroup(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAllCleaningGroups();
          })
        }
       
      }

    })

  }
  deleteRow(item: CleaningGroup) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {     
      if (confirmed) {
        this.deleteCleaningGroup$ = this.cleaningGroupService.deleteCleaningGroup(item.GroupId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAllCleaningGroups();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));

          } 
        })
      }
    })



  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  


  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAllCleaningGroups() {
    this.spinnerService.setSpinnerVisibility(true)
    this.loadAllCleaningGroups$ = this.cleaningGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).
      subscribe(result => {
        var res = result as RequestResult;
        this.editorList = res.Data as Array<CleaningGroup>;
        this.dataSource = new MatTableDataSource(this.editorList);
        this.dataSource.sort = this.sort;
        this.loaded = true;
        this.spinnerService.setSpinnerVisibility(false)
      })
  }

  showAddOrEditMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }
  //#endregion =================================================================================================================



}
