// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

@media (max-width: 599px) {
  .hw-grid-cell {
    float: right;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .hw-mobile-display-inherit {
    display: flex;
  }
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    margin-bottom: 0.5em;
  }
  .boolean-cell {
    font-size: 20px;
  }
  tr.mat-row, tr.mat-footer-row {
    height: 48px;
    width: 100%;
  }
  .hw-mobile-table-title-show {
    text-align: left;
  }
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 0px;
    border-bottom-width: 0px;
    vertical-align: top;
  }
  tr.mat-header-row {
    display: none;
  }
  .hw-mobile-width-90 {
    width: 100%;
  }
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0px;
    margin-bottom: 3em;
  }
  .hw-margin-right-1em-important {
    margin-right: 0em !important;
  }
}
.scroll-top-float-button {
  margin-bottom: 2em;
}

.mat-mdc-table .mat-mdc-header-cell {
  z-index: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
