import { Injectable ,Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
@Injectable({providedIn:'root'})
export class SettingsContainerService {

constructor() { }


@Output() visibilityChanged: EventEmitter<boolean> = new EventEmitter();

@Output() headerTextChanged: EventEmitter<string> = new EventEmitter();

@Output() subHeaderTextChanged: EventEmitter<string> = new EventEmitter();

@Output() listModeSelected: EventEmitter<boolean> = new EventEmitter(); 
@Output() editModeSelected: EventEmitter<boolean> = new EventEmitter(); 


changeVisibility( visible : boolean) {
  this.visibilityChanged.emit(visible);
  }

setHeaderText( text : string) {
  this.headerTextChanged.emit(text);
}

setSubheaderText( text: string) {
  this.subHeaderTextChanged.emit(text);
}

setListMode() {
  this.listModeSelected.emit(true);
}
setEditMode() {
  this.editModeSelected.emit(true)
}

}
