// Angular 
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// Material

import { MatListOption } from '@angular/material/list';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
// 3rd party 
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SubscriberTreeDto } from '@app/core/dto/SubscriberTreeDto';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SelectItem } from '@app/core/classes/SelectItem';
import { ActivatedRoute, Router } from '@angular/router';
// Hostware 



@Component({
  selector: 'hotel-selection',
  templateUrl: './hotel-selection.component.html',
  styleUrls: ['./hotel-selection.component.scss']
})
export class HotelSelectionComponent implements OnInit {

  @Input()  enableRootHotel: boolean;
  hotelUnits: SelectItem[]
  selectedHotelUnit: string;
  selectedHotelName: string;
  
  selectedHotels: string[] = [];
  selectedOptions: MatListOption[];
  allHotels: string[] = [];
  rootHotel: string;
  loadUserData$: Subscription;
   
  constructor(
     public sessionStorageService: SessionStorageService,
     public  topBarManagerService: TopBarManagerService,
     private router: Router

    ) {

   }
  

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.hotelUnits = this.sessionStorageService.getFlattenedHotels();
    if(!this.hotelUnits){return}
   
      // roothotel tiltva
      // if(this.enableRootHotel) {
      //     this.hotelUnits[0].disabled = false;
      // } else {
      //   if(this.hotelUnits.length > 1) {
      //     this.hotelUnits[0].disabled = true;
      //   } else {
      //     this.hotelUnits[0].disabled = false;
      //   }
      // }
      if(this.sessionStorageService.getSelectedHotelId() == 99 && !this.enableRootHotel) {
        this.selectedHotelUnit = this.hotelUnits[1].value;
      } else {
        this.selectedHotelUnit =  this.hotelUnits.find( item=>  item.value == (this.sessionStorageService.getSelectedUnit() + "@" 
                  + this.sessionStorageService.getSelectedHotelId().toString())).value ;
      }
      this.sessionStorageService.setSelectedHotelId(this.selectedHotelUnit.split('@')[1]);
      this.sessionStorageService.setSelectedUnit(this.selectedHotelUnit.split('@')[0]);
      console.log(this.router.url)
     
  }

  //#endregion =================================================================================================================



  //#region =============== Form events ========================================================================================
  changeSelectedHotelunit(event) {
    this.selectedHotelName = this.hotelUnits.find(e=>e.value ==event.value).label; 
    const complex = event.value as string;
    const unit  = complex.split('@')[0];
    const hotelid = complex.split('@')[1];
    this.sessionStorageService.setSelectedUnit(unit);
    this.sessionStorageService.setSelectedHotelId(hotelid);
    if( hotelid == "99") {
      this.sessionStorageService.setSelectedUnitMulti(event.value);
      this.sessionStorageService.setSelectedHotelIdMulti(event.value);
    }
    
    this.topBarManagerService.hotelUnitChanged.emit(event.value)


  }

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  rootDisabled( item : SelectItem) : boolean {
    if(item.value.toString().includes('@99')) {
      const ur = this.router.url;
      if( ur.includes('scheduler'))   {
        return false
      } else {
        return true;
      }
    } else {
      return false;
    }

  }
   //#endregion =================================================================================================================


}
