import { vwCleaningType } from "./vwCleaningType";
import { vwCommonProblem } from "./vwCommonProblem";
import { vwMaintenanceType } from "./vwMaintenanceType";
import { vwExtraTask } from "./vwExtraTask";
import { MiniBarItem } from "../../dto/MiniBar";

export class vwRoomState {
      Subscriber: string;
      HotelId: number;
      DueDate: string;
      Floor: number;
      RoomStateCode: number;
      RoomStateName: string;
      RoomNumber: string;
      RoomTypeName: string;
      Remark: string;
      Ammenities: string;
      ReservationNumber: number;
      NextArrivalDate: string;
      GuestName: string;
      Nationality: string;
      Importance: string;
      RoomReservationStateCode: string;
      RoomReservationStateName: string;
      NumberOfAdults: number;
      NumberOfTeens: number;
      NumberOfChildren: number;
      NumberOfBabies: number;
      NumberOfActiveBabies: number;
      EarlyCheckin: boolean;
      LateCheckOut: boolean;
      RoomStateTransitionCode: string;
      CleaningTypes :vwCleaningType[];
      CommonProblems: vwCommonProblem[];
      Maintenances : vwMaintenanceType[];
      ExtraTasks : vwExtraTask[];
      MinibarItems: MiniBarItem[];
      OutOfOrderEndDate : string;
      CheckedIn : boolean;
      CheckedOut: boolean;
      DateOfDeparture : string;
 }
