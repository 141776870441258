
// Angular
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { RequestResult } from '@app/core/dto/RequestResult';


@AutoUnsubscribe()
@Component({
  selector: 'public-area-editor-dialog',
  templateUrl: './public-area-editor-dialog.component.html',
  styleUrls: ['./public-area-editor-dialog.component.scss']
})
export class PublicAreaEditorDialogComponent implements OnInit, OnDestroy {

  editorForm: FormGroup;
  _data: PublicArea
  loaded: boolean;
  title: string;
  allPublicAreas: PublicArea[]=[]
  publicAreaCodeExists$: Subscription;
  publicAreaNameExists$: Subscription;
  loadAllPublicAreas$: Subscription;

  constructor(public dialogRef: MatDialogRef<PublicAreaEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: PublicArea,
    private sessionStorageService: SessionStorageService,
    private rfHelperService: ReactiveFormsHelperService,
    private fb: FormBuilder,
    private publicAreaService: PublicAreaService,
    private hwAlertService: HwAlertService,
    private translate: TranslateService
  ) {
    this.editorForm = this.fb.group({
      AreaCode: ['', [Validators.required, Validators.maxLength(6)]],
      AreaName: ['', [Validators.required, Validators.maxLength(80)]],
      Active: true
    });
    this._data = data;
  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.rfHelperService.fillFormFromRecord(this._data, this.editorForm);
    this.title = this._data.PublicAreaId == 0 ? this.translate.instant("common.add_public_area") : this.translate.instant("common.edit_public_area");
    this.loadAllPublicAreas$ = this.publicAreaService.loadAll(this._data.Subscriber, this._data.HotelId).subscribe( result=> {
      this.allPublicAreas = (result as RequestResult).Data as Array<PublicArea>
    })

  }
  ngOnDestroy(): void {
    this.publicAreaCodeExists$?.unsubscribe();
    this.publicAreaNameExists$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  submit() {

    // check duplicates 
    
    if (this._data.PublicAreaId == 0) {
      // this.loadAllPublicAreas$ = this.
      if(this.allPublicAreas.find(item=> item.AreaCode.toLowerCase() == this.editorForm.controls["AreaCode"].value.toLowerCase())) {
        this.editorForm.controls["AreaCode"].setErrors({ duplicated: true});
        return;
      }
      if(this.allPublicAreas.find(item=> item.AreaName.toLowerCase() == this.editorForm.controls["AreaName"].value.toLowerCase())) {
        this.hwAlertService.error(this.translate.instant("common.area_name_already_exists"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
        return;
      }
    }
   
    this.rfHelperService.updateRecordFromForm(this._data, this.editorForm);
    this.dialogRef.close(this._data);
  }


  cancel() {
    this.dialogRef.close(undefined)
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  //#endregion =================================================================================================================

}
