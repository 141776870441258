import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[booleanField]'
})
export class BooleanFieldDirective {

  constructor(private el: ElementRef) {
    if(el.nativeElement.value == "true") {
      this.el.nativeElement.style.backgroundColor = 'yellow';
    }
    else {
      this.el.nativeElement.style.backgroundColor = 'red';
    }
    console.log("HELLLLO")
   }

}
