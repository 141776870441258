// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintenence-header-title {
  color: #03a9f4;
  font-weight: 500;
  font-size: 17px;
  display: inline-flex;
  padding-left: 10px;
}

@media (max-width: 599px) {
  .maintenence-header-title {
    font-size: 15px;
  }
  .hw-hr-vertical-2em {
    border-left: 0px solid #03a9f4 !important;
    padding-left: 0px !important;
    margin-left: 0em !important;
  }
}
hr.maintenance-hr {
  border-top: 1px solid #dadada;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
}

.hw-hr-vertical-2em {
  border-left: 3px solid #03a9f4;
  padding-left: 20px;
  margin-left: 0.8em;
}

.white-box {
  text-align: right;
  margin-bottom: 0.5em;
}

.container {
  flex-flow: flex-direction flex-wrap;
}

.maintenance-leader-cards {
  height: 175px;
  box-shadow: 0 2px 0 0 transparent, 0 2px 5px 0 rgba(0, 0, 0, 0.22);
}

.hw-mat-card-vertical-scroll {
  overflow-x: hidden;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.leader-scroll-box {
  height: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
