// Angular

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
// 3rdparty

import customParseFormat from 'dayjs/plugin/customParseFormat'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import dayjs from 'dayjs';
import 'dayjs/locale/hu';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import 'dayjs/locale/th';
import 'dayjs/locale/de';

import localeEn from '@angular/common/locales/en';
import localeUk from  '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeHu from '@angular/common/locales/hu';
import localeTh from '@angular/common/locales/th';


import { provideNgxMask } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// Hostware
import {DirectivesModule} from '../../../shared/directives/DirectivesModule';
import { HwAlertService } from '../../../shared/hw-alert-component/src/lib/hw-alert.service';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '../../../shared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';
import { HwDayjsDateAdapter } from '../../../shared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';
import { MatDayjsDateModule } from '../../../shared/hw-material-dayjs-adapter/lib/adapter/';

import { HwConfirmationDialogService } from '../../../shared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogModule } from '../../../shared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.module';


import { AppComponent } from './app.component';
import { CoreModule } from "./core/core.module";
import { HwAuthInterceptorService } from './core/services/hw-auth-interceptor.service';
import { UserService } from './core/services/user.service';
import { MaterialModule } from './core/material.module';
import { LoginComponent } from './components/login/login.component';
import { HttpLoaderFactory, createTranslateLoader, KipModule } from './kip/kip.module';
import { HwAlertModule } from '@globalshared/hw-alert-component/src/lib/hw-alert.module';


/* dayjs */



import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/hu';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';

dayjs.extend(customParseFormat);
dayjs.extend(isLeapYear);

registerLocaleData(localeHu, 'hu');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeEn, 'en');


export const HU_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',    
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  
  
  ],
  imports: [
    KipModule,
    HwAlertModule,
    DirectivesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    HwAlertModule,
		ReactiveFormsModule,
    HwConfirmationDialogModule,
    // ServiceWorkerModule.register('/assets/sw.js', { enabled: true}),
    TranslateModule.forRoot({
      defaultLanguage:"hu",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDayjsDateModule,
    CurrencyMaskModule  
  ],
  exports: [
    CoreModule,
    MatDayjsDateModule,
    CurrencyMaskModule,
    KipModule,
    HwAlertModule,
    ServiceWorkerModule
  ],
  providers: [
    HwConfirmationDialogService,
    HwAlertService,
    HwConfirmationDialogService,
    UserService,
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HwAuthInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'hu' },
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: MAT_DATE_FORMATS, useValue: HU_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HwAuthInterceptorService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {provide: DateAdapter,
      useValue: HwDayjsDateAdapter,
      deps: [MAT_DATE_LOCALE,MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ],     

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, route: ActivatedRoute, authService: AuthService) {
  // let a = route;
    if(!authService.isAuthTokenValid()) {
      router.navigate(["/login"],{relativeTo:route})
    }
   
   }
 }

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = 'hu';
      translate.addLangs(['hu', 'en','uk']);
      translate.setDefaultLang('hu');
      const browserLang = translate.getBrowserLang();
      if (environment.home) {
        langToSet = 'hu';
      } else {
        langToSet = browserLang.match(/hu|en/) ? browserLang : 'hu';
      }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

