// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: flex-end;
}

.viewport {
  height: 300px;
}

.action-button {
  font-size: 40px;
}

.action-button-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 49%;
}

.user-group-name {
  width: 44%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
