// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
//import { RequestOptions } from "@angular/http";


//HW
import { environment } from "../../../environments/environment";
import { CommonProblemType } from '../dto/CommonProblemType';

// 3rd Party

@Injectable()
export class CommonProblemTypeService {

  constructor(public http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;

  getCleaningTaskById(id: number) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemType/GetById' ); 
  }

  addCommonProblemType(data: CommonProblemType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CommonProblemType/Add',data,{headers});
  }

  updateCommonProblemType( data: CommonProblemType) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CommonProblemType/Update',data,{headers});
  }

  deleteCommonProblemType( ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/CommonProblemType/Delete/' + ids);  
  }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemType/LoadAll/' + subscriber + "/" + hotelId);   
  }

  commonProblemTypeCodeExists(subscriber : string,hotelId:number, commonProblemTypeCode:string) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemType/GetByTypeCode/' + subscriber + "/" + hotelId + "/" + commonProblemTypeCode); 
  }

  commonProblemTypeNameExists(subscriber : string,hotelId:number, commonProblemTypeName:string) {
    return this.http.get(this.apiServer + '/api/kip/CommonProblemType/GetByTypeName/' + subscriber + "/" + hotelId + "/" + commonProblemTypeName); 
  }


}
