// Angular
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

// Hostware
import { vwRoomState } from '@app/kip/viewdto/vwdailytask/vwRoomState';
import { MiniBarItem } from '@app/kip/dto/MiniBar';
import { MinibarService } from '@app/kip/services/minibar.service';



@AutoUnsubscribe()
@Component({
  selector: 'minibar-dialog',
  templateUrl: './minibar-dialog.component.html',
  styleUrls: ['./minibar-dialog.component.scss'],
})
export class MinibarDialogComponent implements OnInit, OnDestroy {

  public _data: vwRoomState;
  dataSource: MatTableDataSource<MiniBarItem>;
 
  tableColumns: string[] = ["ItemName", "Decrement","Quantity", "Increment"];




  constructor( public dialogRef: MatDialogRef<MinibarDialogComponent> , @Inject(MAT_DIALOG_DATA) private data: vwRoomState,
               private translate: TranslateService,
               private minibarService : MinibarService,
               private fb: FormBuilder
               
  ) {
    this._data = data;
    this.dataSource = new MatTableDataSource(this._data.MinibarItems);
   }

  
//#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.MinibarItems);
  }
  ngOnDestroy(): void {   
   
   
  }
//#endregion =================================================================================================================

//#region =============== List event hadlers   ===============================================================================
//#endregion =================================================================================================================

//#region =============== Form events ========================================================================================

addQuantity(item: MiniBarItem) {
  item.Quantity = item.Quantity+1;
}

removeQuantity( item: MiniBarItem) {
  if( item.Quantity -1 < 0) {
    item.Quantity = 0; 
  }  else {
    item.Quantity = item.Quantity-1;
  }
}

charge() {
  this.dialogRef.close(this._data);
}

cancel() {
  this.dialogRef.close(undefined)
}

//#endregion =================================================================================================================

//#region =============== Dialog events ======================================================================================
//#endregion =================================================================================================================
  
//#region =============== Validation    ======================================================================================
//#endregion =================================================================================================================

//#region =============== Functions  =========================================================================================
//#endregion =================================================================================================================


}
