// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trustee-trash {
  color: #e57373;
  font-size: 3em;
}

.trustee-star {
  color: #03a9f4;
  font-size: 3em;
}

.trustee-ellenorzott {
  color: #7ec681;
  font-size: 3em;
}

.trustee-ooo {
  color: #424242;
  font-size: 3em;
}

.trustee-kozossegi {
  color: #ba67c8;
  font-size: 3em;
}

.trustee-keszultseg {
  color: #4db6ac;
  font-size: 3em;
}

.sum-number {
  text-align: end;
  padding-right: 40px;
}

.filler {
  margin-top: 6px;
}

.room-number {
  font-size: larger;
  font-style: inherit;
  font-weight: 500;
  line-height: 55px;
  margin-left: -0.3em;
}

.trustee-label {
  text-align: center;
  font-size: 12px;
}

.box-number {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.box-number-right {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
}

hr.trustee-hr {
  border-top: 1px solid #dfdfdf;
  padding-bottom: 0.5em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
