import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { HokReportDto } from '../dto/HokReportDto';

@Injectable({
  providedIn: 'root'
})
export class HkReportService {
  
  apiServer = environment.apiHost + environment.apiPort;

  constructor(private http: HttpClient) { }


  getHkReportPdf(subscriber: string,hotelId: number, data : HokReportDto) {
    return this.http.put(this.apiServer + '/api/kip/room/getHokreportDetailedPdf/' + subscriber + '/' + hotelId , data,{responseType:'blob'})
  }
}

