import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';




import { MenuService } from '../../../core/services/menu.service';
import { TopBarManagerService } from '../../../core/services/topbar-manager.service';

import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'kip-landing-page',
  templateUrl: './kip-landing-page.component.html',
  styleUrls: ['./kip-landing-page.component.css']
})

export class KipLandingPageComponent implements AfterViewInit, OnDestroy {
  ngOnDestroy(): void {
  
  }
  isVisible: boolean = false;


  constructor(
    public  menuService: MenuService,
    public topBarManagerService: TopBarManagerService
  ) {}
  
  ngAfterViewInit() {
    
    this.topBarManagerService.toggleSideBar.subscribe(evt=> {  
      this.isVisible = evt;
    })
    this.menuService.loadModuleMenu("kip");
   
  }

  hideSidebar() {
    this.topBarManagerService.setToggleSideBar(false);
    this.isVisible = false;
  }
    //this.menuService.loadModuleMenu('kip');
    //this.topBarManagerService.setFormId(ApplicationPage.kip_landing_page);
  }


