// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// 3rdparty
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { saveFile, saveAs } from 'file-saver';


// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { Subscription } from 'rxjs';
import { vwRoomState } from '@app/kip/viewdto/vwdailytask/vwRoomState';
import { vwPublicArea } from '@app/kip/viewdto/vwdailytask/vwPublicArea';
import { RoomState } from '@app/shared/enums/roomstates';
import HwUtils from '@app/shared/helpers/hwutils';
import { RoomStateTransition } from '@app/shared/enums/roomstatetransitions';
import { vwRoomStateListFilter } from '@app/kip/viewdto/vwRoomStateListFilter';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { RoomService } from '@app/kip/services/room.service';
import { DynamicControlDescriptor } from '@app/kip/classes/DynamicControlDescriptor';
import { RoomStateFilterConditions, RoomstateListFilterComponent } from '@app/kip/components/roomstate-list-filter/roomstate-list-filter.component';
import { RoomStateListService } from '@app/kip/services/room-state-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RoomStatePanelComponent } from '../room-state-panel/room-state-panel.component';
import { SpinnerService } from '@app/core/services/spinner.service';
import { PublicAreaPanelComponent } from '../public-area-panel/public-area-panel.component';
import { FormStateService } from '@app/kip/services/form-state.service';
import { vwCleaningType } from '@app/kip/viewdto/vwdailytask/vwCleaningType';
import dayjs from 'dayjs';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { PublicAreaStates } from '@app/shared/enums/public-area-states';


@AutoUnsubscribe()
@Component({
  selector: 'room-state-list',
  templateUrl: './room-state-list.component.html',
  styleUrls: ['./room-state-list.component.scss'],


})
export class RoomStateListComponent implements OnInit, OnDestroy {
  @ViewChild('room_state_panel', { static: true }) roomStatePanel: RoomStatePanelComponent;
  @ViewChild('public_area_panel', { static: true }) publicAreaStatePanel: PublicAreaPanelComponent;

  @ViewChild('roomFilter', { static: false }) roomFilter: RoomstateListFilterComponent

  
  title: string;
  routerEvent$: Subscription;
  loadFroDatum$: Subscription;
  loadRoomStates$: Subscription;
  hotelUnitChanged$: Subscription;
  roomNumber: string;
  froDatum: string;
  loaded: boolean;
  scrollPosition: number = 0;
  roomStateList: vwRoomState[] = [];
  allRoomStateList: vwRoomState[];
  publicAreaList: vwPublicArea[];
  allPublicAreaList: vwPublicArea[];
  tableColumns: string[] = [];
  roomStatePanelVisible: boolean=false;
  publicAreaPanelVisible: boolean = false;
  selectedRoomState : vwRoomState;
  selectedPublicArea : vwPublicArea;
  
  placeType: string;

  dataSource: MatTableDataSource<vwRoomState>;
  filterConditions: RoomStateFilterConditions = new RoomStateFilterConditions();
  constructor(
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private roomService: RoomService,
    private activatedRoute: ActivatedRoute,
    private hokParamsService: HokParamsService,
    private roomStateListService: RoomStateListService,
    private translate: TranslateService,
    private formStateService: FormStateService,
    public styleHelper: StyleHelperService,
    private spinnerService: SpinnerService

  ) { }

  ngOnDestroy(): void {
    this.routerEvent$?.unsubscribe();
    this.loadFroDatum$?.unsubscribe();
    this.loadRoomStates$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.tableColumns = ['RoomNumber', 'RoomType', 'AssignedTo', 'FamilyMembers', 'GuestName', 'DateOfDeparture', 'Tasks', 'RoomState'];
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      const hokpResult = result as RequestResult;
      this.froDatum = hokpResult.Data;
    })
    this.routerEvent$ = this.activatedRoute.queryParams.subscribe(params => {
      if (params["roomNumber"]) {
        this.roomNumber = params["roomNumber"];
      }
      if (params["placeType"]) {
        this.placeType = params["placeType"].toString();
        this.title = this.placeType == "R" ? this.translate.instant("K400p.title") : this.translate.instant("K400p.public_title")
      }

      this.loadRoomStates();
      this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
        this.loadRoomStates();
      })

    })
    this.roomStatePanel.panelClosed.subscribe(event => {

      if (this.placeType == "R") {
        this.loadRoomStates$ = this.roomStateListService.loadRoomStates(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
          var res = result as RequestResult;
          if (res) {
            this.roomStateList = res.Data.VwRoomStateList as Array<vwRoomState>;
            this.allRoomStateList = [...this.roomStateList];
            this.publicAreaList = res.Data.VwPublicAreaList as Array<vwPublicArea>;

            this.dataSource = new MatTableDataSource(this.roomStateList)
            this.loaded = true;
            this.applyRoomFilter();
            window.scrollTo(0, this.scrollPosition);
          }
        })
      }
      this.roomStatePanelVisible = false;
      this.publicAreaPanelVisible = false;
    })

    this.publicAreaStatePanel.panelClosed.subscribe(event => {
      if (this.placeType == "P") {
        this.loadRoomStates$ = this.roomStateListService.loadRoomStates(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
          var res = result as RequestResult;
          if (res) {
            this.roomStateList = res.Data.VwRoomStateList as Array<vwRoomState>;
            this.allRoomStateList = [...this.roomStateList];
            this.publicAreaList = res.Data.VwPublicAreaList as Array<vwPublicArea>;

            this.dataSource = new MatTableDataSource(this.roomStateList)
            this.loaded = true;
            this.applyRoomFilter();
            window.scrollTo(0, this.scrollPosition);
          }
        })
      }
      this.roomStatePanelVisible = false;
      this.publicAreaPanelVisible = false;
    })
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  selectRoomState(item: vwRoomState) {
    this.scrollPosition = document.documentElement.scrollTop;
   
    this.selectedRoomState = item;
    this.roomStatePanelVisible = true;
    this.roomStatePanel.pSelectedRoomState = item;
   

  }
  selectPublicArea(item: vwPublicArea) {
    this.scrollPosition = document.documentElement.scrollTop;
   
    this.selectedPublicArea = item;
    this.publicAreaPanelVisible = true;
    this.publicAreaStatePanel.pSelectedPublicArea = item;
    // this. = item;

  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  roomStatePanelClosed(newState: vwRoomState) {
    console.log(newState);
    this.roomStatePanelVisible = false;
  }
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================


  loadRoomStates() {
    this.loadRoomStates$ = this.roomStateListService.loadRoomStates(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        this.roomStateList = res.Data.VwRoomStateList as Array<vwRoomState>;
        this.allRoomStateList = [...this.roomStateList];
        this.publicAreaList = res.Data.VwPublicAreaList as Array<vwPublicArea>;

        this.dataSource = new MatTableDataSource(this.roomStateList)
        this.loaded = true;
        if (this.placeType === "R") {
          let filter: vwRoomStateListFilter = this.formStateService.getVwRoomStateListFilter();

          if (!this.formStateService.RoomStateListFilterInitialised) {
            this.formStateService.resetVwRoomStateFilter();
          }

          if (filter) {
            //this.formStateService.resetVwRoomStateFilter();
            filter = this.formStateService.getVwRoomStateListFilter();
            this.roomFilter.setFilter(filter);
          }
          this.applyRoomFilter();
        }
      }

    })
  }
  filterChanged(item: RoomStateFilterConditions) {
    this.filterConditions = item;
    this.applyRoomFilter();
  }

  applyRoomFilter() {
    let tmpArr: vwRoomState[] = [];
    let isReservationStatusOk: boolean;
    let isRoomEmpty: boolean;
    let isEarlyCheckin: boolean;
    let isCheckin: boolean;
    let isChecked: boolean;
    let isOccupied: boolean;
    let isCheckout: boolean;
    let isLateCheckout: boolean;
    let isCheckedOut: boolean;

    let isCleaningStatusOk: boolean;
    let isRoomDirty: boolean;
    let isRoomClean: boolean;
    let isRoomOk: boolean;
    let isRoomOOO: boolean;
    let isRoomOOS: boolean;
    let isRoomShowRoom: boolean;

    let isRoomFilterOk: boolean;
    let desc: DynamicControlDescriptor = new DynamicControlDescriptor();


    this.allRoomStateList?.forEach(item => {
      isCleaningStatusOk = false;
      isReservationStatusOk = false;
      isRoomFilterOk = false;
      isRoomEmpty = false;
      isEarlyCheckin = false;
      isCheckin = false;
      isChecked = false;
      isOccupied = false;
      isCheckout = false;
      isLateCheckout = false;
      isCheckedOut = false;

      isRoomDirty = false;
      isRoomClean = false;
      isRoomOk = false;
      isRoomOOO = false;
      isRoomOOS = false;
      isRoomShowRoom = false;

      // Room Empty
      if (this.filterConditions.fRoomEmpty) {
        if ( item.NumberOfAdults + item.NumberOfChildren + item.NumberOfActiveBabies + item.NumberOfBabies == 0) {
          isRoomEmpty = true;
        }
      }
      // Early Checkin
      if (this.filterConditions.fEarlyCheckin) {
        if (item.EarlyCheckin && (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR)) {
          isEarlyCheckin = true;
        }

      }
      // Checkin 
      if (this.filterConditions.fCheckin  ) {
        if ( !item.EarlyCheckin && item.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR
        ) {
          isCheckin = true;
        }

      }
      // Checked
      if (this.filterConditions.fChecked) {
        if (item.RoomReservationStateCode == "L" &&
          (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
            || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
            || item.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR
            || item.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR)) {
          isChecked = true;
        }
      }
      // Occupied 
      if (this.filterConditions.fOccupied) {
        if (item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED) {
          isOccupied = true;
        }

      }
      // Checkout 
      if (this.filterConditions.fCheckout) {
        if (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO) {
          isCheckout = true;
        }
      }
      // Late checkout 
      if (this.filterConditions.fLateCheckout) {
        if (item.LateCheckOut && (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO)) {
          isLateCheckout = true;
        }
      }
      // Checked out
      if (this.filterConditions.fCheckedOut) {
        if (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY
          || item.RoomStateTransitionCode == RoomStateTransition.DAY_USE
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR
          || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO
          && (item.RoomReservationStateCode == 'E' || item.RoomReservationStateCode == 'H')) {
          isCheckedOut = true;
        }
      }

      if (isRoomEmpty || isEarlyCheckin || isCheckin || isChecked
        || isOccupied || isCheckout || isLateCheckout || isCheckedOut) {

        isReservationStatusOk = true;
      }

      if (this.filterConditions.fRoomDirty) {
        if (item.RoomStateCode == RoomState.EMPTY_DIRTY || item.RoomStateCode == RoomState.RESERVED_DIRTY) {
          isRoomDirty = true;
        }
      }
      if (this.filterConditions.fRoomClean) {
        if (item.RoomStateCode == RoomState.RESERVED_CLEAN || item.RoomStateCode == RoomState.EMPTY_CLEAN) {
          isRoomClean = true;
        }
      }
      if (this.filterConditions.fRoomOk) {
        if (item.RoomStateCode == RoomState.RESERVED_OK || item.RoomStateCode == RoomState.EMPTY_AVAILABLE) {
          isRoomOk = true;

        }
      }
      if (this.filterConditions.fRoomOOO) {
        if (item.RoomStateCode == RoomState.OUT_OF_ORDER) {
          isRoomOOO = true;
        }
      }
      if (this.filterConditions.fRoomOOS) {
        if (item.RoomStateCode == RoomState.OUT_OF_SERVICE) {
          isRoomOOS = true;
        }
      }
      if (this.filterConditions.fRoomShowRoom) {
        if (item.RoomStateCode == RoomState.SHOW_ROOM) {
          isRoomShowRoom = true;
        }
      }

      if (isRoomClean || isRoomDirty || isRoomOk || isRoomOOO || isRoomOOS || isRoomShowRoom) {
        isCleaningStatusOk = true;
      }

      if (isReservationStatusOk && isCleaningStatusOk) {
        if (item.RoomNumber.toLowerCase().startsWith(this.filterConditions.fRoomNumber.toLowerCase())) {
          isRoomFilterOk = true;
        }
        if (isRoomFilterOk && item.Floor.toString().toLowerCase().startsWith(this.filterConditions.fFloor.toString().toLowerCase())) {
          isRoomFilterOk = true;
        }
        else {
          isRoomFilterOk = false;
        }
        if (isRoomFilterOk && (item.RoomTypeName == this.filterConditions.fRoomType || (this.filterConditions.fRoomType == "-1"))) {
          isRoomFilterOk == true;
        }
        else {
          isRoomFilterOk = false;
        }
      }
      if (isRoomFilterOk) {
        tmpArr.push(item);
      }

    });

    this.roomStateList = [...tmpArr];
    this.dataSource = new MatTableDataSource(this.roomStateList);

    // ######## 
    // if (this.caller) {
    //   if (this.caller === "addTask") {
    //     this.selectedRoomState = this.roomStateList.find(item => item.RoomNumber == this.formStateService.getSelectedRoomNumber());
    //     if (this.selectedRoomState) {
    //       this.roomClick(this.selectedRoomState);
    //     }

    //   }
    // }

    // this.selectedRoomState = this.roomStateList.find(item=> item.RoomNumber == "325");
    // this.roomClick(this.selectedRoomState);
  }

  /* grid cella stílusok */
  roomStateColorStyle(rowData: vwRoomState) {
    let styles = {};
    if (rowData.RoomStateCode == RoomState.EMPTY_AVAILABLE || rowData.RoomStateCode == RoomState.RESERVED_OK) {
      styles["background-color"] = "#81c784";
    }
    if (rowData.RoomStateCode == RoomState.EMPTY_CLEAN || rowData.RoomStateCode == RoomState.RESERVED_CLEAN) {
      styles["background-color"] = "#03a9f4";
    }
    if (rowData.RoomStateCode == RoomState.EMPTY_DIRTY || rowData.RoomStateCode == RoomState.RESERVED_DIRTY) {
      styles["background-color"] = "#f35870";
    }

    if (rowData.RoomStateCode == RoomState.OUT_OF_SERVICE) {
      styles["background-color"] = "#ce93d8";
    }

    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER) {
      styles["background-color"] = "#424242";
    }

    if (rowData.RoomStateCode == RoomState.SHOW_ROOM) {
      styles["background-color"] = "#ffca28";
    }
    styles["height"] = "auto";
    return styles;
  }
  roomStateIcon(rowData: vwRoomState) {

    if (rowData.RoomStateCode == RoomState.EMPTY_AVAILABLE) { // Ellenőrzött tiszta  
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true,
        'check-all-icon-color': true,
      }
    }

    if (rowData.RoomStateCode == RoomState.EMPTY_AVAILABLE || rowData.RoomStateCode == RoomState.RESERVED_OK) {
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true,
        'checked-icon-color': true,
      };
    }

    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER) { // OOO  
      return {
        'mdi mdi-cog': true,
        'room-state-icon': true,
        'settings-icon-color': true,
      };
    }

    if (rowData.RoomStateCode == RoomState.OUT_OF_SERVICE) { // OOS  
      return {
        'mdi mdi-alert': true,
        'room-state-icon': true,
        'alert-icon-color': true,
      };
    }

    if (rowData.RoomStateCode == RoomState.EMPTY_CLEAN) { // Üres tiszta  
      return {
        'mdi mdi-star': true,
        'room-state-icon': true,
        'star-icon-color': true,
      }
    }

    if (rowData.RoomStateCode == RoomState.SHOW_ROOM) { // Üres tiszta  
      return {
        'mdi mdi-eye': true,
        'room-state-icon': true,
        'show-room-icon-color': true,
      }
    }

    if (rowData.RoomStateCode == RoomState.EMPTY_CLEAN || rowData.RoomStateCode == RoomState.RESERVED_CLEAN) {
      return {
        'mdi mdi-star': true,
        'room-state-icon': true,
        'clean-icon-color': true,
      };
    }
    if (rowData.RoomStateCode == RoomState.EMPTY_DIRTY || rowData.RoomStateCode == RoomState.RESERVED_DIRTY) { // piszkos
      return {
        'mdi mdi-delete': true,
        'room-state-icon': true,
        'dirty-icon-color': true,
      };
    }
    return {
      'mdi mdi-delete': true,
      'room-state-icon': true,
      'dirty-icon-color': true,
    };
  }

  assignedToName(rowData: vwRoomState): string {
    if (rowData.CleaningTypes.length > 0) {
      if (rowData.CleaningTypes[0].AssignedToRealName == null) {
        return this.translate.instant("common.unclassified");
      }
      else {
        return rowData.CleaningTypes[0].AssignedToRealName;
      }
    }
    else {
      return this.translate.instant("common.unclassified");
    }
  }

  familyMembers(rowData: vwRoomState) {
    let strFamily = HwUtils.zeroIfNull(rowData.NumberOfAdults).toString() + '-' +
      HwUtils.zeroIfNull(rowData.NumberOfTeens).toString() + '-' +
      HwUtils.zeroIfNull(rowData.NumberOfChildren).toString() + '-' +
      HwUtils.zeroIfNull(rowData.NumberOfBabies + rowData.NumberOfActiveBabies).toString()
    return strFamily;

  }
  familyMembersNumber(rowData: vwRoomState): number {
    return rowData.NumberOfAdults + rowData.NumberOfTeens + rowData.NumberOfChildren
      + rowData.NumberOfBabies + rowData.NumberOfActiveBabies;
  }
  departureDate(data: string): string {
    return data === '0001.01.01' ? '' : data;
  }

  numberOfMaintenanceTasksStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    if (rowData.Maintenances.length == 0) {
      styles["color"] = 'rgb(166,171,175)';
    } else {
      styles["color"] = 'rgb(63,157,239)';
    }
    return styles;
  }
  numberOfMaintenanceTasks(rowData: vwRoomState) {
    return rowData.Maintenances.length;
  }
  numberOfCleaningTasksStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    if (this.numberOfCleaningTasks(rowData) == 0) {
      styles["color"] = 'rgb(166,171,175)';
    } else {
      styles["color"] = 'rgb(63,157,239)';
    }
    return styles;
  }
  numberOfCleaningTasks(rowData: vwRoomState) {
    return rowData.CleaningTypes.length;
  }
  numberOfCleaningTypes(rowData: vwRoomState) {
    if (rowData.CleaningTypes) {
      return rowData.CleaningTypes.length;
    }
  }

  headerStyle() {
    let styles = {};
    styles["height"]= "200px";
    if((!this.roomStatePanelVisible) && (!this.publicAreaPanelVisible)) {
      styles["background-color"]="#03a9f4";
    } else {
        if(this.roomStatePanelVisible) {
          if( this.selectedRoomState.RoomStateCode ==  3 || this.selectedRoomState.RoomStateCode == 6) {
            styles["background-color"]="#e57373";
          }
          if( this.selectedRoomState.RoomStateCode ==  2 || this.selectedRoomState.RoomStateCode == 5) {
            styles["background-color"]="#B7D8FB";
          }
          if( this.selectedRoomState.RoomStateCode ==  7) {
            styles["background-color"]="#424242";
          }
          if( this.selectedRoomState.RoomStateCode ==  8) {
            styles["background-color"]="#eba9e5";
          }
          if( this.selectedRoomState.RoomStateCode ==  9) {
            styles["background-color"]="#ffca28";
          }
          if( this.selectedRoomState.RoomStateCode ==  1 || this.selectedRoomState.RoomStateCode == 4) {
            styles["background-color"]="#5DDA86";
          }
        }
        if( this.publicAreaPanelVisible) {
           switch(this.publicAreaState()) {
              //  dirty
              case 0: {   
                  styles["background-color"]= "#e57373"
                break;
              } 
              // clean 
              case 1: {
                styles["background-color"]= "#03a9f4"
                break;
              }  
              //checked
              case 2: {
                styles["background-color"]= "#5DDA86"
                break;
              } 

           }
         
        }
    }
    return styles;
  }
  leftIconColorStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    if (rowData.RoomStateTransitionCode == null) {
      if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER) {
        styles["background-color"] = "#000000";
        styles["color"] = "#fdfdfd";
      }
      if (rowData.RoomStateCode == RoomState.RESERVED_CLEAN ||
        rowData.RoomStateCode == RoomState.RESERVED_DIRTY ||
        rowData.RoomStateCode == RoomState.RESERVED_OK
      ) {
        styles["background-color"] = "#ffffff00";
        styles["color"] = "#85b0ec";
      }
      if (rowData.RoomStateCode == RoomState.EMPTY_CLEAN ||
        rowData.RoomStateCode == RoomState.EMPTY_AVAILABLE ||
        rowData.RoomStateCode == RoomState.EMPTY_DIRTY
      ) {

        styles["background-color"] = "#FFFFFF00";
        styles["color"] = "#ada8a8";
      }
    }
    else {
      if (rowData.RoomStateTransitionCode == RoomStateTransition.DAY_USE) {
        styles["background-color"] = "#FFFFFF00";
        styles["color"] = "#f35870";


      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY ||
        rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO    // dátum jön majd  

      ) {
        if (rowData.LateCheckOut) {
          styles["background-color"] = "#FFFFFF00";
          styles["color"] = "#f35870";
        }
        else {
          styles["background-color"] = "#f35870";
          styles["color"] = "#FFFFFF";
        }

      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_EMPTY ||
        rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_OOO
      ) {
        styles["background-color"] = "#000000"
        styles["color"] = "#fdfdfd";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_OOO
      ) {
        styles["background-color"] = "#FFFFFF00";
        styles["color"] = "#ada8a8";

        //styles["background-color"] = "#ada8a8" 
        //styles["color"] = "#000000";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED ||
        rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED) {
        styles["background-color"] = "#ffffff00";
        styles["color"] = "#85b0ec";
      }

    }

    return styles;


  }

  leftIconClass(rowData: vwRoomState) {
    if (rowData.RoomStateTransitionCode == null) {
      if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER) {
        return "mdi mdi-cog-outline icon-size";
      }
      if (rowData.RoomStateCode == RoomState.EMPTY_CLEAN ||
        rowData.RoomStateCode == RoomState.EMPTY_AVAILABLE ||
        rowData.RoomStateCode == RoomState.EMPTY_DIRTY
      ) {
        return "mdi mdi-border-all-variant icon-size";
      }
    }
    else {
      if (rowData.RoomStateTransitionCode == RoomStateTransition.DAY_USE) {
        return "mdi mdi-alert-octagon icon-size";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY ||
        rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO    // dátum jön majd  

      ) {
        if (rowData.LateCheckOut) {
          return "mdi mdi-alpha-l-box icon-size";
        }
        else {
          return "mdi mdi-arrow-right-bold icon-size";
        }
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_EMPTY ||
        rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_OOO
      ) {
        return "mdi mdi-cog-outline icon-size";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR ||
        rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_OOO
      ) {
        return "mdi mdi-border-all-variant icon-size";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED) {
        return "mdi mdi-delete-variant icon-size";
      }
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED) {
        return "mdi mdi-bed icon-size";
      }
    }
  }

  leftTextStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    styles["padding-left"] = "5px";
    styles["font-weight"] = "bold";
    styles["color"] = "#f35770";
    return styles;
  }

  leftTextValue(rowData: vwRoomState) {

    if( rowData.CheckedIn && rowData.RoomStateTransitionCode === RoomStateTransition.DAY_USE) {
      return "CI"
    }


    if (rowData.CheckedOut) {
      if( rowData.RoomStateTransitionCode !== RoomStateTransition.DAY_USE) {
        return "CO";
      } else {
        return "CO";  // ez a CI CO lesz majd egyszer
      } 
    } 

    
    
    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER && rowData.RoomStateTransitionCode === null) {
      return rowData.OutOfOrderEndDate;
    }

  }

  rightIconColorStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    switch (rowData.RoomStateTransitionCode) {
      case RoomStateTransition.OCCUPIED:
        styles["background-color"] = "#ffffff00";
        styles["color"] = "#85b0ec";
        break;
      case RoomStateTransition.OCCUPIED_BED:
        styles["background-color"] = "#ffffff00";
        styles["color"] = "#85b0ec";
        break;
      case RoomStateTransition.DEP_TO_ARR:
        styles["background-color"] = "#779a34";
        styles["color"] = "#fdfdfd";
        styles["margin-right"] = "0.2em";
        break;
      case RoomStateTransition.EMPTY_TO_ARR:
        styles["background-color"] = "#779a34";
        styles["color"] = "#fdfdfd";
        styles["margin-right"] = "0.2em";
        break;
      case RoomStateTransition.EMPTY_TO_OOO:
        styles["background-color"] = "#000000";
        styles["color"] = "#fdfdfd";
        break;
      case RoomStateTransition.DEP_TO_OOO:
        styles["background-color"] = "#000000"
        styles["color"] = "#fdfdfd";
        break;
      case RoomStateTransition.OOO_TO_OOO:
        styles["background-color"] = "#000000"
        styles["color"] = "#fdfdfd";
        break;
      case RoomStateTransition.DEP_TO_EMPTY:
        styles["background-color"] = "#FFFFFF00";
        styles["color"] = "#ada8a8";
        break;
      case RoomStateTransition.OOO_TO_EMPTY:
        styles["background-color"] = "#FFFFFF00";
        styles["color"] = "#ada8a8";
        break;
      case RoomStateTransition.OOO_TO_ARR:
        styles["background-color"] = "#779a34";
        styles["color"] = "#fdfdfd";
        styles["margin-right"] = "0.2em";
        break;
      case RoomStateTransition.DAY_USE:
        styles["background-color"] = "#779a34";
        styles["color"] = "#fdfdfd";
        styles["margin-right"] = "0.2em";
        break;

    }

    return styles;

    //--------

  }
  rightIconClass(rowData: vwRoomState) {
    if (rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY ||
      rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_EMPTY) {
      return "mdi mdi-border-all-variant icon-size"
    }
    if (rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR ||
      rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR ||
      rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR
    ) {
      if (rowData.EarlyCheckin) {
        return "mdi mdi-alpha-e-box-outline icon-size";
      } else {
        return "mdi mdi-arrow-left-bold icon-size";

      }
    }
    if (rowData.RoomStateTransitionCode == RoomStateTransition.DAY_USE) {
      return ""; //return "mdi mdi-arrow-left-bold icon-size"; 
    }

    if (rowData.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO ||
      rowData.RoomStateTransitionCode == RoomStateTransition.OOO_TO_OOO ||
      rowData.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_OOO
    ) {
      return "mdi mdi-cog-outline icon-size";
    }
  }
  rightTextStyle(rowData: vwRoomState) {
    let styles = {};
    styles["font-size"] = "18px";
    styles["padding-left"] = "5px";
    styles["font-weight"] = "bold";
    styles["color"] = "#779a34";
    return styles;

  }
  rightTextValue(rowData: vwRoomState) {
    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER && (!(rowData.RoomStateTransitionCode === RoomStateTransition.OOO_TO_OOO ||
      rowData.RoomStateTransitionCode === RoomStateTransition.OOO_TO_EMPTY))) {
      return rowData.OutOfOrderEndDate;
    }
    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER && rowData.RoomStateTransitionCode == null) {
      return rowData.OutOfOrderEndDate;
    }

    if (rowData.RoomStateCode == RoomState.OUT_OF_ORDER && rowData.RoomStateTransitionCode === RoomStateTransition.OOO_TO_OOO) {
      return rowData.OutOfOrderEndDate;
    }
    if (rowData.CheckedIn) {
      if (rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED || rowData.RoomStateTransitionCode == RoomStateTransition.OCCUPIED) {
        return ""
      } else {
        return "CI";
      }

    }

  }

  publicAreaState() {

    let foundTime: boolean = false;
    let foundCleaningType: vwCleaningType;
    let foundIndex: number = 0;
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    if (this.selectedPublicArea.CleaningTypes.length == 1) {
      foundCleaningType = this.selectedPublicArea.CleaningTypes[0];
    }
    else {
      for (var i = 0; i < this.selectedPublicArea.CleaningTypes.length; i++) {
        if ((HwUtils.HoursAndMinutesToMinutes(this.selectedPublicArea.CleaningTypes[i].TypeTimeStr) > nowMinutes) && !foundTime) {
          foundTime = true;
          if (i > 0) {
            foundIndex = i - 1;
          } else {
            foundIndex = i;
          }

          foundCleaningType = this.selectedPublicArea.CleaningTypes[foundIndex];
        }
      }

    }
    if (foundCleaningType) {
      // if( foundIndex == this.pSelectedPublicArea.CleaningTypes.length-1 ) {  // utolsó a sorban, nincs több
      switch (foundCleaningType.Tasks[0].TaskState) {
        case TaskStates.Created:
          return PublicAreaStates.DIRTY;
        case TaskStates.Ended:
          return PublicAreaStates.CLEAN;
        case TaskStates.Accepted:
          return PublicAreaStates.ACCEPTED;
        case TaskStates.Deleted:
          return PublicAreaStates.CLEAN;
        default:
          return PublicAreaStates.DIRTY;


        // } else {  // vannak további időpontok
        //   if(foundCleaningType?.Tasks[0].TaskState == 'Created') {
        //     return PublicAreaStates.DIRTY;
        //   }  else      {
        //     return PublicAreaStates.CLEAN;
        //   }

      }

    }
    else {
      if (this.selectedPublicArea.CleaningTypes.length > 0) {
        if (this.selectedPublicArea.CleaningTypes[this.selectedPublicArea.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Deleted ||
          this.selectedPublicArea.CleaningTypes[this.selectedPublicArea.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Accepted) {
          return PublicAreaStates.CLEAN;
        } else {
          return PublicAreaStates.DIRTY;
        }
      } else {
        return PublicAreaStates.CLEAN;

      }

    }
  }
  exportToPdf() {
    this.spinnerService.setSpinnerVisibility(true);
    let fileName: string = ""


    let filter: vwRoomStateListFilter = new vwRoomStateListFilter();
    if (this.placeType == "R") {
      filter = this.buildFilterObj();
      fileName = this.translate.instant("menu.room_state_list") + "-" + this.froDatum + ".pdf";
    }
    else {
      filter.ItemType = "P"
      fileName = this.translate.instant("menu.public_areas") + "-" + this.froDatum + ".pdf";
    }

    this.roomService.getRoomStateListPdf(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      this.spinnerService.setSpinnerVisibility(false);
      saveAs(b, fileName),
        error => {
          console.log(error);
          this.spinnerService.setSpinnerVisibility(false);
        };

    })
  }
  exportToXls() {
    this.spinnerService.setSpinnerVisibility(true);
    let fileName: string

    let filter: vwRoomStateListFilter = new vwRoomStateListFilter();
    if (this.placeType == "R") {
      filter = this.buildFilterObj();
      fileName = this.translate.instant("menu.room_state_list") + "-" + this.froDatum + ".xlsx";
    }
    else {
      filter.ItemType = "P"
      fileName = this.translate.instant("menu.public_areas") + "-" + this.froDatum + ".xlsx";
    }
    this.roomService.getRoomStateListExcel(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/Excel' });
      this.spinnerService.setSpinnerVisibility(false);
      saveAs(b, fileName),
        error => {
          console.log(error);
          this.spinnerService.setSpinnerVisibility(false);
        };
    })
  }

  buildFilterObj(): vwRoomStateListFilter {
    let filter = this.roomFilter.getFilter();
    filter.ItemType = this.placeType;
    return filter;
  }

  //#endregion =================================================================================================================

}
