import { Component, Input, OnInit } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TaskHistoryDialogComponent } from '@app/kip/dialogs/task-history-dialog/task-history-dialog.component';
import { CleaningSchedule } from '@app/kip/dto/CleaningSchedule';
import { KipUser } from '@app/kip/dto/KipUser';
import { SchedulerService } from '@app/kip/services/scheduler.service';
import { vwMaintenance } from '@app/kip/viewdto/vwdailytask/vwMaintenance';
import { vwMaintenanceType } from '@app/kip/viewdto/vwdailytask/vwMaintenanceType';
import { vwMaintenanceSchedule } from '@app/kip/viewdto/vwMaintenanceSchedule';
import { RoomState } from '@app/shared/enums/roomstates';
import { RoomStateTransition } from '@app/shared/enums/roomstatetransitions';
import { AlertType } from '@globalshared/hw-alert-component/src/lib';
import { MatDialog } from '@angular/material/dialog';
import { TaskAssignDialogComponent } from '@app/kip/dialogs/task-assign-dialog/task-assign-dialog.component';
import { ShortTaskItem } from '@app/kip/classes/ShortTaskItem';
import { TaskAssignSupervisorDialogComponent } from '@app/kip/dialogs/task-assign-supervisor-dialog/task-assign-supervisor-dialog.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'task-item-card',
  templateUrl: './task-item-card.component.html',
  styleUrls: ['./task-item-card.component.scss']
})
export class TaskItemCardComponent implements OnInit {
  @Input() cleaningItem: CleaningSchedule | null;
  @Input() maintenanceItem: vwMaintenanceSchedule | null;
  @Input() supervisors: KipUser[] = [];
  @Input() allUsers: KipUser[]=[];
  @Input() selected: boolean = false;

  opened: boolean;
  isOpen: boolean = false;  
  constructor(
    public sessionStorageService: SessionStorageService,
    private schedulerService: SchedulerService,
    private taskAssignDialog: MatDialog,
    private translate : TranslateService
  ) { }

  ngOnInit() {
   
  }

  cardClicked() {
    this.selected = !this.selected;

  }

  openAssignDialog() {
    const dialogParams : ShortTaskItem = new ShortTaskItem();
    dialogParams.Users = this.allUsers;
    dialogParams.Type = this.cleaningItem ? "C": "M";
    dialogParams.Id = this.cleaningItem ? this.cleaningItem.CleaningScheduleId: this.maintenanceItem.MaintenanceScheduleId;
    dialogParams.AssignedTo =  this.cleaningItem ? this.cleaningItem.AssignedTo: this.maintenanceItem.AssignedTo;
    dialogParams.Supervisor = this.cleaningItem ? this.cleaningItem.SuperVisorId: this.maintenanceItem.SuperVisorId;
    const taskAssignDialogDef = this.taskAssignDialog.open(TaskAssignDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "30vw",
      panelClass: "add-accountable-user-dialog",
      data: dialogParams
    })
  
  }

  openAssignSupervisorDialog() {
    const dialogParams : ShortTaskItem = new ShortTaskItem();
    dialogParams.Users = this.supervisors;
    dialogParams.Type = this.cleaningItem ? "C": "M";
    dialogParams.Id = this.cleaningItem ? this.cleaningItem.CleaningScheduleId: this.maintenanceItem.MaintenanceScheduleId;
    dialogParams.Supervisor = this.cleaningItem ? this.cleaningItem.SuperVisorId: this.maintenanceItem.SuperVisorId;
    dialogParams.AssignedTo =  this.cleaningItem ? this.cleaningItem.AssignedTo: this.maintenanceItem.AssignedTo;
    const taskAssignDialogDef = this.taskAssignDialog.open(TaskAssignSupervisorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "30vw",
      panelClass: "add-accountable-user-dialog",
      data: dialogParams
    })
  }
  /* style functions */

  isSelected() {
    let styles = {};
    if (this.selected) {
      styles["border"] = "solid 1px green";

    } else {
      styles["border"] = "solid 1px #ccc";
    }
    return styles;
  }
  roomStateColorStyle() {

    let styles = {};
    styles["font-size"] = "20px";

    if (this.cleaningItem) {
      if (this.cleaningItem.RooomState == RoomState.EMPTY_AVAILABLE || this.cleaningItem.RooomState == RoomState.RESERVED_OK) {
        styles["color"] = "#81c784";
      }
      if (this.cleaningItem.RooomState == RoomState.EMPTY_CLEAN || this.cleaningItem.RooomState == RoomState.RESERVED_CLEAN) {
        styles["color"] = "#03a9f4";
      }
      if (this.cleaningItem.RooomState == RoomState.EMPTY_DIRTY || this.cleaningItem.RooomState == RoomState.RESERVED_DIRTY) {
        styles["color"] = "#f35870";
      }

      if (this.cleaningItem.RooomState == RoomState.OUT_OF_SERVICE || this.cleaningItem.RooomState == RoomState.OUT_OF_SERVICE) {
        styles["color"] = "#ce93d8";
      }

      if (this.cleaningItem.RooomState == RoomState.OUT_OF_ORDER || this.cleaningItem.RooomState == RoomState.OUT_OF_ORDER) {
        styles["color"] = "#424242";
      }

      if (this.cleaningItem.RooomState == RoomState.SHOW_ROOM || this.cleaningItem.RooomState == RoomState.SHOW_ROOM) {
        styles["color"] = "#ffca28";
      }
    } else {
      if (this.maintenanceItem.RooomState == RoomState.EMPTY_AVAILABLE || this.maintenanceItem.RooomState == RoomState.RESERVED_OK) {
        styles["color"] = "#81c784";
      }
      if (this.maintenanceItem.RooomState == RoomState.EMPTY_CLEAN || this.maintenanceItem.RooomState == RoomState.RESERVED_CLEAN) {
        styles["color"] = "#03a9f4";
      }
      if (this.maintenanceItem.RooomState == RoomState.EMPTY_DIRTY || this.maintenanceItem.RooomState == RoomState.RESERVED_DIRTY) {
        styles["color"] = "#f35870";
      }

      if (this.maintenanceItem.RooomState == RoomState.OUT_OF_SERVICE || this.maintenanceItem.RooomState == RoomState.OUT_OF_SERVICE) {
        styles["color"] = "#ce93d8";
      }

      if (this.maintenanceItem.RooomState == RoomState.OUT_OF_ORDER || this.maintenanceItem.RooomState == RoomState.OUT_OF_ORDER) {
        styles["color"] = "#424242";
      }

      if (this.maintenanceItem.RooomState == RoomState.SHOW_ROOM || this.maintenanceItem.RooomState == RoomState.SHOW_ROOM) {
        styles["color"] = "#ffca28";
      }
    }



    return styles;
  }
  roomStateIcon() {

    if (this.cleaningItem.RooomState == RoomState.EMPTY_AVAILABLE) { // Ellenőrzött tiszta  
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true
      }
    }

    if (this.cleaningItem.RooomState == RoomState.EMPTY_AVAILABLE || this.cleaningItem.RooomState == RoomState.RESERVED_OK) {
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true
      };
    }

    if (this.cleaningItem.RooomState == RoomState.OUT_OF_ORDER) { // OOO  
      return {
        'mdi mdi-cog': true,
        'room-state-icon': true
      };
    }

    if (this.cleaningItem.RooomState == RoomState.OUT_OF_SERVICE) { // OOS  
      return {
        'mdi mdi-alert': true,
        'room-state-icon': true
      };
    }

    if (this.cleaningItem.RooomState == RoomState.EMPTY_CLEAN) { // Üres tiszta  
      return {
        'mdi mdi-star': true,
        'room-state-icon': true
      }
    }

    if (this.cleaningItem.RooomState == RoomState.SHOW_ROOM) { // Üres tiszta  
      return {
        'mdi mdi-eye': true,
        'room-state-icon': true
      }
    }

    if (this.cleaningItem.RooomState == RoomState.EMPTY_CLEAN || this.cleaningItem.RooomState == RoomState.RESERVED_CLEAN) {
      return {
        'mdi mdi-star': true,
        'room-state-icon': true
      };
    }
    if (this.cleaningItem.RooomState == RoomState.EMPTY_DIRTY || this.cleaningItem.RooomState == RoomState.RESERVED_DIRTY) { // piszkos
      return {
        'mdi mdi-delete': true,
        'room-state-icon': true
      };
    }
    // return {'mdi mdi-delete': true,
    //     'room-state-icon' : true 
    //   };
  }
  roomStateTransitionClassFrom() {
    switch (this.cleaningItem.RoomStateTransitionCode) {
      case RoomStateTransition.OCCUPIED_BED:
        return {
          'mdi mdi-bed': true,
          'occupied-from': true
        }
      case RoomStateTransition.DEP_TO_ARR:
        {
          return {
            'mdi mdi-arrow-right-bold': true,
            'dep-to-arr-from': true
          }
        }
      case RoomStateTransition.EMPTY_TO_ARR:
        {
          return {
            'mdi mdi-checkbox-blank-outline': true,
            'empty-to-arr-from': true
          }
        }
      case RoomStateTransition.DEP_TO_EMPTY:
        {
          return {
            'mdi mdi-arrow-right-bold': true,
            'dep-to-empty-from': true
          }
        }
      case RoomStateTransition.OCCUPIED:
        {
          return {
            'mdi mdi-bed': true,
            'occupied-from': true
          }
        }
      case RoomStateTransition.DEP_TO_OOO:
        {
          return {
            'mdi mdi-arrow-right-bold': true,
            'dep-to-ooo-from': true
          }
        }
      case RoomStateTransition.OOO_TO_ARR:
        {
          return {
            'mdi mdi-cog': true,
            'ooo-to-arr-from': true
          }
        }
      case RoomStateTransition.OOO_TO_EMPTY:
        {
          return {
            'mdi mdi-cog': true,
            'ooo-to-empty-from': true
          }
        }

    }

  }

  roomStateTransitionClassTo() {
    switch (this.cleaningItem.RoomStateTransitionCode) {
      case RoomStateTransition.DEP_TO_ARR:
        {
          return {
            'mdi mdi-arrow-left-bold': true,
            'dep-to-arr-to': true
          }
        }
      case RoomStateTransition.EMPTY_TO_ARR:
        {
          return {
            'mdi mdi-arrow-left-bold': true,
            'empty-to-arr-to': true
          }
        }
      case RoomStateTransition.DEP_TO_EMPTY:
        {
          return {
            'mdi mdi-checkbox-blank-outline': true,
            'dep-to-empty-to': true
          }
        }
      case RoomStateTransition.OCCUPIED:
        {
          return {
            'mdi': true,
            'occupied-to': true
          }
        }
      case RoomStateTransition.OCCUPIED_BED:
        {
          return {
            'mdi': true,
            'occupied-to': true
          }
        }
      case RoomStateTransition.DEP_TO_OOO:
        {
          return {
            'mdi mdi-cog': true,
            'dep-to-ooo-to': true
          }
        }
      case RoomStateTransition.OOO_TO_ARR:
        {
          return {
            'mdi mdi-arrow-left-bold': true,
            'ooo-to-arr-to': true
          }
        }
      case RoomStateTransition.OOO_TO_OOO:
        {
          return {
            'mdi mdi-cog': true,
            'ooo-to-ooo-to': true
          }
        }
      case RoomStateTransition.OOO_TO_EMPTY:
        {
          return {
            'mdi mdi-checkbox-blank-outline': true,
            'ooo-to-empty-to': true
          }
        }

    }

  }

  areaNumberStyle() {
    let styles = {};
    styles["font-size"] = "20px";
    if (this.cleaningItem.RooomState == RoomState.EMPTY_AVAILABLE || this.cleaningItem.RooomState == RoomState.RESERVED_OK) {
      styles["color"] = "#81c784";
    }
    if (this.cleaningItem.RooomState == RoomState.EMPTY_CLEAN || this.cleaningItem.RooomState == RoomState.RESERVED_CLEAN) {
      styles["color"] = "#03a9f4";
    }
    if (this.cleaningItem.RooomState == RoomState.EMPTY_DIRTY || this.cleaningItem.RooomState == RoomState.RESERVED_DIRTY) {
      styles["color"] = "#f35870";
    }

    if (this.cleaningItem.RooomState == RoomState.OUT_OF_SERVICE || this.cleaningItem.RooomState == RoomState.OUT_OF_SERVICE) {
      styles["color"] = "#ce93d8";
    }

    if (this.cleaningItem.RooomState == RoomState.OUT_OF_ORDER || this.cleaningItem.RooomState == RoomState.OUT_OF_ORDER) {
      styles["color"] = "#424242";
    }

    if (this.cleaningItem.RooomState == RoomState.SHOW_ROOM || this.cleaningItem.RooomState == RoomState.SHOW_ROOM) {
      styles["color"] = "#ffca28";
    }

    return styles;
  }
  commentChunk(item: string): string {
    let ret = item;
    if (ret?.length > 55) {
      ret = ret.substring(0, 55) + '...';
    }
    return ret;
  }

  supervisorName() {
    if (this.cleaningItem) {
      if (this.cleaningItem.SuperVisorId) {
        return this.supervisors.find(su => su.UserId == this.cleaningItem.SuperVisorId)?.RealName;
      }
    } else {
      if (this.maintenanceItem.SuperVisorId) {
        return this.supervisors.find(su => su.UserId == this.maintenanceItem.SuperVisorId).RealName;
      }
    }
  }

  responsibleName() {
    if (this.cleaningItem) {
      if (this.cleaningItem.AssignedTo) {
        return this.allUsers.find(su => su.UserId == this.cleaningItem.AssignedTo)?.RealName;
      }
    } else {
      if (this.maintenanceItem.AssignedTo) {
        return this.allUsers.find(su => su.UserId == this.maintenanceItem.AssignedTo).RealName;
      }
    }
    
 
  }

  responsibleToolTip() {
      return this.translate.instant("common.assigned_to") + " : " + this.responsibleName() 
  }
  supervisorToolTip() {
    return this.translate.instant("common.supervisor") + " : " + this.supervisorName() 
  }
  hello() {
   console.log(JSON.stringify(this.maintenanceItem))
  }
}


