
// Angular
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { RequestResult } from '@app/core/dto/RequestResult';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';

import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { MaintenanceTask } from '@app/kip/dto/MaintenanceTask';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { MaintenanceTaskService } from '@app/kip/services/maintenance-task.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { vwMaintenanceTask } from '@app/kip/viewdto/vwdailytask/vwMaintenanceTask';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';


@Component({
  selector: 'app-maintenance-type-editor-dialog',
  templateUrl: './maintenance-type-editor-dialog.component.html',
  styleUrls: ['./maintenance-type-editor-dialog.component.scss']
})
export class MaintenanceTypeEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('tasks') tasks: MatSelectionList;
  @ViewChild('localizer') localizer: DataLocalizerComponent;
  editorForm: FormGroup;
  _data: MaintenanceType;
  maintenanceGroups: MaintenanceGroup[] = [];
  maintenanceTasks: MaintenanceTask[] = [];
  loadMaintenanceGroups$: Subscription;
  taskListVisible: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<MaintenanceTypeEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: MaintenanceType,
    private maintenanceTypeService: MaintenanceTypeService,
    private maintenanceGroupService: MaintenanceGroupService,
    private fb: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private rfHelperService: ReactiveFormsHelperService,
    private maintenanceTaskService: MaintenanceTaskService,
    private translate: TranslateService) {
    this.editorForm = fb.group({
      'Active': new FormControl(false),
      'TypeCode': new FormControl('', [Validators.required, , Validators.maxLength(10)]),
      'TypeName': new FormControl('', [Validators.required, , Validators.maxLength(40)]),
      'MaintenanceGroupId': new FormControl(0)

    });
    this._data = data;
  }      

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.loadAll();
  }

  ngOnDestroy(): void {
    this.loadMaintenanceGroups$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }


  //#endregion =================================================================================================================
  
  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================
    
  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAll() {
    const combinedSources= combineLatest({
      maintenanceGroups: this.maintenanceGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      maintenanceTasks: this.maintenanceTaskService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    }).pipe(takeUntil(this.destroy$)).subscribe(
      value => {
        this.maintenanceTasks = (value.maintenanceTasks as RequestResult).Data as Array<MaintenanceTask>;
        this.maintenanceGroups = (value.maintenanceGroups as RequestResult).Data as Array<MaintenanceGroup>;
        this.rfHelperService.fillFormFromRecord(this._data,this.editorForm);
        this.editorForm.controls["MaintenanceGroupId"].setValue(this._data.MaintenanceGroupId);
        if(this._data.MaintenanceGroupId == null) {
          this.editorForm.controls["MaintenanceGroupId"].setValue(this.maintenanceGroups[0].GroupId);
        }
    })
  }

  /* dialógus kezelőgombok */
  cancel() {
    this.dialogRef.close(undefined);
  }

  submit() {
    // ellenőrzés !!
    // get  selected
    this._data.MaintenanceGroupId = this.editorForm.controls["MaintenanceGroupId"].value;
    this._data.Active = this.editorForm.controls["Active"].value;
    this._data.SData = this.localizer.jsonData;
    this._data.TypeCode =this.editorForm.controls["TypeCode"].value;
    this._data.TypeName = this.editorForm.controls["TypeName"].value;
    this.dialogRef.close(this._data);
  }

  /* ellenörző lista műveleinek gombjai */ 
  deleteTasks() {
    // kiválasztott elemek
    const sel =  this.tasks.selectedOptions.selected;
    sel.forEach(item=> {
      this._data.Tasks.splice(this._data.Tasks.findIndex( task=> task.TaskId === parseInt(item.value)),1);
    })

  }  


  /* Tasklista választó gombjai */
  addToSelected() {
    const sel =  this.tasks.selectedOptions.selected;
    sel.forEach(item=> {
      if( !this._data.Tasks.find( task=> task.TaskId == parseInt(item.value))) {
        this._data.Tasks.push( this.maintenanceTasks.find( ct=> ct.TaskId ==  parseInt(item.value) ));
      }
    })
    this.tasks.selectedOptions.deselect();
    this.taskListVisible = false;
  }

  cancelAddTask() {
    this.tasks.selectedOptions.deselect();
    this.taskListVisible = false;
  }
     
  alreadyAdded( cItem: MaintenanceTask) : boolean {
    return this._data.Tasks.find( item=>item.TaskId == cItem.TaskId) ? true: false
  }
  title() : string {
    return this._data.TypeId === 0 ?   this.translate.instant("common.add_new_task_type"):   this.translate.instant("common.change_task_type")
    
  }
  //#endregion =================================================================================================================





}
