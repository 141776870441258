import { Injectable, Output , EventEmitter} from '@angular/core';

@Injectable({providedIn:'root'})
export class ImageCaptureService {
  @Output() startCapture : EventEmitter<string> = new EventEmitter<string>()
  @Output() imageCaptured : EventEmitter<string> = new EventEmitter<string>()
  @Output() imageApproved : EventEmitter<string> = new EventEmitter<string>()
  @Output() imageDeclined: EventEmitter<null> = new EventEmitter<null>()
  
constructor() {
  
 }

}
