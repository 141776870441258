// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content {
  overflow: auto !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.hw-popup-title {
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 0em !important;
}

.mdc-button {
  min-width: 150px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
