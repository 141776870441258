import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ModuleSelectionService {
  @Output() navigateToRoot: EventEmitter<boolean> = new EventEmitter();
  @Output() onNavigationStarted: EventEmitter<boolean> = new EventEmitter(); 
  @Output() onNavigationEnded: EventEmitter<boolean> = new EventEmitter(); 


  navigationStarted() {
    this.onNavigationStarted.emit(true);
  }

  navigationEnded() {
    this.onNavigationEnded.emit(true);
  }


  moduleSelectionVisible(isVisible : boolean ) {
    this.navigateToRoot.emit(isVisible);
  }

}