// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

//Hostware
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';

import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { MaintenanceTask } from '@app/kip/dto/MaintenanceTask';
import { ApplicationPage } from '@app/core/enums/application-page';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { MaintenanceTypeEditorDialogComponent } from '@app/kip/dialogs/maintenance-type-editor-dialog/maintenance-type-editor-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'maintenance-type-editor',
  templateUrl: './maintenance-type-editor.component.html',
  styleUrls: ['./maintenance-type-editor.component.scss']
})
export class MaintenanceTypeEditorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  tableColumns: string[] = ["TypeCode", "TypeName", "Active", "Action"];

  loaded: boolean;
  filterCaptions: string[] = [];
  filterForm: FormGroup;
  editorList: MaintenanceType[];
  allMaintenanceTypes: MaintenanceType[];
  maintenanceGroups: MaintenanceGroup[];

  dataSource: MatTableDataSource<MaintenanceType>;
  // Subscriptions
  loadAllMaintenanceGroups$: Subscription;
  hotelUnitChanged$: Subscription;
  addMaintenanceType$: Subscription;
  deleteMaintenanceType$: Subscription;
  updateMaintenanceType$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private hwAlertService: HwAlertService,
    private rfHelperService: ReactiveFormsHelperService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private maintenanceGroupService: MaintenanceGroupService,
    private maintenanceTypeService: MaintenanceTypeService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private maintenanceTypeDialog: MatDialog,
    private translate: TranslateService,
    private fb: FormBuilder

  ) {
    this.filterForm = fb.group({
      'fActive': new FormControl(true),
      'fTypeCode': new FormControl('', [Validators.required, , Validators.maxLength(10)]),
      'fTypeName': new FormControl('', [Validators.required, , Validators.maxLength(40)]),
      'fMaintenanceGroupId': new FormControl(0)
    })

  }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.setFormId(ApplicationPage.maintenance_type_editor);
    this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    });
    this.loadAll();
  }
  ngOnDestroy() {
    this.loadAllMaintenanceGroups$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.addMaintenanceType$?.unsubscribe();
    this.deleteMaintenanceType$?.unsubscribe();
    this.updateMaintenanceType$?.unsubscribe();
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================



  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  openDialog(action: string, data?: MaintenanceType) {
    let maintenanceTypeItem: MaintenanceType;
    if (action == OpenDialogAction.ADD) {
      maintenanceTypeItem = new MaintenanceType();
      maintenanceTypeItem.TypeId = 0;
      maintenanceTypeItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      maintenanceTypeItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      maintenanceTypeItem.Active = true;
      maintenanceTypeItem.Tasks = [];
    } else {
      maintenanceTypeItem = data;
    }
    const maintenanceTypeDialogDef = this.maintenanceTypeDialog.open(MaintenanceTypeEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      panelClass: "maintenance-type-editor-dialog",
      // height: "60vh",
      data: maintenanceTypeItem
    })

    maintenanceTypeDialogDef.afterClosed().subscribe(data => {
      if (data) {
        const processableItem = data as MaintenanceType;
        if (processableItem.TypeId == 0) {
          this.addMaintenanceType$ = this.maintenanceTypeService.add(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        } else {
          this.updateMaintenanceType$ = this.maintenanceTypeService.update(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        }
      }
    })

  }
  deleteRow(item: MaintenanceType) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteMaintenanceType$ = this.maintenanceTypeService.delete(item.TypeId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAll();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

          }
        })
      }
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  applyFilter() {

    var tmpArr: MaintenanceType[] = [];
    var mustInclude: boolean;
    this.allMaintenanceTypes.forEach(item => {
      mustInclude = true;
      this.filterCaptions = [];

      // Active
      if (this.filterForm.controls["fActive"].value && mustInclude) {
        if (!item.Active) {
          mustInclude = false;
        }
      }

      // TypeCode
      if (!item.TypeCode.toLowerCase().startsWith(this.filterForm.controls["fTypeCode"].value.toString().toLowerCase()) &&
        this.filterForm.controls["fTypeCode"].value !== "" && mustInclude) {
        mustInclude = false;
      }

      // TypeName
      if (!item.TypeName.toLowerCase().startsWith(this.filterForm.controls["fTypeName"].value.toString().toLowerCase()) &&
        this.filterForm.controls["fTypeName"].value !== "" && mustInclude) {
        mustInclude = false;
      }


      // MaintenanceGroup 
      if (parseInt(this.filterForm.controls["fMaintenanceGroupId"].value) == -1 && mustInclude) {
        mustInclude = true;
      }
      else {
        if (item.MaintenanceGroupId !== parseInt(this.filterForm.controls["fMaintenanceGroupId"].value)) {
          mustInclude = false;
        }
      }
      if (mustInclude) {
        tmpArr.push(item);
      }
    });
    // update filter line
    if (this.filterForm.controls["fActive"].value) {
      this.filterCaptions.push(this.translate.instant("common.active"));
    }

    this.filterCaptions.push(this.translate.instant("common.group") + ": " + this.maintenanceGroups.find(item => item.GroupId == parseInt(this.filterForm.controls["fMaintenanceGroupId"].value)).GroupName);

    if (this.filterForm.controls["fTypeCode"].value !== "") {
      this.filterCaptions.push(this.translate.instant("common.code") + ": " + this.filterForm.controls["fTypeCode"].value);
    }
    if (this.filterForm.controls["fTypeName"].value !== "") {
      this.filterCaptions.push(this.translate.instant("common.appellation") + ": " + this.filterForm.controls["fTypeName"].value);
    }
    // 
    this.editorList = JSON.parse(JSON.stringify(tmpArr)) as MaintenanceType[];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;
  }


  loadAll() {
    const combinedSources = combineLatest({
      maintenanceGroups: this.maintenanceGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      maintenanceTypes: this.maintenanceTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    })
    combinedSources.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.allMaintenanceTypes = (value.maintenanceTypes as RequestResult).Data as Array<MaintenanceType>;
      this.maintenanceGroups = (value.maintenanceGroups as RequestResult).Data as Array<MaintenanceGroup>;
      this.maintenanceGroups.unshift(
        {
          GroupId: -1,
          GroupName: this.translate.instant("common.all"),
          HotelId: this.sessionStorageService.getSelectedHotelId(),
          Subscriber: this.sessionStorageService.getSelectedUnit(),
          SData: ''
        })
      this.loaded = true;
      this.filterForm.controls["fMaintenanceGroupId"].patchValue(this.maintenanceGroups[0].GroupId);
      this.applyFilter();
    })

  }

  showAddOrEditMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }

  booleanCell(val: boolean) {
    if (val) {
      return {
        'mdi mdi-check': true,
        'boolean-cell': true

      }
    }
  }


  //#endregion =================================================================================================================

}
