

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { RoomReservation } from '../dto/RoomReservation';
import { vWOOORoomFilter } from '../viewdto/vwOOORoomFilter';


@Injectable({
  providedIn: 'root'
})
export class OooRoomService {
  apiServer = environment.apiHost + environment.apiPort;

  constructor(
    public http: HttpClient
  ) { }

  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/RoomReservation/LoadAll/' + subscriber + "/" + hotelId);
    //                                   
  }
  loadByDateInterval(subscriber: string, hotelId: number, dateFrom: string, dateTo: string) {

    return this.http.get(this.apiServer + '/api/kip/RoomReservation/LoadIntervalOOO/' + subscriber + "/" + hotelId +
      "/" + dateFrom + "/" + dateTo);
  }
  getById(Id: number) {
    return this.http.get(this.apiServer + '/api/kip/RoomReservation/GetById/' + Id);
  }
  getByRoomNumber(subscriber: string, hotelId: number,roomNumber : string) {
    return this.http.get(this.apiServer + '/api/kip/RoomReservation/GetByRoomNumber/' + subscriber + "/" + hotelId + "/" +roomNumber)
  }
  Update(data: RoomReservation) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/Update', data, { headers });
  }
  Add(data: RoomReservation) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/Add', data, { headers });
  }
  AddMulti(data: RoomReservation[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/AddMulti', data, { headers });
  }
  Delete(ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/RoomReservation/Delete/' + ids);
  }


  getOOORoomListPdf(subscriber: string, hotelId: number, data: vWOOORoomFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/GetOOOListPdf/' + subscriber + "/" + hotelId, data, { responseType: 'blob' });
  }


  getOOORoomListExcel(subscriber: string, hotelId: number, data: vWOOORoomFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/GetOOOListExcel/' + subscriber + "/" + hotelId, data, { responseType: 'blob' });
  }

  updateOOOReturn(oooId: number, state: String, cleaning: boolean) {
    //var goodBoolean: string = cleaning.toString().charAt(0).toUpperCase() + cleaning.toString().slice(1)
    var data = {};
    return this.http.put(this.apiServer + '/api/kip/RoomReservation/UpdateOOOReturn/' + oooId.toString() + "/" + state + "/" + cleaning,data);
  }
}

