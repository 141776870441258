// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.mat-mdc-form-field {
  width: 100%;
  min-width: 300px;
}

mat-card-title,
mat-card-content {
  display: flex;
  justify-content: center;
}

.error {
  padding: 16px;
  width: 300px;
  color: white;
  background-color: red;
}

.button {
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.inf-login-logo {
  width: 50%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;
}

.login-page-text {
  color: #424242;
  text-align: center;
  font-size: large;
}

.login-card-border {
  box-shadow: 0 21px 47px rgba(0, 0, 0, 0.46);
  padding: 5em;
}

@media screen and (max-width: 500px) and (min-width: 100px) {
  .login-card-border {
    padding: 4em 2em 4em 2em;
  }
}
.hw-login-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

div.spinner-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
