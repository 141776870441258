import { Injectable } from '@angular/core';
import { vwKipUserRoles } from '../viewdto/vwKipUserRoles';
import { RoleType } from '@shared/enums/roles';
import { Roles } from '@core/dto/UserInfo';

@Injectable({
  providedIn: 'root'
})
export class RoleHelperService {

constructor() { }

  hasRole ( roles: string , role : string) {
    return  roles.split(',').findIndex(item=>item.trim() == role) === -1 ? false : true;
  }
}
