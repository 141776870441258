import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscribable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alert, HwAlertModule, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwAlertComponent } from '@globalshared/hw-alert-component/src/lib/hw-alert.component';
import { RequestResult } from '@app/core/dto/RequestResult';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  editorForm: FormGroup;
  changePassword$  : Subscription;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private fb: FormBuilder,
    private hwAlertService: HwAlertService,
    private translate: TranslateService

  ) {

    this.editorForm = fb.group({
      'oldPassword': new FormControl('', [Validators.required, Validators.maxLength(20)]),
      'newPassword': new FormControl('',[Validators.required,  Validators.maxLength(20),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]),
      'newPasswordAgain' : new FormControl('',[Validators.required,  Validators.maxLength(20),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]),
    } , {validator: ChangePasswordDialogComponent.isNewPasswordOk});


   }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnDestroy(): void {
    this.changePassword$?.unsubscribe();
  }

  ngOnInit() {
    
  }

  //#endregion =================================================================================================================


  //#region =============== Form events ========================================================================================
  
  cancel() {
    this.dialogRef.close(undefined);
  }
  submit() {


    if(this.editorForm.controls["newPassword"].value !== this.editorForm.controls["newPasswordAgain"].value  ) {
      this.editorForm.setErrors({passwordError :true})
    }
     this.authService.changePassword(this.sessionStorageService.getUserInfo().UserName,this.editorForm.controls["oldPassword"].value,this.editorForm.controls["oldPassword"].value).subscribe(result=> {
      const res: RequestResult = result  as RequestResult;
      if( res.Code === -1)  {
        this.hwAlertService.error(this.translate.instant("changepassword.incorrect_old_password") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
      } else {
        this.hwAlertService.info(this.translate.instant("changepassword.password_change_is_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }) )
        this.authService.logout();
      }
     })

  }

  //#endregion =================================================================================================================

  static isNewPasswordOk(control: AbstractControl): ValidationErrors | null {
     if(control.get('newPassword').value == control.get('newPasswordAgain').value) {
      return null 
     } else {
      return {passwordError:true}
     }
  }

}

