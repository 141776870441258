// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-play {
  line-height: 0.9;
  font-size: 1.5em;
  color: #03a9f4;
}

.task-pause {
  line-height: 0.9;
  font-size: 1.5em;
  color: #eeca7a;
}

.task-ended {
  line-height: 0.9;
  font-size: 1.5em;
  color: #6aa546;
}

.task-deleted {
  line-height: 0.9;
  font-size: 1.5em;
  color: #f30d0d;
}

.task-created {
  line-height: 0.9;
  font-size: 1.5em;
  color: #e2835e;
}

.task-accepted {
  line-height: 0.9;
  font-size: 1.5em;
  color: #6aa546;
}

.task-status-icon {
  padding-top: 1.5em;
}

.hw-padding-right-1em {
  padding-right: 1em !important;
}

.dirty-button-icon-color {
  color: #ffc2cb !important;
}

@media screen and (max-width: 767px) and (min-width: 100px) {
  .task-play {
    line-height: 1.5em;
    font-size: 1.5em;
    color: #03a9f4;
  }
  .task-pause {
    line-height: 1.5em;
    font-size: 1.5em;
    color: #eeca7a;
  }
  .task-ended {
    line-height: 1.5em;
    font-size: 1.5em;
    color: #6aa546;
  }
  .task-deleted {
    line-height: 1.5em;
    font-size: 1.5em;
    color: #f30d0d;
  }
  .task-created {
    line-height: 1.5em;
    font-size: 1.5em;
    color: #e2835e;
  }
}
.tasktime {
  margin-top: 0.5em;
}

.action-button-container {
  cursor: pointer;
  width: 100%;
  width: 160px;
  height: 150px;
  border-radius: 2px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1), 0 3px 3px 3px rgba(0, 0, 0, 0.05), 0 2px 1px -1px rgba(0, 0, 0, 0.1) !important;
}

.action-icon-font-size {
  font-size: 70px;
}

.button-label {
  font-size: 15px;
  font-weight: 600;
}

/* dirty */
.dirty-button-background {
  background-color: #e57373;
}

.dirty-button-icon-color {
  color: #ffc2cb !important;
}

.dirty-panel-room-state-icon {
  color: #ffcdd2;
  font-size: 50px;
}

.dirty-panel-background {
  background-color: #e57373;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.dirty-panel-room-number {
  color: white;
  font-size: 50px;
}

/* clean */
.clean-button-background {
  background-color: #B7D8FB;
}

.clean-button-icon-color {
  color: #03a9f4;
}

.clean-panel-background {
  background-color: #03a9f4;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.clean-panel-room-number {
  color: #fff;
  font-size: 50px;
}

.clean-panel-room-state-icon {
  color: #b7d8fb;
  font-size: 50px;
}

/* Showroom*/
.showr-button-background {
  background-color: #FDF6B3;
}

.showr-button-icon-color {
  color: #FED833;
}

/* OOO */
.ooo-panel-background {
  background-color: #424242;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.ooo-panel-roomstate-icon {
  color: #bdbdbd;
  font-size: 50px;
}

.ooo-panel-room-number, .ooo-panel-room-state-icon {
  color: #fff;
  font-size: 50px;
}

/* OOS */
.oos-panel-background {
  background-color: #eba9e5;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.oos-panel-roomstate-icon {
  color: #f3e5f5;
  font-size: 50px;
}

.oos-panel-room-number, .oos-panel-room-state-icon {
  color: #fff;
  font-size: 50px;
}

.oos-button-background {
  background-color: #eba9e5;
}

.oos-button-icon-color {
  color: #9750b9;
}

/* Showroom */
.showroom-panel-background {
  background-color: #ffca28;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.showroom-panel-roomstate-icon {
  color: #fff6d8;
  font-size: 50px;
}

.showroom-panel-room-number {
  color: #fff;
  font-size: 50px;
}

/* Inspected */
.inspected-button-background {
  background-color: #bcf0c9;
}

.inspected-button-color {
  color: #29cf6d;
}

/* add task */
.addtask-button-background {
  background-color: #A5E4DA;
}

.addtask-button-icon-color {
  color: #02B09A;
}

/* Minibar */
.minibar-button-background {
  background-color: #C7BFE8;
}

.minibar-button-icon-color {
  color: #6C40BD;
}

.checked-panel-background {
  background-color: #5DDA86;
  position: absolute;
  min-height: -webkit-fill-available;
  margin-top: -3.5em;
}

.checked-panel-roomstate-icon {
  color: #b9efc7;
  font-size: 50px;
}

.save-button-icon-color {
  color: #FED833;
}

.back-icon-font-size {
  font-size: 60px;
  float: left;
  margin-top: 0em;
  color: white !important;
  cursor: pointer;
}

@media screen and (max-width: 597px) and (min-width: 100px) {
  .back-icon-font-size {
    margin-top: -0.2em;
  }
}
/* Cleaningtypes grid icons*/
.task-play {
  line-height: 0.9;
  font-size: 1.5em;
  color: #03a9f4;
}

.task-pause {
  line-height: 0.9;
  font-size: 1.5em;
  color: #eeca7a;
}

.task-ended {
  line-height: 0.9;
  font-size: 1.5em;
  color: #6aa546;
}

.task-deleted {
  line-height: 0.9;
  font-size: 1.5em;
  color: #f30d0d;
}

.task-created {
  line-height: 0.9;
  font-size: 1.5em;
  color: #e2835e;
}

.broom-style {
  color: #03ADBE;
  font-size: x-large;
}

.wrench-style {
  font-size: large;
  padding-right: 0.3em;
}

.task-items {
  border-bottom: 1px solid grey;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: end;
}

.task-status-icon-wrapper {
  margin-top: 10px;
}

/* Panel button align */
.button-align-right {
  display: flex;
  justify-content: flex-end;
}

.button-align-center {
  display: flex;
  justify-content: center;
}

.webkit-center {
  text-align: -webkit-center;
}

.dirty-panel-background {
  background-color: #e57373;
  /* height: -webkit-fill-available; */
  margin-top: -3em !important;
  min-height: -webkit-fill-available;
  position: absolute;
}

.public-area-labels-margin {
  margin-top: auto;
  margin-bottom: auto;
}

.public-area-panel-container {
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 597px) and (min-width: 100px) {
  .public-area-panel-container {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 1000px) and (min-width: 598px) {
  .public-area-panel-container {
    width: 75%;
    margin: auto;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
