import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "../../../environments/environment";
import { SubscriberParameter } from '../dto/SubscriberParameter';
@Injectable({
  providedIn: 'root'
})
export class SubscriberParameterService {

  constructor(public http: HttpClient) { }

  apiServer = environment.apiHost + environment.apiPort;

  loadAll( subscriberCode: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/SubscriberParameter/LoadAll/' + subscriberCode + "/" + hotelId);   
  }

  loadByTypeCode(subscriberCode: string, hotelId: number, typeCode: string) {
    return this.http.get(this.apiServer + '/api/kip/SubscriberParameter/LoadByTypeCode/' + subscriberCode + "/" + hotelId + '/' + typeCode); 
  }
  add(data : SubscriberParameter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer + '/api/kip/SubscriberParameter/Add', data, { headers });
  }

  update(data : SubscriberParameter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer + '/api/kip/SubscriberParameter/Update', data, { headers });
  }

  delete(id : number) {
    return this.http.delete(this.apiServer + '/api/kip/SubscriberParameter/Delete/' + id);
  }

  updateAll( data : SubscriberParameter[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer + '/api/kip/SubscriberParameter/UpdateAll', data, { headers });
  }
}
