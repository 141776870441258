// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generation-method {
  padding-top: 12px;
  text-align: center;
}

.active {
  padding-top: 4px;
}

.float-button {
  margin-bottom: -1em;
  margin-right: 2em;
}

.add-button {
  text-align: end;
}

.boolean-cell {
  font-size: 25px;
  color: #2196f3;
}

@media (max-width: 599px) {
  .hw-grid-cell {
    float: right;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .hw-mobile-display-inherit {
    display: flex;
  }
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    margin-bottom: 1em;
  }
  .boolean-cell {
    font-size: 20px;
  }
  .action-buttons {
    width: 100%;
    float: right;
    text-align: right;
    margin-top: 0.25em;
    padding-bottom: 1em !important;
  }
  tr.mat-row, tr.mat-footer-row {
    height: 48px;
    width: 100%;
  }
  .hw-mobile-table-title-show {
    text-align: left;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
