import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RabbitLogService {

constructor(private http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;

  checkNoAck(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/RabbitLogs/CheckNoAck/' + subscriber + '/' + hotelId )
  }

  repairRabbitToFro(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/RabbitLogs/RepairRabbitToFRO/' + subscriber + '/' + hotelId ) 
  }
}
