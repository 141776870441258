import { Component, OnInit, Input } from '@angular/core';
import { ParamKeyValue } from '../../../classes/ParamKeyValue';

@Component({
  selector: 'multi-icon-chips',
  templateUrl: './multi-icon-chips.component.html',
  styleUrls: ['./multi-icon-chips.component.css']
})
export class MultiIconChipsComponent implements OnInit {

  @Input() paramKeyValue: ParamKeyValue[]
  constructor() { }

  ngOnInit() {
  }

}
