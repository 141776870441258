// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Hostware 
import { MaintenanceGroup } from '@app/kip/dto/MaintenanceGroup';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { MaintenanceGroupService } from '@app/kip/services/maintenance-group.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MatTableDataSource } from '@angular/material/table';
import { MaintenanceGroupEditorDialogComponent } from '@app/kip/dialogs/maintenance-group-editor-dialog/maintenance-group-editor-dialog.component';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { GlobalMessageService } from '@app/shared/services/global-message.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'maintenance-group-editor',
  templateUrl: './maintenance-group-editor.component.html',
  styleUrls: ['./maintenance-group-editor.component.scss']
})
export class MaintenanceGroupEditorComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort) sort!: MatSort;
  tableColumns: string[] = ["GroupName", "Action"];
  loaded: boolean;
  editorForm: FormGroup;
  editorList: MaintenanceGroup[] = [];
  dataSource: MatTableDataSource<MaintenanceGroup>;

  loadAllMaintenanceGroups$: Subscription;
  getMaintenanceGroupById$: Subscription;
  addMaintenanceGroup$: Subscription;
  updateMaintenanceGroup$: Subscription;
  deleteMaintenanceGroup$: Subscription;
  maintenanceGroupNameExists$: Subscription;
  hotelUnitChanged$: Subscription;

  constructor(
    public hwAlertService: HwAlertService,
    private hwConfirmationService: HwConfirmationDialogService,
    public maintenanceGroupService: MaintenanceGroupService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private maintenanceGroupDialog: MatDialog
  ) {

    this.editorForm = fb.group({
      'filterInput': new FormControl(''),
    })
    this.dataSource = new MatTableDataSource();
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.loadAllMaintenanceGroups();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event=> {
      this.loadAllMaintenanceGroups();
    })
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy(): void {
    this.loadAllMaintenanceGroups$?.unsubscribe();
    this.getMaintenanceGroupById$?.unsubscribe();
    this.addMaintenanceGroup$?.unsubscribe();
    this.updateMaintenanceGroup$?.unsubscribe();
    this.deleteMaintenanceGroup$?.unsubscribe();
    this.maintenanceGroupNameExists$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  openDialog(action: string, data?: MaintenanceGroup) {
    let maintenanceGroupItem: MaintenanceGroup;

    if (action == OpenDialogAction.ADD) {
      maintenanceGroupItem = new MaintenanceGroup();
      maintenanceGroupItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      maintenanceGroupItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      maintenanceGroupItem.GroupId = 0;
    } else {
      maintenanceGroupItem = data;
    }

    const maintenanceGroupDialogRef = this.maintenanceGroupDialog.open(MaintenanceGroupEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "65vw",
      // height: "70vh",
      panelClass: "cleaning-group-editor-dialog",
      data: maintenanceGroupItem

    })
    maintenanceGroupDialogRef.afterClosed().subscribe(data => {
      // nem Cancellel zártuk le a dialógust
      if (data) {
        const processableItem = data as MaintenanceGroup;
        if (processableItem.GroupId == 0) {
          // add
          this.addMaintenanceGroup$ = this.maintenanceGroupService.addMaintenanceGroup(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAllMaintenanceGroups();
          })
        } else {
          // update 
          this.updateMaintenanceGroup$ = this.maintenanceGroupService.updateMaintenanceGroup(processableItem).subscribe(result => {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAllMaintenanceGroups();
          })
        }

      }

    })

  }
  deleteRow(item: MaintenanceGroup) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteMaintenanceGroup$ = this.maintenanceGroupService.deleteMaintenanceGroup(item.GroupId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAllMaintenanceGroups();
          } else {
            this.hwAlertService.error(this.translate.instant("common.error_while_deleting"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

          }
        })
      }
    })



  }
  //#endregion =================================================================================================================

    //#region =============== Form events ========================================================================================

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
    }
  
    sortData(sortState: Sort) {
      switch (sortState.direction) {
        case 'asc':
          this.sort.direction = 'asc';
          break;
        case 'desc':
          this.sort.direction = 'desc';
          break;
        default:
          this.sort.direction = 'asc';
      }
      this.dataSource.sort = this.sort;
    }
    
  
  
    //#endregion =================================================================================================================
  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAllMaintenanceGroups() {

    this.loadAllMaintenanceGroups$ = this.maintenanceGroupService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).
      subscribe(result => {
        var res = result as RequestResult;
        this.editorList = res.Data as Array<MaintenanceGroup>;
        this.dataSource = new MatTableDataSource(this.editorList);
        this.dataSource.sort = this.sort;
        this.loaded = true;
      })
  }

  showAddOrEditMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }
  //#endregion =================================================================================================================

}
