// Angular
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


// 3rdparty
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
// Hostware
import { PublicArea } from '@app/kip/dto/PublicArea';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { vwPublicAreaCleaningType } from '@app/kip/viewdto/vwPublicAreaCleningType';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-public-area-cleaning-type-editor-dialog',
  templateUrl: './public-area-cleaning-type-editor-dialog.component.html',
  styleUrls: ['./public-area-cleaning-type-editor-dialog.component.scss']
})
export class PublicAreaCleaningTypeEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('localizer') localizer : DataLocalizerComponent;
  editorForm: FormGroup;
  _data: vwPublicAreaCleaningType;
  title: string;

  cleaningTypes: CleaningType[] = [];
  publicAreas: PublicArea[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor( public dialogRef: MatDialogRef<PublicAreaCleaningTypeEditorDialogComponent>,@Inject(MAT_DIALOG_DATA) private data: vwPublicAreaCleaningType,
    private publicAreaService: PublicAreaService,
    private cleaningTypeService: CleaningTypeService,
    private sessionStorageService: SessionStorageService,
    private rfHelperService: ReactiveFormsHelperService,
    private translate: TranslateService,

    private fb: FormBuilder
  ) { 
    this.editorForm = fb.group({
      'PublicAreaId': new FormControl(0),
      'PublicAreaName': new FormControl(''),
      'CleaningTypesId': new FormControl(''),
      'CleaningTypeName': new FormControl(''),
      'PeriodHourStart': new FormControl(0),
      'PeriodHourEnd': new FormControl(0),
      'PeriodMinStart': new FormControl(0),
      'PeriodMinEnd': new FormControl(0),
      'PeriodicityMinutes': new FormControl(0)
    })
    this._data = data;
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    const combinedSources = combineLatest({
      cleaningTypes: this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      publicAreas: this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
    }).pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.cleaningTypes = (value.cleaningTypes as RequestResult).Data as Array<CleaningType>;
      this.publicAreas = (value.publicAreas as RequestResult).Data as Array<PublicArea>;
      this.rfHelperService.fillFormFromRecord(this._data,this.editorForm);
    })

    this.title = this._data.PublicAreaCleaningTypeId !== null ? this.translate.instant('common.add_public_area_cleaning') :
     this.translate.instant('common.creat_public_area_cleaning')

    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }



  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  submit() {
    
    this.rfHelperService.updateRecordFromForm(this._data,this.editorForm)
    this.dialogRef.close(this._data);
    
  }
  cancel() {
    this.dialogRef.close(undefined);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  //#endregion =================================================================================================================


}
