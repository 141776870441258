// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sum-number {
  text-align: end;
  padding-right: 40px;
}

.filler {
  margin-top: 6px;
}

.room-number {
  font-size: larger;
  font-style: inherit;
  font-weight: 500;
  line-height: 55px;
  margin-left: -0.3em;
}

.trustee-label {
  text-align: center;
  font-size: 12px;
}

.box-number {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.box-number-right {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
}

.arrival-rooms-icon {
  color: #7ec681;
  font-size: 3em;
}

.occupied-rooms-icon {
  color: #03a9f4;
  font-size: 3em;
}

.departure-rooms-icon {
  color: #e57373;
  font-size: 3em;
}

.empty-rooms-icon {
  color: #9b9b9b;
  font-size: 3em;
}

.ooo-rooms-icon {
  color: #444;
  font-size: 3em;
}

.oos-rooms-icon {
  color: #a86dc5;
  font-size: 3em;
}

.showrooms-icon {
  color: #f2d758;
  font-size: 3em;
}

hr.recepcionist-hr {
  border-top: 1px solid #dfdfdf;
  padding-bottom: 0.5em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
