export class RepeatingTask {
  Subscriber : string;
  HotelId: number;
  TaskId?: number;
  TaskTypeId: number;
  TaskType: string;  // C || M 
  StartDate: string;
  RepeatDay: number;
  ShowBefore: number;
  IsActive: boolean;
  AreaType: string;
  Areas: string;
  
}


export class AreaItem {
  public AreaId: number;
}