import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from '@environments/environment';
import { KipUserGroup } from '../dto/KipUserGroup';

@Injectable({
  providedIn: 'root'
})
export class KipUserGroupService {

  apiServer = environment.apiHost + environment.apiPort;
  constructor(private http: HttpClient) { }

  getById ( id: number) {
    return this.http.get(this.apiServer + '/api/kip/KipUserGroup/GetById/' + 1 ); 
  }

  add( data: KipUserGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/KipUserGroup/Add',data,{headers});
  }

  update( data: KipUserGroup) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/KipUserGroup/Update',data,{headers});
  }

  delete( ids : string) {
    return this.http.delete(this.apiServer + '/api/kip/KipUserGroup/Delete/' + ids);
  }

  //--------
  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/KipUserGroup/LoadAll/' + subscriber + "/" + hotelId); 
  }
  loadAllMulti(subscriber: string, hotelIds: string) {
    return this.http.get(this.apiServer + '/api/kip/KipUserGroup/LoadAll_Multi/' + subscriber + "/" + hotelIds); 
  }
  //--------

  getByUserGroupName(subscriber: string, hotelId : number, userGroupName : string) {
    return this.http.get(this.apiServer + '/api/kip/KipUserGroup/GetByUserGroupName/' + subscriber + "/" + hotelId + "/" + userGroupName); 
  } 


}
