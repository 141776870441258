
import { EventEmitter, Injectable, Output } from '@angular/core';


import { Subscription, Observable, Subscriber, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { AuthItem } from '../interfaces/auth-item';
import { environment } from '../../../environments/environment';
import  jwt_decode from "jwt-decode";
import { UserInfo } from '../dto/UserInfo';

import { LoginDto } from '../dto/LoginDto';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() rolesLoaded : EventEmitter<null> = new EventEmitter();
  private tokenPhrase = 'hw-kip-token';
  private authorizationExp = 'Authorization';
  private contentType = 'application/x-www-form-urlencoded';
  private bearerExp = 'Bearer ';
  private refreshSubscription: Subscription = null;

  private unAuthorizedRequests: ((req: HttpRequest<any>) => boolean)[] = [];
  public pendingRefresh = false;


  private url = environment.apiHost + environment.apiPort;
  
  private differedRequests: { request: HttpRequest<any>, subject: Subject<any> }[] = [];


  constructor(
    protected httpClient: HttpClient,
    protected resourceService: ResourceService,
    protected router: Router) {
    const sign: string = this.resourceService.apiPort.length > 0 ? ':' : '';
    // this.url = `${this.resourceService.apiHost}${sign}${this.resourceService.apiPort}`;
    this.unAuthorizedRequests = [
      this.isLoginRequest,
      this.isLogoutRequest,
      this.isRefreshRequest
    ];

  }

  public differRequest<T = any>(req: HttpRequest<T>): Observable<T> {
    const subject = new Subject<T>();
    this.differedRequests.push({ request: req, subject: subject });
    return subject;
  }

  public triggerDifferedRequests() {
    this.differedRequests.forEach(obj => this.httpClient.request(obj.request).subscribe(res => obj.subject.next(res)));
    this.differedRequests = [];
  }

  public isRefreshRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'refresh-token';
  }

  public isLoginRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'access-token';
  }

  public isLogoutRequest<T = any>(req: HttpRequest<T>): boolean {
    return req.headers.has('hw-action') && req.headers.get('hw-action') === 'delete-token';
  }

  public isAthorizedRequest<T = any>(req: HttpRequest<T>) {
    const result = !this.unAuthorizedRequests.some(u => u(req));
    return result;
  }

  public clearDifferedRequests() {
    this.differedRequests = [];
  }

  public hasToken() {
    const token = sessionStorage.getItem(this.tokenPhrase);
    return Boolean(token);
  }

  public isAuthTokenExpired(): boolean {
    const token = this.getAuthToken();
    if (!Boolean(token.next)) {
      return false;
    }
    return this.getAuthToken().next < new Date().getTime();
  }

  public getAuthToken(): AuthItem {
    const token = sessionStorage.getItem(this.tokenPhrase);
    if (!Boolean(token)) {
      return <AuthItem>{};
    }
    return (<AuthItem>JSON.parse(token));
  }
public getRawToken() {
  return sessionStorage.getItem(this.tokenPhrase);
}

  public get isLoggedIn(): boolean {
    const token = this.getAuthToken();
    if (!Boolean(token) || !Boolean(token.access_token)) {
      return false;
    }
    return token.next > new Date().getTime();
  }


  public logout(): Observable<boolean> {
    sessionStorage.removeItem(this.tokenPhrase);
    this.clearDifferedRequests();
    const headers = new HttpHeaders({ 'Content-Type': this.contentType, 'hw-action': 'delete-token' });
    const params: HttpParams = new HttpParams();
    if (this.refreshSubscription !== null) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    return this.httpClient.post<boolean>(`${this.url}${this.resourceService.logoutUrl}`, params.toString(), { headers: headers });
  }

  public gotoLogin() {
   
    this.router.navigate([`./${this.resourceService.loggedOutRoute}`]);
  }

  public login(userName: string, password: string): Observable<AuthItem> {
    const headers = new HttpHeaders({ 'Content-Type': this.contentType, 'hw-action': 'access-token' });
    const options = { headers: headers };
    const data = { username: userName, password: password };
    const params: URLSearchParams = new URLSearchParams();
    Object.keys(data).forEach(key =>
      params.append(key, data[key])
    );
    return this.httpClient.post<AuthItem>(`${this.url}${this.resourceService.loginUrl}`,
      params.toString(), { headers: headers }).pipe(map((response: AuthItem) => {
        this.saveToken(response);
        
        return response;
      }));
  }

  public get<T = any>(url: string, headers?: HttpHeaders): Observable<T> {
    const next = this.httpClient.get<T>(`${this.url}${url}`, { headers: headers });
    return next;
  }

  public post<T = any>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.post<T>(`${this.url}${url}`, body, { headers: headers });
  }

  public put<T = any>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.put<T>(`${this.url}${url}`, body, { headers: headers });
  }

  public delete<T = any>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.delete<T>(`${this.url}${url}`, { headers: headers });
  }

  public request<T = any>(req: HttpRequest<any>): Observable<HttpEvent<T>> {
    return this.httpClient.request<T>(req);
  }

  public fireTokenRefresh(): Observable<AuthItem> {
    const token: AuthItem = <AuthItem>JSON.parse(sessionStorage.getItem(this.tokenPhrase));
    const headers = new HttpHeaders({
      'Content-Type': this.contentType,
      'hw-action': 'refresh-token',
      'Authorization': `Bearer ${token.refresh_token}`
    });
    return this.httpClient.post<AuthItem>(`${this.url}${this.resourceService.refreshUrl}`, {}, { headers: headers });
  }

  public saveToken(token: AuthItem): void {
    const oldToken = <AuthItem>JSON.parse(sessionStorage.getItem(this.tokenPhrase));
    sessionStorage.setItem(this.tokenPhrase, JSON.stringify(<AuthItem>{
      access_token: token.access_token,
      refresh_token: token.refresh_token === undefined || token.refresh_token === null ? oldToken.refresh_token : token.refresh_token,
      next: this.getNextTimeout(<number>token.next),
      timeout: <number>token.next,
      expires_in: <number>token.expires_in
    }));
  }
  public getNextTimeout(timeout: number): number {
    const now = new Date();
    return new Date(now.getTime() + (timeout / 2) * 1000).getTime();
  }

  public isAuthTokenValid(): boolean {

    const token = this.getAuthToken();
    if (token.expires_in > new Date().getTime()) {
      return true;
    }
    else {
      return false;
    }
    // if (!Boolean(token.next)) {
    //   return false;
    // }
    // return token.next > new Date().getTime();
  }
  public getPendingRefreshStatus() {
    return this.pendingRefresh;
  }

  public setPendingRefreshStatus(status: boolean) {
    this.pendingRefresh = status;
  }

  getEmailFromToken() {
    if (this.hasToken()) {
      let token = jwt_decode(this.getAuthToken().access_token);
      return token["Email"];
    }
    else {
      return "";
    }
  }
  getUserIdFromToken() {
    const  u : UserInfo =   JSON.parse(sessionStorage.getItem("userInfo")) as UserInfo;
    if(u) {
      return u.UserId;
    }
    else {
      return -1;
    }


    // if (this.hasToken()) {
    //   let token = jwt_decode(this.getAuthToken().access_token);
    //   return parseInt(token["UserId"]);
    // }
    // else {
    //   return -1;
    // }
  }

  changePassword( userName : string ,oldPassword: string, newPassword : string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const data: LoginDto = {password:newPassword, oldpassword:oldPassword,username:userName}
    return this.httpClient.post(this.url + '/api/ChangePass',data,{headers});


  }
  ///
}
