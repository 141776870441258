export class vwRoomStateListFilter {
  ItemType: string;         // "-1" Housekeeping+Maintenance ; Housekeeping: "0" ; maintenance : "1"
  RoomEmpty : boolean;      // Üres
  EarlyCheckin : boolean;   // Korai érkező
  Checkin : boolean;        // Érkező
  Checked : boolean;        // Megérkezett
  Occupied: boolean;        // Lakó szoba
  Checkout: boolean;        // Utazó
  LateCheckout: boolean;    // Késői utazó
  CheckedOut: boolean;      // Elutazott
  RoomDirty : boolean;      
  RoomClean : boolean;
  RoomOk  : boolean;
  RoomOOO : boolean;
  RoomOOS : boolean
  RoomShowRoom: boolean;
  RoomNumber : string;
  Floor: string;
  RoomType : string;
}