// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// 3rdparty
import { Subscription } from 'rxjs';
// Hostware
import { SelectItem } from '@app/core/classes/SelectItem';
import { HousekeepingSettingsContainerService } from '@app/kip/services/housekeeping-settings-container.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationPage } from '@app/core/enums/application-page';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatSelectionListChange } from '@angular/material/list';


@AutoUnsubscribe()
@Component({
  selector: 'app-housekeeping-settings-container',
  templateUrl: './housekeeping-settings-container.component.html',
  styleUrls: ['./housekeeping-settings-container.component.scss']
})
export class HousekeepingSettingsContainerComponent implements OnInit, OnDestroy {

  
  listActive: boolean = true;
  editorActive: boolean = false;
  selectedEditor = 'hg';
  headerText: string = "Housekeeping beállítások";
  subHeaderText: string;
  editorList: SelectItem[];
  editorSelectionVisible: boolean = true;
  visibilityChanged$: Subscription;
  headerTextChanged$ : Subscription;
  subHeaderTextChanged$ :Subscription;
  listModeSelected$: Subscription;
  editModeSelected$: Subscription;

  constructor(

    private hkscService: HousekeepingSettingsContainerService,
    private topbarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
  ) { }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topbarManagerService.setFormId(ApplicationPage.hotel_settings_container);
    this.topbarManagerService.setTopbarBackgroundVisibility(true);
    this.headerText = this.translate.instant("common.housekeeping_settings");
    this.visibilityChanged$ = this.hkscService.visibilityChanged.subscribe(evn => {
      this.editorSelectionVisible = evn;
    })

    this.headerTextChanged$= this.hkscService.headerTextChanged.subscribe(evn => {
      this.headerText = evn;
    });

    this.subHeaderTextChanged$= this.hkscService.subHeaderTextChanged.subscribe(evn => {
      this.subHeaderText = evn;
    });

    this.listModeSelected$ = this.hkscService.listModeSelected.subscribe(evn => {
      this.listActive = evn;
      this.editorActive = !evn;
    });

    this.editModeSelected$ = this.hkscService.editModeSelected.subscribe(evn => {
      this.editorActive = evn;
      this.listActive = !evn;
    });

    this.editorList = [
      {
        label : this.translate.instant("common.type_groups"),
        value : "hg"
      },
      {
        label : this.translate.instant("common.task_types"),
        value : "hu"
      },
      {
        label : this.translate.instant("common.task_to_do"),
        value : "ht"
      },
      {
        label : this.translate.instant("common.standard_room_cleaning_types"),
        value : "sf"
      },
      {
        label : this.translate.instant("common.public_area_cleaning_settings"),
        value : "kf"
      },
      {
        label : this.translate.instant("extra_tasks.title"),
        value : "ex"
      }
      
    ]
    this.subHeaderText = this.editorList.find(item=> item.value == "hg").label;

  }
  ngOnDestroy(): void {
    this.visibilityChanged$?.unsubscribe();
    this.headerTextChanged$?.unsubscribe();
    this.subHeaderTextChanged$?.unsubscribe;
    this.listModeSelected$?.unsubscribe();
    this.editModeSelected$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  editorChanged(event: MatSelectionListChange) {
    this.selectedEditor = event.options[0].value;
    this.subHeaderText =  event.options[0].getLabel();
    
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  //#endregion =================================================================================================================

}
