import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions, ResponseType } from "@angular/http";

//HW
import { environment } from "../../../environments/environment";
import { CleaningSchedule } from '../dto/CleaningSchedule';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { ExtraTask } from '../dto/ExtraTask';

@Injectable({
  providedIn: 'root'
})
export class ExtraTaskService {

  apiServer = environment.apiHost + environment.apiPort;

  constructor(public http: HttpClient, private sessionStorageService : SessionStorageService) { }
  
  loadAll(subscriber: string, hotelId: number) {
    return this.http.get(this.apiServer + "/api/kip/extratasks/LoadAll/" + subscriber + "/" + hotelId )
  }

  add( data: ExtraTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + "/api/kip/extratasks/add/", data,{headers} )
  }
  update( data: ExtraTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + "/api/kip/extratasks/update/", data,{headers} )
  }

  delete( id : number ) {
    return this.http.delete(this.apiServer + "/api/kip/extratasks/delete/" + id );
  }

  generateTasks( id: number ) {
    return this.http.get(this.apiServer + "/api/kip/extratasks/generatetasks/" + id )
  }

}