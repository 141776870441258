// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#video {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}

.webcam-wrapper {
  display: inline-block;
  position: relative;
  line-height: 0;
  /* width: 100% !important;
  height: 100% !important; */
 
}

#canvas {
  display: none;
}


.photo-button-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  text-align: center;
  align-content: center;
}
.photo-button {
  font-size: 80px;
  color: red;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
