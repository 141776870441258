// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { HotelLanguage } from '@app/kip/dto/HotelLanguage';
import { SysParamService } from '@app/shared/services/sysparam.service';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'data-localizer',
  templateUrl: './data-localizer.component.html',
  styleUrls: ['./data-localizer.component.scss']
})



export class DataLocalizerComponent implements OnInit {

  @Input()
  public get jsonData(): string {
    return this._jsonData;
  }
  public set jsonData(value: string) {
    if (value === undefined) {
      value = "{}";
    }
    this._jsonData = value;
    this.savedData = value;
    this.jsonObj = JSON.parse(value);
  }

  @Input()
  public get nameFieldContent(): string {
    return this._nameFieldContent;
  }
  public set nameFieldContent(value: string) {
    this._nameFieldContent = value;
  }



  @Output() translationChanged = new EventEmitter<string>();


  formGroup: FormGroup;

  private savedData: string;
  private jsonObj: any;
  private _jsonData: string;
  private _nameFieldContent: string;
  private markedLanguages: HotelLanguage[] = [];
  selectedLanguages: HotelLanguage[] = [];
  installedLanguages: HotelLanguage[] = [];




  constructor(
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private sysParamService: SysParamService,
    private fb: FormBuilder
  ) {

    this.formGroup = fb.group({})
  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.installedLanguages = this.sysParamService.installedLanguages();
    // Magyar nyelv eltávolítása, a magyar nevet a Description mező tartalmazza, nem a JSon
    this.installedLanguages.splice(this.installedLanguages.findIndex(item => item.Code == "hu"), 1)
    for (const key in this.jsonObj) {
      let found = this.installedLanguages.find(item => item.Code == key)
      if (found) {
        this.selectedLanguages.push(found);
      }

    }
    this.selectedLanguages.forEach(item => {
      this.formGroup.addControl("txt" + item.Code, new FormControl(this.jsonObj[item.Code]));
      this.formGroup.addControl("chk" + item.Code, new FormControl(false));
      this.formGroup.addControl("cbo" + item.Code, new FormControl(item.Code));
    })

  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  addLanguage() {
    if (this.selectedLanguages.length < this.installedLanguages.length) {
      let missingLanguage = "";
      let boolNotFound: boolean = true;
      this.installedLanguages.forEach(item => {
        if (boolNotFound) {
          let found = this.selectedLanguages.find(ins => ins.Code == item.Code);
          if (!found) {
            missingLanguage = item.Code;
            boolNotFound = false;
          }
        }
      })
      let newItem = this.installedLanguages.find(item => item.Code == missingLanguage);
      this.formGroup.addControl("txt" + newItem.Code, new FormControl(''));
      this.formGroup.addControl("chk" + newItem.Code, new FormControl(false));
      this.formGroup.addControl("cbo" + newItem.Code, new FormControl(newItem.Code));
      this.formGroup.controls["chk" + newItem.Code].setValue(false);
      this.selectedLanguages.push(newItem);
    }
    this.rebuildJSONObj();
  }

  removeLanguage() {
    let index = 0;
    this.markedLanguages.forEach(item => {
      index = this.selectedLanguages.findIndex(fitem => fitem.Code == item.Code);
      this.selectedLanguages.splice(index, 1);
    })
    this.markedLanguages = [];
    this.rebuildJSONObj()
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  addLanguageDisabled(): boolean {
    return this.installedLanguages.length == this.selectedLanguages.length ? true: false;
  }

  removeLanguageDisabled(): boolean {
    return this.markedLanguages.length == 0 ? true: false;
  }

  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  checkBoxName(hlng: HotelLanguage) {
    return "chk" + hlng.Code;
  }
  comboName(hlng: HotelLanguage) {
    return "cbo" + hlng.Code;
  }
  inputTextName(hlng: HotelLanguage) {
    return "txt" + hlng.Code;
  }
  checkChanged(event : MatCheckboxChange, hlng: HotelLanguage) {
    let index: number = 0;
    if (event.checked || !this.markedLanguages.find(item => item.Code == hlng.Code)) {
      this.markedLanguages.push(hlng);
    }
    else {
      index = this.markedLanguages.findIndex(item => item.Code == hlng.Code)
      this.markedLanguages.splice(index, 1);
    }
  }

  textChanged(event, hlng: HotelLanguage) {
    this.rebuildJSONObj();
  }
  rebuildJSONObj() {
    this._jsonData;
    this.jsonObj = {};

    this.selectedLanguages.forEach(item => {
      this.jsonObj[item.Code] = this.formGroup.controls["txt" + item.Code].value;
    })
    this._jsonData = JSON.stringify(this.jsonObj).toString();
    this.translationChanged.emit(this._jsonData);
  }
  //#endregion =================================================================================================================


}
