// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.week-day {
  font-size: 1.2em;
  font-weight: 400;
  /* opacity: 0.5; */
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
}

.weekend-day {
  font-size: 1.2em;
  font-weight: 500;
  /* opacity: 0.5; */
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
  color: #f35870;
}

.mwldraggable {
  font-weight: 500;
  background-color: white;
  margin: 3px 3px 3px 3px;
  padding: 1px 5px 0px 10px;
  transition: background-color 0.3s;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
}

.mwldraggable:hover {
  color: #2abdff;
  border-color: #2abdff;
}

.pencil-size {
  font-size: 2em;
  color: #0099de;
  margin-top: -0.2em;
  margin-bottom: -0.1em;
}

.pencil-size:hover {
  color: #2abdff;
}

.save-command-button {
  float: right;
  width: 68px;
  margin-left: 5px;
  margin-bottom: 30px !important;
  background-color: #41c300 !important;
}

.save-command-button:hover {
  background-color: #349d00 !important;
}

.hw-save-float-button {
  color: white;
  position: absolute;
  top: 0em;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 30px;
  font-family: "Roboto";
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
  background-color: #8bc34a;
}

.command-button {
  margin-bottom: 100px !important;
}

.calendar-update {
  text-align: left;
}

@media (max-width: 767px) {
  .calendar-update {
    text-align: center;
  }
}
@media screen and (max-width: 1279px) and (min-width: 768px) {
  .calendar-update {
    text-align: right;
  }
}
.mat-mdc-form-field-infix {
  min-height: 56px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
