export enum RoomReservationStatusNames {

  
  Empty = 'Empty',
  EarlyCheckin = 'EarlyCheckin',
  Checkin = 'Checkin',
  CheckedIn = 'CheckedIn',
  Occupied =  'Occupied',
  Checkout = 'Checkout',
  LateCheckout = 'LateCheckout',
  CheckedOut = 'CheckedOut'

}