import { LostAndFoundState } from "@app/kip/enums/LostAndFoundState";

export class LostAndFound {
  Id: number;
  Subscriber: string;
  HotelId : number;
  FoundTime: string;
  Area: string;
  FindObject: string;
  Finder: string;
  PhotoId: number;
  Status: LostAndFoundState;
  LastChange: string;



/*
        public int Id { get; set; }
        public string Subscriber { get; set; }
        public int HotelId { get; set; }

        public DateTime FoundTime { get; set; }
        public string Area { get; set; }
        public string FindObject { get; set; }
        public string Finder { get; set; }
        public int PhotoId { get; set; }
        public string Status { get; set; }  // F - felvéve, R - visszaadva, P - postázva, D - selejtezve
        public DateTime LastChange { get; set; }

*/

}
