// Angular
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataLocalizerComponent } from '@app/kip/components/data-localizer/data-localizer.component';

// 3rdparty

// Hostware
import { CleaningGroup } from '@app/kip/dto/CleaningGroup';
import { CleaningGroupService } from '@app/kip/services/cleaning-group.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'cleaning-group-editor-dialog',
  templateUrl: './cleaning-group-editor-dialog.component.html',
  styleUrls: ['./cleaning-group-editor-dialog.component.scss']
})
export class CleaningGroupEditorDialogComponent implements OnInit, OnDestroy {

  @ViewChild('localizer',{static:true}) localizer :DataLocalizerComponent


  loadAllCleaningGroups$ : Subscription;
  allCleaningGroups: CleaningGroup[]=[];
  editorForm: FormGroup;
  _data: CleaningGroup;

  constructor(public dialogRef: MatDialogRef<CleaningGroupEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: CleaningGroup,
    private cleaningGroupService: CleaningGroupService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this._data = data;
    this.editorForm = fb.group({
      "GroupName" : new FormControl(this.data.GroupName)
    });
  }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.loadAllCleaningGroups$ = this.cleaningGroupService.loadAll(this._data.Subscriber, this._data.HotelId).subscribe( result=> {
      this.allCleaningGroups = result as Array<CleaningGroup>;
   })
  
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  submit() {
    this._data.GroupName=this.editorForm.controls["GroupName"].value;

    this._data.SData = this.localizer.jsonData;
    this.dialogRef.close(this._data);
  }
  

  groupNameAlreadyExist( groupName: string) : boolean {
    return false;
  }

  title() : string {
    return this._data.GroupId === 0 ?   this.translate.instant("common.add_new_task_group"):   this.translate.instant("common.change_task_group")
  }
}
