

// Angular
import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
// 3rdparty
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { vwPublicArea } from '@app/kip/viewdto/vwdailytask/vwPublicArea';
import { RoleService } from '@app/shared/services/role.service';
import { PublicAreaStates } from '@app/shared/enums/public-area-states';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { vwCleaningType } from '@app/kip/viewdto/vwdailytask/vwCleaningType';
import HwUtils from '@app/shared/helpers/hwutils';
import { AddTaskQueryParam } from '@app/kip/classes/AddTaskParam';
import { AddTaskDialogComponent } from '@app/kip/dialogs/add-task-dialog/add-task-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormOperationMode } from '@app/core/enums/form-operation-mode';
import { RequestResult } from '@app/core/dto/RequestResult';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { vwTaskReadinessItem } from '@app/kip/viewdto/vwTaskReadinessItem';
import { SpinnerService } from '@app/core/services/spinner.service';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { TaskStatus } from '@app/kip/viewdto/taskStatus';

@AutoUnsubscribe()
@Component({
  selector: 'public-area-panel',
  templateUrl: './public-area-panel.component.html',
  styleUrls: ['./public-area-panel.component.scss']
})
export class PublicAreaPanelComponent implements OnInit, OnDestroy {
  updateTaskReadiness$: Subscription;
  updateTaskStatus$: Subscription;

  @Input() pSelectedPublicArea: vwPublicArea;
  @Output() panelClosed: EventEmitter<void> = new EventEmitter()
  constructor(
    public roleService: RoleService,
    private addTaskDialog: MatDialog,
    private crudMessageService: CrudMessagesService,
    private confirmService: HwConfirmationDialogService,
    private hwAlertService: HwAlertService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private dailyTaskService: DailyTaskService
  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {

  }
  ngOnDestroy(): void {

  }

  //#endregion =================================================================================================================

  //#region =============== Task events ======================================================================================
  accept(item: vwCleaningType) {
    var items: vwTaskReadinessItem[] = [];
    item.Tasks[0].TaskState = TaskStates.Accepted;
    item.Tasks[0].TimeSinceLastChange = 0;
    item.Tasks.forEach(task => {
      task.Accepted = true;

    })
    let itemToUpdate: vwTaskReadinessItem = new vwTaskReadinessItem();
    itemToUpdate.DateValue = dayjs().format("YYYY.MM.DD hh:mm").toString();
    itemToUpdate.ReadinessCode = TaskStates.Accepted;
    itemToUpdate.TypeId = item.Tasks[0].CleaningTypesId;
    items.push(itemToUpdate);
    this.updateTaskReadiness$ = this.dailyTaskService.updateTaskReadiness(items).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        this.crudMessageService.showAddOrEditMessage(res)
        if (res.Code === 0) {
          this.setCleaningTypeState(item, TaskStates.Accepted);

        }
      }
    })
  }
  delete(item: vwCleaningType) {
    this.updateTaskStatusByeTypeId(item.Tasks[0].CleaningTypesId, TaskStates.Deleted);
    
  }
  endCleaning(item: vwCleaningType) {
    this.setCleaningTypeState(item, TaskStates.Ended );
    this.updateTaskStatusByeTypeId(item.Tasks[0].CleaningTypesId,TaskStates.Ended);
  }

  modify(item: vwCleaningType) {
    if( !item) {
      return
    }
    let id: number;
    id = item.Tasks[0].CleaningTypesId;
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeEdit;
    addTaskParam.dailyTypeId = id;
    addTaskParam.placeNumber = this.pSelectedPublicArea.AreaCode 
    addTaskParam.placeTypeCode = "P";
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      height: "80vh",
      data: addTaskParam
    })
  
    dialogRef.afterClosed().subscribe( result=> {
      if(result) {
        const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
      }
    })
  }
  reAssign(item: vwCleaningType) {
    if( !item) {
      return
    }
    let id: number;
    id = item.Tasks[0].CleaningTypesId;
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeEdit;
    addTaskParam.dailyTypeId = id;
    addTaskParam.placeNumber = this.pSelectedPublicArea.AreaCode 
    addTaskParam.placeTypeCode = "P";
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      height: "80vh",
      data: addTaskParam
    })
  
    dialogRef.afterClosed().subscribe( result=> {
      if(result) {
        const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
      }
    })
  }
  startCleaning(item: vwCleaningType) {
    this.setCleaningTypeState(item, TaskStates.Running );
    this.updateTaskStatusByeTypeId(item.Tasks[0].CleaningTypesId,TaskStates.Running);
  }
  pauseCleaning(item: vwCleaningType) {
    this.setCleaningTypeState(item, TaskStates.Paused );
    this.updateTaskStatusByeTypeId(item.Tasks[0].CleaningTypesId,TaskStates.Paused);
  }

  //#endregion =================================================================================================================


  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  setToClean() {
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    var items: vwTaskReadinessItem[] = [];

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.set_all_task_to_complete"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.spinnerService.setSpinnerVisibility(true);
        this.pSelectedPublicArea.CleaningTypes.forEach(item => {
          if (HwUtils.HoursAndMinutesToMinutes(item.TypeTimeStr) < nowMinutes) {
            item.Tasks.forEach(task => {
              task.TaskState = TaskStates.Ended;
              task.TaskStartTime = dayjs().hour() * 60 + dayjs().minute();
              task.TaskEndTime = task.TaskStartTime;
            });
            let itemToUpdate: vwTaskReadinessItem = new vwTaskReadinessItem();
            itemToUpdate.DateValue = dayjs().format("YYYY.MM.DD hh:mm").toString();
            itemToUpdate.ReadinessCode = TaskStates.Ended;
            itemToUpdate.TypeId = item.CleaningTypeId;
            items.push(itemToUpdate);
          }
        });
        this.updateTaskReadiness$ = this.dailyTaskService.updateTaskReadiness(items).subscribe(result => {
          this.crudMessageService.showAddOrEditMessage((result as RequestResult));
        })

      }

    })

  }

  setToDirty() {
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    var items: vwTaskReadinessItem[] = [];

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.set_public_areas_to_dirty"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.spinnerService.setSpinnerVisibility(true);
        this.pSelectedPublicArea.CleaningTypes.forEach(item => {
          if (HwUtils.HoursAndMinutesToMinutes(item.TypeTimeStr) < nowMinutes) {
            item.Tasks.forEach(task => {
              task.TaskState = TaskStates.Ended;
              task.TaskStartTime = dayjs().hour() * 60 + dayjs().minute();
              task.TaskEndTime = task.TaskStartTime;
            });
            let itemToUpdate: vwTaskReadinessItem = new vwTaskReadinessItem();
            itemToUpdate.DateValue = dayjs().format("YYYY.MM.DD hh:mm").toString();
            itemToUpdate.ReadinessCode = TaskStates.Created;
            itemToUpdate.TypeId = item.CleaningTypeId;
            items.push(itemToUpdate);
          }
        });
        this.updateTaskReadiness$ = this.dailyTaskService.updateTaskReadiness(items).subscribe(result => {
          this.crudMessageService.showAddOrEditMessage((result as RequestResult));
        })
      }
    })
  }

  setToInspected() {
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    var items: vwTaskReadinessItem[] = [];

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.set_public_area_to_inspected"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.spinnerService.setSpinnerVisibility(true);
        this.pSelectedPublicArea.CleaningTypes.forEach(item => {
          if (HwUtils.HoursAndMinutesToMinutes(item.TypeTimeStr) < nowMinutes) {
            item.Tasks.forEach(task => {
              task.TaskState = TaskStates.Accepted;
              task.TaskStartTime = dayjs().hour() * 60 + dayjs().minute();
              task.TaskEndTime = task.TaskStartTime;
            });
            let itemToUpdate: vwTaskReadinessItem = new vwTaskReadinessItem();
            itemToUpdate.DateValue = dayjs().format("YYYY.MM.DD hh:mm").toString();
            itemToUpdate.ReadinessCode = TaskStates.Accepted;
            itemToUpdate.TypeId = item.CleaningTypeId;
            items.push(itemToUpdate);
          }
        });
        this.updateTaskReadiness$ = this.dailyTaskService.updateTaskReadiness(items).subscribe(result => {
          this.crudMessageService.showAddOrEditMessage((result as RequestResult));
        })
      }
    })
  }

  showAddTasks() {
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeAdd;
    addTaskParam.dailyTypeId = -1;
    addTaskParam.placeTypeCode = "P";
    addTaskParam.placeNumber = this.pSelectedPublicArea.AreaCode;
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      height: "80vh",
      data: addTaskParam
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
      }
    })
  }
  showChat() {

  }
  navigateBack() {
    this.panelClosed.emit();
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  publicAreaState() {

    let foundTime: boolean = false;
    let foundCleaningType: vwCleaningType;
    let foundIndex: number = 0;
    const nowMinutes: number = dayjs().hour() * 60 + dayjs().minute();
    if (this.pSelectedPublicArea.CleaningTypes.length == 1) {
      foundCleaningType = this.pSelectedPublicArea.CleaningTypes[0];
    }
    else {
      for (var i = 0; i < this.pSelectedPublicArea.CleaningTypes.length; i++) {
        if ((HwUtils.HoursAndMinutesToMinutes(this.pSelectedPublicArea.CleaningTypes[i].TypeTimeStr) > nowMinutes) && !foundTime) {
          foundTime = true;
          if (i > 0) {
            foundIndex = i - 1;
          } else {
            foundIndex = i;
          }

          foundCleaningType = this.pSelectedPublicArea.CleaningTypes[foundIndex];
        }
      }

    }
    if (foundCleaningType) {
      // if( foundIndex == this.pSelectedPublicArea.CleaningTypes.length-1 ) {  // utolsó a sorban, nincs több
      switch (foundCleaningType.Tasks[0].TaskState) {
        case TaskStates.Created:
          return PublicAreaStates.DIRTY;
        case TaskStates.Ended:
          return PublicAreaStates.CLEAN;
        case TaskStates.Accepted:
          return PublicAreaStates.ACCEPTED;
        case TaskStates.Deleted:
          return PublicAreaStates.CLEAN;
        default:
          return PublicAreaStates.DIRTY;


        // } else {  // vannak további időpontok
        //   if(foundCleaningType?.Tasks[0].TaskState == 'Created') {
        //     return PublicAreaStates.DIRTY;
        //   }  else      {
        //     return PublicAreaStates.CLEAN;
        //   }

      }

    }
    else {
      if (this.pSelectedPublicArea.CleaningTypes.length > 0) {
        if (this.pSelectedPublicArea.CleaningTypes[this.pSelectedPublicArea.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Deleted ||
          this.pSelectedPublicArea.CleaningTypes[this.pSelectedPublicArea.CleaningTypes.length - 1].Tasks[0].TaskState == TaskStates.Accepted) {
          return PublicAreaStates.CLEAN;
        } else {
          return PublicAreaStates.DIRTY;
        }
      } else {
        return PublicAreaStates.CLEAN;

      }

    }
  }
  setCleaningTypeState(item: vwCleaningType, newState: TaskStates) {
    let ct: vwCleaningType = this.pSelectedPublicArea.CleaningTypes.find(cleaningItem => cleaningItem.CleaningTypeId == item.CleaningTypeId)
    ct.Tasks.forEach(item => {
      item.TaskState = newState;
    });
  }
  updateTaskStatusByeTypeId(typeId: number, newState: TaskStates) {
    var items: TaskStatus[] = [];
    var item: TaskStatus = new TaskStatus();
    item.DateValue = dayjs().format("YYYY.MM.DD hh:mm:ss");
    item.StatusCode = newState;
    item.TypeId = typeId;
    items.push(item);
    this.updateTaskStatus$ = this.dailyTaskService.updateTaskStatus(items).subscribe(result => {
      var res = result as RequestResult;
      if (res.Code !== 0) {
        this.hwAlertService.error(this.translate.instant("common.error_while_changing"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      }
      else { 
        let messageExplained: string = "";
        switch (item.StatusCode) {
          case TaskStates.Deleted: {
            messageExplained = this.translate.instant("common.task_deleted");
          }
            break;
          case TaskStates.Ended: {
            messageExplained = this.translate.instant("common.task_ended");
          }
            break;
          case TaskStates.Paused: {
            messageExplained = this.translate.instant("common.task_pause");
          }
            break;
          case TaskStates.Running: {
            messageExplained = this.translate.instant("common.task_started");
          }
            break;
          case TaskStates.Accepted: {
            messageExplained = this.translate.instant("K450p.room_is_inspected");
          }
            break;
        }
        this.hwAlertService.success(messageExplained), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight });

      }
    })
  }

  formatMinutes( minutes : number) {
    let result : string = "";
    let hour : string = "";
    let min : string = "";
    if( minutes >= 60 && minutes < 1440) {
      hour = Math.floor(minutes / 60 ).toString();
      min = (minutes%60).toString();
    }
    else {
      min = minutes.toString();
      hour = "00";
    }
    return HwUtils.leftPad(parseInt(hour),2)+  ":" + HwUtils.leftPad(parseInt(min),2);
  }

  taskStatusIcon( item: vwCleaningType) {
   
    switch ( item.Tasks[0].TaskState) {
      case  TaskStates.Created : {
        return {
          'mdi mdi-checkbox-blank-circle-outline': true,
          'task-created': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Deleted : {
        return {
          'mdi mdi-close-circle-outline': true,
          'task-deleted': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Ended : {
        return {
          'mdi mdi-radiobox-marked': true,
          'task-ended': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Paused : {
        return {
          'mdi mdi-pause-circle-outline': true,
          'task-pause': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Running : {
        return {
          'mdi mdi-play-circle-outline': true,
          'task-play': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Accepted : {
        return {
          'mdi mdi-check-all': true,
          'task-accepted': true,
          'room-state-icon': true
        }
      }
    }
   }
  //#endregion =================================================================================================================




}
