export class VwNewDailyTask {
  Subscriber : string;
  HotelId : number;
  AreaNumber: number;
  AreaTypeCode : string;        // 'R' szoba 'P' közösségi tér
  TypeCode: string;             // 'M' maintenance 'C' cleaning 'O' Common 'X' Extra
  TypeId : number ;             // feladattípus ID ja
  DueDate : string;
  UserId: number;
  SupervisorId : number;
  Comment : string;
  DailyTypeId: number;
}
