// import { SidebarComponent } from '../core/components/app-sidebar/app-sidebar.component';
// import { NotFoundComponent } from './components/not-found/not-found.component';
/*import { Router, NavigationEnd, NavigationStart, NavigationError, NavigationCancel } from '@angular/router';

import { AuthService } from './services/auth.service';
import { TopBarManagerService } from './services/topbar-manager.service';
import { SessionStorageService } from './services/session-storage.service';*/
// import { AvatarModule } from 'ngx-avatar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../environments/environment';
// import { TopbarComponent } from './components/app-topbar/app-topbar.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing-module';
import { MenuService } from './services/menu.service';
import { ModuleSelectionService } from './services/module-selection.service';
import { HwAuthInterceptorService } from './services/hw-auth-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import { ResourceService } from './services/resource.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UserService } from './services/user.service';
import { MaterialModule } from './material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { TopbarComponent} from './components/topbar/topbar.component'
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HwAlertModule } from '@globalshared/hw-alert-component/src/lib';
import { HotelSelectionComponent } from '@app/kip/components/hotel-selection/hotel-selection.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
export function createTranslateLoader(http: HttpClient) {
  let  httpServer = environment.httpHost;
  return new TranslateHttpLoader(http, httpServer + '/assets/i18n/', '.json');
}
@NgModule({
  imports: [
  
    MaterialModule,
    AngularFireModule,
    AngularFireMessagingModule,
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HwAlertModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader,
                useFactory:(createTranslateLoader),
                deps: [HttpClient] },
      isolate: true

    }),
  ],
  declarations: [
    TopbarComponent,
    SidebarComponent,
    ChangePasswordDialogComponent,
    HotelSelectionComponent,
  ],
  exports: [
    TopbarComponent,
    SidebarComponent,
    CoreRoutingModule,
    MaterialModule,
    AngularFireModule,
    AngularFireMessagingModule,
    MaterialModule
   
  ],
  providers: [
    MenuService,
    ModuleSelectionService,
    AuthGuard,
    ResourceService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HwAuthInterceptorService,
      multi: true
    }
  ],
  // entryComponents: [
  //   ChangePasswordDialogComponent,

  // ]
})


export class CoreModule {
}
