import { NgModule } from '@angular/core';
import { HwConfirmationDialogComponent } from './hw-confirmation-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [HwConfirmationDialogComponent],
  imports: [ 
    MatCardModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [HwConfirmationDialogComponent]
  
})
export class HwConfirmationDialogModule { }
