// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { RoomType } from '@app/kip/dto/RoomType';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { RoomTypeService } from '@app/kip/services/roomtype-service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { StyleHelperService } from '@app/kip/services/style-helper.service';

@AutoUnsubscribe()
@Component({
  selector: 'roomtype-editor',
  templateUrl: './roomtype-editor.component.html',
  styleUrls: ['./roomtype-editor.component.scss']
})
export class RoomtypeEditorComponent implements OnInit,OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;

  loadAllRoomTypes$: Subscription;
  hotelUnitChanged$: Subscription;

  editorList: RoomType[];
  tableColumns: string[] = ["RoomType", "RoomTypeName", "HKWeight", "NumberOfBed",  "NumberOfAddBed",'BedType','Active'];

  dataSource : MatTableDataSource<RoomType>
  
  
  constructor(
    private roomTypeService: RoomTypeService,
    private topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    public styleHelperService: StyleHelperService

  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.loadAll();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    });
  }

  ngOnDestroy(): void {
    this.loadAllRoomTypes$?.unsubscribe;
    this.hotelUnitChanged$?.unsubscribe;
  }
  
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    this.loadAllRoomTypes$ = this.roomTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        this.editorList = res.Data as Array<RoomType>;
        this.dataSource = new MatTableDataSource(this.editorList);
      }
    });
  }

  
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================



}
