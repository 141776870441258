import { Component, OnInit, OnChanges, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, HostListener, Input, SimpleChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ImageManagerService } from '@app/kip/services/image-manager.service';
import { ImageCaptureService } from '@app/kip/services/image-capture.service';

@Component({
  selector: 'image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.css']
})
export class ImageCaptureComponent implements OnInit, AfterViewInit, OnDestroy {
  _taskId: number;

  get taskId() {
    return this._taskId;
  }
  @Input() set taskId(value: number) {
    this._taskId = value;

  }


  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('photoinput') photoInput: ElementRef;
  @ViewChild('captureCanvas', { static: true }) captureCanvas: ElementRef;
  @ViewChild('previewCanvas', { static: true }) previewCanvas: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('back', { static: true }) back: ElementRef;

  @ViewChild('previewImg', { static: true }) previewImg: ElementRef;

  @Input()
  imageWidth: number;

  captureContext: CanvasRenderingContext2D;
  previewContext: CanvasRenderingContext2D;

  previewImageVisible: boolean = true;

  backImagePath;
  landscape: boolean;
  landscapeString: string = "hello"
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  screenRatio = this.screenHeight / this.screenWidth;

  capturedImage = new Image();
  capturedFile: File;

  approveLineVisible: boolean;
  videoWidth = 0;
  videoHeight = 0;
  capturedImageVisible: boolean;



  constructor(
    private breakPointObserver: BreakpointObserver,
    private uploadService: ImageManagerService,
    private imageCaptureService: ImageCaptureService
  ) { }

  ngOnDestroy(): void {

  }


  //#region Angular lifecycle events 
  ngOnInit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait

    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.landscape = true;
        this.landscapeString = "landscape";
      }
      if (state.breakpoints[Breakpoints.HandsetPortrait]) {
        this.landscape = false;
        this.landscapeString = "portrait"
      }
    })
    this.imageCaptureService.startCapture.subscribe(result => {
      this.photoInput.nativeElement.click();
    })

  }  

  ngAfterViewInit(): void {
    this.captureContext = this.captureCanvas.nativeElement.getContext("2d");
    this.previewContext = this.previewCanvas.nativeElement.getContext("2d");

  }
  //#endregion


  //#region capture functions

  async capture() {
    this.approveLineVisible = false;
    this.capturedImage = new Image();
    let fl: FileList = this.photoInput.nativeElement.files;
    this.capturedImage.onload = () => this.drawImageScaled(this.capturedImage);
    if (fl) {
      this.capturedImage.src = URL.createObjectURL(fl[0])
      this.capturedFile = fl[0];
    }
    //this.captureCanvas.nativeElement.style.visibility = "hidden"
  }

  private drawImageScaled(img: any): void {
    this.previewCanvas.nativeElement.style.visibility = 'visible';
    let imageRatio: number = 1
    imageRatio = img.height / img.width;
    if (img.width < 1024) {
      this.imageWidth = img.width;
    } else {
      this.imageWidth = 1024;
    }

    let ratio: number;
    const width = this.wrapper.nativeElement.clientWidth;
    const height = this.wrapper.nativeElement.clientHeight;
    const hRatio = width / img.width;
    const vRatio = height / img.height;
    ratio = Math.min(hRatio, vRatio);
    if (ratio > 1) {
      ratio = 1;
    }
    this.captureCanvas.nativeElement.width = img.width * ratio;
    this.captureCanvas.nativeElement.height = img.height * ratio;
    this.previewCanvas.nativeElement.width = width;
    this.previewCanvas.nativeElement.height = width * imageRatio
    this.captureContext.clearRect(0, 0, width, height);
    this.captureContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * ratio, img.height * ratio);
    this.previewContext.clearRect(0, 0, this.imageWidth, this.imageWidth * imageRatio)
    this.previewContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, width * imageRatio)
    const dataUrl = this.previewCanvas.nativeElement.toDataURL();
    const base64Img = this.getBase64StringFromDataURL(dataUrl);
    this.imageCaptureService.imageCaptured.emit(base64Img)
    this.previewImageVisible = true;
    this.approveLineVisible = true;

  }


  getBase64StringFromDataURL(dataURL: string): string {
    const splitted: string[] = dataURL.split('/');

    return dataURL.replace('data:', '').replace(/^.+,/, '')
  }

  declineImage() {
    this.approveLineVisible = false;
    this.previewImageVisible = false;
    this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }
  approveImage() {
    this.approveLineVisible = false;
    const dataUrl = this.previewCanvas.nativeElement.toDataURL();
    const base64Img = this.getBase64StringFromDataURL(dataUrl);
    this.imageCaptureService.imageApproved.emit(base64Img)
    this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }

  //#endregion

  //#region control event handlers 

  //#endregion
}
