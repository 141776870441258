//--- Angular

import { NgModule } from '@angular/core';

import { ToSelectItemPipe } from './pipes/to-selectitem.pipe';
import { ChatService } from './services/chat.service';
import { ZeroIfNullPipe } from './pipes/zero-if-null.pipe';
import { sortBy } from './pipes/sortBy';

@NgModule({
  declarations: [
    ToSelectItemPipe,
    ZeroIfNullPipe,
    sortBy
  ],
  imports: [
 
  ],
  exports: [
    ToSelectItemPipe,
    ZeroIfNullPipe,
    sortBy
 
  ],
  providers: [
   ChatService,
   
  ],
})
export class SharedModule { }
