export enum ApplicationPage {
  module_selection = "HOT000",

  // KIP 
  fro_landing_page = "KIP001",
  kip_landing_page = "KIP004",
  common_problem_task_editor = "KIP005",
  common_problem_type_editor = "KIP006",
  service_area_editor = "KIP009",
  housekeeping_list = "KIP010",
  room_handler = "KIP14",



   

   //trustee-landing-page
   trustee_landing_page = "K100p1",
  
   //maintenance-leader-landing-page
   maintenance_leader_landing_page = "K100p3",
 
   //recepcionist-landing-page
   recepcionist_landing_page = "K100p5",

   //task-list-editor / edit-task / export-task-list
   task_list_editor = "K200p",

   //add-task
   add_task = "K201p",

   //room-state-list
   room_state_list = "K400p",

   //room-state-panel
   room_state_panel = "K450p",

   //ooo-list / ooo-room-export / ooo-edit-room

   //add-ooo-room
   

   //public-area-panel
   public_area_panel = "K650p",

   //trustee-scheduler
   trustee_scheduler = "K701p",

   //maintenance-scheduler
   maintenance_scheduler = "K705p",

   //minibar-editor / terhelés-lista K800p1 / terhelés K801p1 / export K803p1
   minibar_editor = "K800p",

   //usergroup-editor
   usergroup_editor = "K901p6",

   //user-settings-editor
   user_editor = "K903p6",

   //hotel-editor
   hotel_editor = "K904p6",

   // room-editor
   room_editor = "K905p6",

   //room_type-editor
   room_type_editor = "K906p6",

   // cleaning-task-editor
   cleaning_task_editor = "K907p6",

   // cleaning-type-roomstate-editor
   cleaning_type_roomstate_editor = "K908p6",

   // public-area-cleaning-type-editor
   public_area_cleaning_type_editor = "K909p6",

   // maintenance-group-editor
   maintenance_group_editor = "K910p6",

   // maintenance-type-editor
   maintenance_type_editor = "K911p6",

   //cleaning-type-editor
   cleaning_type_editor = "K912p6",

   //public-area-editor
   public_area_editor = "K913p6",

   // maintenance-task-editor
   maintenance_task_editor = "K914p61",

   //cleaning-group-editor
   cleaning_group_editor = "K915p6",

   //user-settings-container
   user_settings_container = "K901p6",

   //hotel-settings-editor / hotels K904p6 / roomtypes K905p6 / rooms K906p6 / public-areas K913p6
  hotel_settings_editor = "K921p6",

  //hotel-settings-container
  hotel_settings_container = "K1p6",
  
  subscriber_settings_editor= "K1p61",

  ooo_room_list = "K500p1",
  add_ooo_room = "K501p",
  modify_ooo_room = "K502p",
  room_journal = "K500p3",

  repeating_tasks = "K925",
  // HOT 
  hot_landing_page = "HOT001",
 

  task_scheduler_calendar="K950"

 





  

  // FRO 



}
