export enum ChipsTypes {
  DateInterval = "dateInterval",
  LabelValue = "labelValue",
  Label = "label",
  IconValue = "iconValue",
  RoomState = "roomState",
  TaskState = "taskState",
  TaskType = "taskType",
  RoomReservationState = "roomReservationState",
  MultiIcon = "multiIcon"
}