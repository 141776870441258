// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-tree-invisible {
  display: none;
}

.data-tree ul,
.data-tree li {
  margin-bottom: 0;
  list-style-type: none;
}

.hotel-selection {
  height: auto !important;
  width: auto !important;
}

.mat-button {
  margin: 0.5em !important;
}

.parent-span {
  font-weight: 600;
}

.mat-select {
  width: auto !important;
}

.mat-select-value {
  overflow: inherit !important;
}

.mat-select-trigger {
  width: max-content !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
