export enum TaskStates {

    Running ="Running",
    Paused = "Paused",
    Created="Created", 
    Ended = "Ended",
    Deleted = "Deleted",
    Ready  = "Ready",
    Accepted = "Accepted",
  
}
