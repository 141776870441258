// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brush-icon {
  font-size: 20px;
  color: #5aa0ba;
  padding-right: 5px;
  /* padding-left: 5px; */
}

.camera-icon {
  font-size: 20px;
  color: cornflowerblue;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.wrench-icon {
  font-size: 20px;
  padding-right: 5px;
  /*padding-left: 5px;*/
  color: #5aa0ba;
}

.wrapper {
  background-color: white;
}

.person-wrapper {
  background-color: #dfdfdf;
  border-radius: 5px;
  color: #3c3c3c;
  padding: 5px 10px 0px 10px;
  margin-right: 5px;
}

.task-play {
  font-size: 20px;
  color: #03a9f4;
}

.task-pause {
  font-size: 20px;
  color: #eeca7a;
}

.task-ended {
  font-size: 20px;
  color: #6aa546;
}

.task-deleted {
  font-size: 20px;
  color: #f30d0d;
}

.task-created {
  font-size: 20px;
  color: #e2835e;
}

.task-accepted {
  font-size: 20px;
  color: #6aa546;
}

.tasklist {
  margin-left: 0px;
  margin-right: 0px;
}

.striped {
  background-color: #efefef;
}

#camera-panel {
  visibility: hidden !important;
}

#result-list-wrapper {
  overflow-x: auto;
  height: 100%;
  position: relative;
  margin-top: 0em;
  scroll-padding: 0em 0em 2em 0em !important;
  background-color: white;
}

#result {
  padding-inline-start: 2px;
  margin: 0em 1.3em 0em 1.2em;
  background-color: white;
  padding: 0.4em 2em 0.1em 2em;
}

.result-table {
  background-color: white;
  padding: 0.5em 2em 0em 2em;
  margin-left: 1.3em;
  margin-right: 1.3em;
  margin-top: 1em;
}

#editor-frame {
  margin: 0px 20px 0px 20px;
}

.task-border-bottom {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49), 0 1px 1px 0 rgba(0, 0, 0, 0.72), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.54), 0 0px 1px 0 rgba(0, 0, 0, 0.3), 0 2px 0px -4px rgba(0, 0, 0, 0.87);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.type-name-align {
  display: inline-block;
  overflow: hidden;
}

.assigned-to {
  background-color: #dfdfdf;
  border-radius: 5px;
  color: #3c3c3c;
  padding: 5px 10px 0px 10px;
  margin-right: 5px;
  height: 25px;
}

.assigned-inspector-text {
  font-size: 14px;
  padding-left: 0.5em;
  padding-top: 3px;
}

.trustee-hr-vertical {
  border-left: 3px solid #03a9f4;
  padding-left: 0px !important;
  background-color: white;
  margin-left: 1.1em;
  margin-right: 1.3em;
}

.cleaning-item-tasks-list {
  color: #03a9f4;
  padding-left: 1em;
}

@media screen and (max-width: 840px) {
  #editor-frame {
    margin: 0;
    background-color: white;
    margin-bottom: 5em;
  }
  #result {
    padding-inline-start: 2px;
    background-color: white;
    padding: 0.5em 0em 0em 0em;
  }
  .result-table {
    padding: 0.5em 0em 0em 0.5em;
    margin-left: 0em;
    margin-right: 0em;
  }
  .float-box {
    float: right;
  }
  .scroll-top-float-button, .scroll-top-float-button:hover {
    right: 6em;
    margin-bottom: 1em;
  }
  .command-button {
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 841px) {
  .float-box {
    float: left;
  }
}
.col-horizontal-center {
  margin-top: auto;
  margin-bottom: auto;
}

.unhandled-changes-title {
  font-weight: 500;
  font-size: 25px;
  color: #000;
  position: relative;
  margin-bottom: 2em;
  padding-bottom: 1em;
  text-align: center;
}

.repair-all-button {
  text-align: end;
  margin-right: 1em;
  margin-top: 2em;
  padding-bottom: 2em;
}

.break-tasks-text {
  overflow-wrap: break-word;
}

html {
  scroll-behavior: smooth !important;
}

.tasklist-labels-center {
  margin-top: auto;
  margin-bottom: auto;
}

.cleaning-item-box {
  padding-top: 5px;
  padding-bottom: 5px;
}

.full-screen-modal .mat-dialog-container {
  padding: 1em;
  width: 100%;
  height: 100%;
}

.not-ask-for-cleaning-icon {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.maintenance-pdf-export-icon {
  font-size: 30px;
}

.responsibiliy-export-icon {
  font-size: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
