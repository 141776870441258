import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-value',
  templateUrl: './icon-value.component.html',
  styleUrls: ['./icon-value.component.css']
})
export class IconValueComponent implements OnInit {

  @Input() iconClass: string;
  @Input() label: string;
  constructor() { }

  ngOnInit() {
  }
  iconClassName() {
    return this.iconClass;
  }
}
