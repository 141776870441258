// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 25px;
  padding-left: 40px;
  color: #fff;
  position: relative;
  margin-bottom: 0;
  padding-top: 90px;
}

.sub-title {
  font-family: "Roboto";
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  position: relative;
  padding-left: 40px;
}

@media screen and (max-width: 840px) {
  .title {
    padding-left: 0.9em;
    font-size: 17px;
    padding-top: 70px;
  }
  .sub-title {
    display: none;
  }
  .canvas {
    background-color: #03a9f4;
    height: 165px !important;
  }
}
.canvas {
  background-color: #03a9f4;
  height: 200px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
