import { Component, OnInit, Input } from '@angular/core';
import { ParamKeyValue } from '../../../classes/ParamKeyValue';
import { RoomState } from '../../../../shared/enums/roomstates';
import { RoomReservation } from '../../../dto/RoomReservation';
import { RoomReservationStatusNames } from '../../../enums/RoomReservationStatusNames';

@Component({
  selector: 'room-reservation-state-chips',
  templateUrl: './room-reservation-state-chips.component.html',
  styleUrls: ['./room-reservation-state-chips.component.css']
})
export class RoomReservationStateChipsComponent implements OnInit {

  @Input() paramKeyValue: ParamKeyValue[];
  label: string;
  isEmpty : boolean;
  isEarlyCheckin: boolean;
  isCheckin: boolean;
  isCheckedIn: boolean;
  isOccupied: boolean;
  isCheckout: boolean;
  isLateCheckout: boolean;
  isCheckedOut: boolean;


  constructor() { }

  ngOnInit() {
    this.label = this.paramKeyValue.find(item=>item.Key=="Label").Value;
    this.isEmpty = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.Empty).Value === 'true' ? true : false;
    this.isEarlyCheckin = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.EarlyCheckin).Value === 'true' ? true : false;
    this.isCheckin = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.Checkin).Value === 'true' ? true : false;
    this.isCheckedIn = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.CheckedIn).Value === 'true' ? true : false;
    this.isOccupied = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.Occupied).Value === 'true' ? true : false;
    this.isCheckout = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.Checkout).Value === 'true' ? true : false;
    this.isLateCheckout = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.LateCheckout).Value === 'true' ? true : false;
    this.isCheckedOut = this.paramKeyValue.find(item=>item.Key==RoomReservationStatusNames.CheckedOut).Value === 'true' ? true : false;
  }

  allSelected(): boolean {
    if( this.isEmpty && this.isEarlyCheckin && this.isCheckin && this.isCheckedIn
      && this. isOccupied && this.isCheckout  && this.isLateCheckout  && this.isCheckedOut)  {
        return true;
      }
      else {
        return false;
      }
    }
}
