// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-play {
  font-size: 22px;
  color: #03a9f4;
}

.task-pause {
  font-size: 22px;
  color: #eeca7a;
}

.task-ended {
  font-size: 22px;
  color: #6aa546;
}
.task-deleted {
  font-size: 22px;
  color: #f30d0d;
}
.task-created {
  font-size: 22px;
  color: #e2835e;
}
.task-accepted {
  font-size: 22px;
  color: #29CF6D !important;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
