// Angular
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RoomTypeService } from '@app/kip/services/roomtype-service';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

// Hostware
import { RequestResult } from '@app/core/dto/RequestResult';
import { RoomType } from '@app/kip/dto/RoomType';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import HwUtils from '@app/shared/helpers/hwutils';
import { DynamicControlDescriptor } from '@app/kip/classes/DynamicControlDescriptor';
import { ChipsTypes } from '@app/kip/enums/ChipsTypes';
import { RoomReservationStatusNames } from '@app/kip/enums/RoomReservationStatusNames';
import { ActivatedRoute } from '@angular/router';
import {FormStateService} from '@kip/services/form-state.service'
import { vwRoomStateListFilter } from '@app/kip/viewdto/vwRoomStateListFilter';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
@AutoUnsubscribe()
@Component({
  selector: 'roomstate-list-filter',
  templateUrl: './roomstate-list-filter.component.html',
  styleUrls: ['./roomstate-list-filter.component.scss']
})
export class RoomstateListFilterComponent implements OnInit, OnDestroy {
  @Output() filterConditionsChanged : EventEmitter<RoomStateFilterConditions> = new EventEmitter()

  loadAllRoomTypes$ : Subscription;
  hotelUnitChanged$ : Subscription;

  dynamicControlDescriptor: DynamicControlDescriptor[] = [];
  tmpDynamicControlDescriptor: DynamicControlDescriptor[] = [];
  roomReservationStateCaptions: string[] = [];
  roomTypes: RoomType[]=[];
  filterForm: FormGroup;
  constructor( 
    private roomTypeService : RoomTypeService,
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private rfHelper: ReactiveFormsHelperService,
    private topbarManagerService: TopBarManagerService,
    private fb: FormBuilder,
    private formStateService: FormStateService
  ) { 

    
    this.filterForm = fb.group({
      'fRoomEmpty': new FormControl(true),
      'fEarlyCheckin': new FormControl(true),
      'fCheckin': new FormControl(true),
      'fChecked': new FormControl(true),
      'fOccupied': new FormControl(true),
      'fCheckout': new FormControl(true),
      'fLateCheckout': new FormControl(true),
      'fCheckedOut': new FormControl(true),
      'fRoomDirty': new FormControl(true),
      'fRoomClean': new FormControl(true),
      'fRoomOk': new FormControl(true),
      'fRoomOOO': new FormControl(true),
      'fRoomOOS': new FormControl(true),
      'fRoomShowRoom': new FormControl(true),
      'fRoomNumber': '',
      'fFloor': '',
      'fRoomType': '-1'
    })
  }
  
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
      this.loadAll();
      this.hotelUnitChanged$ = this.topbarManagerService.hotelUnitChanged.subscribe(event => {
        this.loadAll();
      })
  }
  ngOnDestroy(): void {
   this.loadAllRoomTypes$?.unsubscribe();
   this.hotelUnitChanged$?.unsubscribe();

  }
  

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  filterChanged() {
    let conditions : RoomStateFilterConditions = new RoomStateFilterConditions();
    this.rfHelper.updateRecordFromForm(conditions,this.filterForm);
    this.filterConditionsChanged.emit(conditions);
    this.dynamicControlDescriptor = [];
    this.tmpDynamicControlDescriptor = [];


    let desc: DynamicControlDescriptor = new DynamicControlDescriptor();
   
    desc.parameters = [];
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.occupancy_status") })
    desc.controlType = ChipsTypes.RoomReservationState;
    desc.parameters.push({ Key: RoomReservationStatusNames.Empty, Value: this.filterForm.controls["fRoomEmpty"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.Checkin, Value: this.filterForm.controls["fCheckin"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.CheckedIn, Value: this.filterForm.controls["fChecked"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.Occupied, Value: this.filterForm.controls["fOccupied"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.Checkout, Value: this.filterForm.controls["fCheckout"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.LateCheckout, Value: this.filterForm.controls["fLateCheckout"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.CheckedOut, Value: this.filterForm.controls["fCheckedOut"].value.toString() });
    desc.parameters.push({ Key: RoomReservationStatusNames.EarlyCheckin, Value: this.filterForm.controls["fEarlyCheckin"].value.toString() });
    this.tmpDynamicControlDescriptor.push(desc);

    desc = new DynamicControlDescriptor();
    desc.parameters = [];
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.room_statues") });
    desc.controlType = ChipsTypes.RoomState;
    
    desc.parameters.push({ Key: "Dirty", Value: this.filterForm.controls["fRoomDirty"].value.toString() });
    desc.parameters.push({ Key: "Clean", Value: this.filterForm.controls["fRoomClean"].value.toString() });
    desc.parameters.push({ Key: "Ok", Value: this.filterForm.controls["fRoomOk"].value.toString() });
    desc.parameters.push({ Key: "OOO", Value: this.filterForm.controls["fRoomOOO"].value.toString() });
    desc.parameters.push({ Key: "OOS", Value: this.filterForm.controls["fRoomOOS"].value.toString() });
    desc.parameters.push({ Key: "ShowRoom", Value: this.filterForm.controls["fRoomShowRoom"].value.toString() });
    this.tmpDynamicControlDescriptor.push(desc);

   

    // ----------
    if (this.filterForm.controls["fRoomNumber"].value.length > 0) {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-door", Value: this.filterForm.controls["fRoomNumber"].value });
      this.tmpDynamicControlDescriptor.push(desc);
    }

    if (this.filterForm.controls["fFloor"].value.length > 0) {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-stairs", Value: this.filterForm.controls["fFloor"].value });
      this.tmpDynamicControlDescriptor.push(desc);
    }
    
    if (this.filterForm.controls["fRoomType"].value !== "-1") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [] //###
      desc.parameters.push({ Key: "mdi mdi-bed-empty", Value: this.roomTypes.find(item => item.RoomType == this.filterForm.controls["fRoomType"].value).RoomTypeName });
      this.tmpDynamicControlDescriptor.push(desc);
    }
    this.dynamicControlDescriptor = JSON.parse(JSON.stringify(this.tmpDynamicControlDescriptor));
   
   
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    this.loadAllRoomTypes$=this.roomTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
      .subscribe( result=> {
        const res = result as RequestResult;
        this.roomTypes = res.Data as Array<RoomType>
        this.roomTypes.unshift(
          {
            Id: -1,
            Subscriber: '',
            HotelId: 0,
            RoomType: '-1',
            RoomTypeName: this.translate.instant("common.all"),
            HKWeight: 0,
            Active: false,
            NumberOfAddBed: 0,
            NumberOfBed: 0,
            BedType: ''
          })
          this.filterChanged();
      })
  }

  roomTypeLabel(data : RoomType) {
    return data.RoomTypeName;
  }
  roomTypeValue(data : RoomType) {
    if (data.Id == -1) {
      return "";
    }
    else {
      return HwUtils.rightPadAlt255(data.RoomType, 6);
    }

  }

  getFilter() : vwRoomStateListFilter {
    let filter: vwRoomStateListFilter = new vwRoomStateListFilter();
    filter.RoomEmpty = this.filterForm.controls["fRoomEmpty"].value;
    filter.EarlyCheckin = this.filterForm.controls["fEarlyCheckin"].value;
    filter.Checkin = this.filterForm.controls["fCheckin"].value;
    filter.Checked = this.filterForm.controls["fChecked"].value;
    filter.Occupied = this.filterForm.controls["fOccupied"].value;
    filter.Checkout = this.filterForm.controls["fCheckout"].value;
    filter.LateCheckout = this.filterForm.controls["fLateCheckout"].value;
    filter.CheckedOut = this.filterForm.controls["fCheckedOut"].value;
    filter.RoomDirty = this.filterForm.controls["fRoomDirty"].value;
    filter.RoomClean = this.filterForm.controls["fRoomClean"].value;
    filter.RoomOk = this.filterForm.controls["fRoomOk"].value;
    filter.RoomOOO = this.filterForm.controls["fRoomOOO"].value;
    filter.RoomOOS = this.filterForm.controls["fRoomOOS"].value;
    filter.RoomShowRoom = this.filterForm.controls["fRoomShowRoom"].value;
    filter.RoomNumber = this.filterForm.controls["fRoomNumber"].value;
    filter.Floor = this.filterForm.controls["fFloor"].value;
    filter.RoomType = this.filterForm.controls["fRoomType"].value;
    return filter;

  }
  //#endregion =================================================================================================================

setFilter(filter : vwRoomStateListFilter) {
  this.filterForm.controls["fRoomEmpty"].setValue( filter.RoomEmpty);
  this.filterForm.controls["fEarlyCheckin"].setValue(filter.EarlyCheckin);
  this.filterForm.controls["fCheckin"].setValue(filter.Checkin);
  this.filterForm.controls["fChecked"].setValue( filter.Checked);
  this.filterForm.controls["fOccupied"].setValue(filter.Occupied);
  this.filterForm.controls["fCheckout"].setValue( filter.Checkout );
  this.filterForm.controls["fLateCheckout"].setValue(filter.LateCheckout);
  this.filterForm.controls["fCheckedOut"].setValue(filter.CheckedOut);
  this.filterForm.controls["fRoomDirty"].setValue(filter.RoomDirty);
  this.filterForm.controls["fRoomClean"].setValue(filter.RoomClean);
  this.filterForm.controls["fRoomOk"].setValue(filter.RoomOk);
  this.filterForm.controls["fRoomOOO"].setValue(filter.RoomOOO);
  this.filterForm.controls["fRoomOOS"].setValue(filter.RoomOOS);
  this.filterForm.controls["fRoomShowRoom"].setValue(filter.RoomShowRoom);
  this.filterForm.controls["fRoomNumber"].setValue(filter.RoomNumber);
  this.filterForm.controls["fFloor"].setValue(filter.Floor);
  this.filterForm.controls["fRoomType"].setValue(filter.RoomType);
  this.filterChanged();
  }

  selectAllReservationStates() {
    this.filterForm.controls["fRoomEmpty"].setValue(true);
    this.filterForm.controls["fEarlyCheckin"].setValue(true);
    this.filterForm.controls["fCheckin"].setValue(true);
    this.filterForm.controls["fChecked"].setValue(true);
    this.filterForm.controls["fOccupied"].setValue(true);
    this.filterForm.controls["fCheckout"].setValue(true);
    this.filterForm.controls["fLateCheckout"].setValue(true);
    this.filterForm.controls["fCheckedOut"].setValue(true);
    this.filterChanged();
  }

  selectAllRoomStatus() {
    this.filterForm.controls["fRoomDirty"].setValue(true);
    this.filterForm.controls["fRoomClean"].setValue(true);
    this.filterForm.controls["fRoomOk"].setValue(true);
    this.filterForm.controls["fRoomOOO"].setValue(true);
    this.filterForm.controls["fRoomOOS"].setValue(true);
    this.filterForm.controls["fRoomShowRoom"].setValue(true);
    this.filterChanged();
  }
}

 

export class RoomStateFilterConditions {
  fRoomEmpty: boolean;
  fEarlyCheckin: boolean;
  fCheckin: boolean;
  fChecked:boolean;
  fOccupied: boolean;
  fCheckout: boolean;
  fLateCheckout: boolean;
  fCheckedOut: boolean;
  fRoomDirty: boolean;
  fRoomClean: boolean;
  fRoomOk: boolean;
  fRoomOOO: boolean;
  fRoomOOS: boolean;
  fRoomShowRoom: boolean;
  fRoomNumber: string;
  fFloor: number;
  fRoomType: string;
}
