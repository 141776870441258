import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment'
import { RepeatingTask } from '../dto/RepeatingTasks';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RepeatingTasksService {

constructor(private http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;
  loadAll(subscriber : string, hotelId : number, taskType : string) : Observable<any> {
    return  this.http.get(this.apiServer + '/api/kip/repeatingtask/LoadAll/' + subscriber + "/" + hotelId + "/" + taskType);
  }

  update( data: RepeatingTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/repeatingtask/Update', data, { headers });
  }

  add( data: RepeatingTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/repeatingtask/add', data, { headers });
  }
  
  delete( ids: string) {
    return this.http.delete(this.apiServer + '/api/kip/repeatingtask/Delete/' + ids);
  }
}
