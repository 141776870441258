import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { RoleType } from '../enums/roles';
import { KipUserService } from '../../kip/services/kip-user.service';
import { Subscription } from 'rxjs';
import { RequestResult } from '../../core/dto/RequestResult';
import { vwKipUserRoles } from '../../kip/viewdto/vwKipUserRoles';
import { RoleHelperService } from '../..//kip/services/role-helper.service';
import { TopBarManagerService } from '../../core/services/topbar-manager.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private kipUserRoles : vwKipUserRoles[];
  allowedActions: string[] = [];
  loadUsersWithRoles$ : Subscription;
 
constructor(
  private sessionStorageService:  SessionStorageService,
  private kipUserService : KipUserService,
  private topBarManagerService: TopBarManagerService,
  
  private roleHelperService : RoleHelperService
  ) {
    this.topBarManagerService.hotelUnitChanged.subscribe( event=> {
      this.loadRoles();
    })
    if( sessionStorageService.getSelectedUnit() !== null) {
      this.loadRoles();

    }
    //this.allowedActions.push("KIP0001"); 
 }

  loadRoles() {
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        if (this.sessionStorageService.getSelectedHotelId() === 99) { //   99,1,4,10 
          units = this.sessionStorageService.getSelectedUnitMulti();
          hotels = this.sessionStorageService.getSelectedHotelIdMulti();
        } else { // 99, és a konkrét szálloda
          const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
          units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
          hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
        }
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    this.loadUsersWithRoles$ = this.kipUserService.loadUsersWithRolesMulti (units,hotels).subscribe(ugResult=> {
      let res = ugResult as RequestResult;
      //var tr : vwKipUserRoles[]  = res.Data  as vwKipUserRoles[];
      this.kipUserRoles =  res.Data  as vwKipUserRoles[];

    });
  }
  hasRight( actionId: string) {
    return this.allowedActions.findIndex(item=>item == actionId)== -1 ?  false : true;
  }

  userIsInRole( roleString : string) {
    const userInfo = this.sessionStorageService.getUserInfo();
    if( !userInfo) {return false}
    let rolesArr : string[] = [];
    if( userInfo["Roles"]) {
      rolesArr =  userInfo["Roles"] as Array<string>;
    }
    return rolesArr.findIndex(item=>item == roleString)== -1 ?  false : true;
  }

  isUserInRole(userName: string, role: RoleType) : boolean {
    let roleFound: boolean = false; 
    this.kipUserRoles.forEach( item=> {
      if( item.UserName == userName &&  this.roleHelperService.hasRole(item.Role,  role)) {
          roleFound = true;
        }
      })
      return roleFound;
    }

    isInTrusteeRole() {
      const userInfo = this.sessionStorageService.getUserInfo();
      let rolesArr : string[] = [];
      if( userInfo["Roles"]) {
        rolesArr =  userInfo["Roles"] as Array<string>;
      }
      return rolesArr.findIndex(item=>item == "Gondnoknő")== -1 ?  false : true;
    }
    isInMaidRole() {
      const userInfo = this.sessionStorageService.getUserInfo();
      let rolesArr : string[] = [];
      if( userInfo["Roles"]) {
        rolesArr =  userInfo["Roles"] as Array<string>;
      }
      return rolesArr.findIndex(item=>item == "Takarító")== -1 ?  false : true; 
    }
  
    isInLeaderRole() {
      const userInfo = this.sessionStorageService.getUserInfo();
      let rolesArr : string[] = [];
      if( userInfo["Roles"]) {
        rolesArr =  userInfo["Roles"] as Array<string>;
      }
      return rolesArr.findIndex(item=>item == "Műszaki vezető")== -1 ?  false : true; 
    }
  
    isInTechnicalRole() {
      const userInfo = this.sessionStorageService.getUserInfo();
      let rolesArr : string[] = [];
      if( userInfo["Roles"]) {
        rolesArr =  userInfo["Roles"] as Array<string>;
      }
      return rolesArr.findIndex(item=>item == "Műszakos")== -1 ?  false : true; 
    }
  
    isInRecepcionistRole() {
      const userInfo = this.sessionStorageService.getUserInfo();
      let rolesArr : string[] = [];
      if( userInfo["Roles"]) {
        rolesArr =  userInfo["Roles"] as Array<string>;
      }
      return rolesArr.findIndex(item=>item == "Recepciós")== -1 ?  false : true; 
    }
  }

  // Tehát Bárki vehet fel bármilyen feladatot, DE Az ellenort korlatozzuk teruletenkent. Hk feladathoz gn, karbihoz mv valaszthato.