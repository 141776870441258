import { Injectable } from '@angular/core';
import { HotelLanguage } from '@app/kip/dto/HotelLanguage';


@Injectable({
  providedIn: 'root'
})
export class SysParamService {

constructor() { }

  installedLanguages() {
    let languages : HotelLanguage[] = [];
    let language: HotelLanguage ;
    language = new HotelLanguage();
    language.Code ="hu"
    language.Name ="Magyar";
    languages.push(language);
    language = new HotelLanguage();

    language.Code ="en"
    language.Name ="English";
    languages.push(language);
    
    language = new HotelLanguage();
    language.Code ="ua"
    language.Name ="Ukrainen";
    languages.push(language);

    language = new HotelLanguage();
    language.Code ="de"
    language.Name ="Deutsch";
    languages.push(language);
    
    language = new HotelLanguage();
    language.Code ="es"
    language.Name ="Espanol";
    languages.push(language);
    
    language = new HotelLanguage();
    language.Code ="th"
    language.Name ="Thai";
    languages.push(language);
    


    return languages;
  }

  installedLanguageCodes() {
     let languages : string[]=["hu","en","ua","de","es","th"];
     return languages;
  }
}
