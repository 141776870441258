// Angular
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
// 3rdparty
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import dayjs from 'dayjs';

// Hostware

import { CleaningType } from '@app/kip/dto/CleaningType';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { RoomReservation } from '@app/kip/dto/RoomReservation';
import { FormStateService } from '@app/kip/services/form-state.service';
import { OooRoomService } from '@app/kip/services/ooo-room.service';
import { RoomService } from '@app/kip/services/room.service';
import { vwRoomState } from '@app/kip/viewdto/vwdailytask/vwRoomState';
import { RoleService } from '@app/shared/services/role.service';
import { AddTaskService } from '@app/kip/services/add-task.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { RoomState } from '@app/shared/enums/roomstates';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { vwRoomHkStatus } from '@app/kip/viewdto/vwRoomHkStatus';
import { HouseKeepingStatus } from '@app/kip/enums/HKStatuses';
import { RequestResult } from '@app/core/dto/RequestResult';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { vwRoomStateListFilter } from '@app/kip/viewdto/vwRoomStateListFilter';
import { RoomStateTransition } from '@app/shared/enums/roomstatetransitions';
import { AddTaskQueryParam } from '@app/kip/classes/AddTaskParam';
import { FormOperationMode } from '@app/core/enums/form-operation-mode';
import { KIPFORM } from '@app/kip/enums/KipForms';
import { vwCleaningType } from '@app/kip/viewdto/vwdailytask/vwCleaningType';
import { AddTaskDialogComponent } from '@app/kip/dialogs/add-task-dialog/add-task-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { MinibarDialogComponent } from '@app/kip/dialogs/minibar-dialog/minibar-dialog.component';
import { MinibarService } from '@app/kip/services/minibar.service';
import { RoomStateListService } from '@app/kip/services/room-state-list.service';


@AutoUnsubscribe()
@Component({
  selector: 'room-state-panel',
  templateUrl: './room-state-panel.component.html',
  styleUrls: ['./room-state-panel.component.scss']
})
export class RoomStatePanelComponent implements OnInit, OnDestroy {

  @Input() pSelectedRoomState: vwRoomState;
  @Input() cleaningTypes: CleaningType[];
  @Input() maintenanceType: MaintenanceType[];
  @Input() froDatum: string;
  @Input() pvwRoomStateListFilter: vwRoomStateListFilter;
  @Output() panelClosed: EventEmitter<vwRoomState | undefined> = new EventEmitter();

  getByRoomNumber$: Subscription;
  removeOOORoom$: Subscription;
  loadOneRoomState$ : Subscription;
  
  constructor(
    public roleService: RoleService,
    private sessionStorageService: SessionStorageService,
    private oooRoomService: OooRoomService,
    private roomService: RoomService,
    private translate: TranslateService,
    private formStateService: FormStateService,
    private minibarService: MinibarService,
    private roomStateListService: RoomStateListService,
    private confirmService: HwConfirmationDialogService,
    private hwAlertService: HwAlertService,
    private router: Router,
    private addTaskDialog: MatDialog ,
    private miniBarDialog: MatDialog,
    private crudMessageService: CrudMessagesService,
 
      
  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    //console.log(this.pSelectedRoomState?.RoomNumber)
  }
  ngOnDestroy(): void {
    this.getByRoomNumber$?.unsubscribe();
    this.removeOOORoom$?.unsubscribe();
    this.loadOneRoomState$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  /* status sunctions */
  setToDirty() {
    this.setRoomState(HouseKeepingStatus.Dirty, "D", "set_room_to_dirty");
  }
  setToClean() {
    this.setRoomState(HouseKeepingStatus.Clean, "C", "set_room_to_clear");
  }

  setToInspected() {
    this.setRoomState(HouseKeepingStatus.Accepted, "I", "set_room_to_inspected");
  }
  setToOOO() {
    const url: string = this.router.url;
    const roomNumber: string = this.pSelectedRoomState.RoomNumber;
    this.formStateService.setVwRoomStateListFilter(this.pvwRoomStateListFilter);
    // this.zone.run(() => {
    //   this.router.navigateByUrl(this.router.createUrlTree(["kip/oooroomadd"], { queryParams: { returnUrl: url, roomNumber: roomNumber } }))
    // })
  }

  setToOOS() {
    this.setRoomState(HouseKeepingStatus.OOS, "C", "set_room_to_out_of_service");
  }

  setToShowRoom() {
    this.setRoomState(HouseKeepingStatus.ShowRoom, "C", "set_room_to_showroom");
  }

  showChat() {

  }

  showAddTasks() {
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeAdd;
    addTaskParam.dailyTypeId = -1;
    addTaskParam.placeTypeCode = "R";
    addTaskParam.placeNumber = this.pSelectedRoomState.RoomNumber;
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      panelClass: "add-task-dialog",
      // height: "80vh",
      data: addTaskParam
    })   

    dialogRef.afterClosed().subscribe( result=> {
      if(result) {
         const res = result as RequestResult;
         this.crudMessageService.showAddOrEditMessage(res);
         if( res.Code == 0) {
            // újratöltés
          this.loadOneRoomState$ = this.roomStateListService.loadRoomStates(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId()).subscribe( result=> {
            const res = result as RequestResult;
            const rec = res.Data.VwRoomStateList as Array<vwRoomState>;
            this.pSelectedRoomState = rec. find( item=> item.RoomNumber== this.pSelectedRoomState.RoomNumber);
          })
         }
      }
     
    


    })
    //const url: string = this.router.url;

    // this.zone.run(() => {
    //   let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    //   addTaskParam.returnUrl = url;
    //   addTaskParam.mode = FormOperationMode.OperationModeAdd;
    //   addTaskParam.dailyTypeId = -1;
    //   addTaskParam.placeTypeCode = "R";
    //   addTaskParam.placeNumber = this.pSelectedRoomState.RoomNumber;
    //   this.formStateService.setAddTaskCaller(KIPFORM.fRoomStatePanel);
    //   this.formStateService.setVwRoomStateListFilter(this.pvwRoomStateListFilter);
    //   this.formStateService.setSelectedRoomNumber(this.pSelectedRoomState.RoomNumber);
    //   this.router.navigateByUrl(this.router.createUrlTree(["kip/addtask"], { queryParams: addTaskParam }))
    // })


  }
  setRoomState(newStateCode: HouseKeepingStatus, oooState: string, confirmMessage: string) {
    if (this.pSelectedRoomState.RoomStateCode == RoomState.OUT_OF_ORDER) {
      this.removeFromOOO(oooState);
    } else {
      const options: HwConfirmationDialogData = {
        cancelButtonCaption: this.translate.instant("common.no"),
        message: this.translate.instant("common." + confirmMessage),
        title: this.translate.instant("common.message"),
        okButtonCaption: this.translate.instant("common.yes")
      }
      this.confirmService.open(options);
      this.confirmService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          let hkStatus: vwRoomHkStatus = new vwRoomHkStatus();
          hkStatus.DateValue = this.froDatum;
          hkStatus.HkStatus = newStateCode// HouseKeepingStatus.Dirty;
          hkStatus.Szonum = this.pSelectedRoomState.RoomNumber;
          
          //this.pSelectedRoomState.RoomStateCode = newStateCode
          this.roomService.setRoomState(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), hkStatus).subscribe(result => {
            let res = result as RequestResult;
            if (res.Code == 0) {
              this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight })); 
          
              this.loadOneRoomState$ = this.roomStateListService.loadRoomStates(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId()).subscribe( result=> {
                const res = result as RequestResult;
                const rec = res.Data.VwRoomStateList as Array<vwRoomState>;
                this.pSelectedRoomState =rec.find( item=> item.RoomNumber == this.pSelectedRoomState.RoomNumber)
                
              })

              //this.panelClosed.emit(this.pSelectedRoomState);
              // this.panelClosed.emit(this.pSelectedRoomState);
            } else {
              this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
            }
          })
        } else {
          //this.panelClosed.emit(undefined)
        }
      })
    }

  }
  removeFromOOO(newState: string) {
    var isRoomClean: boolean;
    if (newState == "C" || newState == "S") {
      isRoomClean = true;
    }
    else {
      isRoomClean = false;
    }
    var itemToRemove: RoomReservation;
    this.getByRoomNumber$ = this.oooRoomService.getByRoomNumber(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this.pSelectedRoomState.RoomNumber).subscribe(result => {
      const res = result as RequestResult;
      if (res) {
        itemToRemove = res.Data as RoomReservation;
        //  OOO_Departure > FRO_DATUM
        if (dayjs(itemToRemove.Departure).isAfter(dayjs(this.froDatum))) {
          const options: HwConfirmationDialogData = {
            cancelButtonCaption: this.translate.instant("common.no"),
            message: this.translate.instant("common.are_you_sure_you_want_to_close_this_period_earlier_and_cancel_your_ooo_status"),
            title: this.translate.instant("common.message"),
            okButtonCaption: this.translate.instant("common.yes")
          }
          this.confirmService.open(options);
          this.confirmService.confirmed().subscribe(confirmed => {
            if (confirmed) {
              if (dayjs(itemToRemove.Arrival).isSame(dayjs(this.froDatum)) || dayjs(itemToRemove.Arrival).isBefore(dayjs(this.froDatum))) {
                this.removeOOORoom$ = this.oooRoomService.updateOOOReturn(itemToRemove.Id, "None", false).subscribe(result => {
                  var res = result as RequestResult;
                  if (res.Code == 0) {
                    if (res.Code == 0) {
                      this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
                    }
                    else {
                      this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
                    }
                  };
                })
              }
            }
          })
        }
        // OOO_Departure = FRO_DATUM
        if (dayjs(itemToRemove.Departure).isSame(dayjs(this.froDatum))) {
          const options: HwConfirmationDialogData = {
            cancelButtonCaption: this.translate.instant("common.no"),
            message: this.translate.instant("common.are_you_sure_you_want_to_terminate_your_ooo_status"),
            title: this.translate.instant("common.message"),
            okButtonCaption: this.translate.instant("common.yes")
          }
          this.confirmService.open(options);
          this.confirmService.confirmed().subscribe(confirmed => {
            if (confirmed) {
              if (dayjs(itemToRemove.Arrival).isBefore(dayjs(this.froDatum))) {
                this.removeOOORoom$ = this.oooRoomService.updateOOOReturn(itemToRemove.Id, "None", false).subscribe(result => {
                  var res = result as RequestResult;
                  if (res.Code == 0) {
                    if (res.Code == 0) {
                      this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
                    }
                    else {
                      this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
                    }
                  };
                })
              }
              if (dayjs(itemToRemove.Arrival).isSame(dayjs(this.froDatum))) {
                const options: HwConfirmationDialogData = {
                  cancelButtonCaption: this.translate.instant("common.no"),
                  message: this.translate.instant("common.need_to_add_a_cleaning_task"),
                  title: this.translate.instant("common.message"),
                  okButtonCaption: this.translate.instant("common.yes")
                }
                this.confirmService.open(options);
                this.confirmService.confirmed().subscribe(confirmed => {
                  if (confirmed) {
                    this.removeOOORoom$ = this.oooRoomService.updateOOOReturn(itemToRemove.Id, "None", true).subscribe(result => {
                      var res = result as RequestResult;
                      if (res.Code == 0) {
                        if (res.Code == 0) {
                          this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
                        }
                        else {
                          this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
                        }
                      };
                    })
                  }
                  else {
                    this.removeOOORoom$ = this.oooRoomService.updateOOOReturn(itemToRemove.Id, "None", false).subscribe(result => {
                      var res = result as RequestResult;
                      if (res.Code == 0) {
                        if (res.Code == 0) {
                          this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
                        }
                        else {
                          this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
                        }
                      };
                    })
                  }
                })
              }
            }
          })
        }
      }
    })
  }

  showMiniBar(st: vwRoomState) {
  
    const dialogRef = this.miniBarDialog.open(MinibarDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "40vw",
      panelClass: "add-task-dialog",
      // height: "80vh",
      data: st
    }) 

    dialogRef.afterClosed().subscribe( dialogResult=> {
      if( dialogResult) {
        const res = dialogResult as vwRoomState;
        this.minibarService.minibarCharge(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId(),
          res.RoomNumber,res.ReservationNumber,res.MinibarItems).subscribe( result=> {
            this.crudMessageService.showAddOrEditMessage(result as RequestResult);
          })
      }
    })
  }

  backToList() {
    this.panelClosed.emit(this.pSelectedRoomState);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  /* style helpers */

  isOccupiedStyle(item: vwRoomState) {
    let ret: boolean;
    let styles = {};

    if (!(
      (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR || item.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR
        || item.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR && item.CheckedIn
      )
      || (item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED || item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED)
      || (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY
        || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO && item.CheckedOut == false
      )
    )) {
      styles["pointer-events"] = "none";
      styles["opacity"] = "0.4";
      styles["filter"] = "grayscale(100%)";
    }

    // Lakó = (*_TO_ARR && CI) || (OCCUPIED || OCCUPIED_BED) || (DEP_TO_* && !CO)

    return styles;

  }
  isNotOccupiedStyle(item: vwRoomState) {
    let ret: boolean;
    let styles = {};

    if ((
      (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR || item.RoomStateTransitionCode == RoomStateTransition.OOO_TO_ARR
        || item.RoomStateTransitionCode == RoomStateTransition.EMPTY_TO_ARR && item.CheckedIn
      )
      || (item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED || item.RoomStateTransitionCode == RoomStateTransition.OCCUPIED_BED)
      || (item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_ARR || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_EMPTY
        || item.RoomStateTransitionCode == RoomStateTransition.DEP_TO_OOO && item.CheckedOut == false
      )
    )) {
      styles["pointer-events"] = "none";
      styles["opacity"] = "0.4";
      styles["filter"] = "grayscale(100%)";
    }

    // Lakó = (*_TO_ARR && CI) || (OCCUPIED || OCCUPIED_BED) || (DEP_TO_* && !CO)

    return styles;
  }

  minibarIconStyle(item: vwRoomState) {
    if (item.RoomReservationStateCode !== 'L') {
      let styles = {};
      styles["pointer-events"] = "none";
      styles["opacity"] = "0.4";
      styles["filter"] = "grayscale(100%)";
      return styles;
    }
  }

  taskStatusIcon( item: vwCleaningType) {
   
    switch ( item.Tasks[0].TaskState) {
      case  TaskStates.Created : {
        return {
          'mdi mdi-checkbox-blank-circle-outline': true,
          'task-created': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Deleted : {
        return {
          'mdi mdi-close-circle-outline': true,
          'task-deleted': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Ended : {
        return {
          'mdi mdi-radiobox-marked': true,
          'task-ended': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Paused : {
        return {
          'mdi mdi-pause-circle-outline': true,
          'task-pause': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Running : {
        return {
          'mdi mdi-play-circle-outline': true,
          'task-play': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Accepted : {
        return {
          'mdi mdi-check-all': true,
          'task-accepted': true,
          'room-state-icon': true
        }
      }
    }
   }
  close() {
    this.panelClosed.emit();
  }
  //#endregion =================================================================================================================


}
