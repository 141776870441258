import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType, ToastPosition } from './hw-alert.model';
import { HwAlertService } from './hw-alert.service';

@Component(
  { selector: 'hw-alert', 
    templateUrl: 'hw-alert.component.html',
    styleUrls : ['hw-alert.component.css']
  })
  
export class HwAlertComponent implements OnInit, OnDestroy {
    @Input() id = 'default-alert';
    @Input() fade = true;
   
    alerts: Alert[] = [];
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(private router: Router, private alertService: HwAlertService) {  }

    ngOnInit() {
     
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService.onAlert(this.id)
            .subscribe(alert => {
              
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

                    // remove 'keepAfterRouteChange' flag on the rest
                    this.alerts.forEach(x => delete x.keepAfterRouteChange);
                    return;
                }

                // add alert to array
                this.alerts.push(alert);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 3000);
                }
           });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        // check if already removed to prevent error on auto close
        if (!this.alerts.includes(alert)) return;

        if (this.fade) {
            // fade out alert
            this.alerts.find(x => x === alert).fade = true;

            // remove alert after faded out
            setTimeout(() => {
                this.alerts = this.alerts.filter(x => x !== alert);
            }, 250);
        } else {
            // remove alert
            this.alerts = this.alerts.filter(x => x !== alert);
        }
    }

    cssClass(alert: Alert) {
        if (!alert) return;
       
        const classes = ['alert', 'alert-dismissable'];
                
        const alertTypeClass = {
            [AlertType.Success]: 'hw-accepted-toast',
            [AlertType.Error]: 'hw-error-toast',
            [AlertType.Info]: 'hw-primary-toast',
            [AlertType.Warning]: 'hw-caution-toast'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }
        classes.push('ui-toast');
        classes.push('zindex_1000');
        switch(alert.position) {
          case ToastPosition.TopRight:
            classes.push('toast-top-right') 
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-top-left');
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-bottom-right');
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-bottom-left');
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-top-center');
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-bottom-center');
            break;
          case ToastPosition.BottomCenter:
            classes.push('toast-center');
            break;
          
        }

        return classes.join(' ');
    }

    iconClass(alert: Alert) {
        switch(alert.type) {
          case AlertType.Error:
            return "mdi mdi-close hw-padding-left-2em hw-pointer";
          case AlertType.Info:
            return "mdi mdi-close hw-padding-left-2em hw-pointer";
          case AlertType.Success:
            return "mdi mdi-close hw-padding-left-2em hw-pointer";
          case AlertType.Warning:
            return "mdi mdi-close hw-padding-left-2em hw-pointer";
        }
    };
    firsticonClass(alert: Alert) {
        switch(alert.type) {
          case AlertType.Error:
            return "mdi mdi-alert hw-padding-right-1em";
          case AlertType.Info:
            return "mdi mdi-information hw-padding-right-1em";
          case AlertType.Success:
            return "mdi mdi-check-bold hw-padding-right-1em";
          case AlertType.Warning:
            return "mdi mdi-alert hw-padding-right-1em";
        }
    }

}



