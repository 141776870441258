// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { RequestOptions, ResponseType } from "@angular/http";

//HW
import { environment } from "../../../environments/environment";
import { CleaningSchedule } from '../dto/CleaningSchedule';
import { VwNewDailyTask } from '../viewdto/vwNewDailyTask';
import { Observable } from 'rxjs';

import { vwDailyTask } from '../viewdto/vwdailytask/vwDailTask';
import { vwMaintenanceSchedule } from '../viewdto/vwMaintenanceSchedule';
import { vwTaskReadinessItem } from '../viewdto/vwTaskReadinessItem';
import { vwTaskListFilter } from '../viewdto/vwTaskListFilter';
import { ParamKeyValue } from '../classes/ParamKeyValue';
import { TaskDifferenceParam } from '../viewdto/vwTaskDiff/task-difference-param';
import { SessionStorageService } from '../../core/services/session-storage.service';




@Injectable({
  providedIn: 'root'
})
export class DailyTaskService {

  loadTaskStatesObservable: Observable<any>
  constructor(public http: HttpClient, private sessionStorageService : SessionStorageService) { }

  apiServer = environment.apiHost + environment.apiPort;

  getCleaningScheduleItems(subscriber: string, hotelIds: string, dateValue: string) {
    return this.http.get(this.apiServer + "/api/kip/DailyTask/cleaningschedule_multi/" + subscriber + "/" + hotelIds + "/" + dateValue);

  }
  
  getMaintenanceScheduleItems(subscriber: string, hotelIds: string, dateValue: string) {
   
    return this.http.get(this.apiServer + "/api/kip/DailyTask/MaintenanceSchedule_multi/" + subscriber + "/" + hotelIds + "/" + dateValue);
  }

  updateTaskStatus(data) {
    const subscriber = this.sessionStorageService.getSelectedUnit();
    const hotelId = this.sessionStorageService.getSelectedHotelId();

    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/UpdateTaskStatus/' +  subscriber + "/" + hotelId , data, { headers });
  }

  updateDailyTask(data:VwNewDailyTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/UpdateDailyTask', data, { headers });
  }
  
  updateTaskReadiness(data: vwTaskReadinessItem[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/UpdateTaskReadiness', data, { headers });
  }

  saveCleaningScheduleItems(data: CleaningSchedule[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/SaveCleaningSchedule', data, { headers });

  }
  saveMaintenanceScheduleItems(data: vwMaintenanceSchedule[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/SaveMaintenanceSchedule', data, { headers });

  }


  addDailyTask(data: VwNewDailyTask) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/AddDailyTask', data, { headers });
  }

  loadTaskStates( subscriber: string, hotelId: number, userId: number) : Observable<any> {

   return  this.http.get(this.apiServer + "/api/kip/DailyTask/LoadTaskStates/" +  subscriber + "/" + hotelId + "/" + userId);

  }

  getNumberOfTasks(subscriber: string, hotelId: number, userName: string) {
    return this.http.get(this.apiServer + '/api/kip/DailyTask/LoadMyTaskCount/' + subscriber + '/' + hotelId + '/' + userName);
  }
  loadOneTaskState(subscriber: string, hotelId: number, dailyTypeId: number) {
    return this.http.get(this.apiServer + '/api/kip/DailyTask/LoadOneTaskState/' + subscriber + '/' + hotelId + '/' + dailyTypeId);
  }

  getTaskListPdf(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetTaskListPdf/' + subscriber + '/' + hotelId , data, {responseType:'blob'});
  }
  getTaskListExcel(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetTaskListExcel/' + subscriber + '/' + hotelId , data, {responseType:'blob'});
  }

  getScheduleListPdf(subscriber: string, hotelId: number, data:vwTaskListFilter) {
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetScheduleListPdf/' + subscriber + '/' + hotelId , data, {responseType:'blob'});
  }

  setDontDisturb( dailytypeid : number,  setvalue : boolean) {
    const subscriber = this.sessionStorageService.getSelectedUnit();
    const hotelId = this.sessionStorageService.getSelectedHotelId();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(this.apiServer + '/api/kip/DailyTask/SetDont_Disturb/' + subscriber + '/' + hotelId  + "/" + dailytypeid , setvalue,httpOptions)
  }

  loadTaskDifferences( subscriber: string, hotelId: number)  {
    return this.http.get(this.apiServer +  '/api/kip/DailyTask/LoadTaskDifferences/' + subscriber + "/" + hotelId)
  }

  updateTaskDifferences( subscriber: string, hotelId: number, data: TaskDifferenceParam[]) {
    return this.http.put(this.apiServer +  '/api/kip/DailyTask/UpdateTaskDifferences/' + subscriber + '/' + hotelId , data )
  }

  
  loadTaskHistory(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/LoadTaskHistory/' + subscriber + '/' + hotelId , data, {headers})
  }

  getTaskHistoryPdf(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetTaskHistoryPdf/' + subscriber + '/' + hotelId , data,{responseType:'blob'})
  }

  getMaintenanceListPdf(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetMaintenanceListPdf/' + subscriber + '/' + hotelId , data,{responseType:'blob'})
  }

  getTaskByResponsibility(subscriber: string, hotelId: number,data: vwTaskListFilter) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/DailyTask/GetTaskListByUserPdf/' + subscriber + '/' + hotelId , data,{responseType:'blob'})
  }
}


/*

 xxxObservable: Observable<any>;

  constructor(private http: HttpClient) {}

  getSomeData(): Observable<any> {
    if (this.xxxObservable) {
      return this.xxxObservable;
    } else {
      this.xxxObservable = this.http.get<any>('some/endpoint').pipe(share());
      return this.xxxObservable;
    }
  }
*/