// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips-icon-size {
  font-size: 22px;
  padding-right: 10px;
} 

.showr-button-icon-color {
  color: #FED833;
}
.dirty-button-icon-color {
  color: #f35770;
}
.clean-button-icon-color {
  color: #03a9f4;
}
.checked-button-icon-color {
  color: #81c784;
}
.ooo-button-icon-color {
  color: #000000;
}

.oos-button-icon-color {
  color: #9750b9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
