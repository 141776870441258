import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable()
export class ResourceService  {  public get logo(): string {
  return 'assets/images/logo_2010.png';
}

public get loggedInRoute(): string {
  return '';
}

public get loggedOutRoute(): string {
  return 'login';
}

public get emailExistsRoute(): string {
  return 'emailexists';
}


public get resourceUrl(): string {
  return '/api/user/get';
}

public get userLanguageUrl(): string {
  return '/api/language/get';
}

public get userUnitUrl(): string {
  return '/api/user/unit/set';
}

public get userProfileUrl(): string {
  return '/api/user/set';
}

public get loginUrl(): string {
  return '/api/login';
}

public get logoutUrl(): string {
  return '/api/logout';
}



public get apiHost(): string {
  return '.';
}

public get apiPort(): string {
  return '';
}
public get apiUrl(): string {
  const sign: string = this.apiPort.length > 0 ? ':' : '';
  return `${this.apiHost}${sign}${this.apiPort}`;
}


public get refreshUrl(): string {
  return '/api/refresh';
}

}
