// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

// 3rdparty
import { combineLatest, Subject, Subscription ,takeUntil} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { vwPublicAreaCleaningType } from '@app/kip/viewdto/vwPublicAreaCleningType';
import { PublicAreaCleaningTypeService } from '@app/kip/services/public-area-cleaning-type.service';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { PublicAreaCleaningTypeEditorDialogComponent } from '@app/kip/dialogs/public-area-cleaning-type-editor-dialog/public-area-cleaning-type-editor-dialog.component';
import { PublicAreaCleaningType } from '@app/kip/dto/PublicAreaCleaningType';
import HwUtils from '@app/shared/helpers/hwutils';
import { TransitionCheckState } from '@angular/material/checkbox';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';


@Component({
  selector: 'public-area-cleaning-type-editor',
  templateUrl: './public-area-cleaning-type-editor.component.html',
  styleUrls: ['./public-area-cleaning-type-editor.component.scss']
})
export class PublicAreaCleaningTypeEditorComponent implements OnInit,OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  loaded: boolean;
  hotelUnitChanged$: Subscription;
  addAreaCleaning$ : Subscription;
  updateAreaCleaning$ : Subscription;
  deleteAreaCleaning$: Subscription;

  destroy$: Subject<boolean> = new Subject<boolean>();
  filterForm: FormGroup;
  editorList: vwPublicAreaCleaningType[];
  origList: vwPublicAreaCleaningType[];
  tableColumns = ['PublicAreaName','CleaningTypeName','PeriodHourStart','PeriodHourEnd','PeriodicityMinutes','Action']
  cleaningTypes: CleaningType[]=[];
  publicAreas: PublicArea[]=[];
  filterCaptions: string[];
  dataSource: MatTableDataSource<vwPublicAreaCleaningType>;
  
  constructor( 
    private topBarManagerService: TopBarManagerService,
    private publicAreaCleaningTypeService: PublicAreaCleaningTypeService,
    private publicAreaService: PublicAreaService,
    private cleaningTypeService: CleaningTypeService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private  publicAreaCleaningTypeDialog : MatDialog,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private fb: FormBuilder

    ) { 
      this.filterForm = fb.group({
        'fPublicAreaId': new FormControl(-1),
        'fCleaningTypeId': new FormControl(-1)
      });
    }
 


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() : void {
    this.hotelUnitChanged$=this.topBarManagerService.hotelUnitChanged.subscribe( event=> {
      this.loadAll();
    })
    this.loadAll()
  }
  ngOnDestroy(): void {
   this.hotelUnitChanged$?.unsubscribe();

   this.hotelUnitChanged$?.unsubscribe();
   this.addAreaCleaning$?.unsubscribe();
   this.updateAreaCleaning$?.unsubscribe();
   this.deleteAreaCleaning$?.unsubscribe();
   this.destroy$.next(true);
   this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  openDialog( action : string, data?:vwPublicAreaCleaningType) {
    let areaCleaning:vwPublicAreaCleaningType;
    if( action == OpenDialogAction.ADD) {
      areaCleaning = new vwPublicAreaCleaningType();
      areaCleaning.PublicAreaCleaningTypeId = null;
    } else {
      areaCleaning = data;
    }
    const areaCleaningDialogDef = this.publicAreaCleaningTypeDialog.open(PublicAreaCleaningTypeEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "80vw",
      panelClass: "cleaning-type-editor-dialog",
      data: areaCleaning
    })

    areaCleaningDialogDef.afterClosed().subscribe(data => {
      let item: PublicAreaCleaningType = new PublicAreaCleaningType() ;
      if(data) {
        const processableItem = data as vwPublicAreaCleaningType;
        item.PeriodHourEnd = processableItem.PeriodHourEnd;
        item.PeriodHourStart = processableItem.PeriodHourStart;
        item.PeriodMinEnd = processableItem.PeriodMinEnd;
        item.PeriodMinStart = processableItem.PeriodMinStart;
        item.PublicAreaId = processableItem.PublicAreaId;
        item.CleaningTypesId = processableItem.CleaningTypesId;
        item.PeriodicityMinutes = processableItem.PeriodicityMinutes;
 
        if ( processableItem.PublicAreaCleaningTypeId == null) {
          this.addAreaCleaning$ = this.publicAreaCleaningTypeService.addPublicAreaCleaningType(item).subscribe( result=> {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        } else {
          this.updateAreaCleaning$ = this.publicAreaCleaningTypeService.updatePublicAreaCleaningType(item).subscribe( result=> {
            this.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        }
       }
    })
  }
  deleteRow(data?:vwPublicAreaCleaningType) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteAreaCleaning$ = this.publicAreaCleaningTypeService.deletePublicAreaCleaningType(data.PublicAreaCleaningTypeId.toString()).subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.loadAll();
          } else {
            this.hwAlerService.error(this.translate.instant("common.error_while_deleting") ,new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight}));
          } 
         })
      }
    })
  }
  
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    const combinedSources = combineLatest({
      cleaningTypes: this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      publicAreas: this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      areasAndTypes: this.publicAreaCleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    }).pipe(takeUntil(this.destroy$)).subscribe(value=> {
      this.cleaningTypes = (value.cleaningTypes as RequestResult).Data as Array<CleaningType>;
      this.cleaningTypes.unshift({
        Active: true,
        CleaningGroupId: 0,
        HotelId: 0,
        Subscriber: '',
        Tasks: [],
        TypeCode: '',
        TypeId: -1,
        TypeName: this.translate.instant("common.all"),
        Weighting: true,
        SData: '',
        IsRoomClean: true,
        AutoAccepted: false
      })
      this.publicAreas = (value.publicAreas as RequestResult).Data as Array<PublicArea>;
      this.publicAreas.unshift({
        PublicAreaId: -1,
        AreaName: this.translate.instant("common.all"),
        Active: true,
        HotelId: 0,
        AreaCode: '',
        Subscriber: ''})
      this.editorList = (value.areasAndTypes as RequestResult).Data as Array<vwPublicAreaCleaningType>;
      this.origList = (value.areasAndTypes as RequestResult).Data as Array<vwPublicAreaCleaningType>;
      this.dataSource = new MatTableDataSource(this.editorList)
      this.dataSource.sort = this.sort;
      this.loaded = true;
      this.applyFilter();
    })
  
  }

  applyFilter() {
    var tmpArr: vwPublicAreaCleaningType[] = [];
    var mustInclude: boolean;
    this.editorList=[];
    this.filterCaptions=[];
    this.origList.forEach(item => {
      mustInclude = true;
      this.filterCaptions = [];
      //-----
      if (parseInt(this.filterForm.controls["fCleaningTypeId"].value) === -1 && mustInclude) {
        mustInclude = true;
      }
      else {
        if (item.CleaningTypesId !== parseInt(this.filterForm.controls["fCleaningTypeId"].value)) {
          mustInclude = false;
        }
      }
      //-----

      if (parseInt(this.filterForm.controls["fPublicAreaId"].value) === -1 && mustInclude) {
        mustInclude = true;
      }
      else {
        if (item.PublicAreaId !== parseInt(this.filterForm.controls["fPublicAreaId"].value)) {
          mustInclude = false;
        }
      }


      if (mustInclude) {
        tmpArr.push(item);
      }
    })
    // update filter line
    this.filterCaptions.push(this.translate.instant("common.public_area") + ": " + this.publicAreas.find(item => item.PublicAreaId ==
      parseInt(this.filterForm.controls["fPublicAreaId"].value)).AreaName);
    this.filterCaptions.push(this.translate.instant("common.cleaning_type") + ": " + this.cleaningTypes.find(item => item.TypeId ==
      parseInt(this.filterForm.controls["fCleaningTypeId"].value)).TypeName);
    this.editorList = JSON.parse(JSON.stringify(tmpArr)) as vwPublicAreaCleaningType[];
    this.dataSource = new MatTableDataSource(this.editorList)
    this.dataSource.sort = this.sort;
  }

  cleaningTypeName( id: number) : string {
    return this.cleaningTypes.find( item=> item.TypeId === id).TypeName;
  }

  timeStart(rowData: vwPublicAreaCleaningType): string {
    return HwUtils.leftPad(rowData.PeriodHourStart, 2) + ":" + HwUtils.leftPad(rowData.PeriodMinStart, 2);
  }
  timeEnd(rowData: vwPublicAreaCleaningType) {
    return HwUtils.leftPad(rowData.PeriodHourEnd, 2) + ":" + HwUtils.leftPad(rowData.PeriodMinEnd, 2);
  }
  publicAreaName(id: number) : string {
    return this.publicAreas.find( item=> item.PublicAreaId === id).AreaName;
  }

  showAddOrEditMessage(result: RequestResult) {
  
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }

  showDeleteMessage(result: RequestResult) {
    if (result.Code == 0) {
      this.hwAlerService.info(this.translate.instant("common.successfully_deleted"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
    } else {
      this.hwAlerService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }

  }

  //#endregion =================================================================================================================


}
