import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { SelectItem } from '@app/core/classes/SelectItem';
import { SettingsContainerService } from '@app/kip/services/settings-container.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'hotel-settings-container',
  templateUrl: './hotel-settings-container.component.html',
  styleUrls: ['./hotel-settings-container.component.scss']
})
export class HotelSettingsContainerComponent implements OnInit , OnDestroy{

  headerText: string;
  subHeaderText: string;
  editorList : SelectItem[];
  editorSelectionVisible : boolean = true;
  listActive : boolean = true;
  editorActive: boolean = false;
  headerTextChanged$ : Subscription;
  subHeaderTextChanged$ :Subscription;
  listModeSelected$: Subscription;
  editModeSelected$: Subscription;
  
  selectedEditor: string = "st"
  selectedEditorName : string = "";
  visibilityChanged$: Subscription;

  constructor(
    private settingsContainerService: SettingsContainerService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.headerText = this.translate.instant("common.hotel_settings");
    this.visibilityChanged$ = this.settingsContainerService.visibilityChanged.subscribe(evn => {
      this.editorSelectionVisible = evn;
    })

    this.headerTextChanged$= this.settingsContainerService.headerTextChanged.subscribe(evn => {
      this.headerText = evn;
    });

    this.subHeaderTextChanged$= this.settingsContainerService.subHeaderTextChanged.subscribe(evn => {
      this.subHeaderText = evn;
    });

    this.listModeSelected$ = this.settingsContainerService.listModeSelected.subscribe(evn => {
      this.listActive = evn;
      this.editorActive = !evn;
    });

    this.editModeSelected$ = this.settingsContainerService.editModeSelected.subscribe(evn => {
      this.editorActive = evn;
      this.listActive = !evn;
    });  
    this.editorList = [
      
      {
        label : this.translate.instant("common.roomtypes"),
        value : "st"
      },
      {
        label : this.translate.instant("common.rooms"),
        value : "so"
      },
      {
        label : this.translate.instant("common.public_areas"),
        value : "pa"
      },
      {
        label : this.translate.instant("common.subscribersettings"),
        value : "sp"
      
      }
      
    ]
    this.selectedEditor = "st";
    this.subHeaderText = this.editorList.find(item=> item.value == "so").label;
    this.selectedEditorName = this.editorList.find(item=> item.value == "so").label;
    this.subHeaderText = this.editorList.find(item=> item.value == "so").label;
  }
  ngOnDestroy(): void {
    this.visibilityChanged$?.unsubscribe();
    this.headerTextChanged$?.unsubscribe();
    this.subHeaderTextChanged$?.unsubscribe;
    this.listModeSelected$?.unsubscribe();
    this.editModeSelected$?.unsubscribe();
  }


  editorChanged(event: MatSelectionListChange) {
    this.selectedEditor = event.options[0].value;
    this.subHeaderText =  event.options[0].getLabel();
    
  }

  editorClass(editorSelectionVisible: boolean) {
    if( editorSelectionVisible) {
      return ("p-col-12 p-md-10 p-lg-10 hw-1em-margin-bottom")
    }
    else {
        return("p-col-12 p-md-12 p-lg-12 hw-1em-margin-bottom")
    }
  }
}
