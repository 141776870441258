// Angular
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, Observable, combineLatest, fromEvent } from 'rxjs';
import { saveFile, saveAs } from 'file-saver';

// Hostware
import { RequestResult } from '@app/core/dto/RequestResult';
import { RoleService } from '@app/shared/services/role.service';
import { RoleType } from '@app/shared/enums/roles';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { HokReportDto } from '@app/kip/dto/HokReportDto';
import { HkReportService } from '@app/kip/services/hk-report.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { KipUser } from '@app/kip/dto/KipUser';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { TaskTypeChipsComponent } from '@app/kip/components/filter-chips/task-type-chips/task-type-chips.component';

@AutoUnsubscribe()
@Component({
  selector: 'hk-report',
  templateUrl: './hk-report.component.html',
  styleUrls: ['./hk-report.component.scss']
})
export class HkReportComponent implements OnInit,OnDestroy {

  allKipUsers: KipUser[] = [];
  kipUsers: KipUser[] = [];
  supervisors: KipUser[] = [];
  translations: any;
  loaded: boolean;
  filterForm: FormGroup;
  // subscriptions 
  hotelUnitChanged$: Subscription;
  loadFroDate$: Subscription;
  getTranslation$: Subscription;
  serverSideProcessing: boolean;

  constructor(
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private kipUserService: KipUserService,
    private roleService: RoleService,
    private topBarManagerService: TopBarManagerService,
    private hkReportService: HkReportService,
    private spinnerService: SpinnerService,
    private fb: FormBuilder
  ) { 

    this.filterForm = fb.group({
      'fFloor': new FormControl(""),
      'fSupervisor': new FormControl("-1"),
      'fAssignedTo': new FormControl("-1"),
    })

  }

//#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.loadAll();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll()
    })
  }
  ngOnDestroy(): void {
    this.hotelUnitChanged$?.unsubscribe();
    this.loadFroDate$.unsubscribe();
  }
//#endregion =================================================================================================================

//#region =============== List event hadlers   ===============================================================================
//#endregion =================================================================================================================

//#region =============== Form events ========================================================================================
runReport() {
  this.spinnerService.setSpinnerVisibility(true);
  let dto: HokReportDto = new HokReportDto();
  dto.AssignedTo = this.filterForm.controls["fAssignedTo"].value;
  dto.Floor = this.filterForm.controls["fFloor"].value;
  dto.Supervisor = this.filterForm.controls["fSupervisor"].value;
  this.serverSideProcessing = true;
  this.hkReportService.getHkReportPdf(this.sessionStorageService.getSelectedUnit(),
    this.sessionStorageService.getSelectedHotelId(), dto).subscribe(fileData => {
      this.spinnerService.setSpinnerVisibility(false);
      this.serverSideProcessing = false;
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      saveAs(b, this.translations["menu"]["hk_report"])
     
    })

}
//#endregion =================================================================================================================

//#region =============== Dialog events ======================================================================================
//#endregion =================================================================================================================
  
//#region =============== Validation    ======================================================================================
//#endregion =================================================================================================================

//#region =============== Functions  =========================================================================================
loadAll() {
  this.loaded = false;
  const allUser = { UserId: -1, UserName: this.translate.instant("common.all"), Subscriber: '', HotelId: 0, UserCode: '', UserUuId: '', Data: '', RealName: this.translate.instant("common.all"), Active:true }

  this.topBarManagerService.HideRootHotel.emit();
  this.kipUserService.loadAllMulti(this.sessionStorageService.getSelectedUnitMulti(),this.sessionStorageService.getSelectedHotelIdMulti()).subscribe( res=> {
    const result = res as RequestResult;
    this.allKipUsers = result.Data as KipUser[];
    this.allKipUsers.forEach(kipUser => {
      if (this.roleService.isUserInRole(kipUser.UserName, RoleType.GONDNOKNO) ||
        this.roleService.isUserInRole(kipUser.UserName, RoleType.MUSZAKI_VEZETO)) {
        this.supervisors.push(JSON.parse(JSON.stringify(kipUser)));
      }
    })
    this.kipUsers = [... this.allKipUsers]
    this.kipUsers.splice(0, 0, allUser);
    this.supervisors.splice(0, 0, allUser);
    this.filterForm.controls["fSupervisor"].setValue(-1)
    this.filterForm.controls["fAssignedTo"].setValue(-1)
    this.loaded = true;
  })
  
}

//#endregion =================================================================================================================


}
