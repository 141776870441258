import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RoomStateListService {
  
  constructor(private http: HttpClient) { }
  apiServer = environment.apiHost + environment.apiPort;
  loadRoomStates( subscriber : string,hotelId: number) {
    return this.http.get(this.apiServer + '/api/kip/Room/LoadRoomStates/'+ subscriber + "/" + hotelId + "/-1" );
  }

  loadRoomStateByRoomNumber(subscriber : string,hotelId: number, roomNumber: string) {
    return this.http.get(this.apiServer + '/api/kip/Room/LoadRoomStates/'+ subscriber + "/" + hotelId + "/" + roomNumber );
  }
}
