// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-wrapper {
  height: 30px;
}

.blue-sided-div {
  border-left: 3px solid #03a9f4;
  padding-left: 1.5em !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.filter-caption {
  background-color: #ececec;
  border-radius: 20px !important;
  padding: 0px 10px 0px 10px !important;
  margin: 1em 0px 0.2em 1em !important;
  display: inline-block !important;
}

@media screen and (max-width: 596px) and (min-width: 100px) {
  .blue-sided-div {
    border-left: 0px solid #03a9f4;
    padding-left: 0em !important;
    margin-top: 0em;
    margin-bottom: 0em;
  }
  .filter-caption {
    margin: 0.1em 0px 0.1em 0.1em !important;
  }
  .mat-expansion-panel-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 767px) and (min-width: 100px) {
  .column-mobile-margin {
    margin-top: 3em;
  }
}
.my-tasks-only {
  margin-top: 1.3em;
}

/* mat-expansion-panel-filter */
.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  display: inline;
}

.mat-expansion-panel-header {
  height: initial;
  min-height: 56px;
  padding-bottom: 0.5em;
}

.mat-expansion-panel-header.mat-expanded {
  height: initial;
  min-height: 56px;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: #fff;
  height: initial;
  min-height: 56px;
}

.hw-container-background {
  margin: -60px 40px 0px 40px;
}

@media screen and (max-width: 840px) {
  .hw-container-background {
    margin: -60px 0px 0px 0px;
    padding: 1em;
  }
}
.mat-expansion-panel-header.mat-expanded {
  background-color: #f5f5f5 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
