// Angular
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';

// 3rdparty

import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// Hostware
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { RoomService } from '@app/kip/services/room.service';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { RepeatingTasksService } from '@app/kip/services/repeating-tasks.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { SelectItem } from '@app/core/classes/SelectItem';
import { Room } from '@app/kip/dto/Room';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaItem, RepeatingTask } from '@app/kip/dto/RepeatingTasks';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { AreaWrapper } from '@app/kip/dto/AreaWrapper';
import { MatSelect } from '@angular/material/select';

@AutoUnsubscribe()
@Component({
  selector: 'app-repeating-tasks-editor-dialog',
  templateUrl: './repeating-tasks-editor-dialog.component.html',
  styleUrls: ['./repeating-tasks-editor-dialog.component.css']
})
export class RepeatingTasksEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('areaList') areaSelection: MatSelect;

  destroy$: Subject<boolean> = new Subject<boolean>();
  loadFroDatum$: Subscription;
  itemTypes: SelectItem[] = [];
  selectedAreas: SelectItem[] = [];
  areas: SelectItem[] = [];
  rooms: SelectItem[] = [];
  publicAreas: SelectItem[] = [];
  selItems: SelectItem[] = [];
  maintenanceTypes: MaintenanceType[] = [];
  froDatum: string;
  editorForm: FormGroup
  _data: RepeatingTask
  constructor(
    public dialogRef: MatDialogRef<RepeatingTasksEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: RepeatingTask,
    private roomService: RoomService,
    private publicAreaService: PublicAreaService,
    private translate: TranslateService,
    private repeatingTasksService: RepeatingTasksService,
    private maintenanceTypeService: MaintenanceTypeService,
    public styleHelper: StyleHelperService,
    private sessionStorageService: SessionStorageService,
    private hokParamsService: HokParamsService,
    private fb: FormBuilder,
    private rfHelperService: ReactiveFormsHelperService
  ) {
    this.editorForm = fb.group({
      'AreaType': new FormControl('R'),
      'Areas': new FormControl('', Validators.required),
      'StartDate': new FormControl(dayjs().format("YYYY.MM.DD"), Validators.required),
      'TaskTypeId': new FormControl(),
      'RepeatDay': new FormControl('1'),
      'ShowBefore': new FormControl('1'),
      'Active': new FormControl(true),
      // 'TypeId': new FormControl()

    })
    this._data = data;
  }



  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.itemTypes = [
      { label: this.translate.instant("common.rooms"), value: "R" },
      { label: this.translate.instant("common.public_areas"), value: "P" }

    ]
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.loadFroDatum$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  submit() {

    let  wrapper: AreaWrapper =  new AreaWrapper()
    wrapper.Areas=[];
    let arr : string[] = this.editorForm.controls["Areas"].value.toString().split(',');
    arr.forEach(element => {
        const newItem = new AreaItem();
        newItem.AreaId = parseInt(element);
        wrapper.Areas.push(newItem);
    });
    this._data.Areas = JSON.stringify(wrapper);
    this.data.StartDate = dayjs(this.editorForm.controls["StartDate"].value).format("YYYY.MM.DD")
    this._data.TaskTypeId = this.editorForm.controls["TaskTypeId"].value;
    this._data.RepeatDay = this.editorForm.controls["RepeatDay"].value;
    this._data.ShowBefore =  this.editorForm.controls["ShowBefore"].value;
    this._data.IsActive = this.editorForm.controls["Active"].value;
    this.dialogRef.close(this._data);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  areaTypeChanged(areaType: string) {
    this.selectedAreas = [];
    this.loadAreas(areaType);
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    let wrapper: AreaWrapper = new AreaWrapper();
    wrapper.Areas=[];
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
      .subscribe(result => {
        const hokpResult = result as RequestResult;
        this.froDatum = hokpResult.Data;
        combineLatest({
          maintenanceTypes: this.maintenanceTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
          publicAreas: this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
          rooms: this.roomService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this.froDatum)
        }).pipe(takeUntil(this.destroy$)).subscribe(value => {
          const r = (value.rooms as RequestResult).Data as Array<Room>;
          this.rooms = [];
          r.forEach(item => {
            this.rooms.push({ value: item.RoomId.toString(), label: item.RoomNumber })
          })
          this.publicAreas = [];
          this.loadAreas('R');
          const p = (value.publicAreas as RequestResult).Data as Array<PublicArea>;
          p.forEach(item => {
            this.publicAreas.push({ value: item.PublicAreaId.toString(), label: item.AreaName })
          })
          this.maintenanceTypes = (value.maintenanceTypes as RequestResult).Data as Array<MaintenanceType>;
          if(this._data.Areas.length>0) {
            wrapper = JSON.parse(this._data.Areas) as AreaWrapper;
          }
         
          let arr = [];
          // új
          if (this._data.TaskId === 0) {
            this.editorForm.controls["TaskTypeId"].patchValue(this.maintenanceTypes[0].TypeId);
            this.editorForm.controls["Active"].patchValue(true);
            this.editorForm.controls['StartDate'].patchValue(dayjs(dayjs().add(1,'day')));
          } else {
            this.editorForm.controls["AreaType"].patchValue("R");
            wrapper.Areas.forEach(element => {
              arr.push(element.AreaId.toString());
            });
            this.editorForm.controls["Areas"].patchValue(arr);
            this.editorForm.controls['StartDate'].patchValue(dayjs(this.data.StartDate,"YYYY.MM.DD"));
            this.editorForm.controls["TaskTypeId"].patchValue(this._data.TaskTypeId);
            this.editorForm.controls["RepeatDay"].patchValue(this._data.RepeatDay);
            this.editorForm.controls["ShowBefore"].patchValue(this._data.ShowBefore);
            this.editorForm.controls["Active"].patchValue(this._data.IsActive);
           
          }
        })


      })
  }
  loadAreas(areaCode: string) {
    const allItem: SelectItem = { value: -1, label: this.translate.instant("common.all") }
    if (areaCode == "R") {
      this.areas = [...this.rooms];
    } else {
      this.areas = [...this.publicAreas];
    }
    // this.editorForm.controls["Areas"].patchValue('');
  }
  //#endregion =================================================================================================================
}
