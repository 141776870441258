// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// 3rdparty
import { SpinnerService } from '@core/services/spinner.service';

// Hostware
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HttpErrorResponse } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';
import { SelectItem } from '@app/core/classes/SelectItem';
import { RequestResult } from '@core/dto/RequestResult';
import { SubscriberTreeDto } from '@core/dto/SubscriberTreeDto';
import { UserInfo } from '@core/dto/UserInfo';
import { AuthService } from '@core/services/auth.service';
import { ResourceService } from '@core/services/resource.service';
import { SessionStorageService } from '@core/services/session-storage.service';
import { TopBarManagerService } from '@core/services/topbar-manager.service';
import { UserService } from '@core/services/user.service';
import { SubscriberParameter } from '@dto/SubscriberParameter';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { SubscriberParamTypes } from '@kip/enums/SubscriberParamTypes';
import { FormStateService } from '@kip/services/form-state.service';
import { SubscriberParameterService } from '@services/subscriber-parameter.service';
import { RoleType } from '@shared/enums/roles';
import { RoleService } from '@shared/services/role.service';
import dayjs from 'dayjs';

import { SubscriberParameterEnum } from '@app/core/enums/subscriber-parameter.enum';
import { LoginService } from '@app/core/services/login.service';
import { MessageRegistration } from '@app/interfaces/MessageRegistration';
import { PushSubscriptionService } from '@app/services/push-subscription.service';
import { PushService } from '@app/services/push.service';
import { environment } from '@environments/environment';
//import { HwDayjsDateAdapter } from '@globalshared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';

@AutoUnsubscribe()
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit, OnDestroy {

  hasError: boolean;
  loginForm: FormGroup;

  loading: boolean;
  private subcriberTree: SubscriberTreeDto;
  allHotels: SelectItem[] = [];
  flattenedHotels: SelectItem[] = [];
  private loggedInRoute = 'main';
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private topBarManagerService: TopBarManagerService,
    private roleService: RoleService,
    private spinnerService: SpinnerService,
    private formStateService: FormStateService,
    private sessionStorageService: SessionStorageService,
    private translateService: TranslateService,
    private subsParams: SubscriberParameterService,
    private fb: FormBuilder,
    private hwAlertService: HwAlertService,
    private cd: ChangeDetectorRef,

    private pushService: PushService,
    private loginService: LoginService
  ) {

    this.loginForm = this.fb.group({
      'userName': new FormControl(''),
      'password': new FormControl('')
    })
    // console.log( dayjs().format('YYYY.MM.DD HH:mm:ss') +  '- login.component created' ) 
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.hasError = false;
    this.topBarManagerService.setTopbarBackgroundVisibility(false);
    this.topBarManagerService.setTopbarVisibility(false);
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  login() {
    var self = this;
    if (this.loginForm.controls["userName"].invalid || this.loginForm.controls["password"].invalid) {
      this.hwAlertService.error(this.translateService.instant("login.loginerror"), new Alert({ autoClose: false, position: ToastPosition.TopRight }));
      return;
    }
    this.hasError = false;
    let firstHotel: string;
    let firstUnit: string;
    let firstHotelId: string
    this.loading = true;
    let email: string;
    let userName: string;
    this.authService.login(this.loginForm.controls["userName"].value, this.loginForm.controls["password"].value)
      .subscribe(
        {
          next: (result) => {
            // this.dateAdapter.setLocale('hu')
            dayjs.locale("hu")
            // this.authService.saveToken(result as AuthItem);
            this.topBarManagerService.setTopbarVisibility(true);
            this.topBarManagerService.setTopbarBackgroundVisibility(true);
            this.loading = false;
            this.topBarManagerService.setUserName(this.loginForm.controls["userName"].value);
            this.userService.getUserInfo(this.loginForm.controls["userName"].value).subscribe(result => {
              let res = result as RequestResult;
              let userinf = res.Data as UserInfo;
              email = this.loginForm.controls["userName"].value;
              userName = userinf.UserName;
              sessionStorage.setItem('userInfo', JSON.stringify(userinf));
              this.allHotels = [];
              this.flattenedHotels = [];
              const oneSub: SubscriberTreeDto = userinf.SubscriberTree as SubscriberTreeDto;
              const subs: SubscriberTreeDto[] = [];

              // fill allHotels  
              subs.push(oneSub);
              this.loopData(oneSub, '');
              // fill flattenedHotel
              if (oneSub.Subscribers?.length > 0) {
                this.flattenedHotels.push({ label: oneSub.SubscriberCode + "- " + oneSub.ShortName, value: oneSub.SubscriberCode + "@" + oneSub.Htlnum })
                this.loopSubscriberData(userinf.SubscriberTree);
              } else {
                this.flattenedHotels.push({ label: oneSub.SubscriberCode + " - " + oneSub.ShortName, value: oneSub.SubscriberCode + "@" + oneSub.Htlnum })
              }
              this.sessionStorageService.setAllHotels(this.allHotels);
              this.sessionStorageService.setFlattenedHotels(this.flattenedHotels);
              // megnézzük hogy multihotel-e egyáltalán
              this.sessionStorageService.multiHotel = this.flattenedHotels.length > 1 ? true : false;

              // a kiválasztott hotel midndeképpen az első elem, lesz, majd később multihotel esetén felülírjuk
              // 
              firstHotel = this.sessionStorageService.getAllHotels()[0].value as string;
              firstUnit = firstHotel.split('@')[0];
              firstHotelId = firstHotel.split('@')[1];
              this.sessionStorageService.setSelectedUnit(firstUnit)
              this.sessionStorageService.setSelectedHotelId(firstHotelId)

              this.subsParams.loadAll(this.sessionStorageService.getSelectedUnit(),this.sessionStorageService.getSelectedHotelId())
                .subscribe(async subsresult => {
                  const allParams = subsresult as SubscriberParameter[];
                  // notification
                  const sendNotificationParam = allParams.find(item => item.Param_Typecode == SubscriberParamTypes.SEND_NOTIFICATIONS) as SubscriberParameter
                  if (sendNotificationParam.Param_Int_Value === 1) {
                    this.sessionStorageService.setPushSettingsVisible(true);
                  } else {
                    this.sessionStorageService.setPushSettingsVisible(false);
                  }

                  // ignoremultihotel
                  const ignoreMultiHotelParam = allParams.find(item => item.Param_Typecode == SubscriberParamTypes.IGNORE_MULTI_HOTEL) as SubscriberParameter;

                  // IgnoreMultihotel perzisztálása 
                  this.sessionStorageService.IgnoreMultiHotel = ignoreMultiHotelParam.Param_Int_Value == 1 ? true : false;
                  const userInf = <UserInfo>JSON.parse(sessionStorage.getItem("userInfo"));
                  this.subcriberTree = userInf.SubscriberTree;

                  //  első elem 99 es id cseréje 'Összesen' - re ha multihotel van
                  if (this.sessionStorageService.multiHotel) {
                    let flattenedHotels = this.sessionStorageService.getFlattenedHotels();
                    const firstUnit = flattenedHotels[0].value.split('@')[0];
                    flattenedHotels[0].label = firstUnit + " / " + this.translateService.instant("common.all");
                    //  új flattenedhotel perzisztálása  
                    this.sessionStorageService.setFlattenedHotels(flattenedHotels);
                    this.flattenedHotels = flattenedHotels;
                  }
                  //  ha használunk multihotelt, és nem szarjuk le, akkor a kiválasztott az összesen lesz
                  if (this.sessionStorageService.multiHotel) {
                    this.sessionStorageService.setSelectedHotelIdMulti(this.flattenedHotels[1].value);
                    this.sessionStorageService.setSelectedUnitMulti(this.flattenedHotels[1].value);
                  } else {
                    this.sessionStorageService.setSelectedHotelIdMulti(this.flattenedHotels[0].value);
                    this.sessionStorageService.setSelectedUnitMulti(this.flattenedHotels[0].value);
                  }
                  this.loginService.notifyLoginProcessFinished(userinf);
                })
              this.topBarManagerService.HideRootHotel.emit();
              // console.log(event);
              //sessionStorage.setItem("userInfo", JSON.stringify(userinf));
              this.roleService.loadRoles();

              this.topBarManagerService.setNumberOfTasks();
              this.topBarManagerService.setNoAck();
              this.topBarManagerService.setLoginState(true);
             
              // szerepkör szerinti navigáció
              if (res.Data["Roles"].length > 0) {
                this.authService.rolesLoaded.emit();
                this.formStateService.resetVwRoomStateFilter();
                this.formStateService.resetVwTaskListFilter();

                switch (res.Data["Roles"][0]) {
                  case RoleType.GONDNOKNO:
                    this.router.navigate(["/kip/trusteelandingpage"]);
                    break;
                  case RoleType.ADMIN:
                    this.router.navigate(["/kip"]);
                    break;
                  case RoleType.TAKARITO: {
                    this.router.navigate(["/kip/tasklist"]);
                    break;
                  }
                  case RoleType.MUSZAKI_VEZETO: {
                    this.router.navigate(["/kip/maintenanceleaderlandingpage"]);
                    break;
                  }
                  case RoleType.MUSZAKOS: {
                    this.router.navigate(["/kip/tasklist"]);
                    break;
                  }
                  case RoleType.RECEPCIOS: {
                    this.router.navigate(["/kip/recepcionistlandingpage"]);
                    break;
                  }
                }

              }
              else {
                this.router.navigate([`/${this.loggedInRoute}`]);
              }
              
              // this.subscribeForPush();
            })
          },
          error(err: HttpErrorResponse) {
            self.hasError = true
            this.loading = false;
            self.cd.detectChanges();
          }
        })

  }



  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation  ========================================================================================
  public validate(): boolean {
    return this.loginForm.controls["userName"].invalid || this.loginForm.controls["password"].invalid;
  }


  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loopData(obj, stack) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == "object") {
          if (!this.allHotels.find(item => item.value == obj["SubscriberCode"] + "@" + obj["Htlnum"])) {
            if (obj.RgtIdx) {
              if (parseInt(obj.RgtIdx) - parseInt(obj.LftIdx) === 1) {
                this.allHotels.push({ value: obj.SubscriberCode + "@" + obj.Htlnum, label: obj.SubscriberCode + " / (" + obj.Htlnum + ")" })
              }
            }

          }
          this.loopData(obj[property], stack + '.' + property);
        }
      }
    }
  }


  loopSubscriberData(data: SubscriberTreeDto) {

    data?.Subscribers.forEach(item => {
      this.flattenedHotels.push({ label: item["SubscriberCode"] + " - " + item.ShortName, value: item.SubscriberCode + "@" + item.Htlnum })
      this.loopSubscriberData(item);
    });
  }

  // subscribeForPush() {
  //   if (this.sessionStorageService.getSelectedUnit()?.length > 0) {
  //     this.subsParams.loadByTypeCode(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),
  //       SubscriberParameterEnum.SEND_NOTIFICATIONS).subscribe(result => {
  //         let payload = {
  //           serverPublicKey: environment.publicVapidKey
  //         }
  //         const res = result as SubscriberParameter;
  //         if (res.Param_Int_Value == 1) {

  //           const userInf = this.sessionStorageService.getUserInfo() as UserInfo;
  //           const vapid = environment.publicVapidKey;

  //           navigator.serviceWorker.register('/assets/sw.js', { scope: '/assets/' }).then((serviceWorkerRegistration) => {

  //             const options = {
  //               userVisibleOnly: true,
  //               applicationServerKey: vapid,
  //             };
  //             serviceWorkerRegistration.pushManager.subscribe(options).then(
  //               (pushSubscription) => {
  //                 console.log(pushSubscription.endpoint);
  //                 let messageReg: MessageRegistration = {
  //                   Email: userInf.email,
  //                   UserName: userInf.UserName,
  //                   EndPoint: pushSubscription.endpoint
  //                 }
  //                 this.sessionStorageService.MessagingToken = messageReg;
  //                 this.pushService.SubscribeForPush(messageReg).subscribe({
  //                   next: result => {
  //                     console.log(result)
  //                     // ServiceWorkerModule.register('/sw.js',{ enabled: true, registrationStrategy: 'registerWhenStable:30000' })
  //                   },
  //                   error: err => {
  //                     console.error(err)
  //                   }
  //                 })
  //               })
  //           }
  //           );
  //           navigator.serviceWorker.addEventListener("message", (e) => {
  //             alert("e");
  //           });

  //         }

  //         // Use serviceWorker.ready to ensure that you can subscribe for push

  //       })
  //   }
  // }
  //#endregion =================================================================================================================







}