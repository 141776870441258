// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';

// 3rdparty
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { KipUser } from '@app/kip/dto/KipUser';
import { KipUserGroup } from '@app/kip/dto/KipUserGroup';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { KipUserGroupService } from '@app/kip/services/kip-usergroup.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { UserGroupEditorDialogComponent } from '@app/kip/dialogs/user-group-editor-dialog/user-group-editor-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_SCROLL_STRATEGY_FACTORY } from '@angular/material/dialog';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { OpenDialogAction } from '@app/core/enums/OpenDialogAction';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';


// Hostware


@AutoUnsubscribe()
@Component({
  selector: 'user-group-editor',
  templateUrl: './user-group-editor.component.html',
  styleUrls: ['./user-group-editor.component.scss']
})
export class UserGroupEditorComponent implements OnInit, OnDestroy {

  

  loadAllUserGroups$: Subscription;
  hotelUnitChanged$ : Subscription;
  deleteUserGroup$ : Subscription;
  addUserGroup$ : Subscription;
  updateUserGroup$: Subscription;

  editorList: KipUserGroup[]=[];
  tableColumns: string[] = ["UserGroupName", "Action"];

  dataSource: MatTableDataSource<KipUserGroup>;
  constructor(
    private topBarManagerService : TopBarManagerService,
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private kipUserGroupService: KipUserGroupService,
    private hwAlerService: HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private userGroupEditorDialog: MatDialog,
    private hwAlertService: HwAlertService,
    private crudMessageService : CrudMessagesService
  ) { }

  //#region =============== Angular Lifecyle events ============================================================================

  ngOnInit() {
    this.loadAll();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe( result=> {
      this.loadAll();
    })
  }
  ngOnDestroy(): void {
    this.loadAllUserGroups$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.deleteUserGroup$?.unsubscribe();
    this.addUserGroup$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  openDialog( action: string, data?:KipUserGroup) {
    let kipUserGroupItem: KipUserGroup;
    if( action == OpenDialogAction.ADD) {
      kipUserGroupItem = new KipUserGroup();
      kipUserGroupItem.HotelId = this.sessionStorageService.getSelectedHotelId();
      kipUserGroupItem.Subscriber = this.sessionStorageService.getSelectedUnit();
      kipUserGroupItem.UserGroupId = -1;
      kipUserGroupItem.Users = [];
    } else {
      kipUserGroupItem = data;
    }
    const dialogRef = this.userGroupEditorDialog.open(UserGroupEditorDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "60vw",
      panelClass: "user-group-modal",
      data:kipUserGroupItem 
    });

    dialogRef.afterClosed().subscribe( data=> {
      if(data) {
        const processableItem = data as KipUserGroup;
        if(processableItem.UserGroupId == -1) {
          processableItem.UserGroupId=0;
          this.addUserGroup$=this.kipUserGroupService.add(processableItem).subscribe(result=> {
            this.crudMessageService.showAddOrEditMessage(result as RequestResult);
            this.loadAll();
          })
        } else {
          this.updateUserGroup$=this.kipUserGroupService.update(processableItem).subscribe(result=> {
             this.crudMessageService.showAddOrEditMessage(result as RequestResult);
          })
        }
      }
    })
 
  }
  deleteRow( item: KipUserGroup) {

    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.delete_selected_rows"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if( confirmed) {
        this.deleteUserGroup$=this.kipUserGroupService.delete(item.UserGroupId.toString()).subscribe( result=> {
          const res = result as RequestResult;
          if(res.Code == 0) {
            this.loadAll();
          } else {
            this.crudMessageService.showDeleteMessage(res);
          }
        })
      }

    })
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
 
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  
  loadAll() {
    let units = this.sessionStorageService.getSelectedUnit();
    let hotels = this.sessionStorageService.getSelectedHotelId();
    this.loadAllUserGroups$ = this.kipUserGroupService.loadAllMulti(units, hotels.toString()).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        this.editorList = res.Data as Array<KipUserGroup>;
        this.dataSource = new MatTableDataSource(this.editorList);
      }
    });
  }
  //#endregion =================================================================================================================

}
