import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { SessionStorageService } from '../core/services/session-storage.service';
import { MessageRegistration } from '@app/interfaces/MessageRegistration';
import { NotificationDto } from '@app/classes/NotificationDto';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  apiServer = environment.messagingWebApiUrl;

  constructor(
     private http: HttpClient,
     private sessionStorageService : SessionStorageService) {

  }

  SubscribeForPush( regOptions : MessageRegistration) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer + "/subscribe",regOptions, {headers});
  }

 

  sendMessageForSpecificUser(not : NotificationDto){
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer +"/sendmessage",not,{headers});
  }


  UnsubscribeFromPush( email:string) {
    return this.http.get(this.apiServer + "/unsubscribe/" + email )
  }

  CheckTokenExpiration( endPoint : any) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(this.apiServer +"/CheckTokenExpiration",endPoint,{headers});
  }

}
