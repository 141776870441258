//---- Angular 
import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { environment } from "../../environments/environment";
import { HousekeepingSettingsContainerComponent } from './components/housekeeping-settings-container/housekeeping-settings-container.component';
import { MaintenanceSettingsContainerComponent } from './components/maintenance-settings-container/maintenance-settings-container.component';
import { SchedulerComponent } from './pages/scheduler/scheduler.component';
import { TaskSchedulerCalendarComponent } from './pages/task-scheduler-calendar/task-scheduler-calendar.component';
import { TaskReportComponent } from './pages/task-report/task-report.component';
import { CanDeactivateGuard } from './guards/can-deactivate-guard.service';
import { CleaningTaskEditorComponent } from './pages/cleaning-task-editor/cleaning-task-editor.component';
import { CleaningTypeEditorComponent } from './pages/cleaning-type-editor/cleaning-type-editor.component';
import { CleaningTypeRoomstateEditorComponent } from './pages/cleaning-type-roomstate-editor/cleaning-type-roomstate-editor.component';
import { KipLandingPageComponent } from './pages/kip-landing-page/kip-landing-page.component';
import { MaintenanceLeaderLandingPageComponent } from './pages/maintenance-leader-landing-page/maintenance-leader-landing-page.component';
import { MaintenanceTaskEditorComponent } from './pages/maintenance-task-editor/maintenance-task-editor.component';
import { MaintenanceTypeEditorComponent } from './pages/maintenance-type-editor/maintenance-type-editor.component';
import { PublicAreaCleaningTypeEditorComponent } from './pages/public-area-cleaning-type-editor/public-area-cleaning-type-editor.component';
import { RoomStateListComponent } from './pages/room-state-list/room-state-list.component';
import { TaskListEditorComponent } from './pages/task-list-editor/task-list-editor.component';
import { TrusteeLandingPageComponent } from './pages/trustee-landing-page/trustee-landing-page.component';
import { UserGroupEditorComponent } from './pages/user-group-editor/user-group-editor.component';
import { RoomEditorComponent } from './pages/room-editor/room-editor.component';
import { HotelSettingsContainerComponent } from './components/hotel-settings-container/hotel-settings-container.component';
import { RoomtypeEditorComponent } from './pages/roomtype-editor/roomtype-editor.component';
import { SubscriberParameterEditorComponent } from './pages/subscriber-parameter-editor/subscriber-parameter-editor.component';
import { RecepcionistLandingPageComponent } from './pages/recepcionist-landing-page/recepcionist-landing-page.component';
import { HkReportComponent } from './pages/hk-report/hk-report.component';
import { LostAndFoundEditorComponent } from './pages/lost-and-found-editor/lost-and-found-editor.component';
import { MinibarListComponent } from './pages/minibar-list/minibar-list.component';
import { ExtraTaskEditorComponent } from './pages/extra-task-editor/extra-task-editor.component';


const routes: Routes = [

  {
    path: '', component: KipLandingPageComponent,
    children: [
      { path: 'trusteelandingpage', component: TrusteeLandingPageComponent },
      { path: 'cleaningtaskeditor', component: CleaningTaskEditorComponent },
      { path: 'cleaningtypeeditor', component: CleaningTypeEditorComponent },
      { path: 'maintenancetaskeditor', component: MaintenanceTaskEditorComponent },
      { path: 'maintenancetypeeditor', component: MaintenanceTypeEditorComponent },
      { path: 'roomstatelist', component: RoomStateListComponent },
      { path: 'careacleaningtype', component: PublicAreaCleaningTypeEditorComponent },
      { path: 'scheduler', component: SchedulerComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'usergroupeditor', component: UserGroupEditorComponent },
      { path: 'clearoomstateeditor', component: CleaningTypeRoomstateEditorComponent },
      { path: 'tasklist', component: TaskListEditorComponent, data: { shouldReuseRoute: false } },
      { path: 'usersettingseditor', component: UserGroupEditorComponent },
      { path: 'hotelsettingseditor', component: HotelSettingsContainerComponent },
      { path: 'roomtypeeditor', component: RoomtypeEditorComponent },
      { path: 'housekeepingsettings', component: HousekeepingSettingsContainerComponent },
      { path: 'maintenancesettings', component: MaintenanceSettingsContainerComponent },
      { path: 'RoomEditor', component: RoomEditorComponent },
      { path: 'recepcionistlandingpage', component: RecepcionistLandingPageComponent },
      { path: 'maintenanceleaderlandingpage', component: MaintenanceLeaderLandingPageComponent },
      { path: 'minibarlist', component: MinibarListComponent },
      { path: 'taskschedulercalendar', component: TaskSchedulerCalendarComponent },
      { path: 'subscriberparameters', component: SubscriberParameterEditorComponent },
      { path: 'taskhistory', component: TaskReportComponent },
      { path: 'hkreport', component: HkReportComponent },
      { path: 'lostandfound', component: LostAndFoundEditorComponent },
      { path: 'extratasks', component: ExtraTaskEditorComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    CanDeactivateGuard
  ]
})

export class KipRoutingModule { }