
// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
import dayjs, { months } from 'dayjs';
import { Subscription, combineLatest, combineLatestWith, forkJoin, map } from 'rxjs';
import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { saveFile, saveAs } from 'file-saver';

// Hostware
import { SpinnerService } from '@app/core/services/spinner.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { RoleService } from '@app/shared/services/role.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { DynamicControlDescriptor } from '@app/kip/classes/DynamicControlDescriptor';
import { AuthService } from '@app/core/services/auth.service';
import { KipUser } from '@app/kip/dto/KipUser';
import { RoleType } from '@app/shared/enums/roles';
import { ChipsTypes } from '@app/kip/enums/ChipsTypes';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { RequestResult } from '@app/core/dto/RequestResult';
import { vwTaskListFilter } from '@viewdto/vwTaskListFilter'
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { SelectItem } from '@app/core/classes/SelectItem';
import HwUtils from '@app/shared/helpers/hwutils';
import { vwTaskReport } from '@app/kip/viewdto/vwTaskReport';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';




@AutoUnsubscribe()
@Component({
  selector: 'app-task-report',
  templateUrl: './task-report.component.html',
  styleUrls: ['./task-report.component.scss']
})
export class TaskReportComponent implements OnInit, OnDestroy {


  @ViewChild(MatSort) sort!: MatSort;

  hotelUnitChanged$: Subscription;

  loadAllKipUsers$: Subscription;
  loadPublicAreas$: Subscription;
  loadFroDate$: Subscription;
  loadTaskHistory$: Subscription;
  loadCleaningTypes$: Subscription;
  loadMaintenanceTypes$: Subscription;

  filterForm: FormGroup;
  itemTypes: SelectItem[];
  taskTypes: SelectItem[] = [];
  dynamicControlDescriptor: DynamicControlDescriptor[] = [];
  tmpDynamicControlDescriptor: DynamicControlDescriptor[] = [];
  kipUsers: KipUser[] = [];
  supervisors: KipUser[] = [];
  publicAreas: PublicArea[] = [];
  editorList: vwTaskReport[] = [];
  cleaningTypes: CleaningType[] = [];
  maintenanceTypes: MaintenanceType[] = [];
  dataSource: MatTableDataSource<vwTaskReport>;
  gridData: vwTaskReport[] = [];
  maintenancesVisible: boolean = true;
  cleaningsVisible: boolean = true;
  allVisible: boolean = true;
  sumTime: number;
  sumTimeString: string;
  houseKeepingLabel: string;
  maintenanceLabel: string;
  froDate: string;
  lastSelectedValue: string;
  lastUnselectedValue: string;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  tableColumns: string[] = ["CleaningDate", "AreaName", "CleaningTypeName", "AssignedToName", "SupervisorName",
    "TypeStateCode", "TypeTime"];


  constructor(
    private kipUserService: KipUserService,
    private roleService: RoleService,
    private sessionStorageService: SessionStorageService,
    private topBarManagerService: TopBarManagerService,
    private publicAreaService: PublicAreaService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private hokParamsService: HokParamsService,
    private rfHelper: ReactiveFormsHelperService,
    private dailyTaskService: DailyTaskService,
    private cleaningTypeService: CleaningTypeService,
    private maintenanceTypeService: MaintenanceTypeService,
    private spinnerService: SpinnerService



  ) {

    this.filterForm = fb.group({
      'fItemType': new FormControl("0"),
      'fTaskType': new FormControl([]),
      'fAllCleaning' : new FormControl(false),
      'fAllMaintenance' : new FormControl(false),
      'fRoomNumber': new FormControl(""),
      'fPublicAreaCode': new FormControl("-2"),
      'fTaskStateCreated': new FormControl(false),
      'fTaskStatePaused': new FormControl(false),
      'fTaskStateEnded': new FormControl(true),
      'fTaskStateRunning': new FormControl(false),
      'fTaskStateDeleted': new FormControl(false),
      'fTaskStateAccepted': new FormControl(true),
      'fAssignedTo': new FormControl({
        value: "-1",
        disabled: !(this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO))
      }),
      'fSupervisor': new FormControl("-1"),
      'fDateFrom': new FormControl(''),
      'fDateTo': new FormControl('')
    })

    this.itemTypes = [
      { value: "-1", label: this.translate.instant("common.all") },
      { value: "0", label: this.translate.instant("common.cleaning") },
      { value: "1", label: this.translate.instant("common.maintenance") }
    ]
  }

  ngOnInit() {
    this.houseKeepingLabel = this.translate.instant('common.cleaning')
    this.maintenanceLabel = this.translate.instant('common.maintenance')

    this.topBarManagerService.HideRootHotel.emit();
    this.dataSource = new MatTableDataSource();


    this.init();

  }
  ngOnDestroy(): void {
    this.loadAllKipUsers$?.unsubscribe();
    this.loadPublicAreas$?.unsubscribe();
    this.loadFroDate$?.unsubscribe();
    this.loadTaskHistory$?.unsubscribe();
    this.loadCleaningTypes$?.unsubscribe();
    this.loadMaintenanceTypes$?.unsubscribe();
  }
  //#region =============== Angular Lifecyle events ============================================================================
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  roomNumberChanged() {
    if (this.filterForm.controls["fRoomNumber"].value !== "") {
      this.filterForm.controls["fPublicAreaCode"].patchValue("-2");
    }
    this.updateFilterIcons();
  }

  publicAreaChanged(event) {
    if (event.value !== -2) { // van kiválasztva elem
      this.filterForm.controls["fRoomNumber"].patchValue("");
    }
    this.updateFilterIcons();
  }


  exportToPdf() {
    const param: vwTaskListFilter = new vwTaskListFilter();
    param.AssignedTo = this.filterForm.controls["fAssignedTo"].value;
    param.EndDate = dayjs(this.filterForm.controls["fDateTo"].value).format("YYYY.MM.DD");
    param.StartDate = dayjs(this.filterForm.controls["fDateFrom"].value).format("YYYY.MM.DD");
    param.RoomNumber = this.filterForm.controls["fRoomNumber"].value;
    param.ItemType = this.filterForm.controls["fItemType"].value;
    param.MyTasksOnly = false;
    param.PublicAreaCode = this.filterForm.controls["fPublicAreaCode"].value;
    param.Supervisor = this.filterForm.controls["fSupervisor"].value;
    param.TaskStateAccepted = this.filterForm.controls["fTaskStateAccepted"].value;
    param.TaskStateCreated = this.filterForm.controls["fTaskStateCreated"].value;
    param.TaskStateDeleted = this.filterForm.controls["fTaskStateDeleted"].value;
    param.TaskStateEnded = this.filterForm.controls["fTaskStateEnded"].value;
    param.TaskStatePaused = this.filterForm.controls["fTaskStatePaused"].value;
    param.TaskStateRunning = this.filterForm.controls["fTaskStateRunning"].value

    if(JSON.parse(this.filterForm.controls['fAllCleaning'].value))  {
      param.CleaningTypes = 'all'  
    } else {
      const tmpArr = (this.filterForm.controls['fTaskType'].value as string[]).filter(item=>item.substring(0,1)=="c")
      tmpArr.forEach((item,index)=> {
        tmpArr[index] = item.split("_")[1];
      })
      param.CleaningTypes = tmpArr.join(',')
    }
    
    if(JSON.parse(this.filterForm.controls['fAllMaintenance'].value))  {
      param.MaintenanceTypes = 'all'  
    } else {
      const tmpArr = (this.filterForm.controls['fTaskType'].value as string[]).filter(item=>item.substring(0,1)=="m")
      tmpArr.forEach((item,index)=> {
        tmpArr[index] = item.split("_")[1];
      })
      param.MaintenanceTypes = tmpArr.join(',')
    }

    this.dailyTaskService.getTaskHistoryPdf(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId(), param).subscribe(fileData => {
        let b: any = new Blob([fileData], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(b);
        window.open(fileURL);
        saveAs(b, this.translate.instant("menu.task_history"))
      });
  }
  exportToXls() {
    let xlsData: any[][] = [[]];
    let wb = utils.book_new();
    let ws = utils.json_to_sheet([]);
    utils.book_append_sheet(wb, ws, "Lista")
    xlsData.push([this.translate.instant("common.date"), this.translate.instant("common.place"), this.translate.instant("common.cleaning_type"),
    this.translate.instant("common.assigned_to"), this.translate.instant("common.supervisor"),
    this.translate.instant("common.status"), this.translate.instant("common.time")])

    this.gridData.forEach(item => {
      xlsData.push([item.CleaningDate, item.AreaName, item.CleaningTypeName, item.AssignedToName, item.SupervisorName,
      item.TypeStateCode, HwUtils.HoursAndMinutesToMinutes(item.TypeTime)])
    })
    utils.sheet_add_aoa(ws, xlsData);
    writeFileXLSX(wb, this.translate.instant("menu.task_history") + ".xlsx");
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  updateFilterIcons() {

    this.dynamicControlDescriptor = [];
    this.tmpDynamicControlDescriptor = [];
    let desc: DynamicControlDescriptor = new DynamicControlDescriptor();
    //------------
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.TaskState;
    desc.parameters = [];
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.task_states") });
    desc.parameters.push({ Key: "Created", Value: this.filterForm.controls["fTaskStateCreated"].value.toString() });
    desc.parameters.push({ Key: "Running", Value: this.filterForm.controls["fTaskStateRunning"].value.toString() });
    desc.parameters.push({ Key: "Paused", Value: this.filterForm.controls["fTaskStatePaused"].value.toString() });
    desc.parameters.push({ Key: "Ended", Value: this.filterForm.controls["fTaskStateEnded"].value.toString() });
    desc.parameters.push({ Key: "Deleted", Value: this.filterForm.controls["fTaskStateDeleted"].value.toString() });
    desc.parameters.push({ Key: "Accepted", Value: this.filterForm.controls["fTaskStateAccepted"].value.toString() });
    this.tmpDynamicControlDescriptor.push(desc);
    //--------------------
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.TaskType;
    desc.parameters = []
    desc.parameters.push({ Key: "Label", Value: this.translate.instant("common.group") });
    switch (this.filterForm.controls["fItemType"].value) {
      case "-1": {
        desc.parameters.push({ Key: "Cleaning", Value: 'true' });
        desc.parameters.push({ Key: "Maintenance", Value: 'true' });
      }
        break;
      case "0": {
        desc.parameters.push({ Key: "Cleaning", Value: 'true' });
        desc.parameters.push({ Key: "Maintenance", Value: 'false' });
      }
        break;
      case "1": {
        desc.parameters.push({ Key: "Maintenance", Value: 'true' });
        desc.parameters.push({ Key: "Cleaning", Value: 'false' });
      }
        break;
    }
    this.tmpDynamicControlDescriptor.push(desc);
    //----------------------

    if (this.filterForm.controls["fRoomNumber"].value !== "") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-door", Value: this.filterForm.controls["fRoomNumber"].value });
      this.tmpDynamicControlDescriptor.push(desc);

    }
    //----------------------


    //----------------------
    desc = new DynamicControlDescriptor();
    desc.controlType = ChipsTypes.MultiIcon;
    desc.parameters = []

    // else {
    //    desc.parameters.push({Key:"", Value:this.translations["common"]["all_task"]});
    // }
    this.tmpDynamicControlDescriptor.push(desc);
    //----------------------

    if (this.filterForm.controls["fAssignedTo"].value !== "-1") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = []
      desc.parameters.push({ Key: 'mdi mdi-clipboard-account', Value: this.kipUsers.find(item => item.UserId == parseInt(this.filterForm.controls["fAssignedTo"].value)).RealName });
      this.tmpDynamicControlDescriptor.push(desc);
    }

    //---------------------------
    if (this.filterForm.controls["fSupervisor"].value !== "-1") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-account-voice", Value: this.supervisors.find(item => item.UserId == this.filterForm.controls["fSupervisor"].value).RealName });
      this.tmpDynamicControlDescriptor.push(desc);

    }

    if (this.filterForm.controls["fPublicAreaCode"].value !== "-1" && this.filterForm.controls["fPublicAreaCode"].value !== "-2") {
      desc = new DynamicControlDescriptor();
      desc.controlType = ChipsTypes.IconValue;
      desc.parameters = [];
      desc.parameters.push({ Key: "mdi mdi-door", Value: this.filterForm.controls["fPublicAreaCode"].value });
      this.tmpDynamicControlDescriptor.push(desc);
    }
    this.dynamicControlDescriptor = [...this.tmpDynamicControlDescriptor];

  }

  fCleaningAllToggle(event: MatCheckboxChange) {
    const allSelected:string[] = this.filterForm.controls["fTaskType"].value;
    const maintenanceItems:string[] = allSelected.filter(item=>item.substring(0,1)=="m")
    if(event.checked) {
      this.filterForm.controls["fTaskType"].value
      let cleanings: string[]=[];
      this.cleaningTypes.forEach(item=>{
        cleanings.push("c_"+item.TypeId)
       })
       this.filterForm.controls["fTaskType"].patchValue([...cleanings, ...maintenanceItems])
     } else {
      this.filterForm.controls["fTaskType"].patchValue([...maintenanceItems])
    }
    //this.applyFilter();
  }
  
  fMaintenanceAllToggle(event: MatCheckboxChange) {
    const allSelected:string[] = this.filterForm.controls["fTaskType"].value;
    const cleaningItems:string[] = allSelected.filter(item=>item.substring(0,1)=="c")
    if(event.checked) {
      this.filterForm.controls["fTaskType"].value
      let maintenances: string[]=[];
      this.maintenanceTypes.forEach(item=>{
        maintenances.push("m_"+item.TypeId)
       })
       this.filterForm.controls["fTaskType"].patchValue([...maintenances, ...cleaningItems])
     } else {
      this.filterForm.controls["fTaskType"].patchValue([...cleaningItems])
    }
    //this.applyFilter();
  }

  selectionChanged(event) {
    // ha az összes  cleaninges ki van jelölve
    if( (event as Array<string>).filter(item=>item.substring(0,1)=='c').length !== this.cleaningTypes.length) {
        this.filterForm.controls["fAllCleaning"].patchValue(false)
    } 
    if( (event as Array<string>).filter(item=>item.substring(0,1)=='m').length !== this.maintenanceTypes.length) {
      this.filterForm.controls["fAllMaintenance"].patchValue(false)
  } 

   
  }

  applyFilter() {
    this.sumTime = 0;
    const param: vwTaskListFilter = new vwTaskListFilter();
    param.AssignedTo = this.filterForm.controls["fAssignedTo"].value;
    param.EndDate = dayjs(this.filterForm.controls["fDateTo"].value).format("YYYY.MM.DD");
    param.StartDate = dayjs(this.filterForm.controls["fDateFrom"].value).format("YYYY.MM.DD");
    param.RoomNumber = this.filterForm.controls["fRoomNumber"].value;
    param.ItemType = this.filterForm.controls["fItemType"].value;
    param.MyTasksOnly = false;
    param.PublicAreaCode = this.filterForm.controls["fPublicAreaCode"].value;
    param.Supervisor = this.filterForm.controls["fSupervisor"].value;
    param.TaskStateAccepted = this.filterForm.controls["fTaskStateAccepted"].value;
    param.TaskStateCreated = this.filterForm.controls["fTaskStateCreated"].value;
    param.TaskStateDeleted = this.filterForm.controls["fTaskStateDeleted"].value;
    param.TaskStateEnded = this.filterForm.controls["fTaskStateEnded"].value;
    param.TaskStatePaused = this.filterForm.controls["fTaskStatePaused"].value;
    param.TaskStateRunning = this.filterForm.controls["fTaskStateRunning"].value;

    if(JSON.parse(this.filterForm.controls['fAllCleaning'].value))  {
      param.CleaningTypes = 'all'  
    } else {
      const tmpArr = (this.filterForm.controls['fTaskType'].value as string[]).filter(item=>item.substring(0,1)=="c")
      tmpArr.forEach((item,index)=> {
        tmpArr[index] = item.split("_")[1];
      })
      param.CleaningTypes = tmpArr.join(',')
    }
    
    if(JSON.parse(this.filterForm.controls['fAllMaintenance'].value))  {
      param.MaintenanceTypes = 'all'  
    } else {
      const tmpArr = (this.filterForm.controls['fTaskType'].value as string[]).filter(item=>item.substring(0,1)=="m")
      tmpArr.forEach((item,index)=> {
        tmpArr[index] = item.split("_")[1];
      })
      param.MaintenanceTypes = tmpArr.join(',')
    }
    
    
    this.spinnerService.setSpinnerVisibility(true);
    this.loadTaskHistory$ = this.dailyTaskService.loadTaskHistory(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId(), param).subscribe(result => {

        const res = result as RequestResult;
        let tmpData: vwTaskReport[] = [];
        tmpData = res.Data as vwTaskReport[];
        tmpData.forEach(item => {
          this.sumTime = this.sumTime + HwUtils.HoursAndMinutesToMinutes(item.TypeTime);
          const hourPart = HwUtils.MinutesToHoursAndMinutes(this.sumTime).split(":")[0];
          const minutePart = HwUtils.MinutesToHoursAndMinutes(this.sumTime).split(":")[1];

          this.sumTimeString = this.translate.instant("common.sum") + " : " + hourPart + " "
            + this.translate.instant("common.hour") + " " + minutePart + " " + this.translate.instant("common.minute");

          item.AreaName = item.PublicAreaName === '' ? item.RoomNumber : item.PublicAreaName;
          switch (item.TypeStateCode) {
            case TaskStates.Accepted: {
              item.TypeStateCode = this.translate.instant("common.inspected");
              break;
            }
            case TaskStates.Created: {
              item.TypeStateCode = this.translate.instant("common.created");
              break;
            }
            case TaskStates.Deleted: {
              item.TypeStateCode = this.translate.instant("common.deleted");
              break;
            }
            case TaskStates.Ended: {
              item.TypeStateCode = this.translate.instant("common.ended");
              break;
            }
            case TaskStates.Paused: {
              item.TypeStateCode = this.translate.instant("common.paused");
              break;
            }
            case TaskStates.Running: {
              item.TypeStateCode = this.translate.instant("common.in_progress");
              break;
            }
          }
        })

        this.dataSource.data = [...tmpData]
        this.gridData = tmpData;

        this.spinnerService.setSpinnerVisibility(false);
      })
  }
  filterChanged() {

  }
  itemTypeChanged() {
    let sources = [
      this.cleaningTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      this.maintenanceTypeService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
    ];
    this.taskTypes = [];
    switch (this.filterForm.controls["fItemType"].value) {
      case "-1":
        forkJoin(sources).subscribe(([cleanigTypes, maintenanceTypes]) => {
          this.cleaningTypes = (cleanigTypes as RequestResult).Data as CleaningType[]
          this.maintenanceTypes = (maintenanceTypes as RequestResult).Data as MaintenanceType[]
          this.cleaningTypes.forEach(ct => {
            this.taskTypes.push({ value: ct.TypeId, label: ct.TypeName })
          })

          this.maintenanceTypes.forEach(ct => {
            this.taskTypes.push({ value: ct.TypeId, label: ct.TypeName })
          })
          this.resetTaskTypes()
        })
        break;
      case "0": //HK
        sources[0].subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.cleaningTypes = res.Data as CleaningType[]
            this.cleaningTypes.forEach(ct => {
              this.taskTypes.push({ value: ct.TypeId, label: ct.TypeName })
            })
          }
          this.resetTaskTypes()
        })
        break;
      case "1": //MAINT
        sources[1].subscribe(result => {
          const res = result as RequestResult;
          if (res.Code == 0) {
            this.maintenanceTypes = res.Data as MaintenanceType[];
            this.maintenanceTypes.forEach(ct => {
              this.taskTypes.push({ value: ct.TypeId, label: ct.TypeName });
            })
          }
          this.resetTaskTypes()
        })
        break;
    }
    this.updateFilterIcons()
  }
  init() {
    this.loadAllKipUsers$ = this.kipUserService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(event => {
      this.kipUsers = event.Data as Array<KipUser>;
      const allUsers = { UserId: -1, UserName: this.translate.instant("common.all"), Subscriber: '', HotelId: 0, UserCode: '', UserUuId: '', Data: '', RealName: this.translate.instant("common.all"), Active: true }
      this.kipUsers.splice(0, 0, allUsers);

      this.kipUsers.forEach(kipUser => {
        if (this.roleService.isUserInRole(kipUser.UserName, RoleType.GONDNOKNO) ||
          this.roleService.isUserInRole(kipUser.UserName, RoleType.MUSZAKI_VEZETO)) {
          this.supervisors.push(JSON.parse(JSON.stringify(kipUser)));
        }
      })
      this.supervisors.splice(0, 0, allUsers);
      this.supervisors = JSON.parse(JSON.stringify(this.supervisors as Array<KipUser>));
      this.filterForm.controls["fAssignedTo"].setValue(-1);
      this.filterForm.controls["fSupervisor"].setValue(-1);

      this.loadPublicAreas$ = this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
        const res = result as RequestResult;
        this.publicAreas = res.Data as PublicArea[];

        this.publicAreas.unshift({
          PublicAreaId: -1,
          AreaName: this.translate.instant("common.all"),
          Active: true,
          HotelId: 0,
          AreaCode: '-1',
          Subscriber: ''
        });
        this.publicAreas.unshift({
          PublicAreaId: -2,
          AreaName: this.translate.instant("common.no_selected_item"),
          Active: true,
          HotelId: 0,
          AreaCode: '-2',
          Subscriber: ''
        })
        this.filterForm.controls["fPublicAreaCode"].setValue("-2")
      })
      this.loadFroDate$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
        const res = result as RequestResult;
        this.froDate = res.Data;
        // set date combo
        let fr = dayjs(this.froDate, 'YYYY.MM.DD');
        // const startOfMonth = dayjs(fr).startOf('month').format('YYYY.MM.DD');
        // const endOfMonth =  dayjs(fr).endOf('month').format('YYYY.MM.DD');
        this.filterForm.controls["fDateFrom"].setValue(fr);
        this.filterForm.controls["fDateTo"].setValue(fr);
        this.updateFilterIcons();
        this.applyFilter();
      })
      this.itemTypeChanged();

    })

  }

  cleaingTypeId( id ) : string{
    return "c_" + id;
  }
  maintenanceTypeId(id) : string {
    return "m_" + id;
  }
  sortData(sortState: Sort) {
    switch (sortState.direction) {
      case 'asc':
        this.sort.direction = 'asc';
        break;
      case 'desc':
        this.sort.direction = 'desc';
        break;
      default:
        this.sort.direction = 'asc';
    }
    this.dataSource.sort = this.sort;
  }

  selectAllTaskStatus() {
    this.filterForm.controls["fTaskStateCreated"].setValue(true);
    this.filterForm.controls["fTaskStateRunning"].setValue(true);
    this.filterForm.controls["fTaskStateRunning"].setValue(true);
    this.filterForm.controls["fTaskStatePaused"].setValue(true);
    this.filterForm.controls["fTaskStateEnded"].setValue(true);
    this.filterForm.controls["fTaskStateDeleted"].setValue(true);
    this.filterForm.controls["fTaskStateAccepted"].setValue(true);
    this.filterChanged();
  }

  resetTaskTypes() {
    this.filterForm.controls["fTaskType"].patchValue([]);
    this.filterForm.controls["fAllCleaning"].patchValue(false);
    this.filterForm.controls["fAllMaintenance"].patchValue(false);
  }
  //#endregion =================================================================================================================
}


/*

 if(cleaningra kattintott) {

 
     if( van allCleaning a tömbben ) {
      allcleaning kivételével maradhat minden
     } else 
        igen => kiszedni 
        nem => az összes elemet betenni a tömbbe 

        select all cleaning

    }
*/